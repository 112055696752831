<template>
  <el-dialog title="配置发送邮箱" :visible.sync="visible" :before-close="close" :close-on-click-modal="false" width="30%">
    <el-form :model="form" :rules="rules" ref="form" label-width="200px">
      <el-form-item label="发送者邮箱及验证账号" prop="from">
        <el-input v-model="form.from" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="发送服务器" prop="host">
        <el-input v-model="form.host" placeholder="例如：smtp.163.com"></el-input>
      </el-form-item>
      <el-form-item label="端口" prop="port">
        <el-input v-model="form.port" placeholder="例如 163服务器的 25 或465"></el-input>
      </el-form-item>
      <el-form-item label="发送邮件的验证密码" prop="pwd">
        <el-input v-model="form.pwd" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="是否启用SSL" prop="enableSSL">
        <vxe-switch
          open-label="是"
          close-label="否"
          v-model="form.enableSSL"
          :close-value="false"
          :open-value="true">
        </vxe-switch>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {warningManageSetEmail} from '@/api/system'
const initData = {
  pwd: '',
  port: '',
  host: '',
  from: '',
  enableSSL: true
}
export default {
  name: 'WarningManageEmail',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      form: {...initData},
      rules: {
        from: { required: true, message: '请输入', trigger: 'blur' },
        host: { required: true, message: '请输入', trigger: 'blur' },
        port: { required: true, message: '请输入', trigger: 'blur' },
        pwd: { required: true, message: '请输入', trigger: 'blur' },
      },
      loading: false
    }
  },
  mounted () {},
  watch: {
    visible (value) {
      if (value) {
        this.form = this.items
      }
    }
  },
  methods: {
    close () {
      this.form = {...initData}
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = false
          warningManageSetEmail(this.form).then(res => {
            this.loading = false
            this.$message.success(res.message)
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
