<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-for="item in dict.PrintType" :key="item.value" :label="item.displayName" :name="item.value.toString()"></el-tab-pane>
    </el-tabs>
    <div style="text-align: right">
      <el-button type="primary" @click="visiblePrintWorld = true" v-hasPermi="['print:printTmp:version']">设置打印控件版本</el-button>
      <el-button type="primary" @click="visible = true" v-hasPermi="['print:printTmp:add']">新增</el-button>
    </div>
    <my-table :data="tableData" ref="myTable">
      <vxe-table-column title="标题" field="title"></vxe-table-column>
      <vxe-table-column title="打印分类" field="printTypeName"></vxe-table-column>
      <vxe-table-column title="模板连接" field="templateLink" min-width="300px">
      </vxe-table-column>
      <vxe-table-column title="默认" width="100px">
        <template #default="{row}">
          <vxe-switch
            open-label="是"
            close-label="否"
            :value="row.defaultSelection"
            :close-value="false"
            @change="changeDefaultTmp($event, row)"
            :open-value="true">
          </vxe-switch>
        </template>
      </vxe-table-column>
      <vxe-table-column title="创建时间" field="createdTime"></vxe-table-column>
      <vxe-table-column title="备注" field="remarks"></vxe-table-column>
      <vxe-table-column title="操作" width="200">
        <template #default="{row}">
          <div class="flex-h">
            <el-button type="text" @click="del(row)" v-hasPermi="['print:printTmp:del']">删除</el-button>
            <el-button type="text" @click="edit(row)" v-hasPermi="['print:printTmp:edit']">编辑</el-button>
            <el-button type="text" @click="eye(row)">预览</el-button>
          </div>
        </template>
      </vxe-table-column>
    </my-table>
    <print-tmp-edit :visible.sync="visible" :items.sync="items" @refresh="refresh"/>
    <print-world-verison :visible.sync="visiblePrintWorld"/>
  </div>
</template>

<script>
import {printTmpList, /* printTmpEdit */ printDefaultTmp, printTmpDel} from '@/api/system'
import MyUploadFile from '@/components/MyUploadFile'
import { mapGetters } from 'vuex'
import PrintTmpEdit from './components/PrintTmpEdit'
import {chargePrintView, arrearsPrintView} from '@/api/print'
import {printTmp} from '@/utils/tools'
import PrintWorldVerison from './components/PrintWorldVerison'
export default {
  name: 'printTmp',
  components: {
    MyUploadFile,
    PrintTmpEdit,
    PrintWorldVerison
  },
  data () {
    return {
      activeName: '1',
      tableData: [],
      visible: false,
      visiblePrintWorld: false,
      items: {}
    }
  },
  computed: {
    ...mapGetters(['dict'])
  },
  mounted () {
    this.getPrintTmpList()
  },
  methods: {
    // input (value, row) {
    //   row.templateLink = this.url + value
    // },
    getPrintTmpList () {
      printTmpList({type: this.activeName}).then(res => {
        this.tableData = res.data
      })
    },
    // save (row) {
    //   printTmpEdit({printType: row.printType, templateLink: row.templateLink}).then(res => {
    //     this.$message.success(res.message)
    //   })
    // },
    handleClick() {
      this.getPrintTmpList()
    },
    refresh () {
      this.getPrintTmpList()
    },
    changeDefaultTmp (value, row) {
      printDefaultTmp({id: row.id}).then(res => {
        this.$message.success(res.message)
        this.getPrintTmpList()
      })
    },
    edit (row) {
      this.items = row
      this.visible = true
    },
    del (row) {
      if (row.defaultSelection) {
        this.$message.error('当前是默认模板，不能删除！')
        return
      }
      this.$confirm(`是否删除模板<span style="color: #da251c">${row.title}</span>`, '提示', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
      }).then(() => {
        printTmpDel({id: row.id}).then(res => {
          this.$message.success(res.message)
          this.getPrintTmpList()
        })
      })
    },
    eye (row) {
      // 单户收费是printType===1，欠费催收printType===6
      if (row.printType === 1) {
        chargePrintView({printTemplateLink: row.templateLink}).then(res => {
          const {data} = res
          printTmp({tmpUrl: data.printTemplateLink, action: 'preview', jsonData: data.paymentDatas, call: false})
        })
      } else {
        arrearsPrintView({printTemplateLink: row.templateLink}).then(res => {
          const {data} = res
          printTmp({tmpUrl: data.printTemplateLink, action: 'preview', jsonData: data.paymentDatas, call: false})
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .vxe-cell .el-upload--picture-card {
  border: none;
}
</style>
