/* 项目 */
<template>
  <el-dialog :visible.sync="visible" :append-to-body="true" :before-close="close" :close-on-click-modal="false">
    <my-search v-if="visible" :items="searchItems" @search="search" @clear="clear"/>
    <my-table
      v-if="visible"
      :selection="selection"
      :show-index="false"
      ref="myTable"
      rowkey=""
      @changeSelect="changeSelect"
      :columns="columns"
      url="/Project/List">
      <vxe-table-column title="操作" v-if="!selection">
        <template #default="{row}">
          <el-button type="text" :loading="row.loading" @click="select(row)">选择</el-button>
        </template>
      </vxe-table-column>
    </my-table>
    <div slot="footer" v-if="selection">
      <el-button @click="close">取消</el-button>
      <el-button @click="submit" type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'areaSelect',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    selection: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      columns: [
        {
          label: '编号',
          prop: 'id'
        },
        {
          label: '项目名称',
          prop: 'name',
        },
        {
          label: '项目类型',
          prop: 'projectTypesName'
        },
        {
          label: '公司名称',
          prop: 'companyName',
          sort: true
        },
        {
          label: '状态',
          prop: 'statusName',
          operate: true,
          render: (h, {row}) => {
            let type = ''
            if (row.status) {
              type = {
                1: 'success',
                2: 'danger',
                3: 'info'
              }[row.status]
            }
            return h('el-tag', {
              props: {
                type: type
              }
            }, row.statusName)
          }
        }
      ],
      checkedRows: [],
      queryData: {}
    }
  },
  computed: {
    ...mapGetters(['dict']),
    searchItems () {
      return [
        {type: 'input', label: '公司名称：', name: 'companyName'},
        {type: 'input', label: '项目名称：', name: 'name'},
        {type: 'select', label: '状态：', name: 'status', options: this.dict.ProjectEntityStatus},
      ]
    }
  },
  mounted () {},
  methods: {
    changeSelect (data) {
      this.checkedRows = data.map(x => {
        return {
          id: x.id,
          name: x.name
        }
      })
    },
    refresh () {
      this.$refs.myTable.refresh(this.queryData)
    },
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    close () {
      this.$emit('update:visible', false)
    },
    select (item) {
      this.$emit('select', {id: item.id, name: item.name})
      this.close()
    },
    submit () {
      this.$emit('select', {ids: this.checkedRows.map(x => x.id), names: this.checkedRows.map(x => x.name)})
      this.close()
    }
  }
}
</script>

<style scoped lang="scss">

</style>
