<!-- 车位信息 -->
<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="close" :close-on-click-modal="false" v-el-drag-dialog>
    <el-descriptions :column="3" border :label-style="{width: '15%'}" :content-style="{width: '18%'}">
      <el-descriptions-item label="所属车场">{{items.parkingLotName}}</el-descriptions-item>
      <el-descriptions-item label="所属区域">{{items.parkingLotAreaName}}</el-descriptions-item>
      <el-descriptions-item label="车位编号">{{items.carplaceNumber}}</el-descriptions-item>
      <el-descriptions-item label="拥有者">{{items.ownerName}}</el-descriptions-item>
      <el-descriptions-item label="手机号">{{items.phone}}</el-descriptions-item>
      <el-descriptions-item label="收费项" v-if="items.bindChargeItems">{{items.bindChargeItems.join('、')}}</el-descriptions-item>
      <el-descriptions-item label="收费项目数量">{{items.chargeItemCount}}</el-descriptions-item>
      <el-descriptions-item label="缴费开始时间">{{items.chargeStart}}</el-descriptions-item>
      <el-descriptions-item label="缴费到期时间">{{items.chargeEnd}}</el-descriptions-item>
      <el-descriptions-item label="展示类型">{{items.carPlaceShowTypeName}}</el-descriptions-item>
      <el-descriptions-item label="是否固定车位">{{items.carPlaceTypeName	}}</el-descriptions-item>
      <el-descriptions-item label="车位类型">{{items.carPlaceShowTypeName}}</el-descriptions-item>
      <el-descriptions-item label="车位状态">{{items.carPlaceStatusName}}</el-descriptions-item>
      <el-descriptions-item label="车位数">{{items.carCount}}</el-descriptions-item>
      <el-descriptions-item label="面积">{{items.area}}</el-descriptions-item>
      <el-descriptions-item label="备注">{{items.remark}}</el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</template>

<script>
export default {
  name: 'CarPlaceDetail',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {

    }
  },
  computed: {
    title () {
      return this.items.carplaceNumber + '车位信息'
    }
  },
  mounted () {},
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
    }
  }
}
</script>

<style scoped lang="scss">

</style>
