<template>
  <el-dialog :title="title" :before-close="close" width="30%" :visible.sync="visible">
    <el-form :model="form" ref="form" label-width="100px" :rules="rules">
      <el-form-item label="所属项目" prop="name" v-if="$route.name === 'areaConfig1'">
        <el-cascader
          style="width: 100%"
          :props="{value: 'id', label: 'name', children: 'child', emitPath: false}"
          filterable
          clearable
          :options="projectList"
          collapse-tags
          v-model="form.projectId">
        </el-cascader>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" type="textarea"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {parkingLotEdit} from '@/api/project'
import {companyProjectTree} from '@/api/system'

export default {
  name: 'ParkingLotEdit',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      loading: false,
      form: {},
      rules: {
        name: [{ required: true, trigger: "blur", message: "请输入" }],
      },
      projectList: []
    }
  },
  computed: {
    title () {
      return this.items.id ? '编辑停车场' : '新增停车场'
    },
    query () {
      return this.$route.query || {}
    }
  },
  watch: {
    visible (value) {
      if (value) {
        this.form = this.items.id ? {
          name: this.items.name,
          remark: this.items.remark,
          id: this.items.id,
          projectId: this.items.projectId
        } : {
          name: ''
        }
      }
    }
  },
  mounted() {
    this.getCompanyProject()
  },
  methods: {
    getCompanyProject () {
      companyProjectTree({types: [1, 2, 3]}).then(res => {
        this.projectList = res.data.map(x => {
          return {
            ...x,
            child: x.child.map(y => {
              return {
                ...y,
                disabled: y.id === this.items.disabledId
              }
            })
          }
        })
      })
    },
    close () {
      this.$emit('update:visible', false)
      this.form = {}
      this.$refs.form.resetFields()
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          // 新增停车场，有2个地方，1、项目管理->项目配置，2、项目配置，项目管理下的项目配置添加停车场是跳转页面，添加了query的，所有判断有没有query
          if (this.query.projectId) {
            this.form.projectId = this.query.projectId
          }
          parkingLotEdit(this.form).then(res => {
            this.$message.success(res.message)
            this.loading = false
            this.$emit('refresh')
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>