<!-- 运营平台角色管理新增/编辑 -->
<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="close" :close-on-click-modal="false" fullscreen>
    <el-form :model="form" :rules="rules" ref="form" label-width="100px">
      <el-row>
        <el-col :span="6">
          <el-form-item label="角色名" prop="name">
            <el-input v-model="form.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="form.remarks" type="textarea" :autosize="{minRows: 2}" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="18">
          <el-form-item label="权限分配">
            <div style="margin-bottom: 10px;">
              <el-button type="primary" @click="checkAll = true">全选</el-button>
              <el-button type="primary" v-if="!form.id" @click="checkAll = false">取消全选</el-button>
              <el-button type="primary" v-if="form.id" @click="getMenu">重置默认</el-button>
              <el-checkbox style="margin-left: 10px;" v-model="menuCheckStrictly" @change="handleCheckedTreeConnect($event)">父子联动</el-checkbox>
            </div>
            <vxe-table
              border
              ref="xTable"
              size="small"
              :data="treeData"
              :row-config="{isCurrent: true, keyField: 'id'}"
              empty-text="暂无数据"
              :tree-config="{children: 'child', reserve: true, trigger: 'row', indent: 25, expandAll: true}">
              <vxe-table-column title="菜单目录" tree-node align="left" field="name" width="270px">
                <template #default="{row}">
                  <el-checkbox
                    @change="changeCheckEvent($event, row)"
                    v-model="row.isSelected"
                    :indeterminate="row.isIndeterminate"
                    :key="row.id">
                    {{row.name}}
                  </el-checkbox>
                </template>
              </vxe-table-column>
              <vxe-table-column title="按钮权限" align="left" field="hasPermissionGroup">
                <template #default="{row}">
                  <el-checkbox
                    v-model="item.isSelected"
                    @change="changeCheckEvent1($event, row)"
                    v-for="(item, index) in row.hasPermissionGroup"
                    :key="index">
                    {{item.buttonName}}
                  </el-checkbox>
                </template>
              </vxe-table-column>
            </vxe-table>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {operateMenu, adminRolesEdit} from '@/api/system'
import {getAllParentArr} from '@/utils/tools'

const initData = {
  name: '',
  remarks: '',
  // functionIds: ''
}
export default {
  name: 'AdminRolesEdit',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      form: {...initData},
      rules: {
        name: { required: true, message: '请输入', trigger: 'blur' },
      },
      loading: false,
      menuList: [],
      checkAll: false,
      menuCheckStrictly: false,
    }
  },
  watch: {
    visible (value) {
      if (value) {
        this.getMenu()
        if (this.items.id !== '0') {
          this.form = {
            name: this.items.name,
            remarks: this.items.remarks,
            id: this.items.id
          }
        }
      }
    }
  },
  computed: {
    title () {
      return this.items.id ? this.items.nam : '新增角色'
    },
    treeData () {
      return this.generator(this.menuList)
    },
    checkedKeys () {
      if (this.treeData.length > 0) {
        return this.reduceDimension(this.treeData).filter(x => x.isSelected || x.isIndeterminate).map(x => x.functionId)
      }
      return []
    },
  },
  mounted () {},
  methods: {
    generator (arr = []) {
      return arr.map(item => {
        const data = {
          ...item,
          isIndeterminate: false,
          isSelected: item.isSelected || this.checkAll
        }
        if (item.child && item.child.length > 0 && item.child[0].functionType !== 3) {
          data.child = this.generator(item.child)
        } else {
          data.hasPermissionGroup = data.child?.map(y => {
            return {
              buttonName: y.name,
              functionId: y.functionId,
              functionType: y.functionType,
              functionTypeName: y.functionTypeName,
              // eslint-disable-next-line no-nested-ternary
              isSelected: this.checkAll ? this.checkAll : y.isSelected,
              sortNumber: y.sortNumber,
              status: y.status,
              statusName: y.statusName,
              id: y.id
            }
          })
          delete data.child
        }
        return data
      })
    },
    changeCheckEvent (value, row) {
      if (!this.menuCheckStrictly) {
        return
      }
      // 点击二级设置子全选
      if (row.child && row.child.length > 0) {
        row.isIndeterminate = false
        row.child.forEach(item => {
          console.log(11, row, item)
          item.isSelected = value
          if (item.hasPermissionGroup && item.hasPermissionGroup.length > 0) {
            item.hasPermissionGroup.forEach(x => {
              x.isSelected = value
            })
          } else {
            this.changeCheckEvent(value, item)
          }
        })
      } else {
        console.log(81812, row)
        // 点击三级设置子全选
        row.hasPermissionGroup.forEach(item => {
          item.isSelected = value
        })
      }
      this.setCheck(this.treeData, row, value)
    },
    changeCheckEvent1 (value, row) {
      if (!this.menuCheckStrictly) {
        return
      }
      console.log(row, 'aaaa')
      if (row.hasPermissionGroup.length === row.hasPermissionGroup.filter(x => x.isSelected).length) {
        row.isSelected = true
        row.isIndeterminate = false
      } else {
        row.isSelected = false
        row.isIndeterminate = row.hasPermissionGroup.filter(x => x.isSelected).length !== 0
      }
      this.setCheck(this.treeData, row, value)
    },
    setCheck (treeData, row, value) {
      // 点击子集设置父级全选,数组第一个是点击的本身，第二个是父级，第三个是父级的父级，依次类推
      const parentList = getAllParentArr(treeData, row.functionId, 'functionId')
      console.log(parentList, 12)
      parentList.forEach(item => {
        let parentCheck = []
        // 排除设置子集，按钮组
        if (!item.hasPermissionGroup && !item.isSelected) {
          parentCheck = item.child.filter(x => x.isSelected)
          // 选中的和child长度相同，判断全选
          if (parentCheck.length === item.child.length) {
            item.isSelected = true
            item.isIndeterminate = false
          } else {
            item.isSelected = false
            // item.isIndeterminate = true
            if (parentCheck.length !== 0) {
              item.isIndeterminate = true
            }
            if (parentCheck.length === 0) {
              item.isIndeterminate = false
            }
            // item.isIndeterminate = childNoChick.length !== 0
            console.log(item, 99, parentCheck)
          }
        } else {
          let childNoChick = item.child?.filter(x => x.isSelected) || []
          if (!item.hasPermissionGroup) {
            // 有子集不选中，设置最上级不选中
            if (childNoChick.length !== item.child?.length) {
              item.isIndeterminate = true
            } else {
              item.isIndeterminate = false
            }
            item.isSelected = value
          } else {
            item.isSelected = value
          }
        }
      })
    },
    // 树权限（父子联动）
    handleCheckedTreeConnect(value) {
      this.menuCheckStrictly = !!value
    },
    reduceDimension (arr = []) {
      let ret = []
      let toArr = arr => {
        arr.forEach(item => {
          toArr(item.child && item.child.length > 0 ? item.child : (item.hasPermissionGroup || []))
          ret.push(item)
        })
      }
      toArr(arr)
      console.log(ret, 'wx')
      return ret
    },
    getMenu () {
      operateMenu({roleId: this.items.id}).then(res => {
        this.menuList = res.data
      })
    },
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {id: '0'})
      this.form = {...initData}
      this.checkAll = false
      this.menuCheckStrictly = false
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          adminRolesEdit({...this.form, functionIds: this.checkedKeys}).then(res => {
            this.$message.success(res.message)
            this.loading = false
            this.$emit('refresh')
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
