/* 公告 */
<template>
  <div>
    <my-search :items="searchItems" @search="search" @clear="clear">
      <el-button slot="btn" type="primary" @click="$router.push('/noticeEdit')" v-hasPermi="['system:notice:add']">新增</el-button>
    </my-search>
    <my-table :columns="columns" ref="myTable" url="/SystemNotice/GetNoticesPagePlatfromPagerList">
      <vxe-table-column title="操作" width="200">
        <template #default="{row}">
          <el-button type="text" @click="edit(row)" v-hasPermi="['system:notice:edit']">编辑</el-button>
          <el-button type="text" @click="del(row)" v-hasPermi="['system:notice:del']">删除</el-button>
          <el-button type="text" @click="wxPush(row)" v-hasPermi="['system:notice:pushWx']">推送微信</el-button>
        </template>
      </vxe-table-column>
    </my-table>
  </div>
</template>

<script>
import {noticeDel, noticeWxPush} from '@/api/system'
import { mapGetters } from 'vuex'

export default {
  name: 'notice',
  data () {
    return {
      columns: [
        {
          label: '公告标题',
          prop: 'title',
          operate: true,
          render: (h, {row}) => {
            return h('div', {
              style: {
                color: row.titleColor
              }
            }, row.title)
          }
        },
        {
          label: '公告类型',
          prop: 'noticeTypeName'
        },
        {
          label: '阅读量',
          prop: 'readCount'
        },
        {
          label: '是否置顶',
          prop: 'isTopName'
        },
        {
          label: '强制阅读',
          prop: 'isMustRedName'
        },
        {
          label: '发布状态',
          prop: 'publishStatusName'
        },
        {
          label: '创建人',
          prop: 'createdName'
        },
        {
          label: '发布时间',
          prop: 'startTime'
        },
        {
          label: '结束时间',
          prop: 'endTime'
        },
        {
          label: '创建时间',
          prop: 'createdTime'
        },
      ],
      queryData: {}
    }
  },
  computed: {
    ...mapGetters(['dict']),
    searchItems () {
      const {PublishStatus, NoticeType, /* NoticeReceivingObject */} = this.dict
      return [
        {type: 'input', label: '公告标题：', name: 'title'},
        {type: 'select', label: '公告类型：', name: 'noticeType', options: NoticeType},
        {type: 'select', label: '发布状态：', name: 'publishStatus', options: PublishStatus},
        // {type: 'select', label: '通知对象：', name: 'receivingObject', options: NoticeReceivingObject},
        {type: 'select', label: '是否置顶：', name: 'isTop', options: [{displayName: '是', value: true}, {displayName: '否', value: false}]},
        {type: 'select', label: '强制阅读：', name: 'isMustRed', options: [{displayName: '是', value: true}, {displayName: '否', value: false}]},
      ]
    }
  },
  mounted () {},
  methods: {
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    del (row) {
      this.$confirm(`确定删除<span style="color: #da251c">${row.title}</span>`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        noticeDel({id: row.id}).then(res => {
          this.$message.success(res.message)
          this.refresh()
        })
      })
    },
    refresh () {
      this.$refs.myTable.refresh(this.queryData)

    },
    edit (row) {
      this.$router.push({
        name: 'noticeEdit',
        query: {
          id: row.id
        }
      })
    },
    wxPush (row) {
      this.$confirm('确定将此公告推送到微信吗?', '提示', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        noticeWxPush({id: row.id}).then(res => {
          this.$message.success(res.data)
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
