import request from '@/utils/request'

export const login = data => {
  return request({
    url: '/SystemUser/Login',
    method: 'post',
    data: data
  })
}

export const logout = data => {
  return request({
    url: '/SystemUser/LoginOut',
    method: 'post',
    data: data
  })
}

export const userInfo = data => {
  return request({
    url: '/SystemUser/GetById',
    method: 'post',
    data
  })
}

export const userEdit = data => {
  return request({
    url: '/SystemUser/EditPersonal',
    method: 'post',
    data,
    loading: true
  })
}
export const msgRead = data => {
  return request({
    url: '/EmployeeMessage/UpdateReadStatus',
    method: 'post',
    data
  })
}

export const peopleList = data => {
  return request({
    url: '/SystemUser/GetUsersByCompany',
    method: 'post',
    data
  })
}

// 数据字典
export const sysModules = data => {
  return request({
    url: '/SystemDataDictionaty/GetSystemEnum',
    method: 'post',
    data
  })
}

// oss 校验签名
export const aliOssPolicy = params => {
  return request({
    url: '/Common/GeneratePostPolicy',
    method: 'get',
    params
  })
}

// oss删除
export const ossDel = params => {
  return request({
    url: '/Common/RemoveFile',
    method: 'get',
    params
  })
}

// 获取系统信息
export const systemConfig = data => {
  return request({
    url: '/Company/GetById',
    method: 'get',
    data
  })
}

// 修改密码
export const editPwd = data => {
  return request({
    url: '/SystemUser/ModifyPassWord',
    method: 'post',
    data,
  })
}