<template>
  <el-dialog title="购买记录" :visible.sync="visible" :before-close="close" :close-on-click-modal="false">
    <my-table v-if="visible" :columns="columns" auto-request ref="myTable" url="/SMSAccount/QuerySMSBuyLogPager" :query="{id: smsId}"></my-table>
  </el-dialog>
</template>

<script>
export default {
  name: 'SmsPayLogs',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    smsId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      columns: [
        {
          label: '购买时间',
          prop: 'createTime'
        },
        {
          label: '购买数量',
          prop: 'buyCount'
        },
        {
          label: '备注',
          prop: 'notes'
        },
      ]
    }
  },
  mounted () {},
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:smsId', '')
    }
  }
}
</script>

<style scoped lang="scss">

</style>
