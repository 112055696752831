<!-- 预警管理 -->
<template>
  <div>
    <my-search :items="searchItems" @search="search" @clear="clear" :hide-length="3">
      <div slot="btn">
        <el-button type="primary" @click="peopleConfig" v-hasPermi="['system:warningManage:people']">人员配置</el-button>
        <el-button type="primary" @click="emailConfig" v-hasPermi="['system:warningManage:sendEmail']">推送邮件配置</el-button>
      </div>
    </my-search>
    <my-table ref="myTable" :columns="columns" url="/EarlyWarning/QueryEarlyWarningLogPager" auto-request>
      <vxe-table-column title="公司名称" width="140" fixed="left">
        <template #default="{row}">{{ row.companyName }}{{ row.companyAlias }}</template>
      </vxe-table-column>
      <vxe-table-column title="项目名称" field="projectName" fixed="left" width="100"></vxe-table-column>
    </my-table>
    <warning-manage-email :visible.sync="visible" :items.sync="items"/>
    <warning-manage-people :visible.sync="visiblePeople"/>
  </div>
</template>

<script>
import {warningManageGetEmail} from '@/api/system'
import WarningManageEmail from './components/WarningManageEmail'
import WarningManagePeople from './components/WarningManagePeople'
import {companyList} from '@/api/project'
import { mapGetters } from 'vuex'

export default {
  name: 'warningManage',
  components: { WarningManageEmail, WarningManagePeople },
  data () {
    return {
      columns: [
        {
          label: '通知标题',
          prop: 'title',
          width: 200
        },
        {
          label: '通知内容',
          prop: 'content',
          minWidth: 300
        },
        {
          label: '预警类型',
          prop: 'earlyWarningTypeName',
          width: 130
        },
        {
          label: '创建日期',
          prop: 'createdTime',
          width: 180
        },
      ],
      visible: false,
      visiblePeople: false,
      items: {},
      projectList: []
    }
  },
  computed: {
    ...mapGetters(['dict', 'companyTree']),
    searchItems () {
      const {dict, companyTree, projectList} = this
      return [
        {
          type: 'selectTree',
          label: '公司名称/编号：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany,
          style: 'margin-right: 0'
        },
        {
          type: 'select',
          label: '',
          name: 'projectId',
          options: projectList
        },
        {type: 'input', label: '内容关键字：', name: 'keyWords'},
        {type: 'select', label: '预警类型：', name: 'earlyWarningType', options: dict.EarlyWarningType},
        {type: 'datePicker', label: '开始时间：', name: 'start'},
        {type: 'datePicker', label: '结束时间：', name: 'end'},
      ]
    }
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
  },
  methods: {
    search (query) {
      this.$refs.myTable.search(query)
    },
    clear () {
      this.$refs.myTable.reset()
    },
    emailConfig () {
      warningManageGetEmail().then(res => {
        this.items = res.data
        this.visible = true
      })
    },
    peopleConfig () {
      this.visiblePeople = true
    },
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    changeCompany (data) {
      this.$store.dispatch('getCompanyDownProject', {companyId: data.id, containsClosed: true}).then(res => {
        this.projectList = res
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
