const getters = {
  token: state => state.user.token,
  user: state => state.user.user,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions,
  routes: state => state.user.routes,
  addRouters: state => state.user.addRouters,
  menu: state => state.basic.menu,
  dict: state => state.basic.dict,
  companyTree: state => state.basic.companyTree,
  // projectList: state => state.basic.projectList,
}
export default getters