<!-- 车场道闸 -->
<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="tabClick">
      <el-tab-pane label="道闸队列" name="queue">
        <my-search :items="searchItemsQueue" v-if="activeName === 'queue'" @search="searchQueue" @clear="clearQueue"></my-search>
        <my-table ref="myTableQueue" url="/ParkinglotRoadgate/QueryCarRoadgateSyncQueuePager" auto-request>
          <vxe-table-column title="公司名称" width="140">
            <template #default="{row}">{{ row.companyName }}{{ row.companyAlias }}</template>
          </vxe-table-column>
          <vxe-table-column title="项目名称" field="projectName" width="100"></vxe-table-column>
          <vxe-table-column title="车场名称" field="parkingLotName" width="120"></vxe-table-column>
          <vxe-table-column title="车场区域" field="parkingAreaName" width="100"></vxe-table-column>
          <vxe-table-column title="车位车辆" field="roadgateResourceTypeBusinessName" min-width="140">
            <template #default="{row}">
              <el-link type="primary" @click="eye(row)">{{ row.roadgateResourceTypeBusinessName }}</el-link>
            </template>
          </vxe-table-column>
          <vxe-table-column title="同步类型" field="roadgateResourceTypeName" width="100"></vxe-table-column>
          <vxe-table-column title="同步指令" field="roadgateSyncInstructionTypeName" width="140"></vxe-table-column>
          <vxe-table-column title="执行次数" field="executeCount" width="80"></vxe-table-column>
          <vxe-table-column title="执行开始时间" field="executeDate" width="160"></vxe-table-column>
          <vxe-table-column title="执行结果" field="executeResult" min-width="200"></vxe-table-column>
          <vxe-table-column title="同步道闸停车开始日期" field="startTime" width="160"></vxe-table-column>
          <vxe-table-column title="同步道闸停车结束日期" field="endTime" width="160"></vxe-table-column>
          <vxe-table-column title="日志内容" field="logContents" min-width="300"></vxe-table-column>
          <vxe-table-column title="操作" width="230" fixed="right">
            <template #default="{row}">
              <el-button type="text" @click="cloud(row)" v-hasPermi="['system:carGate:cloud']" v-if="row.cloudSystemDomain !== null">云平台</el-button>
              <el-button type="text" @click="delQueue(row)" v-hasPermi="['system:carGate:delQueue']">删除队列</el-button>
              <el-button type="text" @click="implement(row)" v-hasPermi="['system:carGate:implement']">立即执行</el-button>
            </template>
          </vxe-table-column>
        </my-table>
      </el-tab-pane>
      <el-tab-pane label="道闸日志" name="logs">
        <my-search :items="searchItemsLogs" @search="searchLogs" v-if="activeName === 'logs'" @clear="clearLogs"></my-search>
        <my-table ref="myTableLogs" url="/ParkinglotRoadgate/QueryCarRoadgateSyncLogPager" auto-request>
          <vxe-table-column title="公司名称" width="140">
            <template #default="{row}">{{ row.companyName }}{{ row.companyAlias }}</template>
          </vxe-table-column>
          <vxe-table-column title="项目名称" field="projectName" width="100"></vxe-table-column>
          <vxe-table-column title="车场名称" field="parkingLotName" width="120"></vxe-table-column>
          <vxe-table-column title="车场区域" field="parkingAreaName" width="100"></vxe-table-column>
          <vxe-table-column title="车位车辆" field="roadgateResourceTypeBusinessName" min-width="140">
            <template #default="{row}">
              <el-link type="primary" @click="eye(row)">{{ row.roadgateResourceTypeBusinessName }}</el-link>
            </template>
          </vxe-table-column>
          <vxe-table-column title="同步类型" field="roadgateResourceTypeName" width="100"></vxe-table-column>
          <vxe-table-column title="日志内容" field="contents" min-width="300"></vxe-table-column>
          <vxe-table-column title="同步状态" field="syncStatusName" width="80">
            <template #default="{row}">
              <el-tag :type="['', 'success', 'warning', 'danger'][row.syncStatus - 1]">{{ row.syncStatusName }}</el-tag>
            </template>
          </vxe-table-column>
          <vxe-table-column title="同步结果" field="syncResult" min-width="400"></vxe-table-column>
          <vxe-table-column title="操作" width="100" fixed="right">
            <template #default="{row}">
              <el-button type="text" @click="cloud(row)" v-hasPermi="['system:carGate:cloud']" v-if="row.cloudSystemDomain !== null">云平台</el-button>
            </template>
          </vxe-table-column>
        </my-table>
      </el-tab-pane>
      <el-tab-pane label="出入记录" name="inOut">
        <my-search :items="searchItemsInOut" @search="searchInOut" @clear="clearInout" v-if="activeName === 'inOut'"></my-search>
        <my-table
          :columns="columns"
          ref="myTableInOut"
          :custom="true"
          summary
          dataKey="pageList"
          auto-request
          :sumKey="['cost']"
          @custom="value => carItems = value"
          url="/ParkinglotRoadgate/QueryCarParkingLog">
          <div slot="leavePic" slot-scope="{row}">
            <el-button type="text" @click="viewlevae(row)" v-if="row.leavePic">查看图片</el-button>
          </div>
          <div slot="enterPic" slot-scope="{row}">
            <el-button type="text" @click="viewEnter(row)" v-if="row.enterPic">查看图片</el-button>
          </div>
        </my-table>
      </el-tab-pane>
    </el-tabs>
    <car-detail :visible.sync="visibleCar" :items.sync="items"/>
    <car-place-detail :visible.sync="visibleCarPlace" :items.sync="items"/>
    <el-image-viewer v-if="viewDialog" :on-close="closeViewer" :url-list="previewImgList"/>
  </div>
</template>

<script>
import {carGateDelQueue, carGateImplement} from '@/api/system'
import { mapGetters } from 'vuex'
import CarDetail from './components/CarDetail'
import CarPlaceDetail from './components/CarPlaceDetail'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import {companyList} from '@/api/project'

export default {
  name: 'carGate',
  components: {CarDetail, CarPlaceDetail, ElImageViewer},
  data () {
    return {
      items: {},
      visibleCar: false,
      visibleCarPlace: false,
      viewDialog: false,
      activeName: 'queue',
      carItems: {},
      previewImgList: [], // 预览图片组
      columns: [
        {
          label: '公司名称',
          prop: 'companyName',
          width: 140
        },
        {
          label: '项目名称',
          prop: 'projectName',
          width: 140
        },
        {
          label: '车场名称',
          prop: 'parkingLotName',
          width: 140
        },
        {
          label: '车牌号',
          prop: 'carNumber',
          width: 100
        },
        {
          label: '车辆类型',
          prop: 'parkingCarTypeName',
          width: 100
        },
        {
          label: '车位组名称',
          prop: 'groupName',
          width: 100
        },
        {
          label: '进场时间',
          prop: 'inTime',
          width: 140
        },
        {
          label: '入口',
          prop: 'inPort',
          width: 100
        },
        {
          label: '离场时间',
          prop: 'outTime',
          width: 140
        },
        {
          label: '出口',
          prop: 'outPort',
          width: 100
        },
        {
          label: '停放时间(分钟)',
          prop: 'parkMinutes',
          width: 80
        },
        {
          label: '停放费用',
          prop: 'cost',
          width: 80
        },
        {
          label: '支付方式',
          prop: 'payTypeName',
          width: 80
        },
        {
          label: '放行类型',
          prop: 'releaseTypeName',
          width: 100
        },
        {
          label: '出场操作员',
          prop: 'leaveOpName',
          width: 100
        },
        {
          label: '创建日期',
          prop: 'createdTime',
          width: 140
        },
        {
          label: '入场图片',
          prop: 'enterPic',
          operate: true,
          width: 100
        },
        {
          label: '出场图片',
          prop: 'leavePic',
          operate: true,
          width: 100
        },
      ],
      projectList: []
    }
  },
  computed: {
    ...mapGetters(['dict', 'companyTree']),
    searchItemsQueue () {
      const {dict, companyTree, projectList} = this
      return [
        {
          type: 'selectTree',
          label: '公司名称/编号：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany,
          style: 'margin-right: 0'
        },
        {
          type: 'select',
          label: '',
          name: 'projectId',
          options: projectList
        },
        {type: 'select', label: '同步类型：', name: 'roadgateResourceType', options: dict.RoadgateResourceType},
        {type: 'select', label: '同步指令：', name: 'roadgateSyncInstructionType', options: dict.RoadgateSyncInstructionType},
        {type: 'input', label: '车场名称：', name: 'parkingLotName'},
        {type: 'input', label: '区域名称：', name: 'parkingLotAreaName'},
        {type: 'input', label: '车位或车辆名称：', name: 'roadgateResourceTypeBusinessName'},
      ]
    },
    searchItemsLogs () {
      const {dict, companyTree, projectList} = this
      return [
        {
          type: 'selectTree',
          label: '公司名称/编号：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany,
          style: 'margin-right: 0'
        },
        {
          type: 'select',
          label: '',
          name: 'projectId',
          options: projectList,
        },
        {type: 'select', label: '同步类型：', name: 'roadgateResourceType', options: dict.RoadgateResourceType, style: 'margin: 10px 0 10px 10px'},
        {type: 'input', label: '车场名称：', name: 'parkingLotName'},
        {type: 'input', label: '区域名称：', name: 'parkingLotAreaName'},
        {type: 'input', label: '车位或车辆名称：', name: 'roadgateResourceTypeBusinessName'},
        {type: 'select', label: '同步状态：', name: 'syncStatus', options: dict.SyncStatus},
      ]
    },
    searchItemsInOut () {
      const {dict, companyTree, projectList} = this
      return [
        {
          type: 'selectTree',
          label: '公司名称/编号：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany,
          style: 'margin-right: 0'
        },
        {
          type: 'select',
          label: '',
          name: 'projectId',
          options: projectList
        },
        {type: 'input', label: '车牌号：', name: 'carNumber'},
        {type: 'select', label: '车辆类型：', name: 'parkingCarType', options: dict.ParkingCarType},
        {type: 'select', label: '支付方式：', name: 'payType', options: dict.TDParkingPayType},
        {type: 'input', label: '车位组名称：', name: 'groupName'},
        {type: 'datePicker', label: '进场时间：', name: 'inStartTime', style: 'margin: 10px 0 10px 10px'},
        {type: 'datePicker', label: '至', name: 'inEndTime', style: 'margin: 10px 10px 10px 0'},
        {type: 'datePicker', label: '出场时间：', name: 'outStartTime', style: 'margin: 10px 0 10px 10px'},
        {type: 'datePicker', label: '至', name: 'outEndTime', style: 'margin: 10px 10px 10px 0'},
      ]
    },
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
  },
  methods: {
    cloud (row) {
      // 首次打开云平台登录，然后再打开
      window.open(row.cloudSystemOpenLink, 'carGate')
      setTimeout(() => {
        window.open(row.cloudSystemOpenLink, 'carGate')
      }, 500)
    },
    delQueue (row) {
      this.$confirm('确定删除此队列？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        carGateDelQueue({id: row.id}).then(res => {
          this.$message.success(res.message)
          this.refresh()
        })
      })
    },
    implement (row) {
      this.$confirm('确定立即执行此队列？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        carGateImplement({id: row.id}).then(res => {
          this.$message.success(res.message)
          this.refresh()
        })
      })
    },
    refresh () {
      if (this.activeName === 'queue') {
        this.$refs.myTableQueue.refresh()
      } else {
        this.$refs.myTableLogs.refresh()
      }
    },
    searchQueue (query) {
      this.$refs.myTableQueue.search(query)
    },
    clearQueue () {
      this.$refs.myTableQueue.reset()
    },
    searchLogs (query) {
      this.$refs.myTableLogs.search(query)
    },
    clearLogs () {
      this.$refs.myTableLogs.reset()
    },
    eye (row) {
      // roadgateResourceType，1：车辆，2：车位
      this.items = row[row.roadgateResourceType === 2 ? 'carplaceInfo' : 'carInfo']
      // this.visible = true
      if (row.roadgateResourceType === 2) {
        this.visibleCarPlace = true
      } else {
        this.visibleCar = true
      }
    },
    viewlevae (row) {
      this.viewDialog = true
      this.previewImgList = [row.leavePic]
    },
    viewEnter (row) {
      this.viewDialog = true
      this.previewImgList = [row.enterPic]
    },
    closeViewer () {
      this.viewDialog = false
    },
    searchInOut (query) {
      this.$refs.myTableInOut.search(query)
    },
    clearInout () {
      this.$refs.myTableInOut.reset()
    },
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    changeCompany (data) {
      this.$store.dispatch('getCompanyDownProject', {companyId: data.id, containsClosed: true}).then(res => {
        this.projectList = res
      })
    },
    tabClick () {}
  }
}
</script>

<style scoped lang="scss">

</style>
