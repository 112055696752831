<template>
  <el-dialog title="道闸编辑" :visible.sync="visible" :before-close="close" :close-on-click-modal="false" width="40%">
    <el-form :model="form" label-width="180px" :rules="rules" ref="form">
      <el-form-item label="道闸类型" prop="parkType">
        <el-select v-model="form.parkType" placeholder="请选择" style="width: 100%" @change="changeType">
          <el-option v-for="item in dict.ParkType" :key="item.value" :value="item.value" :label="item.displayName"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="form.parkType === 2 ? '车场编号' : '道闸编号'" prop="parkID">
        <el-input v-model="form.parkID" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item :label="form.parkType === 2 ? '接口域名' : '道闸云后台系统域'">
        <el-input v-model="form.cloudSystemDomain" placeholder="例如 http://68_9a_f7_0_3c_85.tdzntech.com:9898"></el-input>
      </el-form-item>
      <el-form-item :label="form.parkType === 2 ? '厂商平台账号' : '道闸云后台系统登录账号'">
        <el-input v-model="form.cloudSystemAccount" placeholder="请输入账号"></el-input>
        <div v-if="form.parkType !== 2">提示：请在道闸云上创建其权限账号用于统计查看</div>
      </el-form-item>
      <el-form-item :label="form.parkType === 2 ? 'Key' : '道闸云后台系统登录密码'">
        <el-input v-if="form.parkType === 2" v-model="form.cloudSystemPassword" placeholder="请输入key"></el-input>
        <el-input v-else v-model="form.cloudSystemPassword" type="password" placeholder="请输入密码" show-password></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select v-model="form.status" placeholder="请选择" style="width: 100%">
          <el-option v-for="item in dict.EntityStatus" :key="item.value" :value="item.value" :label="item.displayName"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import {parkingLotGate} from '@/api/project'
const initData = {
  parkType: '',
  parkID: '',
  status: 1,
  cloudSystemDomain: '',
  cloudSystemAccount: '',
  cloudSystemPassword: ''
}
export default {
  name: 'ParkingLogGate',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      form: {...initData},
      rules: {
        parkType: [{ required: true, trigger: "change", message: "请选择" }],
        status: [{ required: true, trigger: "change", message: "请选择" }],
        parkID: [{ required: true, trigger: "blur", message: "请输入" }],
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters(['dict'])
  },
  watch: {
    visible (value) {
      if (value) {
        this.form.parkingLotId = this.items.id
        if (this.items.roadGateConfig !== null) {
          this.form.status = this.items.roadGateConfig.status
          this.form.parkID = this.items.roadGateConfig.parkID
          this.form.parkType = this.items.roadGateConfig.parkType
          this.form.cloudSystemDomain = this.items.roadGateConfig.cloudSystemDomain
          this.form.cloudSystemAccount = this.items.roadGateConfig.cloudSystemAccount
          this.form.cloudSystemPassword = this.items.roadGateConfig.cloudSystemPassword
        }
      }
    }
  },
  methods: {
    change (value) {
      if (value) {
        this.form.parkID = value.parkID
      }
    },
    changeType () {
      if (this.form.parkType === 2) {
        this.form.cloudSystemDomain = 'http://yun.bolink.club'
        this.form.cloudSystemAccount = '200748'
      } else {
        this.form.cloudSystemDomain = 'http://68_9a_f7_0_3c_85.tdzntech.com:9898'
        this.form.cloudSystemAccount = ''
      }
    },
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
      this.form = {...initData}
      this.$refs.form.resetFields()
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          parkingLotGate(this.form).then(res => {
            this.$message.success(res.message)
            this.loading = false
            this.$emit('refresh')
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
