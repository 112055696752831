import {menuTree} from '@/api/system'
import {sysModules} from '@/api/login'
import {companyList, companyGetProject} from '@/api/project'
import { clearState } from '../index'

const initState = () => ({
  menu: [],
  dict: {},
  companyTree: [],
  // projectList: []
})
const basic = {
  state: initState(),
  mutations: {
    SET_MENU: (state, menu) => {
      state.menu = menu
    },
    SET_SYSDICT: (state, dict) => {
      state.dict = dict
    },
    SET_COMPANYTREE: (state, companyTree) => {
      state.companyTree = companyTree
    },
    // SET_PROJECT: (state, projectList) => {
    //   state.projectList = projectList
    // },
    clearBasic(state) {
      clearState(state, initState())
    }
  },
  actions: {
    // 菜单
    getMenu({commit}, data) {
      return new Promise((resolve, reject) => {
        menuTree(data).then(res => {
          commit('SET_MENU', res.data)
          resolve(res)
        }).catch(() => {
          reject(error)
        })
      })
    },
    // 数据字典
    getSysDict({commit}) {
      sysModules().then(res => {
        commit('SET_SYSDICT', res.data)
      })
    },
    getCompanyList({commit}) {
      return new Promise((resolve, reject) => {
        companyList({}).then(res => {
          const companyList = res.data.map(x => {
            return {
              ...x,
              name: `${x.name}(${x.alias})`,
              child: null
            }
          })
          commit('SET_COMPANYTREE', companyList)
          resolve(res)
        }).catch(() => {
          reject(error)
        })
      })
    },
    getCompanyDownProject ({}, query) {
      return new Promise((resolve, reject) => {
        companyGetProject(query).then(res => {
          const projectList = res.data.map(x => {
            return {
              ...x,
              displayName: x.projectName,
              value: x.projectId
            }
          })
          // commit('SET_PROJECT', projectList)
          resolve(projectList)
        }).catch(() => {
          reject(error)
        })
      })
    }
  }
}
export default basic