<template>
  <div>
    <my-search :values="queryData" :items="searchItems" @search="search" @clear="clear">
      <el-alert :closable="false" type="warning" show-icon class="alert-success" slot="alert">
        <div slot="title">
          <span>缴费金额：{{items.payedAmount || 0}}元，</span>
          <span>服务费：{{items.serviceAmount || 0}}元，</span>
          <span>结算金额：{{items.settlementAmount || 0}}元</span>
        </div>
      </el-alert>
      <el-tooltip class="item" effect="dark" content="导出数据之前必须先点击查询按钮查询数据" placement="top" slot="btn" v-hasPermi="['statement:onlinePay:export']">
        <el-button type="primary" @click="exportExcel">导出</el-button>
      </el-tooltip>
    </my-search>
    <my-table
      :columns="columns"
      :custom="true"
      dataKey="pagerData"
      @custom="value => items = value"
      ref="myTable"
      url="/OnlinePayment/QueryOnlinePayRecordsPagerList">
      <vxe-table-column title="操作" width="80px">
        <template #default="{row}">
          <el-button @click="eye(row)" type="text">查看详情</el-button>
        </template>
      </vxe-table-column>
    </my-table>
    <online-pay-detail :visible.sync="visible" :items.sync="rows"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OnlinePayDetail from './components/OnlinePayDetail'
import {downloadXlsx} from '@/utils/fileDownload'
import {companyList} from '@/api/project'

export default {
  name: 'onlinePay',
  components: {OnlinePayDetail},
  data () {
    return {
      columns: [
        {
          label: '公司名称',
          prop: 'companyName',
          width: 140
        },
        {
          label: '项目名称',
          prop: 'projectName',
          width: 100
        },
        {
          label: '资源号',
          prop: 'resourceName',
          width: 100
        },
        {
          label: '订单金额',
          prop: 'amount',
          width: 80
        },
        {
          label: '支付/退费/关闭金额',
          prop: 'payAmount',
          width: 140
        },
        {
          label: '服务费',
          prop: 'serviceAmoumt',
          width: 80
        },
        {
          label: '账户支出',
          prop: 'accountAmount',
          width: 80
        },
        {
          label: '结存金额',
          prop: 'storageAmount',
          width: 80
        },
        {
          label: '结算金额',
          prop: 'settlementAmount',
          width: 80
        },
        {
          label: '支付时间',
          prop: 'payTime',
          width: 140
        },
        {
          label: '支付方式',
          prop: 'methodName',
          width: 80
        },
        {
          label: '通道类型',
          prop: 'paymentTypeName',
          width: 80
        },
        {
          label: '支付状态',
          prop: 'statusName',
          width: 80
        },
        {
          label: '支付/退费/关闭类型',
          prop: 'payTypeName',
          width: 140
        },
        {
          label: '商户名称',
          prop: 'merchartName',
          width: 160
        },
        {
          label: '商户号',
          prop: 'merchartNumber',
          width: 160
        },
        {
          label: '结算银行',
          prop: 'settlementBank',
          width: 160
        },
        {
          label: '结算银行账号',
          prop: 'settlementBankNumber',
          width: 160
        },
      ],
      items: {},
      rows: {},
      visible: false,
      queryData: {
        onlinePaymentStatus: [2, 3],
      },
      projectList: []
    }
  },
  computed: {
    ...mapGetters(['dict', 'companyTree']),
    searchItems () {
      const {dict, companyTree, projectList} = this
      const onlinePaymentStatus = dict.OnlinePaymentStatus?.filter(x => [2, 3].includes(x.value)) // 只保留支付成功和已退款
      const payStatus = dict.PayStatus?.filter(x => [2, 4, 7].includes(x.value)) // 只保留已支付 已退费  已红冲
      return [
        {
          type: 'selectTree',
          label: '公司名称/编号：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany,
          style: 'margin-right: 0'
        },
        {
          type: 'select',
          label: '',
          name: 'projectId',
          options: projectList
        },
        {type: 'select', label: '支付状态：', name: 'onlinePaymentStatus', options: onlinePaymentStatus, multiple: true},
        {type: 'select', label: '支付/退费/关闭类型：', name: 'paymentType', options: dict.OnlinePaymentType},
        {type: 'datePicker', label: '收费开始时间：', name: 'chargeTimeStart'},
        {type: 'datePicker', label: '收费结束时间：', name: 'chargeTimeEnd'},
        {type: 'datePicker', label: '开始时间：', name: 'createTimeStart'},
        {type: 'datePicker', label: '结束时间：', name: 'createTimeEnd'},
        {type: 'select', label: '票据状态：', name: 'payStatus', options: payStatus, multiple: true},
        {type: 'select', label: '支付方式：', name: 'method', options: dict.OnlinePaymentMethod},
        {type: 'input', label: '资源名称：', name: 'resourceName'},
      ]
    }
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
  },
  methods: {
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    eye (row) {
      this.rows = row
      this.visible = true
    },
    exportExcel () {
      const loading = this.$loading({
        lock: true,
        text: '下载中，请稍等...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      downloadXlsx('/api/OnlinePayment/ExportOnlinePayRecordsPagerList',
        this.queryData,
        `在线支付记录导出`,
        () => loading.close()
      )
    },
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    changeCompany (data) {
      this.queryData.projectId = ''
      this.$store.dispatch('getCompanyDownProject', {companyId: data.id, containsClosed: true}).then(res => {
        this.projectList = res
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
