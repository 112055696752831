/* 导入任务 */
<template>
  <div>
    <my-search :items="searchItems" @search="search" @clear="clear">
      <el-button slot="btn" type="primary" @click="visible = true" v-hasPermi="['project:importTask:add']">新增</el-button>
    </my-search>
    <my-table :columns="columns" url="/Import/list" ref="myTable">
      <vxe-table-column title="操作">
        <template #default="{row}">
          <el-button type="text" :loading="row.loading" @click="eye(row)">详情</el-button>
          <el-button type="text" @click="del(row)" v-hasPermi="['project:importTask:del']">删除</el-button>
        </template>
      </vxe-table-column>
    </my-table>
    <import-task-edit :visible.sync="visible" @refresh="refresh"/>
    <import-task-detail :visible.sync="showDetail" :items.sync="detail"/>
  </div>
</template>

<script>
import {importTaskDel, importTaskDetail, companyList} from '@/api/project'
import ImportTaskEdit from './components/ImportTaskEdit'
import ImportTaskDetail from './components/ImportTaskDetail'
import { mapGetters } from 'vuex'
export default {
  name: 'importTask',
  components: {
    ImportTaskEdit,
    ImportTaskDetail
  },
  data () {
    return {
      showDetail: false, // 详情
      visible: false, // 新增对话框
      loading: false,
      columns: [
        {
          label: '公司名称',
          prop: 'companyName'
        },
        {
          label: '项目名称',
          prop: 'projectName'
        },
        {
          label: '状态',
          prop: 'importStatusName',
          operate: true,
          render: (h, {row}) => {
            let type = ''
            if (row.importStatus) {
              type = {
                3: 'success',
                2: 'warning',
                1: ''
              }[row.importStatus]
            }
            return h('el-tag', {
              props: {
                type: type
              }
            }, row.importStatusName)
          }
        },
        {
          label: '导入类型',
          prop: 'importTypeName'
        },
        {
          label: '创建时间',
          prop: 'createdTime'
        },
      ],
      detail: {},
      // options: [],
      queryData: {},
      projectList: []
    }
  },
  computed: {
    ...mapGetters(['dict', 'companyTree']),
    searchItems () {
      const {dict, companyTree, projectList} = this
      return [
        {
          type: 'selectTree',
          label: '公司名称/编号：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany,
          style: 'margin-right: 0'
        },
        {
          type: 'select',
          label: '',
          name: 'projectId',
          options: projectList
        },
        // {
        //   type: 'select',
        //   label: '项目名称：',
        //   name: 'projectId',
        //   remoteMethod: this.remoteMethod,
        //   remote: true,
        //   options: options,
        //   props: {label: 'name', value: 'id'}
        // },
        {
          type: 'select',
          label: '导入类型：',
          name: 'importType',
          options: dict.ImportType
        },
        {
          type: 'select',
          label: '状态：',
          name: 'importStatus',
          options: dict.ImportStatus
        }
      ]
    }
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
  },
  methods: {
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    // remoteMethod(query) {
    //   if (query !== '') {
    //     this.loading = true
    //     projectList({name: query, status: 1, limit: 1000, page: 1}).then(res => {
    //       this.loading = false
    //       this.options = res.data.items.map(x => {
    //         return {
    //           id: x.id,
    //           name: `${x.name}(${x.companyName}${x.companyAlias})`
    //         }
    //       })
    //     }).catch(() => {
    //       this.loading = false
    //     })
    //   } else {
    //     this.options = []
    //   }
    // },
    refresh () {
      this.$refs.myTable.refresh(this.queryData)
    },
    del (row) {
      this.$confirm(`确定删除任务<span style="color: #da251c">${row.projectName}</span>吗?`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        importTaskDel({id: row.id}).then(res => {
          this.$message.success(res.message)
          this.refresh()
        })
      })
    },
    eye (row) {
      row.loading = true
      importTaskDetail({id: row.id}).then(res => {
        this.detail = res.data
        this.showDetail = true
        row.loading = false
      }).catch(() => {
        row.loading = false
      })
    },
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    changeCompany (data) {
      this.$store.dispatch('getCompanyDownProject', {companyId: data.id, containsClosed: true}).then(res => {
        this.projectList = res
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
