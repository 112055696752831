<template>
  <div>
    <my-search :items="searchItems" @search="search" @clear="clear" :values="queryData">
      <div slot="btn">
        <el-button type="primary" @click="changeFee" v-hasPermi="['project:changeFeeStandard:change']">变更费用类型</el-button>
      </div>
    </my-search>
    <my-table
      selection
      :check-box-method="checkBoxMethod"
      @changeSelect="changeSelect"
      @changeSelectAll="changeSelect"
      :columns="columns"
      ref="myTable"
      :sort-config="{remote: true, trigger: 'cell'}"
      @sortChange="sortChange"
      :query="{orderBy: orderBy}"
      url="/FeeStandard/QueryFeeStandardsPager">
      <vxe-table-column title="操作">
        <template #default="{row}">
          <el-button type="text" v-hasPermi="['project:changeFeeStandard:merge']" @click="feeMerge(row)">合并标准</el-button>
          <el-button type="text" v-hasPermi="['project:changeFeeStandard:edit']" @click="edit(row)">编辑</el-button>
          <el-button type="text" v-hasPermi="['project:changeFeeStandard:del']" @click="del(row)">删除</el-button>
        </template>
      </vxe-table-column>
    </my-table>
    <change-fee-edit :visible.sync="visible" :items.sync="items" @refresh="refresh"/>
    <change-fee-standard-edit :visible.sync="visibleEdit" :fee-type="feeTypeList" :items.sync="items" @refresh="refresh"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {projectList, queryDownFeeType, feeStandardDetail, feeStandardMerge, feeStandardDel} from '@/api/project'
import ChangeFeeEdit from './components/ChangeFeeEdit'
import ChangeFeeStandardEdit from './components/ChangeFeeStandardEdit'
import {companyProjectTree} from '@/api/system'

export default {
  name: 'changeFeeStandard',
  components: { ChangeFeeEdit, ChangeFeeStandardEdit },
  data () {
    return {
      columns: [
        {
          label: '公司名称',
          prop: 'companyName'
        },
        {
          label: '项目名称',
          prop: 'projectName'
        },
        {
          label: '费用类型',
          prop: 'feeTypeName'
        },
        {
          label: '费用标准',
          prop: 'feeStandardName',
          sort: true
        },
        {
          label: '状态',
          prop: 'statusName'
        },
        {
          label: '计算方式',
          prop: 'feeModeName'
        },
        {
          label: '单价',
          prop: 'price',
          sort: true
        },
        {
          label: '关联面积类型',
          prop: 'relationAreaTypeName'
        },
        {
          label: '备注',
          prop: 'remarks'
        },
      ],
      queryData: {
        status: 1
      },
      options: [],
      feeTypeList: [],
      checked: [],
      visible: false,
      visibleEdit: false,
      items: {},
      tree: [],
      orderBy: ['feeStandardName desc', 'id desc']
    }
  },
  computed: {
    ...mapGetters(['dict']),
    searchItems () {
      const {feeTypeList, tree} = this
      const statusOptions = [
        {
          displayName: '启用',
          value: 1
        },
        {
          displayName: '禁用',
          value: 2
        }
      ]
      return [
        {
          type: 'cascader',
          label: '项目名称：',
          name: 'projectId',
          options: tree,
          cb: this.changeFeeType
        },
        {type: 'input', label: '标准名称：', name: 'name'},
        {type: 'select', label: '费用类型：', name: 'feeType', options: feeTypeList, group: true},
        {type: 'select', label: '状态：', name: 'status', options: statusOptions}
      ]
    }
  },
  mounted () {
    this.getTreeList()
  },
  methods: {
    getTreeList () {
      companyProjectTree({types: [1, 2, 3]}).then(res => {
        this.tree = res.data.map(x => {
          return {
            ...x,
            name: x.name + `(${x.companyAlias})`
          }
        })
      })
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        projectList({name: query, status: 1, limit: 1000, page: 1}).then(res => {
          this.loading = false
          this.options = res.data.items.map(x => {
            return {
              id: x.id,
              name: `${x.name}(${x.companyName}${x.companyAlias})`,
            }
          })
        }).catch(() => {
          this.loading = false
        })
      } else {
        this.options = []
      }
    },
    changeFeeType (projectId) {
      queryDownFeeType({projectId: projectId}).then(res => {
        this.feeTypeList = res.data.map(x => {
          return {
            value: x.id,
            displayName: `${x.name}(${x.statusName})`,
            ...x
          }
        })
      })
    },
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    clear () {
      this.queryData =  {}
      this.$refs.myTable.reset()
    },
    // 表格选择条件
    checkBoxMethod (row) {
      return row
    },
    // 选择项，返回数据[]
    changeSelect (item) {
      const {list = []} = item
      this.checked = list
    },
    changeFee () {
      if (this.checked.length === 0) {
        this.$message.error('请选择变更数据')
        return
      }
      this.items = {
        projectId: this.queryData.projectId,
        feeStandardIds: this.checked
      }
      this.visible = true
    },
    refresh () {
      this.$refs.myTable.refresh(this.queryData)
    },
    feeMerge (row) {
      if (this.checked.length < 2) {
        this.$message.error('选择合并的数据必须大于1条')
        return
      }
      feeStandardMerge({
        feeStandardIds: this.checked,
        projectId: this.queryData.projectId,
        mergeToFeeStandardIds: row.id
      }).then(res => {
        this.$message.success(res.message)
        this.refresh()
      })
    },
    del (row) {
      this.$confirm(`确定删除<span style="color: #de251c">${row.feeStandardName}</span>吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        feeStandardDel({id: row.id}).then(res => {
          this.$message.success(res.message)
          this.refresh()
        })
      })
    },
    edit (row) {
      feeStandardDetail({id: row.id}).then(res => {
        this.items = res.data
        this.visibleEdit = true
      })
    },
    sortChange (sort) {
      this.orderBy = [`${sort.property} ${sort.order || 'desc'}`, 'id desc']
      this.refresh()
    }
  }
}
</script>

<style scoped lang="scss">

</style>
