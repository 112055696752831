<template>
  <div>
    <my-search :items="searchItems" @search="search" @clear="clear">
      <el-button slot="btn" @click="add" type="primary" v-hasPermi="['system:configure:add']">新增</el-button>
    </my-search>
    <my-table ref="myTable" :columns="columns" url="/ProjectBasicsConfig/GetList">
      <vxe-table-column title="操作" width="80px">
        <template #default="{row}">
          <el-button type="text" @click="edit(row)" v-hasPermi="['system:configure:edit']">编辑</el-button>
        </template>
      </vxe-table-column>
    </my-table>
    <configure-edit :visible.sync="visible" @refresh="refresh" :items.sync="items"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConfigureEdit from './components/ConfigureEdit'
export default {
  name: 'configure',
  components: {ConfigureEdit},
  data () {
    return {
      visible: false,
      items: {},
      columns: [
        {
          label: '唯一编码',
          prop: 'code'
        },
        {
          label: '控件名称',
          prop: 'label'
        },
        {
          label: '配置类型',
          prop: 'configTypeName'
        },
        {
          label: '控件类型',
          prop: 'typeName'
        },
        {
          label: '状态',
          prop: 'statusName',
          operate: true,
          render: (h, {row}) => {
            let type = ''
            if (row.status) {
              type = {
                1: 'success',
                2: 'danger',
                3: 'info'
              }[row.status]
            }
            return h('el-tag', {
              props: {
                type: type
              }
            }, row.statusName)
          }
        },
        {
          label: '排序',
          prop: 'sortNumber',
          sort: true
        },
        {
          label: '收费端排序',
          prop: 'chargeSortNumber',
          sort: true
        },
        {
          label: '是否开放收费端配置',
          prop: 'isOpenChargeSets',
          operate: true,
          render: (h, {row}) => {
            return h('div', {}, row.isOpenChargeSets ? '是' : '否')
          }
        },
        {
          label: '说明',
          prop: 'remarks'
        }
      ],
      queryData: {
        isOpenChargeSets: false
      }
    }
  },
  computed: {
    ...mapGetters(['dict']),
    searchItems () {
      const {dict} = this
      return [
        {type: 'input', label: '唯一编码/控件名称：', name: 'code'},
        {type: 'select', label: '配置类型：', name: 'configType', options: dict.ProjectBasicsConfigType},
        {type: 'checkbox', text: '是否开放收费端配置', name: 'isOpenChargeSets'},
      ]
    }
  },
  mounted () {},
  methods: {
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    add () {
      this.visible = true
    },
    refresh () {
      this.$refs.myTable.refresh(this.queryData)
    },
    edit (row) {
      this.items = Object.assign({}, row)
      this.visible = true
    }
  }
}
</script>

<style scoped lang="scss">

</style>
