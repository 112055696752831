<template>
  <el-dialog
    :title="title + items.name"
    :visible.sync="visible"
    :before-close="close"
    :close-on-click-modal="false"
    :width="items.copyType === 'authorization' ? '70%' : '30%'">
    <div v-if="items.copyType === 'roles'">复制当前角色及权限到选择的公司下</div>
    <div v-if="items.copyType === 'authorization'">复制当前角色的权限覆盖到选择公司角色的权限</div>
    <div class="flex-h">
      <company-tree select-type="checkbox" :tree-data="rolesCompanyTree" v-if="visible" @node-click="nodeClick" :disabled-id="items.companyId"/>
      <my-table :data="tableData" style="flex: 1" v-if="items.copyType === 'authorization'">
        <vxe-table-column title="公司" field="companyName" width="200">
          <template #default="{row}">
            <span>{{ row.companyName }}</span>
            <el-button type="text" @click="changeAll(row)">全选</el-button>
          </template>
        </vxe-table-column>
        <vxe-table-column title="角色">
          <template #default="{row}">
            <el-checkbox v-for="item in row.roles" v-model="item.checked" :key="item.roleId" :label="item.roleId">{{ item.roleName }}</el-checkbox>
          </template>
        </vxe-table-column>
      </my-table>
    </div>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" v-if="items.copyType === 'roles'" @click="submit(1)">确定</el-button>
      <el-button type="primary" v-if="items.copyType === 'authorization'" @click="submit(2)">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import CompanyTree from './CompanyTree'
import {rolesCompany, companyRolesTree, rolesCopy} from '@/api/system'
export default {
  name: 'RolesCopy',
  components: {CompanyTree},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      tableData: [],
      rolesCompanyTree: [],
      checkedCompany: []
    }
  },
  computed: {
    checkedRoles () {
      let checkedRoles = []
      this.tableData.forEach(item => {
        if (item.roles && item.roles.length > 0) {
          item.roles.forEach(elem => {
            if (elem.checked) {
              checkedRoles.push(elem)
            }
          })
        }
      })
      return checkedRoles
    },
    title () {
      return this.items.copyType === 'authorization' ? '复制权限' : '复制角色'
    }
  },
  watch: {
    visible (value) {
      if (value) {
        this.getRolesCompany()
      }
    }
  },
  methods: {
    getRolesCompany () {
      rolesCompany({roleId: this.items.id}).then(res => {
        this.rolesCompanyTree = [res.data]
      })
    },
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
      this.tableData = []
      this.checkedCompany = []
    },
    nodeClick (data) {
      this.checkedCompany = data
      companyRolesTree({ids: data}).then(res => {
        this.tableData = res.data.map(x => {
          return {
            ...x,
            // checkedAll: false,
            roles: x.roles.map(y => {
              return {
                ...y,
                checked: false
              }
            })
          }
        })
      })
    },
    changeAll (row) {
      row.roles.forEach(item => {
        item.checked = true
      })
    },
    submit (type) {
      // type 1.复制角色及权限 2.复制权限
      let msg = '请选择公司'
      if (type === 2) {
        msg = '请选择公司或权限'
      }
      if (this.checkedCompany.length === 0 && type === 1) {
        this.$message.error(msg)
        return
      }
      if (type === 2 && this.checkedRoles.length === 0) {
        this.$message.error(msg)
        return
      }
      rolesCopy({roleId: this.items.id, useType: type, useIds: type === 1 ? this.checkedCompany : this.checkedRoles.map(x => x.roleId) }).then(res => {
        this.$message.success(res.message)
        this.$emit('refresh')
        this.close()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.flex-h {
  align-items: flex-start;
  margin-top: 10px;
}
</style>
