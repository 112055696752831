<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handlePay">
      <my-search :items="searchItems" @search="search" @clear="clear">
        <div slot="btn">
          <div v-show="activeName === 'parkingLot'">
            <el-button
              v-hasPermi="['project:areaConfig1:addParkingLot']"
              type="primary"
              @click="visibleParkingLot = true">
              新增
            </el-button>
          </div>
          <div v-show="activeName === 'pay'" v-hasPermi="['project:areaConfig1:addPayConfig']">
            <el-button type="primary" @click="add">批量新增</el-button>
          </div>
        </div>
      </my-search>
      <el-tab-pane v-for="item in dict.ProjectBasicsConfigType" :label="item.displayName" :key="item.value" :name="item.value.toString()"></el-tab-pane>
      <el-tab-pane label="支付配置" name="pay">
        <my-table :columns="columns" url="/OnlinePaymentConfig/GetProjectConfigList" :auto-request="false" ref="payTable">
          <div slot="isDefault" slot-scope="{row}">
            <vxe-switch
              @change="changeDefault($event, row)"
              open-label="是"
              close-label="否"
              :open-value="true"
              :close-value="false"
              v-model="row.isDefault">
            </vxe-switch>
          </div>
          <div slot="status" slot-scope="{row}">
            <vxe-switch @change="changeStatus(row)" open-label="启用" close-label="禁用" :open-value="1" :close-value="2" v-model="row.status"></vxe-switch>
          </div>
          <vxe-table-column title="操作" width="260">
            <template #default="{row}">
              <el-button type="text" :loading="row.loading" @click="edit(row)" v-hasPermi="['project:areaConfig1:editPayConfig']">编辑</el-button>
              <el-button type="text" :loading="row.loading" @click="copyEdit(row)" v-hasPermi="['project:areaConfig1:copyAddPayConfig']">复制新增</el-button>
              <el-button type="text" :loading="row.loading" @click="useProject(row)" v-hasPermi="['project:areaConfig1:usePorject']">应用到项目</el-button>
            </template>
          </vxe-table-column>
        </my-table>
        <area-pay-config-edit :visible.sync="visiblePay" :items.sync="items" @refresh="refresh"/>
        <area-pay-use :visible.sync="visibleUse" :items.sync="items" @refresh="refresh"/>
      </el-tab-pane>
      <el-tab-pane label="停车场配置" name="parkingLot">
        <my-table
          :columns="columnsParkingLot"
          ref="myTable"
          :auto-request="false"
          url="/ParkingLot/QueryPagerParkingLot"
          :query="{companyId: queryData.companyId}">
          <vxe-table-column title="操作" width="280">
            <template #default="{row}">
              <el-button type="text" @click="initCar(row)" v-hasPermi="['project:areaConfig1:init']">初始化项目车位车辆道闸</el-button>
              <el-button type="text" :loading="row.loading" @click="editParkingLot(row)" v-hasPermi="['project:areaConfig1:editParkingLot']">编辑</el-button>
              <el-button type="text" :loading="row.loading" @click="gateConfig(row)" v-hasPermi="['project:areaConfig1:gateConfig']">道闸配置</el-button>
            </template>
          </vxe-table-column>
        </my-table>
        <parking-lot-edit :visible.sync="visibleParkingLot" :items.sync="items" @refresh="refreshParkingLot"/>
        <parking-lot-gate :visible.sync="visibleGate" :items.sync="items" @refresh="refreshParkingLot"/>
      </el-tab-pane>
      <div v-show="!['pay', 'parkingLot'].includes(activeName)">
        <vxe-table
          border
          ref="xTable"
          size="small"
          style="margin-top: 20px;"
          auto-resize
          sync-resize
          :column-config="{resizable: true}"
          :data="detail.items"
          :edit-config="{trigger: 'manual', mode: 'row'}"
          :row-config="{isCurrent: true, keyField: 'id'}"
          empty-text="暂无数据">
          <vxe-column type="checkbox" width="60" fixed="left"></vxe-column>
          <vxe-table-column title="所属公司" align="left" fixed="left" field="CompanyName" min-width="200px"></vxe-table-column>
          <vxe-table-column title="小区名称" align="left" fixed="left" field="ProjectName" min-width="200px"></vxe-table-column>
          <vxe-table-column
            v-for="item in detail.columns"
            :title-help="{message: item.remarks}"
            :key="item.EnableChangeCarryForward"
            min-width="260px"
            :title="item.label"
            :field="item.code">
            <template #header="{column}">
              <!-- <i class="el-icon-edit-outline" @click="editColumn(column)"></i> -->
              {{ column.title }}
              <el-button type="text" @click="editColumn(column)">批量设置</el-button>
            </template>
          </vxe-table-column>
        </vxe-table>
        <area-config-edit :visible.sync="visible" :items.sync="items" @refresh="refresh"/>
      </div>
    </el-tabs>
  </div>
</template>

<script>
import {
  areaConfigList,
  companyList,
  projectPayConfigGetDetail,
  projectPayConfigStatus,
  projectPayConfigIsdefault,
  initCarGate
} from '@/api/project'
import { mapGetters } from 'vuex'
import AreaConfigEdit from './components/AreaConfigEdit'
import AreaPayConfigEdit from './components/AreaPayConfigEdit'
import AreaPayUse from './components/AreaPayUse'
// import {companyProjectTree} from '@/api/system'
import ParkingLotEdit from './components/ParkingLotEdit'
import ParkingLotGate from './components/ParkingLotGate'
export default {
  name: 'areaConfig1',
  components: {AreaConfigEdit, AreaPayConfigEdit, ParkingLotEdit, ParkingLotGate, AreaPayUse},
  data () {
    return {
      activeName: '1',
      detail: {
        items: [],
        columns: []
      },
      queryData: {},
      tableData: [],
      visible: false,
      visiblePay: false,
      visibleParkingLot: false,
      visibleGate: false,
      visibleUse: false,
      items: {},
      // tree: [],
      columnsParkingLot: [
        {
          label: '所属公司',
          prop: 'companyName'
        },
        {
          label: '项目名称',
          prop: 'projectName'
        },
        {
          label: '停车场名称',
          prop: 'name'
        },
        {
          label: '状态',
          prop: 'statusName',
          operate: true,
          render: (h, {row}) => {
            let type = ''
            if (row.status) {
              type = {
                1: 'success',
                2: 'danger',
                3: 'info'
              }[row.status]
            }
            return h('el-tag', {
              props: {
                type: type
              }
            }, row.statusName)
          }
        },
        {
          label: '创建时间',
          prop: 'createdTime'
        },
        {
          label: '备注',
          prop: 'remark'
        },
      ],
      columns: [
        {
          label: '所属公司',
          prop: 'companyName'
        },
        {
          label: '项目名称',
          prop: 'projectName'
        },
        {
          label: '名称',
          prop: 'name'
        },
        {
          label: '通道类型',
          prop: 'passTypeName'
        },
        {
          label: '是否默认',
          prop: 'isDefault',
          operate: true
        },
        {
          label: '状态',
          prop: 'status',
          operate: true
        },
        {
          label: '费率',
          prop: 'serviceRate'
        },
        {
          label: '创建日期',
          prop: 'createdTime'
        }
      ],
    }
  },
  computed: {
    ...mapGetters(['dict', 'companyTree']),
    searchItems () {
      const {companyTree} = this
      return [
        {
          type: 'selectTree',
          label: '选择公司：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions
        },
      ]
    },
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
  },
  methods: {
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    getList () {
      const loading = this.$loading({
        lock: true,
        text: '加载中，请稍等...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      areaConfigList({companyId: this.queryData.companyId, configType: this.activeName}).then(res => {
        this.detail = res.data
        if (this.activeName === '99') {
          this.detail.items = res.data.items.map(item => {
            return {
              ...item,
              weiXinConfigId: ''
            }
          })
        }
        loading.close()
      }).catch(() => {
        loading.close()
      })
    },
    search (query) {
      this.queryData = query
      if (this.activeName === 'pay') {
        this.getPayConfig()
      } else if (this.activeName === 'parkingLot') {
        this.refreshParkingLot()
      } else {
        this.getList()
      }
    },
    handlePay () {
      const {activeName} = this
      if (!['pay', 'parkingLot'].includes(activeName)) {
        this.getList()
      } else if (this.activeName === 'parkingLot') {
        this.refreshParkingLot()
      } else {
        this.getPayConfig()
      }
    },
    getPayConfig () {
      if (!this.queryData.companyId) {
        return
      }
      this.$refs.payTable.refresh(this.queryData)
    },
    clear () {
      this.tableData = []
      this.queryData = {}
    },
    refresh () {
      if (this.activeName === 'pay') {
        this.getPayConfig()
      } else {
        this.getList()
      }
    },
    add () {
      this.items.companyId = this.queryData.companyId
      this.items.type = 'all'
      this.visiblePay = true
    },
    edit (row) {
      row.loading = true
      projectPayConfigGetDetail({id: row.id}).then(res => {
        row.loading = false
        this.visiblePay = true
        this.items = res.data
      }).catch(() => {
        row.loading = false
      })
    },
    copyEdit (row) {
      row.loading = true
      projectPayConfigGetDetail({id: row.id}).then(res => {
        row.loading = false
        this.visiblePay = true
        this.items = res.data
        this.items.type = 'copy'
        this.items.disabledId = res.data.projectId
        this.items.projectId = null
        // delete this.items.id
      }).catch(() => {
        row.loading = false
      })
    },
    useProject (row) {
      this.items = row
      this.visibleUse = true
    },
    refreshParkingLot () {
      if (!this.queryData.companyId) {
        return
      }
      this.$refs.myTable.refresh()
    },
    editParkingLot(row) {
      this.items = row
      this.visibleParkingLot = true
    },
    gateConfig (row) {
      this.items = row
      this.visibleGate = true
    },
    editColumn (column) {
      const selectRecords = this.$refs.xTable.getCheckboxRecords()
      if (selectRecords.length === 0) {
        this.$message.error('编辑前请先选择数据！')
        return
      }
      const field = column.field
      const item = this.detail.columns.find(x => x.code === field)
      console.log(column, selectRecords, item)
      this.items = {
        ...item,
        projectIds: selectRecords.map(x => x.ProjectId),
        value: item.systemDefaultValue,
      }
      this.visible = true
    },
    changeStatus (row) {
      projectPayConfigStatus({id: row.id, status: row.status === 1}).then(res => {
        this.$message.success(res.message)
        this.getPayConfig()
      }).catch(() => {
        row.status = !row.status
      })
    },
    changeDefault (e, row) {
      projectPayConfigIsdefault({id: row.id, isDefault: e.value}).then(res => {
        this.$message.success(res.message)
        this.getPayConfig()
      }).catch(() => {
        row.isDefault = !row.isDefault
      })
    },
    initCar (row) {
      const msg = `
        <div>1.当前操作属于重要高权限操作仅限admin才能执行</div>
        <div>2.初始化操作请先手动清空车场道闸的车位和车辆信息，确保完全删除了再来执行</div>
        <div>3.道闸初始化操作无法回滚还原，执行之前确保此操作是必要的且正确的</div>
      `
      this.$confirm(msg, '提示', {
        dangerouslyUseHTMLString: true
      }).then(() => {
        initCarGate({projectId: row.projectId}).then(res => {
          this.$message.success(res.message)
          this.refreshParkingLot()
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.el-icon-edit-outline {
  cursor: pointer;
}
</style>
