<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="close" :close-on-click-modal="false" width="70%">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-for="item in dict.PrintType" :label="item.displayName" :key="item.value" :name="item.value.toString()"></el-tab-pane>
    </el-tabs>
    <div class="flex-h">
      <div>所属公司：</div>
      <treeselect
        style="width: 200px"
        v-model="companyId"
        :options="companyTree"
        retryText="重试"
        :load-options="loadOptions"
        :normalizer="normalizer"
        @select="select"
        placeholder="选择公司" />
    </div>
    <my-table :data="tableData" v-if="visible">
      <vxe-table-column title="所属公司" field="companyName" width="120"></vxe-table-column>
      <vxe-table-column title="所属项目" field="projectName" width="120"></vxe-table-column>
      <vxe-table-column title="模板连接">
        <template #default="{row}">
          <el-input placeholder="请输入" @input="inputTmp($event, row)" v-model="row.templateLink" :disabled="row.disabledInput" clearable>
            <el-select
              v-model="row.templateId"
              clearable
              style="width: 190px"
              :class="row.disabledInput ? 'pointer' : ''"
              slot="prepend"
              @change="changeSelect($event, row)"
              placeholder="请选择模板，模板和上传只能2选1"
              :disabled="row.disabledSelect">
              <el-option v-for="item in tmpList" :label="item.title" :value="item.id" :key="item.id"></el-option>
            </el-select>
          </el-input>
        </template>
      </vxe-table-column>
      <vxe-table-column title="操作" width="140">
        <template #default="{row}">
          <div class="flex-h">
            <el-button type="text" @click="save(row)" style="margin-right: 20px">保存</el-button>
            <my-upload-file @input="input($event, row)" :return-url.sync="url" :fileType="['fmx']" :show-gallery="false" v-if="!row.disabledInput">
              <el-button slot="content" type="text">上传</el-button>
            </my-upload-file>
            <el-button type="text" @click="eye(row)" v-if="row.templateLink" style="margin-left: 20px">预览</el-button>
          </div>
        </template>
      </vxe-table-column>
    </my-table>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import {companyList} from '@/api/project'
import {companyPrintTmp, companyProjectPrintTmpEdit, chargePrintView, arrearsPrintView, printTmpList} from '@/api/print'
import MyUploadFile from '@/components/MyUploadFile'
import {printTmp} from '@/utils/tools'
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
export default {
  name: 'CompanyPrintTmpEdit',
  components: {Treeselect, MyUploadFile},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      activeName: '',
      companyId: null,
      // list: [],
      tableData: [],
      tmpList: [],
      url: process.env.VUE_APP_BASE_PRINT,
      columns: [
        {
          label: '所属项目',
          prop: 'projectName'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['dict', 'companyTree']),
    title () {
      return ''
    },
    printType () {
      return this.dict.PrintType.find(x => x.value == this.activeName)
    }
  },
  watch: {
    visible (value) {
      if (value) {
        this.activeName = this.dict.PrintType[0].value.toString()
        // this.getCompanyList()
        if (this.companyTree.length === 0) {
          this.$store.dispatch('getCompanyList')
        }
        // this.getPrintTmp()
        if (this.id) {
          this.companyId = this.id
          this.handleClick()
        }
      }
    }
  },
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:id', '')
      this.companyId = null
      this.tableData = []
    },
    input (value, row) {
      row.templateLink = value
      this.inputTmp(value, row)
    },
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    select () {
      setTimeout(() => {
        this.handleClick()
      }, 200)
    },
    save (row) {
      const data = {
        printType: row.printType,
        templateLink: row.templateId ? '' : row.templateLink,
        projectId: row.projectId,
        templateId: row.templateId ? row.templateId : '0'
      }
      companyProjectPrintTmpEdit(data).then(res => {
        this.$message.success(res.message)
      })
    },
    handleClick () {
      if (!this.companyId) {
        return
      }
      this.getPrintTmp()
      companyPrintTmp({companyId: this.companyId, printType: this.printType.value}).then(res => {
        this.tableData = res.data.map(x => {
          return {
            ...x,
            disabledInput: !!x.templateId,
            disabledSelect: !!x.templateLink
          }
        })
      })
    },
    // 获取打印模板
    getPrintTmp () {
      printTmpList({type: this.activeName}).then(res => {
        this.tmpList = res.data
      })
    },
    eye (row) {
      // 单户收费是printType===1，欠费催收printType===6
      if (row.printType === 1) {
        chargePrintView({printTemplateLink: row.templateLink}).then(res => {
          const {data} = res
          printTmp({tmpUrl: data.printTemplateLink, action: 'preview', jsonData: data.paymentDatas, call: false})
        })
      } else {
        arrearsPrintView({printTemplateLink: row.templateLink}).then(res => {
          const {data} = res
          printTmp({tmpUrl: data.printTemplateLink, action: 'preview', jsonData: data.paymentDatas, call: false})
        })
      }
    },
    changeSelect (value, row) {
      row.templateLink = value ? '' : row.templateLink
      row.disabledInput = !!value
    },
    inputTmp (value, row) {
      row.templateId = value ? '' : row.templateId
      row.disabledSelect = !!value
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .vxe-cell .el-upload--picture-card {
  border: none;
}
::v-deep .pointer .el-input__inner {
  cursor: pointer;
}
// ::v-deep .el-input__suffix-inner {
//   pointer-events: none
// }
</style>
