<template>
  <el-dialog title="常用菜单设置" :visible.sync="visible" :before-close="close" :close-on-click-modal="false" custom-class="p-0" width="30%">
    <div class="manager-box">
      <el-collapse v-model="activeName">
        <el-collapse-item v-for="item in menuList" :key="item.functionId" :title="item.name" :name="item.functionId">
          <div class="flex-h flex-between bg-grey" v-for="elem in item.child" :key="elem.functionId">
            <div>{{ elem.name }}</div>
            <vxe-switch open-label="开启" close-label="关闭" v-model="elem.isEnable" @change="changeSwitch($event, elem)"></vxe-switch>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div slot="footer">
      <el-button @click="close">关闭</el-button>
      <el-button type="primary" :loading="loading" @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {menuUse, menuUseSave} from '@/api/system'
import { mapGetters } from 'vuex'

export default {
  name: 'MenuConfig',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menuList: [],
      activeName: [],
      tags: [],
      loading: false
    }
  },
  watch: {
    visible (value) {
      if (value) {
        this.getMenu()
      }
    }
  },
  computed: {
    ...mapGetters(['menu']),
  },
  mounted () {},
  methods: {
    getMenu () {
      menuUse().then(res => {
        this.tags = res.data
        this.menuList = this.menu[1]?.child.map(x => {
          return {
            ...x,
            child: x.child.map(y => {
              return {
                ...y,
                // eslint-disable-next-line max-nested-callbacks
                isEnable: res.data.map(x => x.functionId).includes(y.functionId)
              }
            })
          }
        })
        this.activeName = this.menuList.map(x => x.functionId)
      })
    },
    close () {
      this.$emit('update:visible', false)
      this.menuList = []
      this.tags = []
    },
    changeSwitch (e, item) {
      const {value} = e
      if (value) {
        this.tags.push({
          name: item.name,
          functionId: item.functionId,
        })
      } else {
        let index = this.tags.map(x => x.functionId).indexOf(item.functionId)
        this.tags.splice(index, 1)
      }
    },
    save () {
      this.loading = true
      let items = this.tags.map((x, i) => {
        return {
          ...x,
          sortNumber: i
        }
      })
      menuUseSave({items: items}).then(res => {
        this.$message.success(res.message)
        this.loading = false
        this.close()
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.bg-grey {
  padding: 5px 10px;
  background-color: #e7e6e6;
  border-bottom: 1px solid #fff;
}
.manager-box {
  height: 500px;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 10px;
  // height: 50px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.4);
  background-clip: padding-box;
  border: 3px solid transparent;
  border-radius: 7px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
</style>
<style lang="scss">
.p-0 .el-dialog__body {
  padding: 30px 0 30px 20px;
}
</style>