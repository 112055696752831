<template>
  <div>
    <my-search :items="searchItems" @search="search" @clear="clear" :values="queryData" :hide-length="5">
      <el-alert :closable="false" type="warning" show-icon slot="alert">
        <div slot="title" style="line-height: 1.5">
          <span>人数总计：{{pageInfo.total || 0}}</span>
        </div>
      </el-alert>
    </my-search>
    <my-table :columns="columns" url="/SystemEmployee/QueryeEmploeeOnline" ref="myTable" :query="queryData"></my-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {companyList} from '@/api/project'

export default {
  name: 'online',
  components: {},
  data () {
    return {
      items: {},
      queryData: {
        onlineStatus: 1,
        platformType: 1
      },
      pageInfo: {},
      columns: [
        {
          label: '公司名称',
          prop: 'companyName',
          operate: true,
          minWidth: 200,
          render: (h, {row}) => {
            return h('div', {}, `${row.companyName}(${row.companyAlias})`)
          }
        },
        {
          label: '当前登录项目',
          prop: 'currentProjectName'
        },
        {
          label: '员工姓名',
          prop: 'userName',
          width: 120,
        },
        {
          label: '角色',
          prop: 'roleNames',
          operate: true,
          render: (h, {row}) => {
            return h('div', {}, row.roleNames.join('、'))
          }
        },
        {
          label: '状态',
          prop: 'onlineStatusName'
        },
        {
          label: '登录平台',
          prop: 'platformTypeName'
        },
        {
          label: '当前/最近在线时长',
          prop: 'lastOnlineTimeSeconds',
          minWidth: 200,
          operate: true,
          render: (h, {row}) => {
            return h('div', {}, this.formatSeconds(row.currentOnlineTimeSeconds) + '/' + this.formatSeconds(row.lastOnlineTimeSeconds))
          }
        },
        {
          label: '最近登录/断开时间',
          prop: 'lastConnectionTime',
          minWidth: 200,
          operate: true,
          render: (h, {row}) => {
            return h('div', {}, row.lastConnectionTime + '/' + row.lastClosedConnectionTime)
          }
        },
        {
          label: '最近登录IP',
          prop: 'lastConnectionIP',
          width: 120
        }
      ],
      projectList: []
    }
  },
  computed: {
    ...mapGetters(['dict', 'companyTree']),
    searchItems () {
      const {dict, companyTree, projectList} = this
      return [
        {
          type: 'selectTree',
          label: '公司名称/编号：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany,
          style: 'margin-right: 0'
        },
        {
          type: 'select',
          label: '',
          name: 'projectId',
          options: projectList
        },
        {type: 'input', label: '员工名称：', name: 'emploeeName'},
        {type: 'select', label: '登录平台：', name: 'platformType', options: dict.PlatformType},
        {type: 'select', label: '状态：', name: 'onlineStatus', options: dict.OnlineStatus},
        {type: 'datePicker', label: '最近连接开始时间：', name: 'connectionStartTime'},
        {type: 'datePicker', label: '最近连接结束时间：', name: 'connectionEndTime'},
      ]
    },
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
  },
  methods: {
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    changeCompany (data) {
      this.$store.dispatch('getCompanyDownProject', {companyId: data.id, containsClosed: true}).then(res => {
        this.projectList = res
      })
    },
    // 秒数转化为时分秒
    formatSeconds (value) {
      if (value === null) {
        return ''
      }
      let secondTime = parseInt(value) // 秒
      let minuteTime = 0 // 分
      let hourTime = 0 // 小时
      // 如果秒数大于60，将秒数转换成整数
      if (secondTime > 60) {
        // 获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60)
        // 获取秒数，秒数取余，得到整数秒数
        secondTime = parseInt(secondTime % 60)
        // 如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
          // 获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60)
          // 获取小时后取余的分，获取分钟除以60取余的分
          minuteTime = parseInt(minuteTime % 60)
        }
      }
      let result = "" + parseInt(secondTime) + "秒"

      if (minuteTime > 0) {
        result = "" + parseInt(minuteTime) + "分" + result
      }
      if (hourTime > 0) {
        result = "" + parseInt(hourTime) + "小时" + result
      }
      return result
    },
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
      setTimeout(() => {
        const $myTable = this.$refs.myTable
        this.pageInfo = $myTable.pageInfo
      }, 500)
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
  }
}
</script>

<style scoped lang="scss">

</style>
