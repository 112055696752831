<template>
  <el-dialog title="公司角色菜单查看" :visible.sync="visible" fullscreen :before-close="close" :close-on-click-modal="false">
    <my-table :data="tableData" :columns="columns"></my-table>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" v-if="items.operaType === 1" @click="editMenu()">确认添加</el-button>
      <el-button type="primary" v-if="items.operaType === 2" @click="editMenu()">确认删除</el-button>
      <el-button type="primary" @click="exportExcel">导出</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {companyRolesView, companyRolesMenu} from '@/api/system'
import {downloadXlsx} from '@/utils/fileDownload'

export default {
  name: 'CompanyRolesView',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {
          roleIds: [],
          functions: []
        }
      }
    }
  },
  data () {
    return {
      tableData: [],
      columns: []
    }
  },
  watch: {
    visible (value) {
      if (value) {
        this.getData()
      }
    }
  },
  mounted () {},
  methods: {
    getData () {
      companyRolesView({functions: this.items.functions, roleIds: this.items.roleIds}).then(res => {
        this.tableData = res.data.data
        this.columns = res.data.heads.map(x => {
          return {
            label: x,
            prop: x
          }
        })
      })
    },
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
    },
    editMenu () {
      companyRolesMenu({functions: this.items.functions, roleIds: this.items.roleIds, operaType: this.items.operaType}).then(res => {
        this.$message.success(res.message)
      })
    },
    exportExcel () {
      const loading = this.$loading({
        lock: true,
        text: '下载中，请稍等...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      downloadXlsx('/api/SystemFunction/CompanyRoleFunctionViewExport',
        {
          functions: this.items.functions,
          roleIds: this.items.roleIds
        },
        `角色菜单导出`,
        () => loading.close()
      )
    },
  }
}
</script>

<style scoped lang="scss">

</style>
