<template>
  <el-container>
    <el-header>
      <img class="logo" src="../assets/images/logo.png" alt="">
      <div class="flex-h">
        <div class="flex-h">
          <el-dropdown size="medium" trigger="click" placement="bottom-start">
            <div class="user-info">你好, {{ user.roleName }}，{{ user.name }}</div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="pwdVisible = true">修改密码</el-dropdown-item>
              <el-dropdown-item>
                <a href="https://thoughts.teambition.com/sharespace/62a18d92e24b2c0041143bfd/folders/62a18d92e24b2c0041143bf4" target="_blank">使用文档</a>
              </el-dropdown-item>
              <el-dropdown-item divided @click.native="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <img src="@/assets/images/auth.png" @click="viewCompany" class="auth" alt="">
          <!-- <div @click="showChat" class="big" v-if="user.isCustomerService">
            <el-badge :value="badgeValue" class="item">
              <i class="el-icon-service"></i>
            </el-badge>
          </div> -->
        </div>
      </div>
    </el-header>
    <el-container>
      <el-aside width="200px">
        <el-menu
          default-active="1"
          unique-opened
          text-color="#ffffff"
          background-color="#545c64"
          active-text-color="#409EFF"
          :router="true">
          <template v-for="(item, index) in addRouters">
            <el-submenu :index="item.name">
              <template slot="title">
                <i style="color: #fff" :class="item.icon"></i>
                {{ item.meta.title }}
              </template>
              <el-menu-item
                :index="elem.name"
                v-for="elem in item.children"
                :key="elem.routeUrl"
              >
                <i style="color: #fff" :class="elem.icon"></i>
                {{ elem.meta.title }}
              </el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
      </el-aside>
      <el-main>
        <el-page-header
          style="margin-bottom: 10px"
          title="首页"
          @back="goHome"
          v-show="!routeMeta.hidden && !noShowHeader.includes($route.name)"
          :content="routeMeta.title">
        </el-page-header>
        <transition name="fade-transform" mode="out-in">
          <keep-alive :include="['area', 'notice']">
            <router-view></router-view>
          </keep-alive>
        </transition>
        <edit-pwd :visible.sync="pwdVisible"/>
        <authorize-company :visible.sync="visibleAuthorize" :items.sync="items"/>
      </el-main>
    </el-container>
    <!-- <chat-msg ref="chatMsg" @newMsg="newMsg"/> -->
  </el-container>
</template>

<script>
import {mapGetters} from 'vuex'
import editPwd from './components/editPwd'
import authorizeCompany from '@/views/system/components/authorizeCompany'
// import {connect, disconnect, /* connection */} from '@/utils/useMessageHub'
// import ChatMsg from './components/ChatMsg'
// import {suportNotify} from '@/utils/notification'

export default {
  name: 'layout',
  components: {editPwd, authorizeCompany, /* ChatMsg */},
  data () {
    return {
      pwdVisible: false,
      visibleAuthorize: false,
      items: {},
      // badgeValue: ''
    }
  },
  computed: {
    routeMeta () {
      return this.$route.meta || {}
    },
    ...mapGetters(['addRouters', 'user']),
    // 不显示面包屑的页，路由名称
    noShowHeader () {
      return ['noticeEdit']
    }
  },
  mounted() {
    this.$store.dispatch('getSysDict')
  },
  methods: {
    goHome () {
      this.$router.push('/home')
    },
    async logout() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          this.$router.push('/login')
          // disconnect()
        })
      })
    },
    viewCompany () {
      this.items.authorizeCompanyNames = this.user.authorizeCompany
      this.visibleAuthorize = true
    },
    // showChat () {
    //   this.badgeValue = ''
    //   this.$refs.chatMsg.show()
    // },
    // newMsg (value) {
    //   this.badgeValue = value
    // }
  }
}
</script>

<style scoped lang="scss">
.logo {
  height: 50px;
  object-fit: cover;
  margin-left: 10px;
}
.el-header {
  background-color: #409EFF;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.el-aside {
  background-color: #545c64;
  height: calc(100vh - 60px);
  &::-webkit-scrollbar {
    width: 0px;
  }
}
.el-menu {
  border: 0;
}
.el-main {
  background-color: #ffffff;
  height: calc(100vh - 60px);
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
}
.el-container {
  height: 100%;
}
// .big {
//   font-size: 30px;
//   color: #ffffff;
//   cursor: pointer;
// }
.el-dropdown-link {
  cursor: pointer;
  color: #ffffff;
  margin: 0 40px 0 50px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.dr-items {
  padding: 0 20px 20px 20px;
  .dr-title {
    font-size: 16px;
    margin: 20px 0;
  }
  .mus {
    width: 100px;
    text-align: center;
    color: #666;
    font-size: 12px;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all .5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.user-info {
  margin: 0 20px 0 0;
  line-height: 1;
  color: #ffffff;
  cursor: pointer;
}
.auth {
  width: 25px;
  height: 25px;
  object-fit: cover;
  margin-right: 30px;
  cursor: pointer;
}
.big {
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  margin-right: 30px;
  i {
    font-size: 24px;
  }
}
</style>
