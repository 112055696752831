import { login, logout, userInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { routerMap, constantRoutes } from '@/router'
import {clearStore, clearState} from '../index'
const layout = () => import('@/layout')

const initState = () => ({
  token: getToken() || '',
  user: {},
  roles: [],
  permissions: [],
  routes: routerMap,
  addRouters: []
})
const user = {
  state: initState(),

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_ROUTES: (state, routes) => {
      state.addRouters = routes
      state.routes = routerMap.concat(routes)
    },
    clearUser(state) {
      clearState(state, initState())
    }
  },

  actions: {
    // 登录
    Login({ commit }, data) {
      const userName = data.userName.trim()
      const passWord = data.passWord.trim()
      return new Promise((resolve, reject) => {
        login({userName, passWord}).then(res => {
          setToken(res.data)
          commit('SET_TOKEN', res.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 退出系统
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_PERMISSIONS', [])
          removeToken()
          clearStore()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_PERMISSIONS', [])
        // commit('SET_PROJECTTREE', [])
        // commit('SET_CURRENT', {})
        clearStore()
        removeToken()
        resolve()
      })
    },
    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        userInfo().then(res => {
          console.log(res.data)
          const {permissions, menus} = res.data
          commit('SET_USER', {
            isAdmin: res.data.isAdmin, // 是否管理员
            loginName: res.data.loginName,
            name: res.data.name,
            phone: res.data.phone,
            email: res.data.email,
            userId: res.data.userId,
            roleNames: res.data.roleNames, // 角色,数组集合
            roleName: res.data.roleName, // 角色,字符串化
            authorizeCompany: res.data.authorizeCompany || [], // 授权公司
            isCustomerService: res.data.isCustomerService, // 是否客服
          })
          commit('SET_PERMISSIONS', permissions)
          const routers = generator(menus)
          commit('SET_ROUTES', routers || [])
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
function generator (routerMap) {
  return routerMap.map(item => {
    const currentRouter = {
      // 路由地址 动态拼接生成
      path: item.routeUrl || '/',
      // 路由名称，建议唯一
      name: item.name || Math.random().toString(36).slice(-6), // 暂时
      // 该路由对应页面的 组件
      component: constantRoutes[item.name] || layout,
      icon: item.iconName,
      // meta: 页面标题, 菜单图标
      meta: { title: item.title, icon: item.icon || '', sortNumber: item.sortNumber }
    }
    // 是否有子菜单，并递归处理
    if (item.child && item.child.length > 0 && item.child[0].functionType !== 3) {
      // Recursion
      currentRouter.children = generator(item.child, currentRouter)
    } else {
      currentRouter.children = []
    }
    return currentRouter
  })
}

export default user
