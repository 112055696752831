<template>
  <el-dialog :title="title" :visible.sync="visible" :close-on-click-modal="false" :before-close="close" destroy-on-close width="30%">
    <el-form :model="form" ref="form" :rules="rules" label-width="100px">
      <el-form-item label="上级菜单" prop="parentId">
        <treeselect v-model="form.parentId" :options="[{functionId: 0, name: '无'}, ...menu]" :normalizer="normalizer" placeholder="选择上级菜单" />
      </el-form-item>
      <el-form-item label="菜单名称" prop="name">
        <el-input v-model.trim="form.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="菜单类型" prop="functionType">
        <el-radio-group v-model="form.functionType">
          <el-radio :label="1">目录</el-radio>
          <el-radio :label="2">菜单</el-radio>
          <el-radio :label="3">按钮</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="菜单图标" prop="iconName" v-if="form.functionType !== 3">
        <el-input v-model="form.iconName" placeholder="请输入element-icon"></el-input>
      </el-form-item>
      <el-form-item label="路由地址" prop="routeUrl" v-if="form.functionType !== 3">
        <el-input v-model.trim="form.routeUrl" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="权限标识" prop="code" v-if="form.functionType === 3">
        <el-input v-model.trim="form.code" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="api地址" prop="apiRoute" v-if="form.functionType === 3">
        <el-input v-model.trim="form.apiRoute" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="显示排序" v-if="form.id">
        <el-input-number :min="1" :max="100" style="width: 100%" controls-position="right" v-model="form.sortNumber" placeholder="请输入"></el-input-number>
      </el-form-item>
      <el-form-item label="状态" v-if="form.id">
        <el-radio-group v-model="form.status">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="2">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="标签类型" prop="functionFlagType">
        <el-radio-group v-model="form.functionFlagType">
          <el-radio :label="item.value" v-for="item in dict.FunctionFlagType" :key="item.value">{{item.displayName}}</el-radio>
          <!-- <el-radio :label="1">特殊</el-radio> -->
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remarks" placeholder="请输入" type="textarea" :autosize="{minRows: 2}"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import {menuEdit} from '@/api/system'
import MyUploadFile from '@/components/MyUploadFile'
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
export default {
  name: 'MenuEdit',
  components: {
    Treeselect,
    MyUploadFile
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    },
    menu: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      form: {},
      rules: {
        parentId: [{ required: true, trigger: "change", message: "请选择" }],
        name: [{ required: true, trigger: "blur", message: "请输入" }],
        functionType: [{ required: true, trigger: "change", message: "请选择" }],
        functionFlagType: [{ required: true, trigger: "change", message: "请选择" }],
      },
      loading: false
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.form = this.items.id > 0 ? this.items : {
          name: '',
          parentId: 0,
          functionType: 1,
          remarks: '',
          routeUrl: '',
          apiRoute: '',
          code: '',
          iconName: '',
          functionFlagType: 0
        }
      }
    }
  },
  computed: {
    ...mapGetters(['dict']),
    title () {
      return this.items.id > 0 ? '编辑' : '新增'
    }
  },
  mounted () {},
  methods: {
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child
      }
      return {
        id: node.functionId,
        label: node.name,
        children: node.child
      }
    },
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
      this.form = {}
      this.$refs.form.resetFields()
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          menuEdit(this.form).then(res => {
            this.$message.success(res.message)
            this.loading = false
            this.$emit('refresh')
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
