<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="close" :close-on-click-modal="false" width="30%">
    <el-form :model="form" :rules="rules" label-width="100px" ref="form">
      <el-form-item label="说明">
        1.房屋资源相关 会删除所有房屋资源、车位、车辆、家庭成员、收费项、票据、抄表
        2.收费项目相关 会删除所有收费项目相关收费项、票据、抄表
        3.家庭成员相关 会删除所有家庭成员
        4.车位车辆相关 会删除所有车位车辆、关联收费项、抄表则也删除，道闸日志
      </el-form-item>
      <el-form-item label="数据类型" prop="removeImportDataType">
        <el-select v-model="form.removeImportDataType" placeholder="请选择" style="width: 100%">
          <el-option v-for="item in dict.RemoveImportDataType" :key="item.value" :label="item.displayName" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remarks" type="textarea" placeholder="请输入" :autosize="{minRows: 2}"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="submit" type="primary" :loading="loading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import {delImport} from '@/api/project'
const initData = {
  removeImportDataType: '',
  remarks: ''
}
export default {
  name: 'AreaDelImportData',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      form: {...initData},
      rules: {
        removeImportDataType: [{ required: true, trigger: "change", message: "请选择" }],
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters(['dict']),
    title () {
      return this.items.name + '导入数据删除'
    }
  },
  mounted () {},
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
      this.form = {...initData}
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          delImport({projectId: this.items.id, ...this.form}).then(res => {
            this.$message.success(res.message)
            this.loading = false
            this.$emit('refresh')
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
