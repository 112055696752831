<template>
  <editor :value="value" @input="changeInput" ref="tinymce" :init="setting"></editor>
</template>

<script>
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/skins/content/default/content.css'
import 'tinymce/themes/silver/theme.min.js'
// import 'tinymce/skins/ui/oxide/skin.css'
import 'tinymce/icons/default/icons'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'// 列表插件
import 'tinymce/plugins/wordcount'// 字数统计插件
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/print'
import "tinymce/plugins/image"
import "tinymce/plugins/paste"
import {uploadFile} from '@/api/system'
import {getLocalStorage, setLocalStorage} from '@/utils/auth'
import {throttle} from '@/utils/tools'
import {aliOssPolicy} from '@/api/login'

export default {
  name: 'MyTinymce',
  components: {
    Editor
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      oss: getLocalStorage('ossConfig') || {},
      url: process.env.VUE_APP_BASE_IMG,
      setting: {
        toolbar:
        "image code print alignleft aligncenter alignright alignjustify table fontsizeselect forecolor backcolor | bold italic underline | indent outdent",
        plugins: "link table lists wordcount print code image paste",
        paste_data_images: true,
        language: 'zh_CN',
        statusbar: true,
        menubar: false, // （1级菜单）最上方的菜单
        language_url: '/tinymce/langs/zh_CN.js',
        skin_url: `/tinymce/skins/ui/oxide`,
        height: 350,
        valid_elements: '*[*]',
        valid_children: '+body[style]',
        branding: false, // （隐藏右下角技术支持）水印“Powered by TinyMCE”
        urlconverter_callback: (url, node, on_save, name) => {
          console.log('图片链接', url, node, on_save, name)
          return url
        },
        images_upload_handler: (blobInfo, success) => {
          // console.log(blobInfo.blob(), blobInfo, 212143434)
          const blob = blobInfo.blob()
          if (!blob.name) {
            return
          }
          let fd = new FormData()
          fd.append("policy", this.oss.policy)
          fd.append("signature", this.oss.signature)
          fd.append("dir", this.oss.dir)
          fd.append("expire", this.oss.expire)
          fd.append("success_action_status", this.oss.success_action_status)
          fd.append("OSSAccessKeyId", this.oss.OSSAccessKeyId)
          fd.append("key", this.oss.dir + blob.lastModified + "/" + blob.name)
          fd.append("file", blobInfo.blob())
          uploadFile(fd).then(() => {
            let filePath = this.oss.dir + blob.lastModified + "/" + blob.name
            let result = this.url + filePath
            success(result)
          }).catch(() => {
            this.$message.error('上传失败')
          })
        }
      },
    }
  },
  mounted () {
    tinymce.init({})
    const date = new Date().getTime()
    if (!this.oss.host) {
      this.getAliOss()
      return
    }
    // 判断是否过期
    if (date - Number(this.oss.expire) > 0) {
      // 移除ossConfig并且重新请求
      localStorage.removeItem('ossConfig')
      this.getAliOss()
    }
  },
  methods: {
    getAliOss: throttle(function () {
      aliOssPolicy().then(res => {
        let oss = {...res.data}
        oss.success_action_status = '200'
        oss.OSSAccessKeyId = res.data.accessid
        // oss.expire = '1676344836236'
        delete oss.accessid
        this.oss = oss
        const ossConfig = {...oss}
        setLocalStorage('ossConfig', ossConfig)
      })
    }),
    changeInput (value) {
      this.$emit('input', value)
    },
    insert (key) {
      const editor = this.$refs.tinymce.editor
      editor.undoManager.transact(() => {
        editor.focus()
        editor.insertContent(`<span>${key}</span>`)
      })
    },
    print () {
      const editor = this.$refs.tinymce.editor
      editor.undoManager.transact(() => {
        editor.execCommand('mcePrint')
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
