<!-- 通用表格组件，带分页，基础传参：url, columns -->
<template>
  <div class="margin-top-20">
    <vxe-table
      border
      :show-footer="summary"
      auto-resize
      ref="xTable"
      :max-height="maxHeight"
      :height="height"
      :footer-method="footerMethod"
      :row-config="{isCurrent: highlight, keyField: rowkey}"
      :sort-config="sortConfig"
      @sort-change="sortChange"
      stripe
      class="my-table"
      :show-header="showHeader"
      :column-config="{resizable: true}"
      align="left"
      size="small"
      empty-text="暂无数据"
      :seq-config="{seqMethod}"
      :row-class-name="rowClassName"
      :expand-config="expandConfig"
      @checkbox-all="selectChangeAll"
      @checkbox-change="selectChange"
      @cell-click="cellClick"
      :checkbox-config="{checkMethod: checkMethod, checkRowKeys: checkRowKeys }"
      :edit-config="editConfig"
      :footer-cell-class-name="footerCellClassName"
      :data="data.length > 0 ? data : [...tableData, ...summaryData]"
    >
      <vxe-table-column
        v-if="selection"
        type="checkbox"
        width="60"
        fixed="left"
      ></vxe-table-column>
      <vxe-table-column
        v-if="showIndex"
        type="seq"
        width="60"
        title="序号"
        fixed="left"
      ></vxe-table-column>
      <template v-for="(item, index) in columns">
        <vxe-table-column
          :title-prefix="item.titleHelp"
          :key="item.prop"
          v-if="item.operate"
          :title="item.label"
          :field="item.prop"
          :visible="item.visible"
          :width="item.width"
          :fixed="item.fixed || undefined"
          :min-width="item.minWidth">
          <template slot-scope="scope">
            <expandDom :column="item" v-if="item.render" :row="scope.row" :render="item.render" :index="index"></expandDom>
            <slot
              v-else
              :name="item.prop"
              :$index="scope.$index"
              :row="scope.row"
            ></slot>
          </template>
        </vxe-table-column>
        <vxe-table-column
          v-else
          :fixed="item.fixed || undefined"
          :edit-render="item.edit"
          :title="item.label"
          :field="item.prop"
          :visible="item.visible"
          :title-prefix="item.titleHelp"
          :width="item.width"
          :min-width="item.minWidth"
          :type="item.type"
          :formatter="item.formatter"
          :sortable="item.sort"
        >
        </vxe-table-column>
        <!-- <div v-if="!item.show" :key="item.prop">
        </div> -->
      </template>
      <slot></slot>
    </vxe-table>
    <div class="pagination-container" v-if="showPage">
      <el-pagination
        v-if="tableData.length > 0"
        style="text-align: right"
        background
        :current-page.sync="params.pageIndex"
        :page-size.sync="params.pageLimit"
        layout='total, sizes, prev, pager, next, jumper'
        :page-sizes="[10, 20, 50, 100, 200]"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request"
import { returnFloat } from "@/utils/tools"

export default {
  name: "myTable",
  components: {
    // renden渲染组件
    expandDom: {
      functional: true,
      props: {
        row: Object,
        render: Function,
        index: Number,
        column: {
          type: Object,
          default: null
        }
      },
      render: (h, ctx) => {
        const params = {
          row: ctx.props.row,
          index: ctx.props.index
        }
        if (ctx.props.column) params.column = ctx.props.column
        return ctx.props.render(h, params)
      }
    }
  },
  props: {
    // 默认选中的复选框
    checkRowKeys: {
      type: Array,
      default: () => []
    },
    // 显示表头
    showHeader: {
      type: Boolean,
      default: true
    },
    // 多选方法
    checkBoxMethod: {
      type: Function
    },
    // 最大高度
    maxHeight: {
      type: [String, Number],
      default: '100%'
    },
    // 是否展示footer
    summary: {
      type: Boolean,
      default: false
    },
    // 数据源
    data: {
      type: Array,
      default: () => []
    },
    // 是否展示checkbox
    selection: {
      type: Boolean,
      default: false
    },
    // 是否折叠行
    expand: {
      type: Boolean,
      default: false
    },
    // 是否展示序号
    showIndex: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default: () => []
    },
    // 默认查询条件
    query: {
      type: Object,
      default: () => {
        return {}
      }
    },
    url: {
      type: String,
      default: ""
    },
    dataKey: {
      type: String,
      default: "items"
    },
    rowkey: {
      type: String,
      default: "id"
    },
    // 展开行配置
    expandConfig: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: {
      type: [String, Number],
      default: ''
    },
    // 求和键,如要显示总序号，则添加index
    sumKey: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 导出文件名
    fileName: {
      type: String,
      default: ''
    },
    // 自定义
    custom: {
      type: Boolean,
      default: false
    },
    // 删除行，添加删除线
    delLine: {
      type: Function
    },
    // 自定义footer
    customFooter: {
      type: Function
    },
    // 高亮行
    highlight: {
      type: Boolean,
      default: true
    },
    // 表尾数据
    footerData: {
      type: Array,
      default: () => []
    },
    // 自定义合计行，拼接数据
    summaryData: {
      type: Array,
      default: () => []
    },
    // 是否自动请求数据
    autoRequest: {
      type: Boolean,
      default: true
    },
    // 自定义序号
    seqMethod: {
      type: Function,
    },
    editConfig: {
      type: Object,
      default: () => {
        return {
          trigger: 'click',
          mode: 'cell'
        }
      }
    },
    // 请求条数，一页10条默认
    pageLimit: {
      type: Number,
      default: 10
    },
    // 是否展示分页，默认显示，在收银台不能显示
    showPage: {
      type: Boolean,
      default: true
    },
    // 排序属性
    sortConfig: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      pageInfo: {
        total: 0
      },
      params: {
        pageIndex: 1,
        pageLimit: this.pageLimit
      },
      items: {},
      tableData: []
    }
  },
  mounted() {
    if (this.autoRequest) {
      setTimeout(() => {
        this.getList()
      }, 200)
    }
    this.bus.$on("refreshCol", this.refreshCol)
    this.bus.$on("resetCol", this.resetCol)
  },
  methods: {
    // 每页条数改变
    handleSizeChange(val) {
      this.params.pageLimit = val
      setTimeout(() => {
        this.getList()
      }, 400)
    },
    // 页数改变
    handleCurrentChange(val) {
      this.params.pageIndex = val
      setTimeout(() => {
        this.getList()
      }, 400)
    },
    cellClick (item) {
      const {column, row} = item
      this.$emit('cell', {column, row})
    },
    // 隐藏列
    refreshCol() {
      if (this.$refs.xTable !== undefined) {
        this.$refs.xTable.refreshColumn()
      }
    },
    // 还原列
    resetCol() {
      if (this.$refs.xTable !== undefined) {
        this.$refs.xTable.resetColumn()
      }
    },
    // 展开所有行
    setAllRowExpand () {
      this.$refs.xTable.setAllRowExpand(true)
    },
    // 手动更新footer
    updateFooter() {
      this.$refs.xTable.updateFooter()
    },
    // footer
    footerMethod({ columns, data }) {
      if (this.footerData.length > 0) {
        return [this.footerData]
      }
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "小计"
          return
        }
        if (this.showIndex && index === 1 && this.sumKey.includes('index')) {
          sums[index] = this.data.length + 1
          return
        }
        // 判断有没求和键
        if (this.sumKey.includes(column.property)) {
          let values = []
          // 自定义表格footer
          if (this.customFooter) {
            values = this.customFooter(column, data)
          } else {
            values = data.map(item => Number(item[column.property] ? item[column.property] : ""))
          }
          const value = values.every(value => isNaN(value))
          if (!value) {
            sums[index] = values.reduce((prev, curr) => {
              const v = Number(curr)
              if (!isNaN(v) /* && v >= 0 */) {
                // 加上v>=0的话就计算合计都是正数，取消掉就可以计算负数
                return Number(prev) + Number(curr)
              } else {
                return ""
              }
            }, 0)
            sums[index] = returnFloat(sums[index])
          }
        } else {
          sums[index] = ""
        }
      })
      return [sums]
    },
    footerCellClassName () {
      return 'count-red'
    },
    clearData () {
      this.tableData = []
    },
    // 刷新
    refresh(query = {}) {
      this.items = query
      if (query.pageIndex) {
        this.params.pageIndex = query.pageIndex
      }
      setTimeout(() => {
        this.getList()
      }, 100)
    },
    // 查询
    search(query = {}) {
      this.items = query
      this.params.pageIndex = 1
      setTimeout(() => {
        this.getList()
      }, 100)
    },
    // 重置
    reset () {
      this.items = {}
      this.params.pageIndex = 1
      if (this.autoRequest) {
        setTimeout(() => {
          this.getList()
        }, 100)
      } else {
        this.tableData = []
      }
    },
    // 请求数据
    getList() {
      if (!this.url) {
        return
      }
      let query = {...this.query, ...this.items, ...this.params}
      const func = data => {
        return request({
          url: this.url,
          method: "post",
          timeout: ['/ReportForm/InputTableAnalysis2', '/ReportForm/InputTableAnalysis'].includes(this.url) ? 180000 : 15000, // 为抄表明细设置请求超时3分钟
          data
        })
      }
      const loading = this.$loading({
        lock: true,
        text: '加载中，请稍等...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      func(query).then(res => {
        // 是否自定义获取表格数据，定义会返回res.data内所有数据
        if (this.custom) {
          this.tableData = res.data[this.dataKey]?.items.map(x => {
            return {
              ...x,
              loading: false
            }
          }) ?? []
          this.pageInfo = {
            total: res.data[this.dataKey]?.dataCount ?? 0
          }
          this.$emit('custom', res.data)
        } else {
          this.tableData = res.data[this.dataKey].map(x => {
            return {
              ...x,
              loading: false
            }
          })
          this.pageInfo = {
            total: res.data.dataCount
          }
        }

        this.getColumns()
        setTimeout(() => {
          loading.close()
        }, 200)
      }).catch(() => {
        loading.close()
      })
    },
    // 获取列名
    getColumns () {
      let filterColumns = this.$refs.xTable.getColumns()
      this.bus.$emit("setCol", filterColumns.filter(x => x.type !== "expand"))
    },
    selectChangeAll ({ records, checked }) {
      let arr = []
      if (this.rowkey) {
        arr = records.map(x => x[this.rowkey])
      } else {
        arr = records
      }
      this.$emit("changeSelectAll", {list: arr, checked: checked})
    },
    selectChange({ records, rowIndex, checked }) {
      // console.log(rowIndex)
      let arr = []
      if (this.rowkey) {
        arr = records.map(x => x[this.rowkey])
      } else {
        arr = records
      }
      this.$emit("changeSelect", {list: arr, index: rowIndex, checked: checked})
    },
    // 清空全部选中
    clearCheckboxRow () {
      this.$refs.xTable.clearCheckboxRow(false)
    },
    // 下载文件，废弃
    // exportFile () {
    //   this.$refs.xTable.exportData({
    //     type: 'xlsx',
    //     message: false,
    //     filename: this.fileName,
    //   })
    // },
    checkMethod ({row}) {
      if (this.checkBoxMethod) {
        return this.checkBoxMethod(row)
      }
    },
    delRow (index) {
      this.tableData.splice(index, 1)
    },
    // 设置全选
    setAllCheckboxRow () {
      setTimeout(() => {
        this.$refs.xTable.setAllCheckboxRow(true)
      }, 100)
    },
    // 切换选中状态
    toggleCheckboxRow (data) {
      this.$refs.xTable.toggleCheckboxRow(data)
    },
    // 设置是否选中行
    setCheckboxRow (data, boolean = true) {
      this.$refs.xTable.setCheckboxRow(data, boolean)
    },
    rowClassName ({ rowIndex, row }) {
      if (this.delLine) {
        return this.delLine(row, rowIndex)
      }
      return ''
    },
    getTableData () {
      return this.$refs.xTable.getTableData()
    },
    isActiveByRow (row) {
      this.$refs.xTable.isActiveByRow(row)
    },
    sortChange ({property, order}) {
      this.$emit('sortChange', {property, order})
    }
  }
}
</script>

<style lang="scss">
.vxe-table--tooltip-wrapper {
  z-index: 10000 !important;
}
// .vxe-cell {
//   max-height: 100px !important
// }
.my-table {
  width: 100%;
  tr {
    position: relative;
  }
}
.row-del {
  position: relative;
  color: #999;
  &::after {
    content: no-open-quote;
    position: absolute;
    margin-top: 20px;
    left: 60px;
    border-bottom: 1px solid #999;
  }
}
.gray-text {
  color: #999;
  position: relative;
}
.ac {
  &::after {
    right: 120px;
  }
}
.no-ac {
  &::after {
    width: calc(100% - 60px);
  }
}
.bg-color {
  background-color: #c4d8e0 !important;
}
.pagination-container {
  background: #fff;
  padding: 20px 0;
}
.count-red {
  color: #da251c;
}
// .margin-top-20 {
//   height: 100vh;
// }
</style>
