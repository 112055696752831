import drag from './drag'
import stopdrag from './stopdrag'

const install = function(Vue) {
  Vue.directive('el-drag-dialog', drag) // 如果第一次打开dialog能拖动，后续打开不能拖动，给dialog外层套一个div，并且v-if判断或者destroy-on-close=false
  Vue.directive('el-stopdrag', stopdrag) // 禁止拖动，解决input框不能选中bug
}

if (window.Vue) {
  window['el-drag-dialog'] = drag
  window['el-stopdrag'] = stopdrag
  Vue.use(install)
}

// drag.install = install
export default install

