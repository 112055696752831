<template>
  <el-dialog title="新增门禁道闸" :visible.sync="visible" :before-close="close" width="30%" :close-on-click-modal="false">
    <el-form :model="form" ref="form" :rules="rules" label-width="100px">
      <el-form-item label="项目名称" prop="projectId">
        <el-cascader
          style="width: 100%"
          :props="{value: 'id', label: 'name', children: 'child', emitPath: false}"
          filterable
          clearable
          :options="tree"
          v-model="form.projectId">
        </el-cascader>
      </el-form-item>
      <el-form-item label="小区名称" prop="village">
        <el-input v-model="form.villageName" placeholder="请选择" :readonly="true">
          <el-button slot="append" @click="openProject">请选择项目</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="submit" type="primary">确定</el-button>
    </div>
    <el-dialog title="选择项目" :visible="visibleArea" :close-on-click-modal="false" append-to-body :before-close="closeProject">
      <my-search :items="searchItems" v-if="visibleArea" @search="search" @clear="clear"></my-search>
      <my-table :columns="columnsArea" ref="myTable" auto-request v-if="visibleArea" url="/EntranceGuard/GetVillageList">
        <vxe-table-column title="操作">
          <template #default="{row}">
            <el-button type="text" @click="select(row)">选择</el-button>
          </template>
        </vxe-table-column>
      </my-table>
    </el-dialog>
  </el-dialog>
</template>

<script>
const initData = {
  village: {},
  projectId: ''
}
import {entranceGuardAdd} from '@/api/project'
export default {
  name: 'EntranceGuardEdit',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    tree: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      form: {...initData},
      columns: [
        {
          label: '小区名称',
          prop: 'villageName'
        },
        {
          label: '对外标识',
          prop: 'villageCode'
        },
        {
          label: '负责人姓名',
          prop: 'principal'
        },
        {
          label: '负责人电话',
          prop: 'principalTel'
        },
      ],
      columnsArea: [
        {
          label: '项目名',
          prop: 'villageName'
        },
        {
          label: '负责人',
          prop: 'principal'
        },
        {
          label: '电话',
          prop: 'principalTel'
        },
        {
          label: '地址',
          prop: 'address'
        },
      ],
      rules: {
        villageName: [{ required: true, trigger: "change", message: "请选择" }],
        projectId: [{ required: true, trigger: "change", message: "请选择" }],
      },
      list: [],
      loading: false,
      visibleArea: false
    }
  },
  computed: {
    searchItems () {
      return [
        {type: 'input', label: '项目名称：', name: 'villageName'}
      ]
    }
  },
  methods: {
    select (item) {
      this.form.villageId = item.villageId
      this.form.villageName = item.villageName
      this.form.villageCode = item.villageCode
      this.visibleArea = false
    },
    search (query) {
      this.$refs.myTable.search(query)
    },
    clear () {
      this.$refs.myTable.reset()
    },
    openProject () {
      this.visibleArea = true
    },
    closeProject () {
      this.visibleArea = false
    },
    close () {
      this.$emit('update:visible', false)
      this.form = {}
      this.loading = false
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const data = {
            projectId: this.form.projectId,
            villageId: this.form.villageId,
            villageCode: this.form.villageCode,
            villageName: this.form.villageName,
          }
          entranceGuardAdd(data).then(res => {
            this.loading = false
            this.$message.success(res.message)
            this.$emit('refresh')
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
