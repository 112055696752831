<template>
  <el-dialog title="设置打印控件版本" :visible.sync="visible" :before-close="close" :close-on-click-modal="false">
    <el-form :model="form" :rules="rules" label-width="120px" ref="form">
      <el-form-item label="版本号" prop="number">
        <el-input v-model="form.number" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="64位下载链接" prop="link_x64">
        <div class="flex-h">
          <el-input v-model="form.link_x64" placeholder="请输入" style="width: 95%"></el-input>
          <my-upload-file :show-loading="true" @input="input($event, 'link_x64')" :fileType="['exe', 'zip', 'rar']" :file-size="100" :show-gallery="false">
            <el-button slot="content" type="text">上传</el-button>
          </my-upload-file>
        </div>
      </el-form-item>
      <el-form-item label="32位下载链接" prop="link_x32">
        <div class="flex-h">
          <el-input v-model="form.link_x32" placeholder="请输入" style="width: 95%"></el-input>
          <my-upload-file :show-loading="true" @input="input($event, 'link_x32')" :file-type="['exe', 'zip', 'rar']" :file-size="100" :show-gallery="false">
            <el-button slot="content" type="text">上传</el-button>
          </my-upload-file>
        </div>
      </el-form-item>
      <el-form-item label="更新日志" prop="updateDesc">
        <el-input v-model="form.updateDesc" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="submit" :loading="loading" type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {printVersion, getPrintVersion} from '@/api/print'
import MyUploadFile from '@/components/MyUploadFile'

const initData = {
  number: '',
  link_x64: '',
  link_x32: '',
  updateDesc: ''
}
export default {
  name: 'PrintWorldVerison',
  components: {MyUploadFile},
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {...initData},
      rules: {
        link_x32: [{ required: true, trigger: "blur", message: "请输入" }],
        link_x64: [{ required: true, trigger: "blur", message: "请输入" }],
        number: [{ required: true, trigger: "blur", message: "请输入" }],
      },
      loading: false,
    }
  },
  watch: {
    visible (value) {
      if (value) {
        this.getVersion()
      }
    }
  },
  mounted () {},
  methods: {
    input (value, key) {
      this.form[key] = value
    },
    getVersion () {
      getPrintVersion().then(res => {
        this.form = res.data || {...initData}
      })
    },
    close () {
      this.$emit('update:visible', false)
      this.form = {...initData}
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          printVersion(this.form).then(res => {
            this.loading = false
            this.$message.success(res.message)
            this.$emit('refresh')
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
