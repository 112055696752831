<template>
  <el-dialog :title="'授权' + `${items.name}`" width="900px" :visible.sync="visible" :before-close="close" destroy-on-close :close-on-click-modal="false">
    <div class="flex-h" v-if="visible" style="align-items: flex-start;">
      <company-Tree @node-click="nodeClick" :checked-company-id="companyId" :tree-data="rolesCompanyTree" style="width: 250px"/>
      <div class="flex-h" style="flex: 1">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>项目授权</span>
            <el-checkbox style="float: right; padding: 3px 0" @change="change($event, 'projects')">全选</el-checkbox>
          </div>
          <div class="card-content">
            <div v-for="item in detail.projects" :key="item.id" class="flex-h flex-between">
              <el-checkbox v-model="item.isSelected" :key="item.id">{{item.name}}</el-checkbox>
            </div>
            <div v-if="detail.projects.length === 0">
              <el-empty :image-size="140"></el-empty>
            </div>
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>角色授权</span>
            <el-checkbox style="float: right; padding: 3px 0" @change="change($event, 'roles')">全选</el-checkbox>
          </div>
          <div class="card-content">
            <div v-for="item in detail.roles" :key="item.id" class="flex-h flex-between">
              <el-checkbox v-model="item.isSelected" :key="item.id">{{item.name}}</el-checkbox>
              <div class="primary-text" @click="edit(item)">查看</div>
            </div>
            <div v-if="detail.roles.length === 0">
              <el-empty :image-size="140"></el-empty>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="close">关闭</el-button>
      <el-button @click="submit" type="primary" :loading="loading">保存</el-button>
    </div>
    <roles-edit :visible.sync="visibleRoles" :disabled="true" :companyTree="rolesCompanyTree" :tree="treeList" :items.sync="roles" @refresh="refresh"/>
  </el-dialog>
</template>

<script>
import {staffCompanyTree, getAuthorization, Authorization, authorizationTree, rolesDetail} from '@/api/system'
import CompanyTree from './CompanyTree'
import RolesEdit from './RolesEdit'

export default {
  name: 'StaffToAuthorization',
  components: {CompanyTree, RolesEdit},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      loading: false,
      detail: {
        projects: [],
        roles: []
      },
      companyId: '',
      roles: {},
      treeList: [],
      visibleRoles: false,
      // companyTree: [],
      rolesCompanyTree: [],
    }
  },
  watch: {
    visible (value) {
      if (value) {
        this.getStaffCompanyTree()
      }
    }
  },
  methods: {
    getStaffCompanyTree () {
      staffCompanyTree({emploeeId: this.items.id}).then(res => {
        this.rolesCompanyTree = [res.data]
        this.companyId = res.data.id
        this.getAuthorization()
      })
    },
    change (value, type) {
      this.detail[type].forEach(item => {
        item.isSelected = value
      })
    },
    nodeClick (data) {
      this.companyId = data.id
      this.getAuthorization()
    },
    getAuthorization () {
      getAuthorization({companyId: this.companyId, userId: this.items.id}).then(res => {
        this.detail = res.data
      })
    },
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
      this.detail = {
        projects: [],
        roles: []
      }
      this.companyId = ''
      this.rolesCompanyTree = []
    },
    submit () {
      this.loading = true
      let formKey = {
        companyId: this.companyId,
        emploeeId: this.items.id,
        projectIds: this.detail.projects.filter(x => x.isSelected).map(x => x.id),
        roleIds: this.detail.roles.filter(x => x.isSelected).map(x => x.id)
      }
      Authorization(formKey).then(res => {
        this.$message.success(res.message)
        this.loading = false
        this.$emit('refresh')
      }).catch(() => {
        this.loading = false
      })
    },
    edit (row) {
      authorizationTree({companyId: this.companyId, roleId: row.id}).then(res => {
        rolesDetail({roleId: row.id}).then(result => {
          this.roles = result.data
          this.treeList = res.data
          this.visibleRoles = true
        })
      })
    },
    refresh () {
      this.getAuthorization()
    }
  }
}
</script>

<style scoped lang="scss">
 .box-card {
  width: 50%;
  &:first-child {
    margin-right: 20px;
  }
}
.card-content {
  overflow-y: auto;
  height: 400px;
  .flex-h {
    margin-bottom: 5px;
  }
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}
.primary-text {
  color: #409EFF;
  cursor: pointer;
}
</style>
