<template>
  <div
    v-loading="mask"
    element-loading-text="当前身份不是客服，请关闭"
    element-loading-spinner="el-icon-warning"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    element-loading-custom-class="custom-chat-msg"
    class="custom-chat-msg">
    <div class="flex-h bg-blue">
      <img src="@/assets/images/serviceTitle.png"/>
      <div class="services-name" v-if="chatUser.companyAlias">
        <div>
          {{chatUser.companyAlias}}{{ chatUser.companyName }}，{{ chatUser.projectName }}，
          {{chatUser.name}}，{{ chatUser.roleName }}，{{ chatUser.phone }}
        </div>
        <div class="flex-h" style="font-size: 14px;">
          备注:{{ chatUser.chatRemarks }}
          <button type="button" @click="chatRemarks" class="chat-remarks" v-if="!chatUser.endMsg">
            <i class="el-icon-edit" style="color: #fff"></i>
          </button>
        </div>
      </div>
      <div class="services-name" v-else>暂无对话</div>
    </div>
    <div class="flex-h flex-start">
      <div class="connect-user">
        <div
          v-for="(item, index) in connectionSuccessUsers"
          :key="item.sessionId"
          class="user-item"
          :class="{'active-item': chatUser.sessionId === item.sessionId}"
          @click="selectUser(item)">
          <div class="user-item-alias">{{item.companyAlias}}{{ item.companyName }}</div>
          <div class="badge" v-if="item.numberUnreadMsg > 0">{{ item.numberUnreadMsg }}</div>
          <div class="user-item-name flex-h">
            <div class="flex-h">
              <span v-if="item.endMsg">{{ item.name }}</span>
              <span v-else style="color: #67C23A">{{ item.name }}</span>
              <span style="font-size: 12px;margin-left: 10px;color: #409EFF;">{{ item.isInputing ? '输入中...' : '' }}</span>
            </div>
            <i class="el-icon-close" style="color: #909399;font-size: 14px;" @click.stop="closeConnection(item, index)"></i>
          </div>
        </div>
      </div>
      <div class="chat-box">
        <div class="chat-logs" id="chatLogs">
          <!-- 没滚动到顶，并且没有结束会话，并且是有会话的情况下才显示 -->
          <div style="text-align: center" v-if="!isTopEnd && !chatUser.endMsg && chatUser.companyAlias">
            <el-button type="text" v-if="!loadingState" @click="getMore">查看更多消息</el-button>
            <i v-else style="font-size: 18px;" class="el-icon-loading"></i>
          </div>
          <template v-for="item in chatUser.logs">
            <!-- 收费平台发送的消息 -->
            <div class="flex-h charge-send-logs-item" v-if="!item.isMe" :key="item.timeStamp">
              <img class="avatar" src="@/assets/images/avatar.png"/>
              <el-tooltip effect="dark" :content="`发送时间：${item.time}`" placement="top">
                <div v-if="item.messageType === 1" class="msg-cell">{{ item.msg }}</div>
                <div v-if="item.messageType === 2">
                  <el-image
                    style="max-height: 100px;max-width: 100px;"
                    :src="item.msg + '?x-oss-process=image/resize,m_lfit,h_100,w_100'"
                    :preview-src-list="[item.msg]">
                  </el-image>
                </div>
              </el-tooltip>
            </div>
            <!-- 运营平台发送的消息 -->
            <div class="flex-h operate-send-logs-item" v-if="item.isMe" :key="item.timeStamp">
              <el-tooltip class="item" effect="dark" :content="`发送时间：${item.time}`" placement="top">
                <div v-if="item.messageType === 1" class="msg-cell">{{ item.msg }}</div>
                <div v-if="item.messageType === 2">
                  <el-image
                    style="max-height: 100px;max-width: 100px;"
                    :src="item.msg + '?x-oss-process=image/resize,m_lfit,h_100,w_100'"
                    :preview-src-list="[item.msg]">
                  </el-image>
                </div>
              </el-tooltip>
              <img class="avatar" src="@/assets/images/kf.png"/>
            </div>
          </template>
        </div>
        <div class="chat-send">
          <div class="action-button">
            <el-button :disabled="disabledBtn" @click="endConnection" v-if="!chatUser.endMsg">结束会话</el-button>
            <el-button :disabled="disabledBtn" @click="changeServices" v-if="!chatUser.endMsg">转接会话</el-button>
            <el-button :disabled="disabledBtn" @click="prtSc" v-if="!chatUser.endMsg">屏幕截图</el-button>
            <my-upload-file @input="inputFile($event)" :fileType="['image/jpg', 'image/jpeg', 'image/png']">
              <el-button :disabled="disabledBtn" slot="content">上传附件</el-button>
            </my-upload-file>
          </div>
          <my-editable
            v-model="msg"
            ref="myEditable"
            :placeholder="chatUser.endMsg ? '会话已结束' : '很高兴为你服务'"
            @inputKeydown="inputKeydown"
            @submit="sendMsg"/>
          <div class="send-button">
            <el-button @click="sendMsg" :disabled="chatUser.endMsg">发送</el-button>
          </div>
        </div>
      </div>
    </div>
    <audio ref="audioMsg" style="display: none;" :src="msgUrl" controls="controls"></audio>
    <audio ref="audioChat" style="display: none;" :src="chatUrl" controls="controls"></audio>
    <el-dialog title="请选择客服" :visible.sync="visibleService" width="20%" :before-close="closeServices" :close-on-click-modal="false" append-to-body>
      <el-select v-model="customerServiceUserid" placeholder="请选择" style="width: 100%;">
        <el-option v-for="item in canConnectionUser" :key="item.userId" :label="item.name" :value="item.userId"></el-option>
      </el-select>
      <div slot="footer">
        <el-button @click="closeServices">取消</el-button>
        <el-button type="primary" @click="confirmServices" :loading="loading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {connection, connect} from '@/utils/useMessageHub'
import {throttle, dateFormat, base64ToFile} from '@/utils/tools'
import { mapGetters } from 'vuex'
import {getLocalStorage, setLocalStorage} from '@/utils/auth'
import {aliOssPolicy} from '@/api/login'
import {uploadFile} from '@/api/system'
import MyUploadFile from '@/components/MyUploadFile'
import MyEditable from '@/components/MyEditable'

export default {
  name: 'ChatMsg',
  components: {
    MyUploadFile,
    MyEditable
  },
  data () {
    return {
      msg: '', // 输入发送的消息
      userList: [], // 连接的用户
      chatUser: {}, // 连接客服信息
      // visible: false,
      // showDialog: true, // 是否展示
      msgUrl: require('@/assets/audio/msg.wav'), // 创建对话声音
      chatUrl: require('@/assets/audio/chat.mp3'), // 发送消息声音
      visibleService: false,
      canConnectionUser: [], // 可转移会话的客服
      customerServiceUserid: '', // 转移客服id
      loading: false,
      hisListEle: null,
      preSrollHeight: 0,
      // isBottomEnd: false,
      isTopEnd: false,
      isTop: false,
      topPage: 1,
      // bottomPage: 1,
      loadingState: false,
      paramsData: {
        pageIndex: 1,
        pageLimit: 10
      },
      initTop: 0,
      mask: false,
      oss: getLocalStorage('ossConfig') || {},
      url: process.env.VUE_APP_BASE_IMG,
    }
  },
  created() {
    connect()
    connection.on('CreateChatEvent', message => {
      console.log(message, '获取收费平台连接信息')
      // 向客服icon标记是否有消息,没有打开对话框就提示有新消息
      // if (!this.visible) {
      //   this.$emit('newMsg', 'new')
      // }
      console.log(1111111111111)
      Formium.external.winfrom.prompt() // 调用cs的方法进行提示
      // if (process.env.NODE_ENV !== 'development') {
      // }
      this.$refs.audioMsg.play() // 播放音频
      this.userList.unshift({
        ...message,
        endMsg: false, // 是否结束会话，断开连接
        isInputing: false, // 是否是输入中
        numberUnreadMsg: 0, // 未读消息数量
        logs: [
          {
            time: dateFormat(new Date(message.time), 'yyyy-MM-dd HH:mm:ss'),
            timeStamp: new Date(message.time).getTime(),
            msg: message.contents,
            isMe: false,
            messageType: message.messageType || 1, // 1:文字，2：图片
          }
        ]
      })
      this.chatUser = this.connectionSuccessUsers[0]
      // 如果是true转移会话，则加载全部历史聊天记录
      if (message.isTransfer) {
        connection.invoke('QueryCurrentSessionMessage', {sessionId: message.sessionId}).then(historyMessage => {
          const logs = historyMessage.map(x => {
            return {
              time: dateFormat(new Date(x.sendTime), 'yyyy-MM-dd HH:mm:ss'),
              timeStamp: new Date(message.time).getTime(),
              msg: x.contents,
              isMe: x.isMe,
              messageType: x.messageType || 1, // 1:文字，2：图片
            }
          })
          this.chatUser.logs = [...this.chatUser.logs, ...logs.reverse()]
        })
      }
      // 创建会话的时候初始化分页，避免收费端刷新后，这边不会出现查看更多按钮
      this.initPageMore()
    })
    // 获取服务端长链接的推送信息
    connection.on('Chat', message => {
      // if (!this.visible) {
      //   this.suportNotify()
      // }
      console.log(2222222222222)
      Formium.external.winfrom.prompt() // 调用cs的方法进行提示
      // if (process.env.NODE_ENV !== 'development') {
      // }
      // 不是自己发送的消息才有声音
      if (!message.isMe) {
        this.$refs.audioChat.play() // 播放音频
      }
      let item = this.userList.find(x => x.sessionId === message.sessionId)
      item.logs.push({
        msg: message.contents,
        isMe: message.isMe || false,
        sessionId: message.sessionId,
        time: message.sendTime ? dateFormat(new Date(message.sendTime), 'yyyy-MM-dd HH:mm:ss') : dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        timeStamp: message.sendTime ? new Date(message.sendTime).getTime() : new Date().getTime(),
        messageType: message.messageType || 1, // 1:文字，2：图片
      })
      // 如果不是自己发的消息，就自增1,并且不是当前连接会话
      if (!message.isMe && message.sessionId !== this.chatUser.sessionId) {
        item.numberUnreadMsg++
      }
      this.ossIsExpire() // 每次获取推送的时候判断oss是否过期
      this.scrollBottom()
      // 如果最小化,则通知有新消息
      // if (!this.showDialog) {
      //   this.$emit('newMsg', 'new')
      // }
    })
    // 订阅收费平台关闭会话
    connection.on('ClosedChatEvent', message => {
      console.log('获取收费平台主动关闭会话', message)
      // 通知icon
      this.$emit('newMsg', '')
      let item = this.userList.find(x => x.sessionId === message.sessionId)
      item.endMsg = true // 是否结束会话
      item.isInputing = false // 结束会话并且不显示输入中
      item.numberUnreadMsg = 0, // 未读消息数量
      item.logs.push({
        msg: message.contents,
        isMe: message.isMe || false,
        sessionId: message.sessionId,
        time: message.sendTime ? dateFormat(new Date(message.sendTime), 'yyyy-MM-dd HH:mm:ss') : dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        timeStamp: message.sendTime ? new Date(message.sendTime).getTime() : new Date().getTime(),
        messageType: message.messageType || 1, // 1:文字，2：图片
      })
    })
    // 订阅是否在输入中
    connection.on('InputingStatus', message => {
      console.log(message, '订阅是否在输入中')
      let item = this.userList.find(x => x.sessionId === message.sessionId)
      item.isInputing = message.isInputing
    })
  },
  computed: {
    ...mapGetters(['user']),
    disabledBtn () {
      return !this.chatUser.sessionId || this.chatUser.endMsg
    },
    // 连接成功并且没结束会话
    connectionSuccessUsers () {
      const {userList} = this
      let newArr = []
      let repeatIds = []
      userList.forEach((x, index) => {
        if (repeatIds.includes(x.userId)) {
          if (x.endMsg) {
            newArr.splice(index, 1)
          }
        } else {
          repeatIds.push(x.userId)
          newArr.push(x)
        }
      })
      return newArr
    }
  },
  mounted() {
    // 这里我在数据请求回来后处理
    this.$nextTick(() => {
      // 拿DOM元素
      this.hisListEle = document.getElementById("chatLogs")
      // 添加滚动条监听事件
      this.hisListEle.addEventListener("scroll", this.handlerMsgInfoScroll)
    })
    // 如果不是客服，就显示一个遮罩，提示不是客服
    if (!this.user.isCustomerService) {
      this.mask = true
    }
    if (!this.oss.host) {
      this.getAliOss()
      return
    }
  },
  // watch: {
  //   visible (value) {
  //     if (value) {
  //       // 订阅监听刷新事件
  //       window.addEventListener('beforeunload', this.handleBeforeUnload)
  //       window.addEventListener('unload', this.handleBeforeUnload)
  //       // 这里我在数据请求回来后处理
  //       this.$nextTick(() => {
  //         // 拿DOM元素
  //         this.hisListEle = document.getElementById("chatLogs")
  //         // 添加滚动条监听事件
  //         this.hisListEle.addEventListener("scroll", this.handlerMsgInfoScroll)
  //       })
  //     } else {
  //       // 移除监听
  //       window.removeEventListener('beforeunload', this.handleBeforeUnload)
  //       window.removeEventListener('unload', this.handleBeforeUnload)
  //     }
  //   }
  // },
  methods: {
    ossIsExpire () {
      const date = new Date().getTime()
      // 判断是否过期
      if (date - Number(this.oss.expire) > 0) {
        // 移除ossConfig并且重新请求
        localStorage.removeItem('ossConfig')
        this.getAliOss()
      }
    },
    // 监听滚动条
    handlerMsgInfoScroll() {
      // 节流
      if (this.loadingState) return
      // 会话结束不能加载消息
      if (this.chatUser.endMsg) return
      const dir = this.getDirection()
      // 触顶,并且滚动距离小于50，并且滚动方向是向上
      if (!this.isTopEnd && this.hisListEle.scrollTop <= 50 && dir === 'up') {
        this.topPage++
        this.isTop = true	// 控制请求数据方向
        this.getPageData()
      }

      // // 触底加载下一页，如果没有数据了，就不再发请求
      // if (this.isBottomEnd) return
      // if (
      //   this.hisListEle.scrollTop + this.hisListEle.clientHeight ===
      //   this.hisListEle.scrollHeight
      // ) {
      //   this.bottomPage++
      //   this.isTop = false // 控制请求数据方向
      //   this.getPageData()
      // }
    },
    getDirection() {
      // scroll滚动方向~~~~
      // this.box = this.$refs.chatListWrapper
      let scrollTop = this.hisListEle.scrollTop
      let scroll = scrollTop - this.initTop
      this.initTop = scrollTop
      let dir = 'down'
      if (scroll < 0) {
        dir = 'up'
      } else {
        dir = 'down'
      }
      return dir
    },
    getPageData () {
      // 如果是触顶，并且没数据了，则return
      if (this.isTopEnd && this.isTop) return
      // 如果是触底，并且没数据了，则return
      // if (this.isBootmEnd && !this.isTop) return
      // 请求状态，节流
      if (this.loadingState) return
      this.loadingState = true
      // 判断当前向上还是向下加载，拿对应的页码
      let paramsData = Object.assign({}, this.paramsData)
      if (this.isTop) {
        paramsData.pageIndex = this.topPage
      }
      // if (!this.isTop) {
      //   paramsData.pageIndex = this.bottomPage
      // }
      // 发请求获取数据
      connection.invoke('QueryHistoryChatMessage', {sessionId: this.chatUser.sessionId, ...paramsData}).then(res => {
        console.log(res)
        let arr = res.items.map(x => {
          return {
            isMe: x.isMe,
            messageType: x.messageType || 1,
            msg: x.contents,
            time: dateFormat(new Date(x.sendTime), 'yyyy-MM-dd HH:mm:ss'),
            timeStamp: new Date(x.sendTime).getTime(),
          }
        })
        arr = arr.reverse() // 反转数据，最新发送的在最下面
        // // 判断当前加载方向，新数据放前面还是放后面
        if (this.isTop) {
          this.chatUser.logs.unshift(...arr)
        } else {
          this.chatUser.logs.push(...arr)
        }
        // 分页
        if (res.pageCount === this.topPage) {
          this.isTopEnd = true
        }
        setTimeout(() => {
          // // 拿到当前的scrollHeight
          let heigh = document.getElementById("chatLogs").scrollHeight
          // 向上滚动的时候，需要计算要滚动到哪里
          let sroll = heigh - this.preSrollHeight
          // 设置滚动条位置
          this.preSrollHeight = heigh
          this.hisListEle.scrollTo(0, sroll)
          this.loadingState = false
        }, 500)
      })
    },
    getMore () {
      // 判断是否加载完所有聊天记录
      if (this.isTopEnd) {
        this.$message.error('暂无聊天记录')
        return
      }
      this.topPage = 1
      this.isTop = true
      this.getPageData()
    },
    // handleBeforeUnload(event) {
    //   // 兼容火狐的做法
    //   event.preventDefault()
    //   event.returnValue = ''
    //   // 展示提示消息
    //   // 这里其实修改默认提示语是不生效的
    //   const message = '确定要离开吗？'
    //   event.returnValue = message
    //   return message
    // },
    // suportNotify () {
    //   if (window.Notification) {
    //     // 支持
    //     console.log("支持"+"Web Notifications API")
    //     // 如果支持Web Notifications API，再判断浏览器是否支持弹出实例
    //     this.sendNotification()
    //   } else {
    //     // 不支持
    //     alert("不支持 Web Notifications API")
    //   }
    // },
    // 创建消息通知
    // sendNotification () {
    //   const that = this
    //   setTimeout(() => {
    //     console.log('1：'+ Notification.permission);
    //     // 如果支持window.Notification 并且 许可不是拒绝状态
    //     if (window.Notification && Notification.permission !== "denied") {
    //       // Notification.requestPermission这是一个静态方法，作用就是让浏览器出现是否允许通知的提示
    //       Notification.requestPermission(status => {
    //         console.log('2: '+status)
    //         // 如果状态是同意
    //         if (status === "granted") {
    //           let m = new Notification('收到信息', {
    //             body: '收费平台发来消息', // 消息体内容
    //             icon: "https://pic1.zhuanstatic.com/zhuanzh/50b6ffe4-c7e3-4317-bc59-b2ec4931f325.png", // 消息图片
    //             requireInteraction: true,
    //             tag: 'b2ec4931f325',
    //             renotify: true
    //           })
    //           m.onclick = function () { // 点击当前消息提示框后，打开dialog
    //             console.log('点击了确定')
    //             that.confirmMsg()
    //             // that.badgeValue = ''
    //             // 向客服icon标记是否有消息
    //             that.$emit('newMsg', '')
    //           }
    //         } else {
    //           alert('当前浏览器不支持弹出消息')
    //         }
    //       })
    //     }
    //   }, 500)
    // },
    // confirmMsg () {
    //   this.visible = true
    // },
    // 关闭所有会话
    // close () {
    //   // 这个目前好像没用了
    //   if (this.connectionSuccessUsers.length === 0) {
    //     this.visible = false
    //     return
    //   }
    //   this.$confirm('是否结束所有对话？', '提示', {
    //     type: 'warning',
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     dangerouslyUseHTMLString: true,
    //   }).then(() => {
    //     this.connectionSuccessUsers.forEach(x => {
    //       connection.invoke('CloseChat', {sessionId: x.sessionId}).then(() => {
    //         this.initData()
    //       })
    //     })
    //   })
    // },
    initData () {
      // this.visible = false
      this.msg = ''
      this.userList = []
      this.chatUser = {}
      this.initPageMore()
      // this.$emit('newMsg', '')
    },
    initPageMore () {
      // this.hisListEle = null
      this.preSrollHeight = 0
      // this.isBottomEnd = false
      this.isTopEnd = false
      this.isTop = false
      this.topPage = 1
      // this.bottomPage = 1
      this.loadingState = false
      this.paramsData = {
        pageIndex: 1,
        pageLimit: 10
      }
    },
    // 结束会话
    endConnection () {
      this.chatUser.endMsg = true
      connection.invoke('CloseChat', {sessionId: this.chatUser.sessionId}).then(res => {
        if (!res.successed) {
          this.$message.error(res.message)
          return
        }
      })
    },
    // 关闭连接并且移除对话
    closeConnection (item, index) {
      console.log('关闭连接并且移除对话')
      // 如果已经结束会话，则不再请求，直接关闭
      if (item.endMsg) {
        this.connectionSuccessUsers.splice(index, 1)
        this.chatUser = {}
        // 如果只剩下最后一个,则关闭整个窗口
        if (this.connectionSuccessUsers.length === 0) {
          this.initData()
        }
        this.$forceUpdate() // 强制视图更新
      } else {
        connection.invoke('CloseChat', {sessionId: item.sessionId}).then(res => {
          if (!res.successed) {
            this.$message.error(res.message)
            return
          }
          this.connectionSuccessUsers.splice(index, 1)
          this.chatUser = {}
          // 如果只剩下最后一个,则关闭整个窗口
          if (this.connectionSuccessUsers.length === 0) {
            this.initData()
          }
          this.$forceUpdate() // 强制视图更新
        })
      }
    },
    // 回车发送消息
    // enterKey (e) {
    //   e.preventDefault ? e.preventDefault() : (e.returnValue = false)
    //   this.sendMsg()
    // },
    // 发送消息,并且自动滚动到最下面看到最新消息
    sendMsg () {
      if (!this.msg) {
        return
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // 匹配出img标签的src属性的值
      let regex = /<img.*?src="(.*?)"/g
      let match
      let srcList = []

      while (match = regex.exec(this.msg)) {
        // match返回一个数组，数组中有两个数值
        // 第一个是img整个标签字段，第二个是src中匹配的内容
        srcList.push(match[1])
      }
      // 把img标签替换掉成空格，并且生成个数组
      let str = this.msg.replace(/<img.+?src=["'](.+?)["'].*?>/gs, " ")
      let strList = str.split(' ')

      if (srcList.length > 0) {
        srcList.forEach(item => {
          // base64转file文件上传
          const blobInfo = base64ToFile(item, new Date().getTime())
          let fd = new FormData()
          fd.append("policy", this.oss.policy)
          fd.append("signature", this.oss.signature)
          fd.append("dir", this.oss.dir)
          fd.append("expire", this.oss.expire)
          fd.append("success_action_status", this.oss.success_action_status)
          fd.append("OSSAccessKeyId", this.oss.OSSAccessKeyId)
          fd.append("key", this.oss.dir + blobInfo.name)
          fd.append("file", blobInfo)
          uploadFile(fd).then(() => {
            let filePath = this.oss.dir + blobInfo.name
            let result = this.url + filePath
            this.inputFile(result)
            setTimeout(() => {
              loading.close()
            }, 500)
          }).catch(() => {
            this.ossIsExpire()
            this.$message.error('图片上传失败')
          })
        })
      }
      if (strList.length > 0) {
        strList.forEach(item => {
          if (!item) {
            return
          }
          // 去除掉&nbsp
          connection.invoke('SendMessage', {contents: item.replace(/&nbsp;/gi, ""), sessionId: this.chatUser.sessionId, messageType: 1}).then(res => {
            console.log(res, '发送成功不成功')
            if (!res.successed) {
              this.$message.error(res.message)
              return
            }
            loading.close()
            this.scrollBottom()
          })
        })
      }
      setTimeout(() => {
        this.msg = ''
        this.$refs.myEditable.clearText()
      }, 400)
    },
    scrollBottom () {
      setTimeout(() => {
        let chatLogs = document.getElementById('chatLogs')
        chatLogs.scrollTop = chatLogs.scrollHeight
      }, 200)
    },
    selectUser (item) {
      this.chatUser = item
      // 当前设置未读消息为0
      this.chatUser.numberUnreadMsg = 0
    },
    // 最小化
    // hide () {
    //   this.showDialog = false
    // },
    // 最小化展示
    // show () {
    //   if (this.userList.length === 0) {
    //     return
    //   }
    //   this.showDialog = true
    //   if (!this.visible) {
    //     this.visible = true
    //   }
    // },
    // input事件,显示正在输入
    inputKeydown: throttle(function(event, isInputing = true) {
      console.log(isInputing, 'aadadas')
      connection.invoke('MessageInputing', {sessionId: this.chatUser.sessionId, isInputing: isInputing}).then(() => {
        if (this.msg && this.chatUser.isInputing) {
          console.log('触发了结束正在输入中')
          setTimeout(() => {
            this.inputKeydown(event, false)
          }, 3000);
        }
      })
    }, 2000),
    // 设置备注
    chatRemarks () {
      this.$prompt('请输入备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入',
        inputValue: this.chatUser.chatRemarks || '',
        inputType: 'textarea'
      }).then(({ value }) => {
        connection.invoke('ModifyPlatfromUserChatRemarks', {sessionId: this.chatUser.sessionId, chatRemarks: value}).then(res => {
          if (!res.successed) {
            this.$message.error(res.message)
            return
          }
          this.chatUser.chatRemarks = value
        })
      })
    },
    // 转移会话，获取可转移的客服信息
    changeServices () {
      connection.invoke('QueryTransferCustomerServices', {sessionId: this.chatUser.sessionId}).then(res => {
        this.canConnectionUser = res
        console.log(res, this.canConnectionUser)
        this.visibleService = true
      })
    },
    closeServices () {
      this.visibleService = false
      this.canConnectionUser = []
      this.customerServiceUserid = ''
    },
    confirmServices () {
      if (!this.customerServiceUserid) {
        this.$message.error('请选择转移会话客服')
        return
      }
      this.loading = true
      // 发送转移会话，并且创建会话
      connection.invoke('TransferCustomerServices', {
        customerServiceUserid: this.customerServiceUserid,
        sessionId: this.chatUser.sessionId
      }).then(res => {
        console.log(res, '转移会话')
        if (!res.successed) {
          this.$message.error(res.message)
          return
        }
        this.loading = false
        this.$message.success(res.message)
        this.chatUser.endMsg = true
        this.closeServices()
        // let index = this.connectionSuccessUsers.findIndex(x => x.userId === this.chatUser.userId) // 获取当前转移会话的下标
        // this.connectionSuccessUsers.splice(index, 1) // 然后删除会话列表
        // // this.chatUser = {}
        // this.$forceUpdate() // 强制刷新
        // // 如果只剩下最后一个,则关闭整个窗口
        // if (this.connectionSuccessUsers.length === 0) {
        //   this.initData()
        // } else {
        //   this.chatUser = this.connectionSuccessUsers[0]
        // }
      })
    },
    // 屏幕截图
    prtSc () {
      connection.invoke('GetPlatfromScreenRequest', {sessionId: this.chatUser.sessionId}).then(res => {
        console.log('请求屏幕截图', res)
        this.$message.success(res.message || '已发送请求，请稍等...')
        this.scrollBottom()
      })
    },
    inputFile (value) {
      connection.invoke('SendMessage', {contents: value, sessionId: this.chatUser.sessionId, messageType: 2}).then(res => {
        console.log(res, '发送成功不成功')
        if (!res.successed) {
          this.$message.error(res.message)
          return
        }
        this.scrollBottom()
      })
    },
    getAliOss: throttle(function () {
      aliOssPolicy().then(res => {
        let oss = {...res.data}
        oss.success_action_status = '200'
        oss.OSSAccessKeyId = res.data.accessid
        delete oss.accessid
        this.oss = oss
        const ossConfig = {...oss}
        setLocalStorage('ossConfig', ossConfig)
      })
    }),
  }
}
</script>

<style lang="scss">
.custom-chat-msg {
  font-size: 14px;
  height: 100vh;
  .bg-blue {
    padding: 0 20px;
    background-color: #409EFF;
    height: 80px;
  }
  .services-name {
    margin-left: 20px;
    color: #fff;
    font-size: 16px;
  }
  .chat-box {
    flex: 1;
    background-color: #f5f5f5;
    height: calc(100vh - 80px);
    border-left: 1px solid #d3d3d3;
    display: flex;
    flex-direction: column;
  }
  .chat-logs {
    flex: 1;
    // height: 359px;
    overflow-y: auto;
    padding-bottom: 10px;
  }
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.4);
    background-clip: padding-box;
    border: 3px solid transparent;
    border-radius: 7px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .chat-send {
    background-color: #fff;
    height: 200px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #dcdfe6;
  }
  .flex-start {
    align-items: start
  }
  .connect-user {
    font-size: 16px;
    // line-height: 2;
    // padding: 40px 20px;
    min-width: 200px;
    width: 15%;
    .user-item {
      position: relative;
      cursor: pointer;
      padding: 10px;
    }
    .user-item-alias {
      font-size: 18px;
    }
    .user-item-name {
      font-size: 16px;
      color: #9999;
      justify-content: space-between;
    }
    .badge {
      position: absolute;
      top: 5px;
      right: 10px;
      background-color: #f56c6c;
      border-radius: 10px;
      color: #fff;
      display: inline-block;
      font-size: 12px;
      height: 18px;
      line-height: 18px;
      padding: 0 6px;
      text-align: center;
      white-space: nowrap;
      border: 1px solid #fff;
    }
    .active-item {
      background-color: #f5f5f5;
    }
  }
  .connect-user-title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  .content {
    width: 100%;
    flex: 1;
    display: block;
    padding: 5px 15px;
    line-height: 1.5;
    box-sizing: border-box;
    font-size: inherit;
    color: #606266;
    background-color: #fff;
    background-image: none;
    border: 0;
    &:focus {
      outline: none;
      border-color: none;
    }
  }
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    resize: none;
  }
  .send-button {
    text-align: right;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .action-button {
    display: flex;
    align-items: center;
    padding: 10px 10px 0 10px;
    button {
      margin-right: 10px;
    }
  }
  .operate-send-logs-item {
    margin: 10px 10px 0 0;
    .avatar {
      margin-left: 10px;
      width: 40px;
      height: 40px;
    }
    .msg-cell {
      background-color: #fff;
      padding: 8px 10px;
      max-width: 55%;
    }
    justify-content: flex-end;
  }
  .charge-send-logs-item {
    margin: 10px 0 0 10px;
    .avatar {
      margin-right: 10px;
      width: 40px;
      height: 40px;
    }
    .msg-cell {
      background-color: #fff;
      max-width: 55%;
      padding: 8px 10px;
    }
  }
  .el-hide {
    right: 70px;
    color: #fff;
  }
  .chat-remarks {
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    margin-left: 15px;
  }
  .online-circle {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #67C23A;
  }
}
.custom-chat-msg {
  .el-loading-text {
    font-size: 20px;
  }
  .el-icon-warning {
    font-size: 40px;
  }
}
</style>