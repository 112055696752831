<template>
  <div>
    <my-search :items="searchItems" @clear="clear" @search="search">
      <el-button type="primary" slot="btn" @click="visible = true" v-hasPermi="['project:staff:add']">新增</el-button>
    </my-search>
    <my-table :columns="columns" ref="myTable" url="/SystemEmployee/QuerySystemEmploeePageList">
      <div slot="isEnableWeiXinChargeNotices" slot-scope="{row}">
        <el-tag :type="row.isEnableWeiXinChargeNotices ? '' : 'danger'">{{row.isEnableWeiXinChargeNotices ? '是' : '否'}}</el-tag>
      </div>
      <vxe-table-column title="操作" width="280px">
        <template #default="{row}">
          <el-button type="text" @click="edit(row)" v-hasPermi="['project:staff:edit']">编辑</el-button>
          <el-button type="text" @click="del(row)" v-hasPermi="['project:staff:del']">删除</el-button>
          <el-button type="text" @click="resetPwd(row)" v-hasPermi="['project:staff:resetPwd']">重置密码</el-button>
          <el-button type="text" @click="logout(row)" v-hasPermi="['project:staff:logout']">强制下线</el-button>
          <el-button type="text" @click="authorization(row)" v-hasPermi="['project:staff:authorization']">授权</el-button>
        </template>
      </vxe-table-column>
    </my-table>
    <staff-edit :showModal.sync="visible" :companyTree="companyTree" :items.sync="items" @refresh="refresh"/>
    <staff-to-authorization :visible.sync="showAuthorization" :items.sync="items" @refresh="refresh"/>
  </div>
</template>

<script>
import {staffDel, resetPwd, forcedOffline, getAuthorization} from '@/api/system'
import {companyList} from '@/api/project'
import { mapGetters } from 'vuex'
import StaffEdit from './components/StaffEdit'
import StaffToAuthorization from './components/StaffToAuthorization'

export default {
  name: 'staff',
  components: {StaffEdit, StaffToAuthorization},
  data () {
    return {
      queryData: {
        // companyId: ''
      },
      columns: [
        {
          label: '姓名',
          prop: 'name',
          width: '100px'
        },
        {
          label: '手机号/账号',
          prop: 'phone'
        },
        {
          label: '授权角色',
          prop: 'relationRoles',
          operate: true,
          render: (h, {row}) => {
            return h('div', {
            }, row.relationRoles.join('，'))
          }
        },
        {
          label: '授权项目',
          prop: 'relationProjects',
          operate: true,
          render: (h, {row}) => {
            return h('div', {
            }, row.relationProjects.join('，'))
          }
        },
        {
          label: '所属公司',
          prop: 'companyName'
        },
        {
          label: '状态',
          prop: 'statusName',
          width: '80px',
          operate: true,
          render: (h, {row}) => {
            let type = ''
            if (row.status) {
              type = {
                1: 'success',
                2: 'danger',
                3: 'info'
              }[row.status]
            }
            return h('el-tag', {
              props: {
                type: type
              }
            }, row.statusName)
          }
        },
        {
          label: '是否开启接收公众号业主缴费通知',
          prop: 'isEnableWeiXinChargeNotices',
          operate: true,
        },
        {
          label: '最近一次登录IP',
          prop: 'lastLoginIp'
        },
        {
          label: '最近一次登录时间',
          prop: 'lastLoginTime'
        },
        {
          label: '最近一次登录设备',
          prop: 'loginDeviceTypeName'
        },
        {
          label: '聊天备注',
          prop: 'chatRemarks'
        },
      ],
      visible: false,
      items: {},
      roles: [], // 角色
      projects: [],
      showAuthorization: false
      // companyTree: []
    }
  },
  computed: {
    ...mapGetters(['companyTree', 'dict']),
    searchItems () {
      const {companyTree} = this
      return [
        {
          type: 'selectTree',
          label: '选择公司：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany
        },
        {type: 'input', label: '姓名：', name: 'name'},
        {type: 'input', label: '电话号码：', name: 'phone'},
        {type: 'select', label: '角色：', name: 'roles', options: this.roles, props: {value: 'id', label: 'name'}, multiple: true},
        {type: 'select', label: '项目：', name: 'projects', options: this.projects, multiple: true, props: {value: 'id', label: 'name'}},
        {type: 'select', label: '状态：', name: 'status', options: this.dict.EntityStatus},
      ]
    }
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
  },
  methods: {
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    edit (row) {
      this.items = Object.assign({}, row)
      this.items.companyId = this.queryData.companyId
      this.visible = true
    },
    refresh () {
      this.$refs.myTable.refresh(this.queryData)
    },
    authorization (row) {
      this.items = Object.assign({}, row)
      this.showAuthorization = true
    },
    del (row) {
      this.$confirm(`是否删除<span style="color: #da251c">${row.name}</span>`, '提示', {
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        staffDel({id: row.id}).then(res => {
          this.$message.success(res.message)
          this.$refs.myTable.refresh()
        })
      })
    },
    // 重置密码
    resetPwd (row) {
      this.$confirm(`你确定将<span style="color: #da251c">${row.name}</span>的密码重置吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        resetPwd({id: row.id}).then(res => {
          this.$notify.success({
            title: '密码重置成功',
            dangerouslyUseHTMLString: true,
            duration: '100000',
            message: `${row.name}当前密码为<span style="color: #da251c">${res.data}</span>`,
          })
        })
      })
    },
    logout (row) {
      this.$confirm(`你确定将<span style="color: #da251c">${row.name}</span>强制下线吗吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        forcedOffline({id: row.id}).then(res => {
          this.$message.success(res.message)
        })
      })
    },
    changeCompany (data) {
      // console.log(value)
      getAuthorization({companyId: data.id}).then(res => {
        this.roles = res.data.roles
        this.projects = res.data.projects
      })
    }
  }
}
</script>

<style scoped lang="scss">
.flex-h {
  align-items: flex-start;
}
</style>
