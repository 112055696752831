
// Web端
const JS_API_KEY = '0fdbfb8abe9b5f003efdce2c089f564c'
// 安全密钥
const JS_CODE = '41e1820e7582f2ba80558318975d6ba6'


// 设置安全密钥
window._AMapSecurityConfig = {
  securityJsCode: JS_CODE
}

module.exports = {
  JS_API_KEY,
  JS_CODE
}