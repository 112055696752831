<template>
  <div style="width: 100%; height: 100%">
    <my-search @search="search" @clear="clear" :items="searchItems" :show-columns="false"></my-search>
    <div id="container" style="width: 100%; height: calc(100% - 110px);margin-top: 20px;"></div>
  </div>
</template>

<script>
import { JS_API_KEY } from '@/utils/mapConfig'
import AMapLoader from "@amap/amap-jsapi-loader"
import { projectMap } from '@/api/system'
import {companyList} from '@/api/project'
import { mapGetters } from 'vuex'

let map = {}

export default {
  data() {
    return {
      projects: [],
      projectList: []
    };
  },
  computed: {
    ...mapGetters(['companyTree']),
    searchItems () {
      const {companyTree, projectList} = this
      return [
        {
          type: 'selectTree',
          label: '公司名称/编号：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany,
          style: 'margin-right: 0'
        },
        {
          type: 'select',
          label: '',
          name: 'projectId',
          options: projectList
        },
        {type: 'input', label: '省/市/区县/地址：', name: 'keywords'},
      ]
    }
  },
  mounted() {
    this.getProjectMap()
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
  },
  methods: {
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    changeCompany (data) {
      this.$store.dispatch('getCompanyDownProject', {companyId: data.id, containsClosed: true}).then(res => {
        this.projectList = res
      })
    },
    search (query) {
      this.getProjectMap(query)
    },
    clear () {
      this.getProjectMap({})
    },
    getProjectMap(query = {}) {
      projectMap(query).then(res => {
        this.projects = res.data.map(x => {
          return {
            lnglat: [x.mapPointY, x.mapPointX],
            name: x.projectName,
            ...x
          }
        })
        this.initMap()
      })
    },
    initMap() {
      AMapLoader.load({
        key: JS_API_KEY, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Scale'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then(AMap => {
        map = new AMap.Map("container", {
          zoom: 5,
          center: [102.342785, 35.312316],
          showIndoorMap: false,
          mapStyle: 'amap://styles/light',	// 设置地图的显示样式，更改darkblue为你想要的样式
          viewMode: "3D",
        });
        map.addControl(new AMap.Scale())
        let style = [
          {
            url: require("@/assets/images/address.png"),
            // url: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
            anchor: new AMap.Pixel(6, 6),
            size: new AMap.Size(20, 20),
            zIndex: 3,
          },
        ];
        let mass = new AMap.MassMarks(this.projects, {
          opacity: 0.8,
          zIndex: 111,
          cursor: "pointer",
          style: style,
        });
        let marker = new AMap.Marker({
          content: " ",
          map: map,
        });
        mass.on("mouseover", function (e) {
          console.log(e, 12)
          const { data } = e
          const contentHtml = `
            <div style="padding: 10px">
              <div style="font-size: 18px;">${data.projectName}</div>
              <div style="margin-top: 10px;font-size: 16px;color: #999">${data.companyName}${data.companyAlias}</div>
              <div style="margin-top: 10px;font-size: 14px;color: #999">${data.address}</div>
            </div>
          `
          marker.setPosition(e.data.lnglat);
          marker.setLabel({
            content: contentHtml
          });
        });
        mass.setMap(map);
      })
    },
  },
};
</script>

<style>
#container .amap-marker-label {
  border: 0 none;
  background-color: #fff;
  white-space: nowrap;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .3);
  border-radius: 5px;
}

#container .amap-marker-label:after {
  position: absolute;
  border: 5px solid transparent;
  border-top-color: #fff;
  top: 19px;
  left: 43%;
  content: '';
  width: 0;
  height: 0;
}
</style>