<template>
  <el-dialog title="新增/编辑支付通道" :visible.sync="visible" :close-on-click-modal="false" width="40%" :before-close="close" append-to-body>
      <el-form :model="form" label-width="100px" :rules="rules" ref="form">
        <el-form-item label="所属项目" prop="projectId" v-if="items.type">
          <el-cascader
            style="width: 100%"
            :props="{value: 'id', label: 'name', children: 'child', emitPath: false, multiple: true}"
            filterable
            clearable
            :options="projectList"
            collapse-tags
            v-model="form.projectId">
          </el-cascader>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="通道类型" prop="passType">
          <el-radio-group v-model="form.passType">
            <el-radio :label="item.value" v-for="item in dict.OnlinePaymentPassType" :key="item.value">{{item.displayName}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="费率" prop="serviceRate">
          <el-input-number v-model="form.serviceRate" :controls="false" :step="0.0001" placeholder="请输入" :min="0" :precision="4"></el-input-number>
        </el-form-item>
        <el-form-item label="接口域" prop="apiDomain">
          <el-input v-model="form.apiDomain" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="回调地址" prop="notifyUrl">
          <el-input v-model="form.notifyUrl" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="机构号" prop="fuyouMerchantSettingsConfig.ins_cd">
          <el-input v-model="form.fuyouMerchantSettingsConfig.ins_cd" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="终端号" prop="fuyouMerchantSettingsConfig.term_id">
          <el-input v-model="form.fuyouMerchantSettingsConfig.term_id" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="订单号前缀" prop="fuyouMerchantSettingsConfig.orderprefix">
          <el-input v-model="form.fuyouMerchantSettingsConfig.orderprefix" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="公钥" prop="fuyouMerchantSettingsConfig.publicKey">
          <el-input v-model="form.fuyouMerchantSettingsConfig.publicKey" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="私钥" prop="fuyouMerchantSettingsConfig.privateKey">
          <el-input v-model="form.fuyouMerchantSettingsConfig.privateKey" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="商户号#" prop="fuyouMerchantSettingsConfig.mchnt_cd">
          <el-input v-model="form.fuyouMerchantSettingsConfig.mchnt_cd" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="商户名称#">
          <el-input v-model="form.merchartName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="结算银行#">
          <el-input v-model="form.settlementBank" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="结算银行账号#">
          <el-input v-model="form.settlementBankNumber" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="submit" :loading="loading" type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {projectPayConfigEdit} from '@/api/project'
import {companyProjectTree} from '@/api/system'
import { mapGetters } from 'vuex'
export default {
  name: 'AreaPayConfigEdit',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    projectId: {
      type: [String, Number],
      default: ''
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      form: {
        fuyouMerchantSettingsConfig: {
          publicKey: '',
          privateKey: '',
          ins_cd: '',
          term_id: '',
          mchnt_cd: '',
          orderprefix: '',
        },
        merchartName: '',
        settlementBank: '',
        settlementBankNumber: '',
        projectId: null
      },
      projectList: [],
      rules: {
        name: [{ required: true, trigger: "blur", message: "请输入" }],
        passType: [{ required: true, trigger: "change", message: "请选择" }],
        projectId: [{ required: true, trigger: "change", message: "请选择" }],
        serviceRate: [{ required: true, trigger: "blur", message: "请输入" }],
        apiDomain: [{ required: true, trigger: "blur", message: "请输入" }],
        notifyUrl: [{ required: true, trigger: "blur", message: "请输入" }],
        fuyouMerchantSettingsConfig: {
          orderprefix: [{ required: true, trigger: "blur", message: "请输入" }],
          mchnt_cd: [{ required: true, trigger: "blur", message: "请输入" }],
          term_id: [{ required: true, trigger: "blur", message: "请输入" }],
          ins_cd: [{ required: true, trigger: "blur", message: "请输入" }],
          privateKey: [{ required: true, trigger: "blur", message: "请输入" }],
          publicKey: [{ required: true, trigger: "blur", message: "请输入" }],
        }
      },
      loading: false
    }
  },
  watch: {
    visible (value) {
      if (value) {
        this.form = this.items.id ? this.items : {
          serviceRate: '',
          passType: '',
          name: '',
          notifyUrl: '',
          apiDomain: '',
          ...this.form
        }
        this.getCompanyProject()
      }
    }
  },
  computed: {
    ...mapGetters(['dict'])
  },
  mounted () {},
  methods: {
    normalizer(node) {
      if (node.child === null) {
        delete node.child
      }
      if (node.id === this.items.disabledId) {
        node.isDisabled = true
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
      this.form = {
        fuyouMerchantSettingsConfig: {
          publicKey: '',
          privateKey: '',
          ins_cd: '',
          term_id: '',
          mchnt_cd: '',
          orderprefix: '',
        },
        projectId: null,
        settlementBankNumber: '',
        merchartName: '',
        settlementBank: ''
      }
    },
    getCompanyProject () {
      companyProjectTree({types: [1, 2, 3]}).then(res => {
        this.projectList = res.data.map(x => {
          return {
            ...x,
            child: x.child.map(y => {
              return {
                ...y,
                disabled: y.id === this.items.disabledId
              }
            })
          }
        })
      })
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          // 复制新增
          if (this.items.type === 'copy') {
            delete this.form.id
          } else {
            // 单个新增，新增projectId是集合，编辑projectId是字符串
            if (this.projectId) {
              if (this.form.id) {
                this.form.projectId = this.projectId
              } else {
                this.form.projectId = [this.projectId]
              }
            }
          }
          projectPayConfigEdit(this.form).then(res => {
            this.$message.success(res.message)
            this.loading = false
            this.close()
            this.$emit('refresh')
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.border-box {
  border: 1px solid #DCDFE6;
  padding: 10px 20px;
  margin-top: 10px;
}
</style>