<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
@import './assets/style/global.scss';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  clear: both;
  margin: 0 auto;
  font-size: 14px;
  height: 100%;
  color: $color333;
  min-width: 1200px;
  overflow: hidden;
}
html, body {
  margin: 0 !important;
  padding: 0;
  clear: both;
  width: 100%;
  height: 100%;
}
.el-input-number .el-input__inner {
  text-align: left !important;
}
.hide-height {
  text-align: center;
  height: 55px;
  overflow-y: hidden;
  // height: 55px;
  // overflow: hidden;
}
.auto-height {
  text-align: center;
}
#nprogress .bar {
  background: #F56C6C !important; //自定义颜色
}
.flex-h {
  .w-190 {
    width: 190px !important;
  }
}
.vxe-table .vxe-table--header-wrapper {
  color: #333 !important;
}
.el-descriptions-item__label.is-bordered-label {
  color: #333 !important;
}
.el-descriptions-item__content {
  color: #333 !important;
}
</style>
