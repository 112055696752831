<template>
  <el-dialog :visible.sync="visible" :title="title" :before-close="close" :close-on-click-modal="false" width="70%">
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="费用名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入" style="width: 90%"></el-input>
          </el-form-item>
          <el-form-item label="费用方式" prop="priceType" v-if="feeTypeValue === 1">
            <el-radio-group v-model="form.priceType" @change="changeRadio">
              <el-radio v-for="item in dict.PriceType" :label="item.value" :key="item.value">{{item.displayName}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="费用精度" prop="amountMantissaType">
            <el-select v-model="form.amountMantissaType" placeholder="请选择" style="width: 90%">
              <el-option v-for="item in dict.AmountMantissaType" :key="item.value" :label="item.displayName" :value="item.value"></el-option>
            </el-select>
            <el-popover
              placement="top-start"
              title="说明"
              width="400"
              trigger="hover"
              content="用于最终费用计算的单位结果，例如：按分计算则保留2位小数进行四舍五入，按角计算则保留1位小数进行四舍五入，按元则直接取整数进行四舍五入">
              <i class="el-icon-info" slot="reference"></i>
            </el-popover>
          </el-form-item>
          <el-form-item label="总费用" v-if="form.priceType === 2" prop="priceOrAmount">
            <el-input-number v-model="form.priceOrAmount" :precision="2" :controls="false"  :min="0"></el-input-number>
          </el-form-item>
          <el-form-item v-if="form.priceType !== 2" label="单价" prop="priceOrAmount">
            <el-input-number v-model="form.priceOrAmount" :precision="4" :controls="false"  :min="0"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="费用类型" prop="feeTypeId">
            <el-select v-model="form.feeTypeId" placeholder="请选择" style="width: 90%" @change="changeFee" :disabled="disabled">
              <el-option v-for="item in feeType" :key="item.id" :label="item.displayName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <!-- 如果按总费用则不显示关联面积 -->
          <el-form-item label="关联面积" prop="relationAreaType" v-if="form.priceType === 1 || feeTypeValue === 99">
            <el-select v-model="form.relationAreaType" placeholder="请选择" style="width: 90%">
              <el-option v-for="item in dict.RelationAreaType" :key="item.value" :label="item.displayName" :value="item.value"></el-option>
            </el-select>
            <el-popover
              placement="top-start"
              title="说明"
              width="400"
              trigger="hover"
              content="如果收费项目费用需要面积参与费用计算则选择对应的面积，例如：物业费">
              <i class="el-icon-info" slot="reference"></i>
            </el-popover>
          </el-form-item>
          <el-form-item label="自动抄表周期" prop="meterReadingCycleType" v-if="feeTypeValue === 1">
            <el-input-number
              v-model="form.meterReadingCycleQuantity"
              :min="1"
              step-strictly
              :controls="false"
              class="w-100"
              placeholder="请输入">
            </el-input-number>
            <el-select v-model="form.meterReadingCycleType" placeholder="请选择" class="w-100">
              <el-option v-for="item in dict.CycleType" :key="item.value" :label="item.displayName" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- 如果按总费 改 -->
          <el-form-item label="按自然月生成欠费" prop="naturalMonth" v-if="feeTypeValue === 1">
            <vxe-switch open-label="是" @change="changeMonth" close-label="否" :disabled="form.priceType === 2" v-model="form.naturalMonth"></vxe-switch>
            <el-popover
              placement="top-start"
              title="说明"
              width="400"
              trigger="hover"
              content="如果按自然月生成欠费，则默认开始时间为每月1号，结束日期则每月最后一天。非自然月生成，结束时间为开始日期加1个月。非自然月例如：开始日期，2022-03-08 则费用周期为 2022-03-08 ~ 2022-04-08">
              <i class="el-icon-info" slot="reference"></i>
            </el-popover>
          </el-form-item>
          <el-form-item label="单位">
            <el-input placeholder="请输入单位" v-model="form.unit"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="费用生成日" prop="autoMRDayNumber" v-if="feeTypeValue === 1">
        <el-input class="max-w-400" placeholder="请输入内容" v-model.number="form.autoMRDayNumber" min="0" max="28" type="number" v-if="form.autoMrTimeType === 1">
          <el-select v-model="form.autoMrTimeType" slot="prepend" class="w-80" placeholder="请选择">
            <el-option :label="item.displayName" :value="item.value" v-for="item in dict.AutoMrTimeType" :key="item.value"></el-option>
          </el-select>
          <div slot="append">号生成下个周期费用</div>
        </el-input>
        <div v-else class="flex-h">
          <el-input class="max-w-400" placeholder="请输入内容" min="0" v-model="form.autoMrTimeTypeValue" type="number">
            <el-select v-model="form.autoMrTimeType" slot="prepend" class="w-80" placeholder="请选择">
              <el-option :label="item.displayName" :value="item.value" v-for="item in dict.AutoMrTimeType" :key="item.value"></el-option>
            </el-select>
            <div slot="append">
              个月
            </div>
          </el-input>
          <el-input class="max-w-400" placeholder="请输入内容" min="0" max="28" v-model.number="form.autoMRDayNumber" type="number">
            <div slot="append">号生成下个周期费用</div>
          </el-input>
        </div>
      </el-form-item>
      <el-form-item label="状态" prop="status" v-if="form.id">
        <el-radio-group v-model="form.status">
          <el-radio v-for="item in dict.EntityStatus" :label="item.value" :key="item.value">{{item.displayName}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="开启违约金" prop="liquidatedDamagesSwitch">
        <vxe-switch open-label="是" @change="change" close-label="否" v-model="form.liquidatedDamagesSwitch"></vxe-switch>
        <el-popover
          placement="top-start"
          title="说明"
          width="400"
          trigger="hover"
          content="利率是天利率，如欠费金额为100，利率为1‰，则每天的逾期金额为0.1元，免利天数是指在逾期后多少天开始计算金额。时间类费用逾期计算是按照抄表的结束日期，水电气是按照费用月计算">
          <i class="el-icon-info" slot="reference"></i>
        </el-popover>
      </el-form-item>
      <el-form-item label="违约金比例" prop="liquidatedDamagesProportion" v-if="form.liquidatedDamagesSwitch">
        <el-input class="max-w-400" placeholder="请输入内容" :min="0" v-model="form.liquidatedDamagesProportion" type="number">
          <div slot="append">‰</div>
        </el-input>
      </el-form-item>
      <el-form-item label="免利天数" prop="interestFreeDays" v-if="form.liquidatedDamagesSwitch">
        <el-input class="max-w-400" placeholder="请输入内容" :min="0" v-model="form.interestFreeDays" type="number">
          <div slot="append">天内不收取违约金</div>
        </el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remarks" placeholder="请输入" type="textarea" :autosize="{minRows: 4}"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="submit" type="primary" :loading="loading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {feeStandardEdit} from '@/api/project'
import { mapGetters } from 'vuex'
import {throttle} from '@/utils/tools'
const initData = {
  name: '',
  // price: '',
  feeTypeId: '',
  relationAreaType: '',
  billingMethod: '',
  amountMantissaType: 3,
  // chargingCycleType: '',
  meterReadingCycleType: '',
  // chargingCycleQuantity: '',
  meterReadingCycleQuantity: '',
  liquidatedDamagesSwitch: false,
  liquidatedDamagesProportion: '',
  interestFreeDays: '',
  // inputTableDate: '',
  autoMRDayNumber: 1,
  autoMrTimeType: 1,
  remarks: '',
  status: '',
  sortNumber: '',
  priceType: 1,
  autoMrTimeTypeValue: 0,
  priceOrAmount: '',
  naturalMonth: true,
  unit: ''
}
export default {
  name: 'CostStandardEdit',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    },
    feeType: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: false,
      form: {...initData},
      rules: {
        name: [{ required: true, trigger: "blur", message: "请输入" }],
        priceOrAmount: [{ required: true, trigger: "blur", message: "请输入" }],
        autoMRDayNumber: [{ required: true, trigger: "blur", message: "请输入" }],
        feeTypeId: [{ required: true, trigger: "change", message: "请选择" }],
        relationAreaType: [{ required: true, trigger: "change", message: "请选择" }],
        billingMethod: [{ required: true, trigger: "change", message: "请选择" }],
        amountMantissaType: [{ required: true, trigger: "change", message: "请选择" }],
        meterReadingCycleType: [{ required: true, trigger: "change", message: "请选择" }],
        meterReadingCycleQuantity: [{ required: true, trigger: "blur", message: "请输入" }],
        liquidatedDamagesSwitch: [{ required: true, trigger: "change", message: "请选择" }],
        naturalMonth: [{ required: true, trigger: "change", message: "请选择" }],
        liquidatedDamagesProportion: [{ required: true, trigger: "blur", message: "请输入" }],
        interestFreeDays: [{ required: true, trigger: "blur", message: "请输入" }],
      },
      feeTypeValue: ''
    }
  },
  computed: {
    ...mapGetters(['dict']),
    title () {
      return '编辑' + this.items.name
    },
    disabled () {
      return this.items.id > 0
    }
  },
  watch: {
    visible (value) {
      if (value) {
        if (this.items.id) {
          this.form = this.items
          this.form.priceOrAmount = this.items.totalAmount || this.items.price
          let item = this.feeType.find(x => x.id === this.form.feeTypeId) || {}
          this.feeTypeValue = item.feeMode
        }
      }
    }
  },
  mounted () {},
  methods: {
    changeFee (value) {
      let item = this.feeType.find(x => x.id === value) || {}
      this.feeTypeValue = item.feeMode
      // 如果不是周期类就为空
      if (item.feeMode !== 1) {
        this.form.meterReadingCycleQuantity = ''
        this.form.meterReadingCycleType = ''
      } else if (item.feeMode === 1) {
        this.form.autoMRDayNumber = 1
        this.form.meterReadingCycleType = 3
      }
    },
    changeRadio (value) {
      if (value === 2) {
        this.form.naturalMonth = false
      }
    },
    change ({value}) {
      if (!value) {
        this.form.liquidatedDamagesProportion = ''
        this.form.interestFreeDays = ''
      }
    },
    changeMonth ({value}) {
      if (value) {}
    },
    normalizer(node) {
      if (node.child === null) {
        delete node.child
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
      this.feeTypeValue = ''
      this.form = {...initData}
      this.$refs.form.resetFields()
    },
    submit: throttle(function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          let data = {}
          for (const key in this.form) {
            if (this.form[key]) {
              data[key] = this.form[key]
            }
          }
          data.naturalMonth = this.form.naturalMonth
          // 其他费，单独处理
          if (this.feeTypeValue === 99) {
            delete this.form.liquidatedDamagesSwitch
            delete this.form.naturalMonth
          }
          feeStandardEdit(data).then(res => {
            this.$message.success(res.message)
            this.loading = false
            this.$emit('refresh')
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    })
  }
}
</script>

<style scoped lang="scss">
.w-100 {
  width: 100px;
}
.max-w-400 {
  max-width: 400px;
}
.w-80 {
  width: 80px;
}
</style>
