<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="close" :close-on-click-modal="false" width="40%">
    <el-form :model="form" ref="form" :rules="rules" label-width="100px">
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="打印分类" prop="printType">
        <el-select v-model="form.printType" placeholder="请选择" style="width: 100%">
          <el-option v-for="item in dict.PrintType" :key="item.value" :value="item.value" :label="item.displayName"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模板连接" prop="templateLink">
        <el-input v-model="form.templateLink" placeholder="请输入"></el-input>
        <div class="flex-h">
          <my-upload-file @input="input($event)" :return-url.sync="url" :fileType="['fmx']" :show-gallery="false">
            <el-button slot="content" type="text">上传</el-button>
          </my-upload-file>
          <el-button style="margin-left: 20px" type="text" @click="eye" v-show="form.templateLink">预览</el-button>
        </div>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model="form.remarks" type="textarea" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="submit" :loading="loading" type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import MyUploadFile from '@/components/MyUploadFile'
import {chargePrintView, arrearsPrintView, printTmpEdit} from '@/api/print'
import {printTmp} from '@/utils/tools'

const initData = {
  title: '',
  printType: '',
  remarks: '',
  templateLink: '',
}
export default {
  name: 'PrintTmpEdit',
  components: {MyUploadFile},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      form: {...initData},
      url: process.env.VUE_APP_BASE_PRINT,
      rules: {
        title: [{ required: true, trigger: "blur", message: "请输入" }],
        templateLink: [{ required: true, trigger: "blur", message: "请输入" }],
        printType: [{ required: true, trigger: "change", message: "请选择" }],
      },
      loading: false
    }
  },
  computed: {
    title () {
      return this.items.id ? '编辑' + this.items.title : '新增模板'
    },
    ...mapGetters(['dict'])
  },
  watch: {
    visible (value) {
      if (value && this.items.id) {
        this.form = this.items
      }
    }
  },
  mounted () {},
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
      this.form = {...initData}
    },
    input (value) {
      this.form.templateLink = value
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          printTmpEdit(this.form).then(res => {
            this.$message.success(res.message)
            this.loading = false
            this.$emit('refresh')
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    eye (row) {
      if (!this.form.printType) {
        this.$message.error('请选中打印分类再预览')
        return
      }
      // 单户收费是printType===1，欠费催收printType===6
      if (row.printType === 1) {
        chargePrintView({printTemplateLink: this.form.templateLink}).then(res => {
          const {data} = res
          printTmp({tmpUrl: data.printTemplateLink, action: 'preview', jsonData: data.paymentDatas, call: false})
        })
      } else {
        arrearsPrintView({printTemplateLink: this.form.templateLink}).then(res => {
          const {data} = res
          printTmp({tmpUrl: data.printTemplateLink, action: 'preview', jsonData: data.paymentDatas, call: false})
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">

</style>
