<template>
  <el-dialog title="新增导入任务" :close-on-click-modal="false" :before-close="close" width="50%" :visible.sync="visible">
    <el-form :model="form" ref="form" label-width="100px" :rules="rules">
      <el-form-item label="导入项目" prop="projectId">
        <el-cascader
          style="width: 100%"
          :props="{value: 'id', label: 'name', children: 'child', emitPath: false}"
          filterable
          clearable
          :options="tree"
          v-model="form.projectId">
        </el-cascader>
      </el-form-item>
      <el-form-item label="导入类型" prop="importType">
        <el-select v-model="form.importType" placeholder="请选择" style="width: 100%" @change="change">
          <el-option :label="item.displayName" v-for="item in dict.ImportType" :value="item.value" :key="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模板链接" v-if="importTypeName">
        <a :href="downUrl" download>下载模板</a>
      </el-form-item>
      <el-form-item label="上传文件" prop="importFilePath">
        <my-upload-file :isOss="false" upload-name="FormFile" v-model="form.importFilePath" :fileType="['xlsx', 'xls']">
          <img v-if="form.importFilePath" class="file-img" src="@/assets/images/excel.png"/>
        </my-upload-file>
      </el-form-item>
      <el-form-item label="状态" v-if="form.reulstMsg">
        <el-tag :type="form.successed ? 'success' : ''">{{form.successed ? '成功' : '失败'}}</el-tag>
      </el-form-item>
      <el-form-item label="执行结果">
        <el-input v-model="form.reulstMsg" readonly type="textarea" :autosize="{minRows: 10, maxRows: 15}" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="reset" v-if="form.reulstMsg" :loading="btnLoading">重置</el-button>
      <el-button type="primary" @click="submit" v-else :loading="btnLoading">导入</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {projectList, importTaskAdd} from '@/api/project'
import MyUploadFile from '@/components/MyUploadFile'
import {companyProjectTree} from '@/api/system'
import { mapGetters } from 'vuex'
export default {
  name: 'importTaskEdit',
  components: {MyUploadFile},
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      url: process.env.VUE_APP_BASE_OLDIMG,
      form: {
        importFilePath: '',
        importType: '',
        projectId: '',
        // remarks: ''
      },
      rules: {
        importFilePath: [{ required: true, trigger: "change", message: "请选择" }],
        importType: [{ required: true, trigger: "change", message: "请选择" }],
        projectId: [{ required: true, trigger: "change", message: "请选择" }],
      },
      // visibleArea: false,
      loading: false,
      btnLoading: false,
      options: [],
      importTypeName: '',
      tree: []
    }
  },
  computed: {
    ...mapGetters(['dict']),
    downUrl () {
      return this.url + 'template/' + this.importTypeName + '模板.xlsx'
    }
  },
  mounted () {
    this.getTreeList()
  },
  methods: {
    getTreeList () {
      companyProjectTree({types: [1, 2, 3]}).then(res => {
        this.tree = res.data.map(x => {
          return {
            ...x,
            name: x.name + `(${x.companyAlias})`
          }
        })
      })
    },
    reset () {
      this.form = {
        importFilePath: '',
        importType: '',
        projectId: '',
        // remarks: ''
      }
    },
    change (value) {
      let item = this.dict.ImportType.find(x => x.value === value)
      this.importTypeName = item.displayName
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        if (this.options.length > 0) {
          setTimeout(() => {
            this.loading = false
            this.options = this.options.filter(item => {
              return item.roomNumber.toLowerCase().indexOf(query.toLowerCase()) > -1
            })
          }, 200)
          return
        }
        projectList({name: query, status: 1, limit: 1000, page: 1}).then(res => {
          this.loading = false
          this.options = res.data.items
        }).catch(() => {
          this.loading = false
        })
      } else {
        this.options = []
      }
    },
    close () {
      this.$emit('update:visible', false)
      this.importTypeName = ''
      this.form = {}
      this.$refs.form.resetFields()
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.btnLoading = true
          importTaskAdd(this.form).then(res => {
            this.btnLoading = false
            this.form.reulstMsg = res.data.reulstMsg
            this.form.successed = res.data.successed
            this.$message.success(res.message)
            this.$emit('refresh')
            // this.close()
          }).catch(() => {
            this.btnLoading = false
          })
        }
      })
    },
    openProject () {
      this.visibleArea = true
    },
  }
}
</script>

<style scoped lang="scss">
.file-img {
  width: 72px;
  height: 72px;
}
a {
  color: #409EFF;
}
/*去除a标签下划线*/
a {
	text-decoration: none;
}
/*去除未被访问的a标签的下划线*/
a:link {
	text-decoration: none;
}
/*去除已经被访问过的a标签的下划线*/
a:visited {
	text-decoration: none;
}
/*去除鼠标悬停时的a标签的下划线*/
a:hover {
	text-decoration: none;
}
/*去除正在点击的a标签的下划线（鼠标按下，尚未松开）*/
a:active {
	text-decoration: none;
}
/*去除获得焦点的a标签的下划线（被鼠标点击过）*/
a:focus {
	text-decoration: none;
}
</style>
