import { render, staticRenderFns } from "./companyPrintTem.vue?vue&type=template&id=4940bf31&scoped=true&"
import script from "./companyPrintTem.vue?vue&type=script&lang=js&"
export * from "./companyPrintTem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4940bf31",
  null
  
)

export default component.exports