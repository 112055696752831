<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="close" :close-on-click-modal="false" width="90%">
    <my-table :columns="columns" :data="tableData"></my-table>
  </el-dialog>
</template>

<script>
import {dateFormat} from '@/utils/tools'
import {projectFeeDetail} from '@/api/system'
export default {
  name: 'OnlineDailyDetail',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      columns: [
        {
          label: '公司名称',
          prop: 'companyName'
        },
        {
          label: '项目名称',
          prop: 'projectName'
        },
        {
          label: '创建日期',
          prop: 'createdTime'
        },
        {
          label: '商户号',
          prop: 'merchartNumber'
        },
        {
          label: '当日总收入金额',
          prop: 'amount',
          width: 120
        },
        {
          label: '手续费',
          prop: 'addnFee',
          width: 80
        },
        {
          label: '退费金额',
          prop: 'refundAmount',
          width: 80
        },
        {
          label: '结余金额',
          prop: 'balanceAmount',
          width: 100
        },
        {
          label: '费用日期',
          prop: 'feeDate'
        },
        {
          label: '结算银行',
          prop: 'settlementBank'
        },
        {
          label: '银行账号',
          prop: 'settlementBankNumber'
        },
        {
          label: '说明',
          prop: 'describe'
        },
      ],
      tableData: []
    }
  },
  computed: {
    title () {
      return this.items.companyName + dateFormat(new Date(this.items.feeDate)) + '费用明细'
    }
  },
  watch: {
    visible (value) {
      if (value) {
        this.getList()
      }
    }
  },
  mounted () {},
  methods: {
    getList () {
      projectFeeDetail({id: this.items.id}).then(res => {
        this.tableData = res.data
      })
    },
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
      this.tableData = []
    }
  }
}
</script>

<style scoped lang="scss">

</style>
