import Cookies from 'js-cookie'

const TokenKey = 'admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

// 获取用户本地缓存数据
export function getLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key))
}

// 设置用户本地缓存数据
export function setLocalStorage(key, data) {
  localStorage.setItem(key, JSON.stringify(data))
}