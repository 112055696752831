<!-- 公司树形结构 -->
<template>
  <div style="width: 300px;margin-right: 20px;">
    <div v-if="selectType === 'checkbox'" >
      <el-button type="primary" @click="checkAll">全选</el-button>
      <el-button type="primary" @click="cancelCheckAll">取消全选</el-button>
    </div>
    <el-tree
      :data="treeData"
      :expand-on-click-node="false"
      highlight-current
      default-expand-all
      :props="defaultProps"
      @node-click="handleNodeClick">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <el-radio v-if="selectType === 'radio'" v-model="activeId" @input="inputRadio(data)" :label="data.id">{{node.label}}</el-radio>
        <el-checkbox v-else :value="checked" :disabled="data.id === disabledId" @change="inputCheckbox(data)" :label="data.id">{{ node.label }}</el-checkbox>
        <div :class="{'circle': data.curUserAouthProjectCount > 0}">{{ data.curUserAouthProjectCount > 0 ? data.curUserAouthProjectCount : '' }}</div>
      </span>
    </el-tree>
  </div>
</template>

<script>
import {throttle} from '@/utils/tools'
export default {
  name: 'CompanyTree',
  components: {},
  props: {
    // 选择框类型：radio, checkbox
    selectType: {
      type: String,
      default: 'radio'
    },
    // 禁用选择id
    disabledId: {
      type: String,
      default: ''
    },
    treeData: {
      type: Array,
      default: []
    },
    // 默认选中公司id
    checkedCompanyId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      defaultProps: {
        children: 'child',
        label: 'name'
      },
      checked: [],
      activeId: ''
    }
  },
  watch: {
    checkedCompanyId (value) {
      if (value) {
        this.activeId = this.checkedCompanyId
      }
    }
  },
  methods: {
    handleNodeClick: throttle(function (data) {
      if (data.id === this.disabledId) {
        return
      }
      if (this.selectType === 'radio') {
        this.activeId = data.id
        this.$emit('node-click', data)
      } else {
        // 判断id是否被选中
        if (this.checked.includes(data.id)) {
          // 如被选中则删除
          this.checked = this.checked.filter(item => item !== data.id)
        } else {
          // 没选中则选中
          this.checked.push(data.id)
        }
        this.$emit('node-click', this.checked)
      }
    }, 500),
    inputRadio (data) {
      this.handleNodeClick(data)
    },
    inputCheckbox (data) {
      this.handleNodeClick(data)
    },
    treeToflat (arr = [], ret = []) {
      arr.forEach(item => {
        if (item.child) {
          this.treeToflat(item.child, ret)
        }
        ret.push(item.id)
      })
      return ret
    },
    checkAll () {
      this.checked = this.treeToflat(this.treeData).filter(item => item !== this.disabledId)
      this.$emit('node-click', this.checked)
    },
    cancelCheckAll () {
      this.checked = []
      this.$emit('node-click', this.checked)
    }
  }
}
</script>

<style scoped lang="scss">
.circle {
  background-color: #e9635c;
  color: #fff;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  line-height: 20px;
}
.custom-tree-node {
  display: flex;
  align-items: center;
}
</style>
