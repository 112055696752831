import {getPageViewWidth, dateFormat} from '@/utils/tools'

export default {
  name: 'searchItems',
  props: {
    items: {
      type: Array,
      default: () => []
      /*
        type: 组件类型：input, select, datePicker, selectTree
        label: 文字描述
        name: 绑定的key
        options: select的选择项，
        props: 作为 options 唯一标识的键名，默认value: 'value', // ID字段名，label: 'displayName', // 显示名称
        cb: 回调函数,
        multiple: 是否多选,
        range-keys: 时间选择组件使用,集合类型[]
       */
    },
    // 默认值，回显在input框内
    values: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 显隐列按钮
    showColumns: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      actions: '',
      queryData: {},
      showPopover: false, // 展示显隐列窗口
      filterColumns: [], // 显隐列的列名
      showAll: false,
      scrollHeight: 0, // 查询条件组的高度，通过判断高度来显示是否更多
    }
  },
  watch: {
    values: {
      handler () {
        this.queryData = this.values
      },
      // immediate: true,
    },
  },
  computed: {
    dateAction () {
      let actions = []
      return {
        dateOptions: {
          shortcuts: [
            {
              text: '天'
            },
            {
              text: '昨天',
              onClick(picker) {
                actions.push('昨天')
                const start = new Date()
                start.setDate(start.getDate() - 1) // 设置为昨天
                start.setHours(0, 0, 0, 0) // 设置为00:00:00
                picker.$emit('pick', start)
              }
            },
            {
              text: '今天',
              onClick(picker) {
                actions.push('今天')
                const start = new Date()
                start.setHours(0, 0, 0, 0) // 设置为00:00:00
                picker.$emit('pick', start)
              }
            },
            {
              text: '月'
            },
            {
              text: '上月',
              onClick(picker) {
                actions.push('上月')
                const start = new Date()
                start.setMonth(start.getMonth() - 1) // 设置为上个月的第一天
                start.setDate(1)
                start.setHours(0, 0, 0, 0) // 设置为00:00:00
                picker.$emit('pick', start)
              }
            },
            {
              text: '本月',
              onClick(picker) {
                actions.push('本月')
                const start = new Date()
                start.setDate(1) // 设置为本月的第一天
                start.setHours(0, 0, 0, 0) // 设置为00:00:00
                picker.$emit('pick', start)
              }
            },
            {
              text: '季'
            },
            {
              text: '上季',
              onClick(picker) {
                actions.push('上季')
                let y = new Date().getFullYear();  // 当前年份
                let m = new Date().getMonth();  // 当前月份
                let q = parseInt(m / 3);  // 当前季度
                let start = new Date(y, (q - 1) * 3, 1);  // 上一季度的开始日期
                start.setHours(0, 0, 0, 0) // 设置为00:00:00
                picker.$emit('pick', start)
              }
            },
            {
              text: '本季',
              onClick(picker) {
                actions.push('本季')
                const date = new Date()
                let thisYear = date.getFullYear()
                let thisMonth = date.getMonth() + 1
                let n = Math.ceil(thisMonth / 3) // 季度
                let Month = n * 3 - 1
                let start = new Date(thisYear, Month - 2, 1)
                start.setHours(0, 0, 0, 999) // 设置为00:00:00
                picker.$emit('pick', start)
              }
            },
            {
              text: '年'
            },
            {
              text: '上年',
              onClick(picker) {
                actions.push('上年')
                let currentDate = new Date()
                // 获得当前年份4位年
                let currentYear = currentDate.getFullYear() - 1
                // 本年第一天
                const start = new Date(currentYear, 0, 1)
                start.setHours(0, 0, 0, 0)
                picker.$emit('pick', start)
              }
            },
            {
              text: '本年',
              onClick(picker) {
                actions.push('本年')
                const start = new Date().getFullYear()
                const startYear = new Date(start, 0, 1)
                picker.$emit("pick", startYear)
              }
            },
          ]
        },
        actions
      }
    },
    word () {
      return this.showAll ? '收起更多' : '展开更多'
    },
    // 获取可视窗口宽度
    viewWidth () {
      return getPageViewWidth()
    },
  },
  mounted() {
    this.queryData = this.values // 设置初始查询值
    this.bus.$on('setCol', val => this.filterColumns = val)
    console.log(this.$refs.searchBox.clientHeight, this.$refs.searchBox.scrollHeight, 'aaa')
    this.scrollHeight = this.$refs.searchBox.scrollHeight // 获取到滚动视窗内所有查询条件高度撑起来的高度
  },
  render () {
    const {items, queryData, dateAction} = this
    const renderComponent = item => {
      let component = null
      switch (item.type) {
      case 'input': {
        component = <ElInput
          placeholder={item.placeholder || '请输入'}
          clearable={item?.clearable ?? true}
          v-model={queryData[item.name]}
          class="w-190"/>
      }
        break;
      case 'selectTree': {
        let options = item.options || []
        const scopedSlots = item.slot ? {
          'value-label': item.slot,
        } : null
        component = (
          <treeselect
            class="w-190"
            options={options}
            placeholder="请选择"
            no-results-text="无搜索结果"
            no-children-text="无子节点"
            no-options-text="无可用选项"
            retryText="重试"
            limit={1}
            appendToBody={true}
            zIndex="9999"
            limitText={e => limitText(e)}
            value-consists-of="LEAF_PRIORITY"
            disable-branch-nodes={item.nodes || false}
            load-options={item.loadOptions || undefined}
            multiple={item.multiple || false} // 多选
            flat={item.flat || false} // 是否平面模式，父子不联动
            normalizer={item.normalizer}
            scopedSlots={scopedSlots}
            onSelect={e => changeTree(e, item)}
            v-model={queryData[item.name]}>
          </treeselect>
        )
      }
        break;
      case 'select': {
        let options = item.options || []
        let props = item.props || {
          value: 'value', // ID字段名
          label: 'displayName', // 显示名称
        }
        component = (
          <div class="flex-h">
            <ElSelect
              clearable={item?.clearable ?? true}
              onClear={clear.bind(this, item.name)}
              placeholder={item.placeholder || '请选择'}
              v-model={queryData[item.name]}
              class="w-190"
              reserve-keyword={true}
              allow-create={item.create || false}
              collapse-tags={item.collapse || true}
              remote={item.remote || false}
              onChange={e => change(e, item)}
              multiple={item.multiple || false}
              remote-method={item.remoteMethod}
              filterable={true}>
              {
                options.map(x =>
                  x.child ? <ElOptionGroup key={x.id} label={x[props.label]}>
                    {
                      x.child?.map(y => <ElOption key={y.id} value={y[props.value]} label={y[props.label]}></ElOption>)
                    }
                  </ElOptionGroup> :
                    <ElOption key={x.id} value={x[props.value]} label={x[props.label]}></ElOption>
                )
              }
            </ElSelect>
            <ElTooltip effect="dark" content={item.tooltipText} placement="top" v-show={item.tooltipText}>
              <i class="el-icon-question" style="margin-left: 10px"></i>
            </ElTooltip>
            <i class="el-icon-refresh" style="color: #409EFF;cursor: pointer;margin-left: 10px" onClick={refreshPeople} v-show={item.refresh || false}></i>
          </div>
        )
      }
        break;
      case 'cascader': {
        let props = item.props || {
          value: 'id',
          label: 'name',
          children: 'child',
          emitPath: false,
        }
        component = (
          <el-cascader
            clearable
            onChange={e => change(e, item)}
            options={item.options}
            v-model={queryData[item.name]}
            props={{
              props: props
            }}
            filterable>
          </el-cascader>
        )
      }
        break;
      case 'datePicker': {
        // shortcut时间选择快捷选项，开启的时候默认使用dateOptions
        let range = null
        let datePicker = null
        if (['datetimerange', 'daterange'].includes(item.dateType)) {
          range = (
            <div>
              <ElDatePicker
                placeholder='开始日期'
                v-model={queryData[item.rangeKeys[0]]}
                time-arrow-control
                default-time={item.defaultTime ? item.defaultTime[0] : undefined}
                value-format={item.format || 'yyyy-MM-dd'}
                type={item.dateType === 'datetimerange' ? 'datetime' : 'date'}
                clearable={item?.clearable ?? true}
                popper-class={'date-style'}
                picker-options={item.pickerOptions || dateAction.dateOptions}
                onBlur={e => changeRangeDate(e, item.rangeKeys[0], item)}
                class='w-190'>
              </ElDatePicker>
              <span>至</span>
              <ElDatePicker
                placeholder='结束日期'
                v-model={queryData[item.rangeKeys[1]]}
                time-arrow-control
                default-time={item.defaultTime ? item.defaultTime[1] : undefined}
                value-format={item.format || 'yyyy-MM-dd'}
                type={item.dateType === 'datetimerange' ? 'datetime' : 'date'}
                clearable={item?.clearable ?? true}
                picker-options={item.pickerOptions}
                onBlur={e => changeRangeDate(e, item.rangeKeys[1], item)}
                class='w-190'>
              </ElDatePicker>
            </div>
          )
        } else {
          datePicker = (
            <div>
              <ElDatePicker
                placeholder={item.placeholder || '请选择'}
                v-model={queryData[item.name]}
                time-arrow-control
                default-time={item.defaultTime || undefined}
                value-format={item.format || 'yyyy-MM-dd'}
                type={item.dateType || 'date'}
                clearable={item?.clearable ?? true}
                popper-class={item.shortcut ? 'date-style' : ''}
                picker-options={item.shortcut ? dateAction.dateOptions : item.pickerOptions}
                class='w-190'>
              </ElDatePicker>
              {/* 输入框后面展示文字 */}
              <span>{item.text}</span>
            </div>
          )
        }
        component = ['datetimerange', 'daterange'].includes(item.dateType) ? range : datePicker
      }
        break;
      case 'checkbox': {
        component = (
          <div>
            <ElCheckbox v-model={queryData[item.name]}>{item.text}</ElCheckbox>
            <ElTooltip effect="dark" content={item.tooltipText} placement="top" v-show={item.tooltipText}>
              <i class="el-icon-question"></i>
            </ElTooltip>
          </div>
        )
      }
        break
      default:
        component = (
          <div style={{width: item.width || '100px'}}></div>
        )
        break;
      }
      return component
    }
    const showComponents = () => {
      return items.map(x => {
        const renderItem = renderComponent(x)
        return (
          <div id={x.name} class="flex-h" style={x.style || 'margin: 10px'}  v-show={x.show === undefined ? true : x.show}>
            <div>{x.label}</div>
            {renderItem}
          </div>
        )
      })
    }
    const limitText = e => {
      return "+" + e
    }
    const clear = key => {
      delete this.queryData[key]
    }
    const change = (value, item) => {
      item.cb && item.cb(value)
      this.$forceUpdate()
    }
    const changeRangeDate = (value, key, item) => {
      // 获取到最后一次操作，比如本月今日等
      this.actions = this.dateAction.actions.pop()
      if (this.actions === undefined) {
        this.$forceUpdate() // 强制刷新视图
      } else {
        setTimeout(() => {
          // 获取到结束时间属性
          // let end = item.rangeKeys[1]
          let date = ''
          // 根据点击，来获取时间
          if (this.actions) {
            date = {
              '昨天': yesterday(),
              '今天': today(),
              '上月': precedingMonth(),
              '本月': theMonth(),
              '上季': lastQuarter(),
              '本季': currentSeason(),
              '上年': lastYear(),
              '本年': currentYear(),
            }[this.actions]
          }
          // 根据设定日期来进行格式化，如果没设定，则默认格式化
          const format = item.format || 'yyyy-MM-dd'
          this.queryData[item.rangeKeys[1]] = dateFormat(date, format)
          this.$forceUpdate() // 强制刷新视图
        }, 200)
      }
    }
    const refreshPeople = () => {
      this.$store.dispatch('clearPeople', this.queryData.projectId)
    }
    const changeTree = (value, item) => {
      item.cb && item.cb(value)
    }
    const search = () => {
      let query = {}
      for (const key in this.queryData) {
        if (this.queryData[key] !== undefined && this.queryData[key] !== null && this.queryData[key] !== '' && this.queryData[key].length !== 0) {
          let data = this.queryData[key]
          // 只有类型为字符串的，去除查询前后空格
          if (typeof data === 'string') {
            query[key] = data.toString().trim()
          } else {
            query[key] = data
          }
        }
      }
      this.$emit('search', query)
    }
    const reset = () => {
      this.queryData = {}
      this.$emit('clear', {})
    }
    const ok = () => {
      this.showPopover = false
      this.bus.$emit('refreshCol')
    }
    const resetCol = () => {
      this.showPopover = false
      this.bus.$emit('resetCol')
    }
    const changeShowAll = () => {
      this.showAll = !this.showAll
    }
    // 昨天
    const yesterday = () => {
      let end = new Date()
      end.setDate(end.getDate() - 1)
      end.setHours(23, 59, 59, 0) // 设置为23:59:59
      return end
    }
    // 今天
    const today = () => {
      let end = new Date()
      end.setHours(23, 59, 59, 0) // 设置为23:59:59
      return end
    }
    // 上月
    const precedingMonth = () => {
      let end = new Date()
      end.setDate(0) // 设置为上个月的最后一天
      end.setHours(23, 59, 59, 0) // 设置为23:59:59
      return end
    }
    // 本月
    const theMonth = () => {
      let date = new Date() // 本月的今天
      let y = date.getFullYear()
      let m = date.getMonth()
      let end = new Date(y, m + 1, 0)
      end.setHours(23, 59, 59, 0) // 设置为23:59:59
      return end
    }
    // 上季
    const lastQuarter = () => {
      let y = new Date().getFullYear()  // 当前年份
      let m = new Date().getMonth()  // 当前月份
      let q = parseInt(m / 3)  // 当前季度
      let end = new Date(y, q * 3, 0)  // 上一季度的结束日期
      end.setHours(23, 59, 59, 0) // 设置为00:00:00
      return end
    }
    // 本季
    const currentSeason = () => {
      let oDate = new Date()
      let thisYear = oDate.getFullYear()
      let thisMonth = oDate.getMonth() + 1
      let n = Math.ceil(thisMonth / 3) // 季度
      let Month = n * 3 - 1
      let end = new Date(thisYear, Month + 1, 1)
      end.setHours(23, 59, 59, 0) // 设置为00:00:00
      end.setDate(0)
      return end
    }
    // 上年
    const lastYear = () => {
      let currentDate = new Date()
      // 获得当前年份4位年
      let currentYear = currentDate.getFullYear() - 1
      // 本年最后一天
      let end = new Date(currentYear, 11, 31)
      end.setHours(23, 59, 59, 0)
      return end
    }
    // 本年
    const currentYear = () => {
      let currentDate = new Date()
      // 获得当前年份4位年
      let currentYear = currentDate.getFullYear()
      // 本年最后一天
      let end = new Date(currentYear, 11, 31)
      end.setHours(23, 59, 59, 0)
      return end
    }
    return (
      <div ref="mySearch">
        <div>
          <div style="flex-wrap: wrap" class={[this.showAll ? 'auto-height' : 'hide-height', 'flex-h']} ref="searchBox">
            {showComponents()}
            {this.$scopedSlots.default && this.$scopedSlots.default({queryData: this.queryData})}
          </div>
          <div style="text-align: center">
            <ElButton type="text" onClick={changeShowAll} style={this.scrollHeight > 56 ? 'inline-block' : 'display: none'}>
              {this.word}
              <i class={this.showAll ? 'el-icon-arrow-up ': 'el-icon-arrow-down'}></i>
            </ElButton>
          </div>
        </div>
        <div class="flex-h flex-between">
          <div style="max-width: 70%">{this.$scopedSlots.alert && this.$scopedSlots.alert()}</div>
          <div class="flex-h">
            <ElButton type="primary" onClick={search.bind(this)}>查询</ElButton>
            <ElButton onClick={reset.bind(this)}>重置</ElButton>
            <div style="margin: 0 10px">
              {this.$scopedSlots.btn && this.$scopedSlots.btn({queryData: this.queryData})}
            </div>
            <ElPopover placement="bottom" v-model={this.showPopover} trigger="click">
              {
                this.filterColumns.map((item, index) => {
                  return (
                    <div key={index}>
                      <ElCheckbox v-model={item.visible}>{item.title}</ElCheckbox>
                    </div>
                  )
                })
              }
              <div class="flex-h" style="font-size: 14px; margin-top: 10px;justify-content: space-around;">
                <ElLink onClick={ok.bind(this)}>确定</ElLink>
                <ElLink onClick={resetCol.bind(this)}>还原</ElLink>
              </div>
              <ElButton size="mini" v-show={this.showColumns} slot="reference">显隐列</ElButton>
            </ElPopover>
          </div>
        </div>
      </div>
    )
  }
}