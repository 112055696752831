<template>
  <div class="drawStation">
    <div class="input">
      <div>
        <el-input id="tipinput" v-model="tipinput" size="small" placeholder="请输入地址查询经纬度"></el-input>
      </div>
    </div>
    <div id="draw-station-container"></div>
  </div>
</template>

<script>
import { JS_API_KEY } from '@/utils/mapConfig'
import AMapLoader from "@amap/amap-jsapi-loader"

export default {
  name: "DrawStation",
  data() {
    return {
      // 地图对象
      AMap: null,
      // 地图实例对象
      map: null,
      // 经纬度
      location: {
        lat: '',
        lng: '',
        address: '',
        province: '',
        city: '',
        district: '',
      },
      // 搜索输入
      tipinput: '',
      auto: null,
      placeSearch: null,
      markers: []
    }
  },
  mounted() {
    // DOM初始化完成进行地图初始化
    this.initMap()
  },
  methods: {
    /**
     * 创建地图
     */
    initMap() {
      AMapLoader.load({
        key: JS_API_KEY, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Scale', 'AMap.PlaceSearch', 'AMap.AutoComplete', "AMap.Geocoder"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then(AMap => {
          this.AMap = AMap
          this.map = new AMap.Map("draw-station-container", {
            // 设置地图容器id
            viewMode: "3D", // 是否为3D地图模式
            zoom: 15, // 初始化地图级别
            center: [116.397428, 39.90923], // 初始化地图中心点位置
          })

          // 地图控件
          this.map.addControl(new AMap.Scale())
          // 搜索框自动完成类
          this.auto = new AMap.AutoComplete({
            input: "tipinput"
          })
          // 构造地点查询类
          this.placeSearch = new AMap.PlaceSearch({
            map: this.map
          })

          // 当选中某条搜索记录时触发
          this.auto.on("select", this.select)
          // poi覆盖物点击事件
          this.placeSearch.on("markerClick", this.clickMarkerHandler)
          // 地图点击事件
          this.map.on('click', this.clickMapHandler)

        })
        .catch(e => {
          console.log(e)
        })
    },

    // 当选中某条记录时会触发
    select(e) {
      this.placeSearch.setCity(e.poi.adcode)
      this.placeSearch.search(e.poi.name) // 关键字查询查询
    },

    // 点击地图事件
    clickMapHandler(e) {
      console.log(e, '点击地图事件')
      // 这里通过高德 SDK 完成。
      let geocoder = new AMap.Geocoder({
        radius: 100,
        extensions: "all"
      });
      const vm = this
      geocoder.getAddress([e.lnglat.lng, e.lnglat.lat], function (status, result) {
        if (status === "complete" && result.info === "OK") {
          if (result && result.regeocode) {
            console.log("点击获取地址的数据", result)
            vm.location.lng = e.lnglat.lng ? e.lnglat.lng.toString() : ""
            vm.location.lat = e.lnglat.lat ? e.lnglat.lat.toString() : ""
            vm.location.province = result.regeocode.addressComponent.province
            vm.location.city = result.regeocode.addressComponent.city
            vm.location.district = result.regeocode.addressComponent.district
            vm.location.address = result.regeocode.formattedAddress
            vm.handleDrawStation()
            // vm.markers = []
            // const obj = {
            //   position: [e.lnglat.lng, e.lnglat.lat],
            //   text: result.regeocode.formattedAddress,
            //   offset: [0, 30],
            // };
            // vm.markers.push(obj)
            // let marker = new AMap.Marker({
            //   ...obj
            // })
            // marker.setMap(vm.map)
            // vm.markers.push(marker)
          }
        }
      });
    },
    // 点击poi覆盖物事件
    clickMarkerHandler(e) {
      console.log(e, '点击poi覆盖物事件')
      this.location.lng = e.data.location.lng
      this.location.lat = e.data.location.lat
      this.location.province = e.data.pname
      this.location.city = e.data.cityname
      this.location.district = e.data.adname
      this.location.address = e.data.address
      this.handleDrawStation()
    },
    // 确定拾取坐标点
    handleDrawStation() {
      this.$emit('selectLocation', this.location)
    }

  },
  beforeDestroy() {
    // 销毁地图
    if (this.map) this.map.destroy()
  },
};
</script>

<style lang="scss">
.drawStation {

  #draw-station-container {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 400px;
  }

  .input {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .el-input {
      width: 220px;
      margin-right: 20px;
    }
  }
}
.amap-sug-result{
  z-index: 9999
}
</style>