<template>
  <div>
    <my-search :values="queryData" :items="searchItems" @search="search" @clear="clear">
      <el-button slot="btn" type="primary" @click="visible = true" v-hasPermi="['project:feeType:add']">新增</el-button>
    </my-search>
    <my-table :columns="columns" ref="myTable" url="/FeeType/QueryFeeTypePagerList" :query="queryData">
      <vxe-table-column title="操作" width="100">
        <template #default="{row}">
          <el-button type="text" @click="edit(row)" v-hasPermi="['project:feeType:edit']">编辑</el-button>
          <el-button type="text" @click="del(row)" v-hasPermi="['project:feeType:del']">删除</el-button>
        </template>
      </vxe-table-column>
    </my-table>
    <fee-type-edit :visible.sync="visible" :tree="tree" @refresh="refresh" :items.sync="items"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FeeTypeEdit from './components/FeeTypeEdit'
import {companyProjectTree} from '@/api/system'
import {delFeeType, companyList} from '@/api/project'
export default {
  name: 'feeType',
  components: {FeeTypeEdit},
  data () {
    return {
      columns: [
        {
          label: '公司名称',
          prop: 'companyName'
        },
        {
          label: '项目名称',
          prop: 'projectName'
        },
        {
          label: '费用名称',
          prop: 'name'
        },
        {
          label: '状态',
          prop: 'statusName',
          operate: true,
          render: (h, {row}) => {
            let type = ''
            if (row.status) {
              type = {
                1: 'success', // 启用
                2: 'danger', // 禁用
              }[row.status]
            }
            return h('el-tag', {
              props: {
                type: type
              }
            }, row.statusName)
          }
        },
        {
          label: '计算方式',
          prop: 'feeModeName'
        },
        {
          label: '钱包类型',
          prop: 'accountTypeName'
        },
        {
          label: '资源类型',
          prop: 'resourceTypeName'
        },
        {
          label: '固定分类名称',
          prop: 'fixedFeeTypeName'
        },
        {
          label: '收费项目是否唯一',
          prop: 'chargeItemUnique',
          operate: true,
          render: (h, {row}) => {
            return h('div', {
            }, row.chargeItemUnique ? '是' : '否')
          }
        },
        {
          label: '备注',
          prop: 'remarks'
        },
      ],
      queryData: {
        status: 1
      },
      visible: false,
      tree: [],
      items: {}, // 行详情
      projectList: []
    }
  },
  computed: {
    ...mapGetters(['dict', 'companyTree']),
    searchItems () {
      const {dict, companyTree, projectList} = this
      const statusOptions = [
        {
          displayName: '启用',
          value: 1
        },
        {
          displayName: '禁用',
          value: 2
        }
      ]
      return [
        {
          type: 'selectTree',
          label: '公司名称/编号：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany,
          style: 'margin-right: 0'
        },
        {
          type: 'select',
          label: '',
          name: 'projectId',
          options: projectList
        },
        // {type: 'input', label: '公司名称：', name: 'companyName'},
        // {type: 'input', label: '项目名称：', name: 'projectName'},
        {type: 'input', label: '费用类型名称：', name: 'name'},
        {type: 'select', label: '费用模式：', name: 'feeMode', options: dict.FeeMode},
        {type: 'select', label: '状态：', name: 'status', options: statusOptions }
      ]
    }
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
    this.getTreeList()
  },
  methods: {
    getTreeList () {
      companyProjectTree({types: [1, 2, 3]}).then(res => {
        this.tree = res.data.map(x => {
          return {
            ...x,
            name: x.name + `(${x.companyAlias})`
          }
        })
      })
    },
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    refresh () {
      this.$refs.myTable.refresh(this.queryData)
    },
    edit (row) {
      this.items = row
      this.visible = true
    },
    del (row) {
      this.$confirm(`是否删除<span style="color: #da251c">${row.name}</span>？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        delFeeType({id: row.id}).then(res => {
          this.$message.success(res.message)
          this.refresh()
        })
      })
    },
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    changeCompany (data) {
      this.$store.dispatch('getCompanyDownProject', {companyId: data.id, containsClosed: true}).then(res => {
        this.projectList = res
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
