<template>
  <el-dialog title="短信充值" :visible.sync="visible" :before-close="close" width="30%">
    <el-form :model="form" label-width="100px" ref="form" :rules="rules">
      <el-form-item label="充值条数" prop="count">
        <el-input-number v-model="form.count" style="width: 100%" :step-strictly="false" :controls="false"></el-input-number>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.notes" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {recharge} from '@/api/sms'
export default {
  name: 'SmsRecharge',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    itemId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      form: {
        count: '',
        notes: ''
      },
      rules: {
        count: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      loading: false
    }
  },
  mounted () {},
  methods: {
    close () {
      this.$emit('update:itemId', '')
      this.$emit('update:visible', false)
      this.form = {
        count: '',
        notes: ''
      }
      this.$refs.form.resetFields()
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.form.id = this.itemId
          recharge(this.form).then(res => {
            this.$message.success(res.message)
            this.loading = false
            this.$emit('refresh')
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
