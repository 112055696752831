<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="close" width="30%" :close-on-click-modal="false">
    <div class="flex-h" style="margin-bottom: 10px;">
      <div style="margin-right: 10px;">{{items.label}}</div>
      <el-input v-model="items.value" v-if="items.controlType === 1" style="width: 200px" placeholder=""></el-input>
      <el-input-number v-model="items.value" v-if="items.controlType === 2" :controls="false" placeholder=""></el-input-number>
      <vxe-switch open-label="是" close-label="否" open-value="true" close-value="false" v-model="items.value" v-if="items.controlType === 3"></vxe-switch>
      <el-select v-model="items.value" placeholder="请选择" v-if="items.controlType === 4" style="width: 200px">
        <el-option v-for="elem in items.selectItem" :key="elem.key" :label="elem.value" :value="elem.key"></el-option>
      </el-select>
      <el-select v-model="items.value" placeholder="请选择" v-if="items.controlType === 5" style="width: 200px" multiple>
        <el-option v-for="elem in items.selectItem" :key="elem.key" :label="elem.value" :value="elem.key"></el-option>
      </el-select>
    </div>
    <div v-if="items.code === 'DoesOpenOfficialAccount'" v-show="items.value === 'true'">
      <div class="flex-h">
        <div>公众号配置：</div>
        <el-select v-model="items.weiXinConfigId" placeholder="请选择" style="margin-right: 10px">
          <el-option v-for="item in wxDownList" :key="item.configId" :value="item.configId" :label="item.configName"></el-option>
        </el-select>
      </div>
      <div>
        <el-button type="text" @click="viewQrCode(items, '公众号二维码')">查看公众号二维码</el-button>
      </div>
    </div>
    <div v-if="items.controlType === 3">系统默认值：{{ items.systemDefaultValue === 'true' ? '是' : '否' }}</div>
    <div v-else-if="items.controlType === 4">系统默认值：{{ getDefaultText(items)}}</div>
    <div v-else-if="items.controlType === 5">系统默认值：{{ getDefaultText(items)}}</div>
    <div v-else>系统默认值：{{ items.systemDefaultValue}}</div>
    <div style="color: #da251c;margin-top: 10px;">当前为批量设置，所以只能显示系统初始默认值</div>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="submit" :loading="loading" type="primary">确定</el-button>
    </div>
    <el-dialog :title="qrTitle" :visible.sync="visibleQrCode" :before-close="closeQrcode" append-to-body width="340px">
      <vue-qr
        ref="qrCode"
        :text="qrUrl"
        :size="300"
        :margin="10"
        :logoScale="0.2">
      </vue-qr>
    </el-dialog>
  </el-dialog>
</template>

<script>
import {allAreaConfigEdit, wxConfigDown} from '@/api/project'
import VueQr from 'vue-qr'

export default {
  name: 'AreaConfigEdit',
  components: {VueQr},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      loading: false,
      wxDownList: [],
      qrUrl: '',
      visibleQrCode: false,
      qrTitle: ''
    }
  },
  computed: {
    title () {
      return this.items.label
    }
  },
  watch: {
    visible (value) {
      if (value && this.items.code === 'DoesOpenOfficialAccount') {
        wxConfigDown().then(res => {
          this.wxDownList = res.data
        })
      }
    }
  },
  mounted () {},
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
    },
    getDefaultText (items) {
      const item = items.selectItem.find(x => x.key === items.systemDefaultValue)
      return item.value
    },
    viewQrCode (data, title) {
      if (data.weiXinConfigId) {
        this.$message.success('请选择公众号配置')
        return
      }
      let item = this.wxDownList.find(x => x.configId === data.weiXinConfigId)
      this.qrUrl = item.weiXinNumber
      this.qrTitle = title
      this.visibleQrCode = true
    },
    closeQrcode () {
      this.visibleQrCode = false
      this.qrUrl = ''
      this.qrTitle = ''
    },
    submit () {
      this.$confirm('此操作将更改选中项目，请谨慎操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        const data = {
          projectIds: this.items.projectIds,
          code: this.items.code,
          value: this.items.controlType === 5 ? this.items.value.join(',') : this.items.value
        }
        if (this.items.code === 'DoesOpenOfficialAccount') {
          data.weiXinConfigId = this.items.weiXinConfigId
        }
        allAreaConfigEdit(data).then(res => {
          this.$message.success(res.message)
          this.loading = false
          this.$emit('refresh')
          this.close()
        }).catch(() => {
          this.loading = false
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
