/* 公司授权菜单 */
<template>
  <el-dialog :title="`${items.name}` + '菜单授权'" :visible.sync="visible" :before-close="close" :close-on-click-modal="false" destroy-on-close width="30%">
    <div class="flex-h">
      <div>菜单标签类型：</div>
      <el-select v-model="functionFlagTypes" placeholder="请选择" multiple @change="changeFlag" collapse-tags>
        <el-option v-for="item in dict.FunctionFlagType" :key="item.value" :value="item.value" :label="item.displayName"></el-option>
      </el-select>
    </div>
    <el-tree
      :data="menus"
      default-expand-all
      node-key="functionId"
      ref="menuTree"
      show-checkbox
      :props="defaultProps">
    </el-tree>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="submit" type="primary" :loading="loading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {companyEditMenu} from '@/api/project'
import { mapGetters } from 'vuex'
export default {
  name: 'CompanyAuthorizationMenu',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      loading: false,
      defaultProps: {
        children: "child",
        label: "name"
      },
      functionFlagTypes: [0, 1, 2, 3, 4, 5]
    }
  },
  computed: {
    ...mapGetters(['menu', 'dict']),
    menus () {
      // 屏蔽运营平台菜单, id=2是运营平台
      return this.menu.filter(x => x.id !== '2')
    }
  },
  watch: {
    visible (value) {
      if (value) {
        setTimeout(() => {
          this.list.forEach(v => {
            this.$nextTick(() => {
              this.$refs.menuTree.setChecked(v.functionId, true, false)
            })
          })
        }, 300)
      }
    }
  },
  mounted () {
    this.getMenuTree()
  },
  methods: {
    getMenuTree () {
      this.$store.dispatch('getMenu', {functionFlagTypes: this.functionFlagTypes})
    },
    changeFlag () {
      this.getMenuTree()
      setTimeout(() => {
        this.list.forEach(v => {
          this.$nextTick(() => {
            this.$refs.menuTree.setChecked(v.functionId, true, false)
          })
        })
      }, 300)
    },
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:list', [])
      this.$emit('update:items', {})
    },
    submit () {
      let checkedNode = this.getMenuAllCheckedKeys()
      if (checkedNode.length === 0) {
        return
      }
      this.loading = true
      let formKey = {
        id: this.items.id,
        functions: checkedNode
      }
      companyEditMenu(formKey).then(res => {
        this.$message.success(res.message)
        this.loading = false
        this.$emit('refresh')
        this.close()
      }).catch(() => {
        this.loading = false
      })
    },
    getMenuAllCheckedKeys () {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs.menuTree.getCheckedKeys()
      // 半选中的菜单节点
      let halfCheckedKeys = this.$refs.menuTree.getHalfCheckedKeys()
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys)
      return checkedKeys
    },
  }
}
</script>

<style scoped lang="scss">

</style>
