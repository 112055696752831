<template>
  <el-dialog title="新增短信账户" :before-close="close" width="30%" :visible.sync="visible">
    <el-form :model="form" ref="form" label-width="100px" :rules="rules">
      <el-form-item label="所属公司" prop="companyId">
        <treeselect v-model="form.companyId" :options="companyTree" retryText="重试" :load-options="loadOptions" :normalizer="normalizer" placeholder="选择公司" />
      </el-form-item>
      <el-form-item label="所属项目">
        <el-select v-model="form.projectIds" multiple style="width: 100%">
          <el-option v-for="item in downList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import {projectDownList, companyList} from '@/api/project'
import {createdSmsAccount} from '@/api/sms'
import { mapGetters } from "vuex"
export default {
  name: 'SmsEdit',
  components: {Treeselect},
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      form: {
        companyId: null,
        projectIds: []
      },
      // list: [], // 公司列表
      project: [],
      rules: {
        companyId: [{ required: true, trigger: "change", message: "请选择" }],
      }
    }
  },
  computed: {
    ...mapGetters(['companyTree']),
    // 通过选择公司，进行过滤出来下级项目
    downList () {
      const {companyId = ''} = this.form
      let options = this.project.filter(item => {
        return item.companyId.indexOf(companyId) > -1
      })
      return options || []
    }
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
    this.projectList()
  },
  methods: {
    projectList () {
      projectDownList().then(res => {
        this.project = res.data
      })
    },
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    close () {
      this.$emit('update:visible', false)
      this.form = {
        companyId: null,
        projectIds: []
      }
      this.$refs.form.resetFields()
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          createdSmsAccount(this.form).then(res => {
            this.$message.success(res.message)
            this.loading = false
            this.$emit('refresh')
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
