import * as signalR from '@microsoft/signalr'
import { getToken } from '@/utils/auth'
let url = process.env.VUE_APP_BASE_WS + '/hubs/chathub'
if (process.env.NODE_ENV === 'development') {
  url = '/hubs/chathub'
}
// 如果需要身份验证 .withUrl("/messageHub", {accessTokenFactory: () => sessionStorage.getItem("token")})
const connection = new signalR.HubConnectionBuilder()
  .withUrl(url, {accessTokenFactory: () => getToken()}) // 跨域需要使用绝对地址
  .withAutomaticReconnect([0,  5000, 10000, 15000, 20000, 25000])
  // .withAutomaticReconnect([0,  5000, 30000, 60000, 300000, 600000])
  .build()

async function start() {
  try {
    await connection.start()
    console.log('连接成功')
  } catch (err) {
    console.log('错误重连', err)
    setTimeout(start, 10000) // 错误重连
  }
}

// connection.onclose(async error => {
//   console.log('运营断线重连', error)
//   // 断线重连 error是空的话则是手动断开，不需要重连
//   // if (!!error) await start()
//   // await start()
// })

// 开始signalr连接
const connect = async () => {
  await start()
}
// 调用服务端方法
async function send(methodName, param) {
  try {
    await connection.invoke(methodName, param)
  } catch (err) {
    console.error(err)
  }
}
// 获取服务端长链接的推送信息
connection.on('default', message => {
  console.log(message, '111234')
})
// 断开连接
const disconnect = async () => {
  await connection.stop()
}
export { connection, connect, send, disconnect }
