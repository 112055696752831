<template>
  <div>
    <my-search :items="searchItems" @search="search" @clear="clear"></my-search>
    <my-table :columns="columns" url="/OperateLog/QueryOperaLogPagerList" ref="myTable" :query="{orderBy: ['id desc']}"></my-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {companyList} from '@/api/project'

export default {
  name: 'logs',
  components: {},
  data () {
    return {
      columns: [
        {
          label: '公司名称',
          prop: 'companyName',
        },
        {
          label: '项目名称',
          prop: 'projectName',
        },
        {
          label: '日志类型',
          prop: 'logTypeName',
          width: '100px'
        },
        {
          label: '业务类型',
          prop: 'businessTypeName',
          width: '100px'
        },
        {
          label: '操作内容',
          prop: 'content',
          minWidth: '400px'
        },
        {
          label: '操作人',
          prop: 'createdByName',
          width: '80px'
        },
        {
          label: '操作时间',
          prop: 'createdTime'
        },
        {
          label: 'ip',
          prop: 'ip'
        }
      ],
      queryData: {},
      projectList: []
    }
  },
  computed: {
    ...mapGetters(['dict', 'companyTree']),
    searchItems () {
      const {dict, companyTree, projectList} = this
      return [
        {
          type: 'selectTree',
          label: '公司名称/编号：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany,
          style: 'margin-right: 0'
        },
        {
          type: 'select',
          label: '',
          name: 'projectId',
          options: projectList
        },
        {type: 'input', label: '内容关键字：', name: 'contents'},
        {type: 'input', label: '操作人：', name: 'operaName'},
        {type: 'datePicker', label: '操作开始时间：', name: 'createdTimeStart'},
        {type: 'datePicker', label: '操作结束时间：', name: 'createdTimeEnd'},
        {type: 'select', label: '业务类型：', name: 'businessType', options: dict.OperatorBusinessType},
      ]
    }
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
  },
  methods: {
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    changeCompany (data) {
      this.$store.dispatch('getCompanyDownProject', {companyId: data.id, containsClosed: true}).then(res => {
        this.projectList = res
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
