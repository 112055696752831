<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="close" :close-on-click-modal="false" width="70%">
    <el-descriptions :column="3" border :label-style="{width: '14%'}" :content-style="{width: '19%'}" size='medium'>
      <el-descriptions-item label="公司名称">{{items.companyName}}</el-descriptions-item>
      <el-descriptions-item label="项目名称">{{items.projectName}}</el-descriptions-item>
      <el-descriptions-item label="资源名称">{{items.resourceName}}</el-descriptions-item>
      <el-descriptions-item label="订单金额">{{items.amount}}</el-descriptions-item>
      <el-descriptions-item label="支付/退费/关闭金额">{{items.payAmount}}</el-descriptions-item>
      <el-descriptions-item label="服务费">{{items.serviceAmoumt}}</el-descriptions-item>
      <el-descriptions-item label="支付时间">{{items.payTime}}</el-descriptions-item>
      <el-descriptions-item label="支付方式">{{items.methodName}}</el-descriptions-item>
      <el-descriptions-item label="通道类型">{{items.paymentTypeName}}</el-descriptions-item>
      <el-descriptions-item label="支付状态">{{items.statusName}}</el-descriptions-item>
      <el-descriptions-item label="支付/退费/关闭类型">{{items.payTypeName}}</el-descriptions-item>
      <el-descriptions-item label="账户支出">{{items.accountAmount}}</el-descriptions-item>
      <el-descriptions-item label="结存金额">{{items.storageAmount}}</el-descriptions-item>
      <el-descriptions-item label="结算金额">{{items.settlementAmount}}</el-descriptions-item>
      <el-descriptions-item label="订单号">{{items.orderSN}}</el-descriptions-item>
      <el-descriptions-item label="渠道订单号">{{items.orderSNChannel}}</el-descriptions-item>
      <el-descriptions-item label="票据号">{{items.payNumber}}</el-descriptions-item>
      <el-descriptions-item label="票据状态">{{items.paymentStatusName}}</el-descriptions-item>
      <el-descriptions-item label="备注">{{items.remark}}</el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</template>

<script>
export default {
  name: 'OnlinePayDetail',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {

    }
  },
  computed: {
    title () {
      return ''
    }
  },
  mounted () {},
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
    }
  }
}
</script>

<style scoped lang="scss">

</style>
