<template>
  <div>
    <my-search :items="searchItems" @search="search" @clear="clear">
      <el-button slot="btn" type="primary" @click="visibleEdit = true" v-hasPermi="['sms:smsAccount:add']">新增</el-button>
    </my-search>
    <my-table ref="myTable" auto-request :columns="columns" url="/SMSAccount/List" :query="{orderBy: ['id desc']}">
      <vxe-table-column title="操作">
        <template #default="{row}">
          <el-button type="text" :loading="row.loading" v-hasPermi="['sms:smsAccount:recharge']" @click="recharge(row)">短信充值</el-button>
          <el-button type="text" :loading="row.loading" v-hasPermi="['sms:smsAccount:payLogs']" @click="logs(row)">购买记录</el-button>
        </template>
      </vxe-table-column>
    </my-table>
    <sms-edit :visible.sync="visibleEdit" @refresh="refresh"/>
    <sms-recharge :visible.sync="visible" :item-id="itemId" @refresh="refresh"/>
    <sms-pay-logs :visible.sync="visibleLogs" :sms-id.sync="smsId"/>
  </div>
</template>

<script>
import SmsRecharge from './components/SmsRecharge'
import SmsEdit from './components/SmsEdit'
import SmsPayLogs from './components/SmsPayLogs'
import { mapGetters } from "vuex"
import {companyList} from '@/api/project'

export default {
  name: 'smsAccount',
  components: {
    SmsRecharge,
    SmsEdit,
    SmsPayLogs
  },
  data () {
    return {
      columns: [
        {
          label: '公司名称',
          prop: 'companyName'
        },
        {
          label: '项目名称',
          prop: 'projectName'
        },
        {
          label: '短信账号余额(条数)',
          prop: 'accountCount'
        },
        {
          label: '类型名称',
          prop: 'typeName'
        },
        {
          label: '创建日期',
          prop: 'createdTime'
        }
      ],
      itemId: '',
      visible: false,
      visibleEdit: false,
      visibleLogs: false,
      smsId: '',
      queryData: {},
      projectList: []
    }
  },
  computed: {
    ...mapGetters(['companyTree']),
    searchItems () {
      const {companyTree, projectList} = this
      return [
        {
          type: 'selectTree',
          label: '公司名称/编号：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany,
          style: 'margin-right: 0'
        },
        {
          type: 'select',
          label: '',
          name: 'projectId',
          options: projectList
        },
        {type: 'select', label: '账户类型：', name: 'type', options: [{displayName: '公司账户', value: 1}, {displayName: '项目账户', value: 2}]}
      ]
    }
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
  },
  methods: {
    recharge (row) {
      this.itemId = row.id
      this.visible = true
    },
    refresh () {
      this.$refs.myTable.refresh(this.queryData)
    },
    logs (row) {
      this.smsId = row.id
      this.visibleLogs = true
    },
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    changeCompany (data) {
      this.$store.dispatch('getCompanyDownProject', {companyId: data.id, containsClosed: true}).then(res => {
        this.projectList = res
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
