<!-- 授权公司 -->
<template>
  <el-dialog title="授权公司" :before-close="close" :close-on-click-modal="false" :visible.sync="visible" width="30%">
    <div v-for="(item, index) in items.authorizeCompanyNames" :key="index" style="margin-bottom: 10px;">
      <i class="el-icon-check"></i>
      {{ item }}
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'authorizeCompany',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {

    }
  },
  mounted () {},
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
    }
  }
}
</script>

<style scoped lang="scss">

</style>
