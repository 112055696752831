<template>
  <div>
    <my-search :items="searchItems" @search="search" @clear="clear">
      <el-button type="primary" slot="btn" @click="visible = true" v-hasPermi="['system:adminRoles:add']">新增</el-button>
    </my-search>
    <my-table auto-request :columns="columns" ref="myTable" url="/OperateRole/QueryRolePagerList">
      <vxe-table-column title="操作" width="100px">
        <template #default="{row}">
          <el-button type="text" @click="edit(row)" v-hasPermi="['system:adminRoles:edit']">编辑</el-button>
          <el-button type="text" @click="del(row)" v-hasPermi="['system:adminRoles:del']">删除</el-button>
        </template>
      </vxe-table-column>
    </my-table>
    <admin-roles-edit :visible.sync="visible" :items.sync="items" @refresh="refresh"/>
  </div>
</template>

<script>
import AdminRolesEdit from './components/AdminRolesEdit'
import {adminRolesDel} from '@/api/system'
export default {
  name: 'adminRoles',
  components: {AdminRolesEdit},
  data () {
    return {
      columns: [
        {
          label: '角色名',
          prop: 'name'
        },
        {
          label: '状态',
          prop: 'statusName'
        },
        {
          label: '备注',
          prop: 'remarks'
        },
        {
          label: '创建人',
          prop: 'createdByName'
        },
        {
          label: '创建时间',
          prop: 'createdTime'
        },
      ],
      visible: false,
      items: {
        id: '0'
      },
      queryData: {}
    }
  },
  computed: {
    searchItems () {
      return [
        {type: 'input', label: '角色名：', name: 'name'}
      ]
    }
  },
  mounted () {},
  methods: {
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    refresh () {
      this.$refs.myTable.refresh(this.queryData)
    },
    del (row) {
      this.$confirm(`确定删除<span style="color: #da251c">${row.name}</span>吗`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        adminRolesDel({id: row.id}).then(res => {
          this.$message.success(res.message)
          this.refresh()
        })
      })
    },
    edit (row) {
      this.items = row
      this.visible = true
    }
  }
}
</script>

<style scoped lang="scss">

</style>
