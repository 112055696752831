<template>
  <div>
    <div class="flex-h">
      <el-button type="primary" @click="add" v-hasPermi="['system:menuAllot:add']">添加权限</el-button>
      <el-button type="primary" @click="del" v-hasPermi="['system:menuAllot:del']">删除权限</el-button>
      <el-radio-group v-model="functionType" style="margin-left: 20px;">
        <el-radio :label="1">公司菜单及下属角色菜单</el-radio>
        <el-radio :label="2">仅限公司菜单</el-radio>
      </el-radio-group>
      <div class="flex-h" style="margin-left: 40px;">
        <div>菜单标签类型：</div>
        <el-select v-model="functionFlagTypes" placeholder="请选择" multiple @change="changeFlag" collapse-tags>
          <el-option v-for="item in dict.FunctionFlagType" :key="item.value" :value="item.value" :label="item.displayName"></el-option>
        </el-select>
      </div>
    </div>
    <div style="margin: 20px 0">
      <el-radio-group v-model="activeName" @input="changeTab">
        <!-- id=2是运营平台 -->
        <el-radio-button v-for="item in menu" v-show="item.id !== '2'" :key="item.id" :label="item.functionId">{{ item.name }}</el-radio-button>
      </el-radio-group>
      <el-checkbox style="margin-left: 10px;" v-model="menuCheckStrictly" @change="handleCheckedTreeConnect($event)">父子联动</el-checkbox>
    </div>
    <vxe-table
      border
      ref="xTable"
      :data="tableData"
      size="small"
      height="640px"
      :row-config="{isCurrent: true, keyField: 'id'}"
      empty-text="暂无数据"
      :tree-config="{children: 'child', reserve: true, trigger: 'row', indent: 25, expandAll: true}">
      <vxe-table-column title="菜单目录" tree-node align="left" field="name" width="270px">
        <template #default="{row}">
          <el-checkbox
            @change="changeCheckEvent($event, row)"
            v-model="row.isSelected"
            :indeterminate="row.isIndeterminate"
            :key="row.id">
            <span :style="{color: row.functionFlagType !== 1 ? '' : '#da251c'}">{{row.name}}</span>
          </el-checkbox>
        </template>
      </vxe-table-column>
      <vxe-table-column title="新增" width="100" align="left" field="hasPermissionGroup">
        <template #default="{row}">
          <el-checkbox
            v-model="item.isSelected"
            v-if="item.functionFlagType === 3"
            v-for="(item, index) in row.hasPermissionGroup"
            @change="changeCheckEventChild($event, row)"
            :key="index">
            <span :style="{color: item.functionFlagType !== 1 ? '' : '#da251c'}">{{item.name}}</span>
          </el-checkbox>
        </template>
      </vxe-table-column>
      <vxe-table-column title="编辑" width="100" align="left" field="hasPermissionGroup">
        <template #default="{row}">
          <el-checkbox
            v-model="item.isSelected"
            v-if="item.functionFlagType === 4"
            v-for="(item, index) in row.hasPermissionGroup"
            @change="changeCheckEventChild($event, row)"
            :key="index">
            <span :style="{color: item.functionFlagType !== 1 ? '' : '#da251c'}">{{item.name}}</span>
          </el-checkbox>
        </template>
      </vxe-table-column>
      <vxe-table-column title="删除" width="100" align="left" field="hasPermissionGroup">
        <template #default="{row}">
          <el-checkbox
            v-if="item.functionFlagType === 5"
            v-model="item.isSelected"
            v-for="(item, index) in row.hasPermissionGroup"
            @change="changeCheckEventChild($event, row)"
            :key="index">
            <span :style="{color: item.functionFlagType !== 1 ? '' : '#da251c'}">{{item.name}}</span>
          </el-checkbox>
        </template>
      </vxe-table-column>
      <vxe-table-column title="导出" width="100" align="left" field="hasPermissionGroup">
        <template #default="{row}">
          <el-checkbox
            v-if="item.functionFlagType === 6"
            v-model="item.isSelected"
            v-for="(item, index) in row.hasPermissionGroup"
            @change="changeCheckEventChild($event, row)"
            :key="index">
            <span :style="{color: item.functionFlagType !== 1 ? '' : '#da251c'}">{{item.name}}</span>
          </el-checkbox>
        </template>
      </vxe-table-column>
      <vxe-table-column title="其他" align="left" field="hasPermissionGroup">
        <template #default="{row}">
          <el-checkbox
            v-model="item.isSelected"
            v-if="![3, 4, 5, 6].includes(item.functionFlagType)"
            v-for="(item, index) in row.hasPermissionGroup"
            @change="changeCheckEventChild($event, row)"
            :key="index">
            <span :style="{color: item.functionFlagType !== 1 ? '' : '#da251c'}">{{item.name}}</span>
          </el-checkbox>
        </template>
      </vxe-table-column>
    </vxe-table>
  </div>
</template>

<script>
import {menuAction} from '@/api/system'
import { mapGetters } from 'vuex'
import {/* throttle, */ /* flatten */ getAllParentArr, /* getClientHeight */} from '@/utils/tools'

export default {
  name: 'menuAllot',
  components: {},
  data () {
    return {
      defaultProps: {
        children: "child",
        label: "name"
      },
      functionType: 1,
      activeName: '2',
      checkAll: false,
      treeData: [],
      menu: [],
      functionFlagTypes: [0, 1, 2, 3, 4, 5, 6],
      menuCheckStrictly: false
    }
  },
  computed: {
    ...mapGetters(['dict']),
    checkedKeys () {
      if (this.tableData.length > 0) {
        return this.reduceDimension(this.tableData).filter(x => x.isSelected || x.isIndeterminate).map(x => x.functionId)
      }
      return []
    },
    tableData () {
      return this.generator(this.treeData.filter(x => x.functionId === this.activeName))
    },
  },
  mounted () {
    this.getMenuTree()
  },
  methods: {
    getMenuTree () {
      this.$store.dispatch('getMenu', {functionFlagTypes: this.functionFlagTypes}).then(res => {
        this.menu = res.data.map(x => {
          return {
            name: x.name,
            functionId: x.functionId,
            id: x.id
          }
        })
        this.treeData = res.data
      })
    },
    changeFlag () {
      this.getMenuTree()
    },
    reduceDimension (arr = []) {
      let ret = []
      let toArr = arr => {
        arr.forEach(item => {
          toArr(item.child && item.child.length > 0 ? item.child : (item.hasPermissionGroup || []))
          ret.push(item)
        })
      }
      toArr(arr)
      return ret
    },
    add () {
      let checkedNode = this.checkedKeys
      if (checkedNode.length === 0) {
        return
      }
      this.$confirm(`确认添加<span style="color: #da251c">${checkedNode.length}</span>个权限吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        this.action({functions: checkedNode, operaType: 1, functionType: this.functionType})
      })
    },
    del () {
      let checkedNode = this.checkedKeys
      if (checkedNode.length === 0) {
        return
      }
      this.$confirm(`确认删除<span style="color: #da251c">${checkedNode.length}</span>个权限吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        this.action({functions: checkedNode, operaType: 2, functionType: this.functionType})
      })
    },
    action (data) {
      menuAction(data).then(res => {
        this.$message.success(res.message)
      })
    },
    generator (arr = []) {
      return arr.map(item => {
        const data = {
          ...item,
          isIndeterminate: false,
          // isSelected: false,
          isSelected: this.checkAll || false
        }
        if (item.child && item.child.length > 0 && item.child[0].functionType !== 3) {
          data.child = this.generator(item.child)
        } else {
          data.hasPermissionGroup = data.child?.map(y => {
            return {
              ...y,
              // isSelected: false,
              isSelected: this.checkAll ? this.checkAll : false,
            }
          })
          delete data.child
        }
        return data
      })
    },
    changeCheckEventChild (value, row) {
      if (!this.menuCheckStrictly) {
        return
      }
      console.log(row, 'aaaa')
      if (row.hasPermissionGroup.length === row.hasPermissionGroup.filter(x => x.isSelected).length) {
        row.isSelected = true
        row.isIndeterminate = false
      } else {
        row.isSelected = false
        row.isIndeterminate = row.hasPermissionGroup.filter(x => x.isSelected).length !== 0
      }
      this.setCheck(this.tableData, row, value)
    },
    changeTab () {
      setTimeout(() => {
        this.$refs.xTable.setAllTreeExpand(true)
      }, 200)
    },
    // 树权限（父子联动）
    handleCheckedTreeConnect(value) {
      this.menuCheckStrictly = !!value
    },
    changeCheckEvent (value, row) {
      if (!this.menuCheckStrictly) {
        return
      }
      // 点击二级设置子全选
      if (row.child && row.child.length > 0) {
        row.isIndeterminate = false
        row.child.forEach(item => {
          console.log(11, row, item)
          // 只有不是禁用状态下才能选择
          item.isSelected = value || false
          if (item.hasPermissionGroup && item.hasPermissionGroup.length > 0) {
            item.hasPermissionGroup.forEach(x => {
              // 只有不是禁用状态下才能选择
              x.isSelected = value
            })
          } else {
            this.changeCheckEvent(value, item)
          }
        })
      } else {
        // 点击三级设置子全选
        row.hasPermissionGroup.forEach(item => {
          // 只有不是禁用状态下才能选择
          item.isSelected = value
        })
      }
      this.setCheck(this.tableData, row, value)
    },
    setCheck (treeData, row, value) {
      console.log(treeData, row, value)
      // 点击子集设置父级全选,数组第一个是点击的本身，第二个是父级，第三个是父级的父级，依次类推
      const parentList = getAllParentArr(treeData, row.functionId, 'functionId')
      console.log(parentList, 12)
      parentList.forEach(item => {
        let parentCheck = []
        // 排除设置子集，按钮组
        if (!item.hasPermissionGroup && !item.isSelected) {
          parentCheck = item.child.filter(x => x.isSelected)
          // 选中的和child长度相同，判断全选
          if (parentCheck.length === item.child.length) {
            item.isSelected = true
            item.isIndeterminate = false
          } else {
            item.isSelected = false
            // item.isIndeterminate = true
            if (parentCheck.length !== 0) {
              item.isIndeterminate = true
            }
            if (parentCheck.length === 0) {
              item.isIndeterminate = false
            }
            // item.isIndeterminate = childNoChick.length !== 0
            console.log(item, 99, parentCheck)
          }
        } else {
          let childNoChick = item.child?.filter(x => x.isSelected) || []
          if (!item.hasPermissionGroup) {
            // 有子集不选中，设置最上级不选中
            if (childNoChick.length !== item.child?.length) {
              item.isIndeterminate = true
            } else {
              item.isIndeterminate = false
            }
            item.isSelected = value
          } else {
            item.isSelected = value
          }
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.scroll {
  height: calc(100vh - 200px);
  overflow-y: auto;
  margin-top: 20px;
}
::-webkit-scrollbar {
  width: 10px;
  height: 50px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.4);
  background-clip: padding-box;
  border: 3px solid transparent;
  border-radius: 7px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
</style>
