<template>
  <div>
    <my-search :items="searchItems" @search="search" @clear="clear">
      <el-button slot="btn" type="primary" @click="visible = true" v-hasPermi="['print:companyPrintTem:add']">新增</el-button>
    </my-search>
    <my-table ref="myTable" :columns="columns" url="/printTemplateRelation/list">
      <vxe-table-column title="操作" width="160">
        <template #default="{row}">
          <el-button type="text" :loading="row.loading" @click="eye(row)">详情</el-button>
          <el-button type="text" :loading="row.loading" @click="del(row)" v-hasPermi="['print:companyPrintTem:del']">删除</el-button>
        </template>
      </vxe-table-column>
    </my-table>
    <company-print-tmp-edit :visible.sync="visible" :id.sync="companyId" @refresh="refresh"/>
  </div>
</template>

<script>
import {companyList} from '@/api/project'
import {companyPrintDel} from '@/api/print'
import CompanyPrintTmpEdit from './components/CompanyPrintTmpEdit'
import { mapGetters } from 'vuex'
export default {
  name: 'companyPrintTem',
  components: {/* CompanyPrintEdit,  */CompanyPrintTmpEdit},
  data () {
    return {
      visible: false,
      isEdit: false,
      columns: [
        {
          label: '公司名称',
          prop: 'companyName'
        },
        {
          label: '项目名称',
          prop: 'projectName'
        },
        {
          label: '模板分类',
          prop: 'printTypeName'
        },
        {
          label: '创建日期',
          prop: 'createdTime'
        }
      ],
      // detail: {},
      options: [],
      loading: false,
      queryData: {},
      companyId: '',
      projectList: []
    }
  },
  computed: {
    ...mapGetters(['dict', 'companyTree']),
    searchItems () {
      const {dict, /* options, */ companyTree, projectList} = this
      return [
        {
          type: 'selectTree',
          label: '公司名称/编号：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany,
          style: 'margin-right: 0'
        },
        {
          type: 'select',
          label: '',
          name: 'projectId',
          options: projectList
        },
        // {
        //   label: '项目名称：',
        //   type: 'select',
        //   name: 'projectId',
        //   remoteMethod: this.remoteMethod,
        //   remote: true,
        //   options: options,
        //   props: {label: 'name', value: 'id'}
        // },
        {label: '模板类型：', type: 'select', name: 'printType', options: dict.PrintType}
      ]
    }
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
  },
  methods: {
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    // remoteMethod(query) {
    //   if (query !== '') {
    //     this.loading = true
    //     projectList({name: query, status: 1, limit: 1000, page: 1}).then(res => {
    //       this.loading = false
    //       this.options = res.data.items.map(x => {
    //         return {
    //           id: x.id,
    //           name: `${x.name}(${x.companyName}${x.companyAlias})`
    //         }
    //       })
    //     }).catch(() => {
    //       this.loading = false
    //     })
    //   } else {
    //     this.options = []
    //   }
    // },
    refresh () {
      this.$refs.myTable.refresh(this.queryData)
    },
    eye (row) {
      this.companyId = row.companyId
      this.visible = true
    },
    del (row) {
      this.$confirm(`确定删除<span style="color: #da251c">${row.projectName}</span>的打印模板`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        companyPrintDel({id: row.id}).then(res => {
          this.$message.success(res.message)
          this.refresh()
        })
      })
    },
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    changeCompany (data) {
      this.$store.dispatch('getCompanyDownProject', {companyId: data.id, containsClosed: true}).then(res => {
        this.projectList = res
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
