<template>
  <el-tabs>
    <el-tab-pane label="图文模式">
      <div class="wx-menu">
        <div class="flex-h" style="justify-content: center; margin-bottom: 20px">
          <div class="phone flex-h">
            <div v-for="(item, index) in menu.button" :key="index" class="menu-item">
              <!-- 一级菜单 -->
              <div @click="menuFun(index, item)" :class="{'active': isActive === index}">{{item.name}}</div>
              <!-- 二级菜单 -->
              <div v-if="isSubMenuFlag === index" class="sub-menu">
                <div v-for="(subItem, k) in item.sub_button" :key="k" class="sub-title">
                  <div
                    class="menu-sub-item"
                    :class="{'active': isSubMenuActive === index + '' + k}"
                    @click="subMenuFun(item, subItem, index, k)">
                    {{subItem.name}}
                  </div>
                </div>
                <!--  二级菜单加号， 当长度 小于  5 才显示二级菜单的加号  -->
                <div class="menu-addicon" style="width: 100%" v-if="item.sub_button && item.sub_button.length < 5" @click="addSubMenu(item)">
                  <i class="el-icon-plus"></i>
                </div>
              </div>
            </div>
            <!-- 一级菜单加号 -->
            <div class="menu-addicon" v-if="menuKeyLength < 3" @click="addMenu">
              <i class="el-icon-plus"></i>
            </div>
          </div>
          <div class="content">
            <div style="margin-bottom: 20px;justify-content: space-between;" class="flex-h">
              <div>
                <div>
                  <span style="margin-right: 20px">公众号常用地址:</span>
                  <span style="margin-right: 20px">首页: /index</span>
                  <span style="margin-right: 20px">费用查缴: /payment</span>
                </div>
                <div>
                  <span style="margin-right: 20px">小程序常用地址:</span>
                  <span style="margin-right: 20px">首页: /pages/index</span>
                  <span style="margin-right: 20px">登录页: /pages/login</span>
                </div>
              </div>
              <el-button size="mini" type="danger" @click="deleteMenu" icon="el-icon-delete">删除当前菜单</el-button>
            </div>
            <el-form :model="form" label-width="100px" ref="form">
              <el-form-item label="菜单名称">
                <el-input v-model="form.name" placeholder="请输入菜单名称"></el-input>
              </el-form-item>
              <el-form-item label="菜单内容">
                <el-radio-group v-model="form.type">
                  <el-radio label="view">跳转链接</el-radio>
                  <el-radio label="miniprogram">小程序</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="跳转链接" v-if="form.type === 'view'">
                <el-input v-model="form.url" placeholder="请输入链接,例如http://wechat-test.ielong.cn/index.html?c=26226000006000000#/index"></el-input>
              </el-form-item>
              <div v-if="form.type === 'click'">
                <el-form-item label="关键词">
                  <el-input v-model="form.key" placeholder="请输入关键词"></el-input>
                </el-form-item>
                <p class="blue">提示:这里需要配合关键词推送消息一起使用</p>
              </div>
              <div v-if="form.type === 'miniprogram'">
                <el-form-item label="appid">
                  <el-input v-model="form.appid" placeholder="请输入小程序的appid"></el-input>
                </el-form-item>
                <el-form-item label="页面路径">
                  <el-input v-model="form.pagepath" placeholder="请输入小程序的页面路径，如：pages/index"></el-input>
                </el-form-item>
                <p class="blue">提示:需要和公众号进行关联才可以把小程序绑定带微信菜单上！</p>
              </div>
            </el-form>
            <div>menu对象值：{{menu}}</div>
          </div>
        </div>
        <div style="text-align: center">
          <el-button type="primary" @click="save">保存</el-button>
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="json模式">
      <div class="wx-menu">
        <div class="flex-h" style="justify-content: center; margin-bottom: 20px">
          <div class="phone flex-h">
            <div v-for="(item, index) in menu.button" :key="index" class="menu-item">
              <!-- 一级菜单 -->
              <div @click="menuFunJson(index, item)" :class="{'active': isActive === index}">{{item.name}}</div>
              <!-- 二级菜单 -->
              <div v-if="isSubMenuFlag === index" class="sub-menu">
                <div v-for="(subItem, k) in item.sub_button" :key="k" class="sub-title">
                  <div
                    class="menu-sub-item"
                    :class="{'active': isSubMenuActive === index + '' + k}"
                    @click="subMenuFunJson(item, subItem, index, k)">
                    {{subItem.name}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content">
            <div style="margin-bottom: 20px;">
              <div>
                <span style="margin-right: 20px">公众号常用地址:</span>
                <span style="margin-right: 20px">首页: /index</span>
                <span style="margin-right: 20px">费用查缴: /payment</span>
              </div>
              <div>
                <span style="margin-right: 20px">小程序常用地址:</span>
                <span style="margin-right: 20px">首页: /pages/index</span>
                <span style="margin-right: 20px">登录页: /pages/login</span>
              </div>
            </div>
            <el-input v-model="menuJson" placeholder="请输入" type="textarea" :autosize="{minRows: 20,maxRows: 25}"></el-input>
          </div>
        </div>
        <div style="text-align: center">
          <el-button type="primary" @click="saveJson">保存</el-button>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import {getWxMenu, saveWxMenu, /* saveWxMenuJson */} from '@/api/project'
export default {
  name: 'wxMenu',
  components: {},
  data () {
    return {
      // costomUrl: '',
      isActive: -1, // 一级菜单点中样式
      isSubMenuActive: -1, // 二级菜单点中样式
      isSubMenuFlag: -1, // 二级菜单显示标志
      form: {
        // type:"view",//media_id:素材内容; view:跳转链接
        // name:"",//菜单名称
        // material:"",//素材名称
        // link:"",//跳转链接
      },
      tempSelfObj: {
        // 一些临时值放在这里进行判断，如果放在tempObj，由于引用关系，menu也会多了多余的参数
        // grand:"" 1:表示一级菜单； 2:表示二级菜单
        // index:"" 表示一级菜单索引
        // secondIndex:"" 表示二级菜单索引
      },
      menu: {
        button: []
      },
      menuJson: ''
    }
  },
  computed: {
    query () {
      return this.$route.query
    },
    // menuObj的长度，当长度 小于  3 才显示一级菜单的加号
    menuKeyLength () {
      return this.menu.button.length
    }
  },
  mounted () {
    this.getMenu()
  },
  methods: {
    getMenu () {
      if (!this.query.configId) {
        return
      }
      getWxMenu({configId: this.query.configId}).then(res => {
        this.menu = res.data.menu || {button: []}
        this.menuJson = JSON.stringify(this.menu)
        console.log(this.menuJson, '111')
        this.menu.button.forEach(item => {
          if (!item.sub_button) {
            item.sub_button = []
          }
        })
      })
    },
    // 一级菜单点击事件
    menuFun(i, item) {
      // this.showRightFlag = false;// 右边菜单隐藏
      this.form = item // 这个如果放在顶部，flag会没有。因为重新赋值了。
      this.tempSelfObj.grand = "1" // 表示一级菜单
      this.tempSelfObj.index = i // 表示一级菜单索引

      this.isActive = i // 一级菜单选中样式
      this.isSubMenuFlag = i // 二级菜单显示标志
      this.isSubMenuActive = -1 // 二级菜单去除选中样式
    },
    // 一级菜单点击事件
    menuFunJson(i) {
      // this.form = item // 这个如果放在顶部，flag会没有。因为重新赋值了。
      this.tempSelfObj.grand = "1" // 表示一级菜单
      this.tempSelfObj.index = i // 表示一级菜单索引

      this.isActive = i // 一级菜单选中样式
      this.isSubMenuFlag = i // 二级菜单显示标志
      this.isSubMenuActive = -1 // 二级菜单去除选中样式
    },
    // 二级菜单点击事件
    subMenuFun(item, subItem, index, k) {
      // this.showRightFlag = false // 右边菜单隐藏

      this.form = subItem // 将点击的数据放到临时变量，对象有引用作用

      this.tempSelfObj.grand = "2" // 表示二级菜单
      this.tempSelfObj.index = index // 表示一级菜单索引
      this.tempSelfObj.secondIndex = k // 表示二级菜单索引

      this.isSubMenuActive = index + "" + k // 二级菜单选中样式
      this.isActive = -1 // 一级菜单去除样式
    },
    // 二级菜单点击事件
    subMenuFunJson(item, subItem, index, k) {
      // this.showRightFlag = false // 右边菜单隐藏

      // this.form = subItem // 将点击的数据放到临时变量，对象有引用作用

      this.tempSelfObj.grand = "2" // 表示二级菜单
      this.tempSelfObj.index = index // 表示一级菜单索引
      this.tempSelfObj.secondIndex = k // 表示二级菜单索引

      this.isSubMenuActive = index + "" + k // 二级菜单选中样式
      this.isActive = -1 // 一级菜单去除样式
    },
    // 添加横向一级菜单
    addMenu () {
      this.menu.button.push({
        name: "菜单" + (this.menuKeyLength + 1),
        sub_button: []
      })
    },
    // 添加竖向二级菜单
    addSubMenu (item) {
      let subMenuKeyLength = item.sub_button?.length ?? 0
      item.sub_button.push({
        name: "子菜单" + (subMenuKeyLength + 1),
      })
      this.$forceUpdate() // 强制刷新页面
    },
    deleteMenu () {
      this.$confirm('是否删除该菜单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteData()// 删除菜单数据
      })
    },
    // 删除菜单数据
    deleteData () {
      // 一级菜单的删除方法
      if (this.tempSelfObj.grand === "1") {
        this.menu.button.splice(this.tempSelfObj.index, 1)
      }
      // 二级菜单的删除方法
      if (this.tempSelfObj.grand === "2") {
        this.menu.button[this.tempSelfObj.index].sub_button.splice(this.tempSelfObj.secondIndex, 1)
        this.$forceUpdate() // 强制刷新页面
      }
      this.$message.success('删除成功!')
    },
    save () {
      const {query} = this
      saveWxMenu({configId: query.configId, resultFull: {menu: this.menu}}).then(res => {
        this.$message.success(res.message)
        this.$router.back()
      })
    },
    saveJson () {
      const {query} = this
      saveWxMenu({configId: query.configId, resultFull: {menu: JSON.parse(this.menuJson)}}).then(res => {
        this.$message.success(res.data.message)
        this.$router.back()
      })
    },
  }
}
</script>

<style scoped lang="scss">
.phone {
  width: 300px;
  height: 600px;
  border: 1px solid #999;
  margin-right: 20px;
  align-items: flex-end;
}
.content {
  width: 800px;
  height: 560px;
  background: #eee;
  padding: 20px;
  .blue{
    color:#29b6f6;
    margin-left: 30px;
  }
}
.menu-item {
  position: relative;
  width: 33.33%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: #e2e2e2;
  border: 1px solid #ebedee;
  cursor: pointer;
  .active{
    border: 1px solid #2bb673;
  }
  .sub-menu {
    position: absolute;
    bottom: 45px;
    width: 100%;
    .sub-title {
      background-color: #e8e7e7;
      box-sizing: border-box;
      margin-bottom: 2px;
    }
    .menu-sub-item{
      height: 44px;
      line-height: 44px;
      text-align: center;
      box-sizing: border-box;
      /*padding: 14px 0;*/
      &.active{
        border: 1px solid #2bb673;
      }
    }
  }
}
.menu-addicon {
  width: 33.33%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background-color: #e2e2e2;
  border: 1px solid #ebedee;
  cursor: pointer;
}
</style>
