/* 项目 */
<template>
  <div>
    <my-search :items="searchItems" @search="search" @clear="clear">
      <div slot="btn">
        <el-button type="primary"  @click="$refs.areaEdit.open()" v-hasPermi="['project:area:add']">新增</el-button>
        <el-button type="primary"  @click="open" v-hasPermi="['project:area:editDate']">批量修改到期时间</el-button>
      </div>
    </my-search>
    <my-table
      selection
      :show-index="false"
      :check-box-method="checkBoxMethod"
      @changeSelectAll="changeSelect"
      @changeSelect="changeSelect"
      ref="myTable"
      rowkey=""
      auto-request
      :query="{orderBy: ['id desc']}"
      :columns="columns"
      url="/Project/List">
      <vxe-table-column title="操作" width="280px" fixed="right">
        <template #default="{row}">
          <el-button type="text" :loading="row.loading" @click="edit(row)" v-hasPermi="['project:area:edit']">编辑</el-button>
          <el-button type="text" :loading="row.loading" @click="projectConfig(row)" v-hasPermi="['project:area:config']">项目配置</el-button>
          <el-button type="text" :loading="row.loading" @click="editDate(row)" v-hasPermi="['project:area:editDate']">修改到期时间</el-button>
          <el-button type="text" :loading="row.loading" @click="delData(row)" v-hasPermi="['project:area:delImportData']">删除导入数据</el-button>
        </template>
      </vxe-table-column>
    </my-table>
    <area-edit ref="areaEdit" @refresh="refresh"/>
    <area-del-import-data :visible.sync="visibleDelImport" :items.sync="items" @refresh="refresh"/>
    <area-edit-use-date :visible.sync="visible" :items.sync="items" @refresh="refresh"/>
  </div>
</template>

<script>
import {projectDetail, companyList} from '@/api/project'
import { mapGetters } from 'vuex'
import AreaEdit from './components/AreaEdit'
import AreaEditUseDate from './components/AreaEditUseDate'
import AreaDelImportData from './components/AreaDelImportData'

export default {
  name: 'area',
  components: {AreaEdit, AreaEditUseDate, AreaDelImportData},
  data () {
    return {
      visible: false,
      items: {},
      checked: [],
      columns: [
        {
          label: '编号',
          prop: 'id',
          width: 160
        },
        {
          label: '项目名称',
          prop: 'name',
          minWidth: 120
        },
        {
          label: '项目类型',
          prop: 'projectTypesName',
          minWidth: 100
        },
        {
          label: '公司名称',
          prop: 'companyName',
          minWidth: 200,
          sort: true
        },
        {
          label: '状态',
          prop: 'statusName',
          operate: true,
          minWidth: 80,
          render: (h, {row}) => {
            let type = ''
            if (row.status) {
              type = {
                1: 'success',
                2: 'danger',
                3: 'info'
              }[row.status]
            }
            return h('el-tag', {
              props: {
                type: type
              }
            }, row.statusName)
          }
        },
        {
          label: '行政区域',
          prop: 'city',
          minWidth: 80,
        },
        {
          label: '详细地址',
          prop: 'address',
          minWidth: 180,
        },
        {
          label: '是否信托制',
          prop: 'isTrustSystem',
          operate: true,
          minWidth: 100,
          render: (h, {row}) => {
            return h('el-tag', {
              props: {
                type: row.isTrustSystem ? 'success' : ''
              }
            }, row.isTrustSystemName)
          }
        },
        {
          label: '创建时间',
          prop: 'createdTime',
          width: 150
        },
        {
          label: '合同约定到期时间',
          prop: 'ggreementEndTimeFormat',
          width: 160
        },
        {
          label: '系统停机时间',
          prop: 'useEndTimeFormat',
          minWidth: 160,
        },
        {
          label: '备注',
          prop: 'remarks',
          minWidth: 120,
        },
      ],
      configList: [],
      showConfig: false,
      visibleDelImport: false,
      queryData: {},
      projectList: []
    }
  },
  computed: {
    ...mapGetters(['dict', 'companyTree']),
    searchItems () {
      const {companyTree, projectList} = this
      return [
        {
          type: 'selectTree',
          label: '公司名称/编号：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany,
          style: 'margin-right: 0'
        },
        {
          type: 'select',
          label: '',
          name: 'projectId',
          options: projectList
        },
        {type: 'select', label: '状态：', name: 'status', options: this.dict.ProjectEntityStatus},
        {type: 'datePicker', label: '合同约定到期开始时间：', name: 'ggreementStart'},
        {type: 'datePicker', label: '合同约定到期结束时间：', name: 'ggreementEnd'},
        {type: 'datePicker', label: '系统停机开始时间：', name: 'useTimeStart'},
        {type: 'datePicker', label: '系统停机结束时间：', name: 'useTimeEnd'},
        {type: 'checkbox', text: '是否信托制', name: 'isTrustSystem'},
      ]
    }
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
  },
  methods: {
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    edit (row) {
      row.loading = true
      projectDetail({id: row.id}).then(res => {
        this.$refs.areaEdit.open(res.data)
        row.loading = false
      }).catch(() => {
        row.loading = false
      })
    },
    refresh () {
      this.$refs.myTable.refresh(this.queryData)
    },
    projectConfig (row) {
      this.$router.push({
        name: 'areaConfig',
        query: {
          projectId: row.id,
          configId: row.weiXinConfigId,
          companyId: row.companyId,
          projectName: row.name
        }
      })
    },
    editDate (row) {
      this.visible = true
      this.items.projectIds = [row.id]
      this.items.ggreementEndTime = row.ggreementEndTime
      this.items.useEndTime = row.useEndTime
    },
    // 表格选择条件
    checkBoxMethod (row) {
      return row
    },
    // 选择项，返回{}
    changeSelect (item) {
      const {list = []} = item
      this.checked = list
    },
    open () {
      if (this.checked.length === 0) {
        this.$message.error('请选择项目')
        return
      }
      // 合同约定到期时间
      const ggreementEndTimeFormat = this.checked.map(x => x.ggreementEndTimeFormat)
      // 系统停机时间
      // const useEndTimeFormat = this.checked.map(x => x.useEndTimeFormat)
      this.items.projectIds = this.checked.map(x => x.id)
      // console.log(this.equals(ggreementEndTimeFormat), ggreementEndTimeFormat, 'aaa')
      const result = this.equals(ggreementEndTimeFormat)
      if (result) {
        this.items.ggreementEndTime = ggreementEndTimeFormat[0]
      } else {
        this.$message({
          message: '合同约定到期时间不一致',
          type: 'error',
          customClass: 'z-index9999',
        })
      }
      this.visible = true
      // this.items.useEndTime = row.useEndTime
    },
    // 判断时间是否相同
    equals (list) {
      return list.every(item => item === list[0])
    },
    delData (row) {
      this.items = row
      this.visibleDelImport = true
    },
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    changeCompany (data) {
      this.$store.dispatch('getCompanyDownProject', {companyId: data.id, containsClosed: true}).then(res => {
        this.projectList = res
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
<style>
.z-index9999 {
  z-index: 9999 !important;
}
</style>
