<template>
  <el-dialog title="微信配置" :close-on-click-modal="false" :before-close="close" :visible.sync="visible">
    <el-form :model="form" :rules="rules" label-width="180px" ref="form">
      <el-form-item label="配置名称" prop="configName">
        <el-input v-model="form.configName" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="appid" prop="appId">
        <el-input v-model="form.appId" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="秘钥" prop="appSecret">
        <el-input v-model="form.appSecret" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="微信公众号二维码连接">
        <div class="flex-h">
          <el-input v-model="form.weiXinNumber" placeholder="请输入"></el-input>
          <el-button type="text" style="margin-left: 20px;" @click="wxLink">获取二维码链接</el-button>
        </div>
      </el-form-item>
      <el-form-item label="缴费通知模板ID">
        <el-input v-model="form.templateChargeNotices" placeholder="请输入"></el-input>
        <div>历史模板中搜索关键字:缴费通知，筛选编号为OPENTM416267864的模板ID</div>
      </el-form-item>
      <el-form-item label="公告通知模板ID">
        <el-input v-model="form.templatePropertyNotices" placeholder="请输入"></el-input>
        <div>历史模板中搜索关键字:物业管理通知，筛选编号为OPENTM204594069的模板ID</div>
      </el-form-item>
      <el-form-item label="工单管理通知模板ID">
        <el-input v-model="form.templateWorkOrderNotices" placeholder="请输入"></el-input>
        <div>历史模板中搜索关键字:工单处理通知，筛选编号为OPENTM417733501的模板ID</div>
      </el-form-item>
      <el-form-item label="欠费催收类目模板ID" class="label-red">
        <el-input v-model="form.newTemplateArrearsCollection" placeholder="请输入"></el-input>
        <div>在类目模板中（一级行业：房地产服务  二级行业：物业管理）搜索标题：缴费账单生成提醒 且模板编号=43167的模板 且按照
          <el-popover
            trigger="hover"
            content="">
            <img style="object-fit: cover;width: 900px;" src="@/assets/images/tip.png"/>
            <el-button type="text" slot="reference">实例图</el-button>
          </el-popover>
          按顺序添加属性</div>
      </el-form-item>
      <el-form-item label="首页title" prop="homeTime">
        <el-input v-model="form.homeTime" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="版权信息" prop="copyrightTitle">
        <el-input v-model="form.copyrightTitle" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="是否启用开发者模式" prop="isEnableDeveloper">
        <vxe-switch open-label="是" close-label="否" v-model="form.isEnableDeveloper"></vxe-switch>
        配置域名+/api/WeiXin/Index?configId=配置ID
        <div v-if="form.isEnableDeveloper">开启开发者模式配置微信公众号，消息加密方式选择<span style="color: #da251c">安全模式</span></div>
      </el-form-item>
      <el-form-item label="服务器地址url" v-if="form.isEnableDeveloper">
        {{url}}/api/WeiXin/Index?configId={{items.id}}
      </el-form-item>
      <el-form-item label="微信首页入口链接" v-if="form.isEnableDeveloper">
        {{url}}/index.html?configId={{items.id}}#/index
      </el-form-item>
      <el-form-item label="开发者模式消息加密密钥" v-if="form.isEnableDeveloper" prop="developerEncodingAESKey">
        <el-input v-model="form.developerEncodingAESKey" style="width: 80%" placeholder="请输入"></el-input>
        <el-button type="primary" @click="createdWx">{{form.id ? '重新生成密钥和token' : '生成密钥和token'}}</el-button>
        <div>{{wxData.developEncodingAESKey}}</div>
      </el-form-item>
      <el-form-item label="开发者模式token" v-if="form.isEnableDeveloper" prop="developerToken">
        <el-input v-model="form.developerToken" placeholder="请输入"></el-input>
        <div>{{wxData.developToken}}</div>
      </el-form-item>
      <el-form-item label="微信公众号关注事件推送语" v-if="form.isEnableDeveloper">
        <el-input v-model="form.weiXinFollowContext" type="textarea" :autosize="{minRows: 2}" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {wxConfigEdit, wxDevCreateKeyToken, createdWxQrcodeLink} from '@/api/project'
const initData = {
  configName: '',
  isEnableDeveloper: true,
  appId: '',
  appSecret: '',
  homeTime: '',
  copyrightTitle: '',
  developerEncodingAESKey: '',
  developerToken: '',
  weiXinNumber: '',
  templateChargeNotices: '',
  weiXinFollowContext: '',
  templatePropertyNotices: '',
  templateWorkOrderNotices: '',
  newTemplateArrearsCollection: ''
}
export default {
  name: 'WxConfigEdit',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      loading: false,
      url: process.env.VUE_APP_BASE_MOBILE,
      wxData: {},
      form: {...initData},
      rules: {
        configName: [{ required: true, trigger: "blur", message: "请输入" }],
        appId: [{ required: true, trigger: "blur", message: "请输入" }],
        appSecret: [{ required: true, trigger: "blur", message: "请输入" }],
        homeTime: [{ required: true, trigger: "blur", message: "请输入" }],
        copyrightTitle: [{ required: true, trigger: "blur", message: "请输入" }],
        developerEncodingAESKey: [{ required: true, trigger: "blur", message: "请输入" }],
        developerToken: [{ required: true, trigger: "blur", message: "请输入" }],
        isEnableDeveloper: [{ required: true, trigger: "change", message: "请选择" }],
      }
    }
  },
  watch: {
    visible (value) {
      if (value && this.items.id) {
        this.form = this.items
      }
    }
  },
  mounted () {},
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
      this.form = {...initData}
      this.wxData = {}
      this.$refs.form.resetFields()
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          // this.form.companyId = this.items.companyId
          wxConfigEdit(this.form).then(res => {
            this.$message.success(res.message)
            this.loading = false
            this.$emit('refresh')
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    createdWx () {
      wxDevCreateKeyToken().then(res => {
        this.$message.success(res.message)
        this.wxData = res.data
      })
    },
    wxLink () {
      createdWxQrcodeLink({appId: this.form.appId, appSecret: this.form.appSecret}).then(res => {
        this.form.weiXinNumber = res.data.url
      })
    }
  }
}
</script>

<style lang="scss">
.label-red .el-form-item__label {
  color: red;
}
</style>
