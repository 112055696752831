<template>
  <div class="login-box">
    <div class="login">
      <el-form :model="form" class="login-form" :rules="rules" ref="loginForm">
        <div class="login-title">易隆物业管理系统运营平台</div>
        <el-form-item prop="userName" class="margin-b-30">
          <el-input
          size="medium"
          placeholder="请输入账号"
          prefix-icon="el-icon-user"
          v-model="form.userName"/>
        </el-form-item>
        <el-form-item prop="passWord" class="margin-b-30">
          <el-input
          size="medium"
          @keyup.enter.native="handleLogin"
          placeholder="请输入密码"
          prefix-icon="el-icon-lock"
          type="password"
          v-model="form.passWord"/>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="loading"
            type="primary"
            style="width:100%;"
            @click.native.prevent="handleLogin"
          >
            <span v-if="!loading">登 录</span>
            <span v-else>登 录 中...</span>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {setLocalStorage} from '@/utils/auth'
export default {
  name: 'Login',
  data () {
    return {
      loading: false,
      form: {
        passWord: '',
        userName: ''
      },
      rules: {
        userName: [{ required: true, trigger: "blur", message: "账号不能为空" }],
        passWord: [{ required: true, trigger: "blur", message: "密码不能为空" }]
      },
      // redirect: undefined
    }
  },
  // watch: {
  //   $route: {
  //     handler: function(route) {
  //       this.redirect = route.query && route.query.redirect;
  //     },
  //     immediate: true
  //   }
  // },
  computed: {
    redirect () {
      return this.$route.query.redirect || ''
    }
  },
  methods: {
    handleLogin () {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.$store.dispatch("Login", this.form).then(() => {
            this.$router.push({ path: this.redirect || "/home" }).catch(() => {})
            setLocalStorage('userName', this.form.userName)
            setTimeout(() => {
              this.loading = false
            }, 20000)
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.login-box {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-image: url('../assets/images/bg.jpeg');
  .login-title {
    font-size: 20px;
    text-align: center;
    color: #777777;
    margin-bottom: 20px;
  }
  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .login-form {
    background: #fff;
    width: 400px;
    padding: 20px 20px 5px 20px;
  }
}
.w-360 {
  width: 360px;
}
.margin-b-30 {
  margin-bottom: 30px;
}
</style>
