export const project = {
  company: () => import(/* webpackChunkName: 'company' */ '@/views/project/company'), // 公司
  area: () => import(/* webpackChunkName: 'area' */ '@/views/project/area'), // 项目
  areaConfig1: () => import(/* webpackChunkName: 'areaConfig1' */ '@/views/project/areaConfig1'), // 项目配置
  rooms: () => import(/* webpackChunkName: 'rooms' */ '@/views/project/rooms'), // 房间
  importTask: () => import(/* webpackChunkName: 'importTask' */ '@/views/project/importTask'), // 上传任务
  wxConfig: () => import(/* webpackChunkName: 'wxConfig' */ '@/views/project/wxConfig'), // 微信配置
  feeType: () => import(/* webpackChunkName: 'feeType' */ '@/views/project/feeType'), // 费用类型
  onlineQuestion: () => import(/* webpackChunkName: 'onlineQuestion' */ '@/views/project/onlineQuestion'), // 反馈管理
  entranceGuard: () => import(/* webpackChunkName: 'entranceGuard' */ '@/views/project/entranceGuard'), // 门禁道闸
  changeFeeStandard: () => import(/* webpackChunkName: 'changeFeeStandard' */ '@/views/project/changeFeeStandard'), // 变更费用标准
  roles: () => import('@/views/project/roles'), // 角色管理
  staff: () => import('@/views/project/staff'), // 员工管理
  weekChargeRepair: () => import(/* webpackChunkName: 'weekChargeRepair' */ '@/views/project/weekChargeRepair'), // 周期类导入收费项修复
}