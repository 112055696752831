<!-- 反馈管理 -->
<template>
  <div>
    <my-search :items="searchItems" @search="search" @clear="clear">
      <el-button type="primary" slot="btn" @click="visible = true" v-hasPermi="['project:onlineQuestion:add']">新增</el-button>
    </my-search>
    <my-table :columns="columns" url="/PlatformWorkOrder/PageList" ref="myTable">
      <vxe-table-column title="操作" width="120">
        <template #default="{row}">
          <el-button type="text" @click="eye(row)">查看详情</el-button>
          <el-button type="text" @click="allot(row)" v-hasPermi="['project:onlineQuestion:allot']">分配</el-button>
        </template>
      </vxe-table-column>
    </my-table>
    <online-question-edit :visible.sync="visible" :tree="tree" @refresh="refresh"/>
    <online-question-detail :visible.sync="visibleDetail" :items.sync="items" @refresh="refresh"/>
  </div>
</template>

<script>
import {companyProjectTree} from '@/api/system'
import OnlineQuestionEdit from './components/OnlineQuestionEdit'
import OnlineQuestionDetail from './components/OnlineQuestionDetail'
import {onlineQuestionDetail, onlineQuestionAllot, companyList} from '@/api/project'
import { mapGetters } from 'vuex'

export default {
  name: 'onlineQuestion',
  components: { OnlineQuestionEdit, OnlineQuestionDetail },
  data () {
    return {
      queryData: {}, // 查询条件
      visible: false, // 编辑模态框
      visibleDetail: false, // 详情模态框
      columns: [
        {
          label: '服务编号',
          prop: 'id'
        },
        {
          label: '公司名称/编号',
          prop: 'companyAlias',
          operate: true,
          render: (h, {row}) => {
            return h('div', {}, row.companyName + '(' + row.companyAlias + ')')
          }
        },
        {
          label: '项目名称',
          prop: 'projectName'
        },
        // {
        //   label: '问题描述',
        //   prop: 'contents'
        // },
        {
          label: '问题分类',
          prop: 'workOrderTypeName'
        },
        {
          label: '状态',
          prop: 'statusName',
          operate: true,
          render: (h, {row}) => {
            let type = ''
            if (row.status) {
              type = {
                1: '',
                2: 'success',
                3: 'danger',
              }[row.status]
            }
            return h('el-tag', {
              props: {
                type: type
              }
            }, row.statusName)
          }
        },
        {
          label: '紧急程度',
          prop: 'urgentName',
          operate: true,
          render: (h, {row}) => {
            let type = ''
            if (row.urgent) {
              type = {
                1: 'info',
                2: '',
                3: 'warning',
                4: 'danger',
              }[row.urgent]
            }
            return h('el-tag', {
              props: {
                type: type
              }
            }, row.urgentName)
          }
        },
        {
          label: '提交时间',
          prop: 'createTime'
        },
        {
          label: '处理人',
          prop: 'processor'
        },
        {
          label: '联系人',
          prop: 'createName'
        },
        {
          label: '电话',
          prop: 'phone'
        },
      ],
      items: {}, // 详情结果
      tree: [],
      projectList: []
    }
  },
  computed: {
    ...mapGetters(['dict', 'companyTree']),
    searchItems () {
      const {companyTree, projectList} = this
      return [
        {
          type: 'selectTree',
          label: '公司名称/编号：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany,
          style: 'margin-right: 0'
        },
        {
          type: 'select',
          label: '',
          name: 'projectId',
          options: projectList
        },
        // {type: 'input', label: '公司名称/别名：', name: 'companyNameOrAlias'},
        // {type: 'input', label: '项目名称：', name: 'projectName'},
        {type: 'input', label: '问题描述：', name: 'contents'},
        {type: 'select', label: '问题分类：', name: 'workOrderType', options: this.dict.PlatformWorkOrderType},
        {type: 'select', label: '处理状态：', name: 'status', options: this.dict.PlatformWorkOrderStatus},
        {type: 'select', label: '紧急程度：', name: 'urgent', options: this.dict.PlatformWorkOrderUrgent},
        {type: 'input', label: '服务编号：', name: 'code'},
        {type: 'input', label: '处理人：', name: 'processor'},
        {type: 'datePicker', label: '提交开始时间：', name: 'start'},
        {type: 'datePicker', label: '提交结束时间：', name: 'end'},
      ]
    }
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
    this.getTreeList()
  },
  methods: {
    getTreeList () {
      companyProjectTree({types: [1, 2, 3]}).then(res => {
        this.tree = res.data
      })
    },
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    refresh () {
      this.$refs.myTable.refresh(this.queryData)
    },
    eye (row) {
      onlineQuestionDetail({id: row.id}).then(res => {
        this.items = res.data
        this.visibleDetail = true
      })
    },
    allot (row) {
      this.$prompt('', '工单分配', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入处理人',
      }).then(({ value }) => {
        onlineQuestionAllot({workOrderId: row.id, processor: value}).then(res => {
          this.$message.success(res.message)
          this.refresh()
        })
      })
    },
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    changeCompany (data) {
      this.$store.dispatch('getCompanyDownProject', {companyId: data.id, containsClosed: true}).then(res => {
        this.projectList = res
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
