// import store from '@/store'

// 手机号验证
export const validateMobilePhone = (rules, value, callback) => {
  if (!value) {
    return callback(new Error('手机号不能为空'))
  } else {
    const reg = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
    if (reg.test(value)) {
      callback()
    } else {
      const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/
      if (reg.test(value)) {
        callback()
      } else {
        return callback(new Error('请输入正确的手机号'))
      }
    }
  }
}
// 车牌号校验
export const carPhoneValid = (rule, value, callback) => {
  let xreg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/
  let creg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/
  let number = value.replace(/\s/g, '')
  if (value.length !== 0) {
    if (value.length === 7) {
      if (!creg.test(number)) {
        callback(new Error('车牌号输入不合法'))
      } else {
        callback()
      }
    } else if (value.length === 8) {
      if (!xreg.test(number)) {
        callback(new Error('车牌号输入不合法'))
      } else {
        callback()
      }
    } else {
      callback(new Error('车牌号输入不合法'))
    }
  }
}

// 邮箱验证
export const isEmail = data => {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(data)
}
// 是否微信浏览器
export const isWx = () => {
  return ua.match(/microMessenger/i) == 'micromessenger'
}
// 是否移动端
export const isDeviceMobile = () => {
  return /android|webos|iphone|ipod|balckberry/i.test(ua)
}
// 是否qq浏览器
export const isQQBrowser = () => {
  return !!ua.match(/mqqbrowser|qzone|qqbrowser|qbwebviewtype/i)
}
// 是否ios
export const isIos = () => {
  let u = navigator.userAgent
  if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {  // 安卓手机
    return false
  } else if (u.indexOf('iPhone') > -1) { // 苹果手机
    return true
  } else if (u.indexOf('iPad') > -1) { // iPad
    return false
  } else if (u.indexOf('Windows Phone') > -1) { // winphone手机
    return false
  } else {
    return false
  }
}
// 是否pc
export const isPC = () => {
  let userAgentInfo = navigator.userAgent
  let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"]
  let flag = true
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
}
// 检测密码强度
export const checkPwd = str => {
  let Lv = 0
  if (str.length < 6) {
    return Lv
  }
  if (/[0-9]/.test(str)) {
    Lv++
  }
  if (/[a-z]/.test(str)) {
    Lv++
  }
  if (/[A-Z]/.test(str)) {
    Lv++
  }
  if (/[\.|-|_]/.test(str)) {
    Lv++
  }
  return Lv
}
// 字符转换，type: 1:首字母大写 2：首字母小写 3：大小写转换 4：全部大写 5：全部小写
export const changeCase = (str, type) => {
  type = type || 4
  switch (type) {
  case 1:
    return str.replace(/\b\w+\b/g, word => {
      return word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase()
    })
  case 2:
    return str.replace(/\b\w+\b/g, word => {
      return word.substring(0, 1).toLowerCase() + word.substring(1).toUpperCase()
    })
  case 3:
    return str.split('').map(word => {
      if (/[a-z]/.test(word)) {
        return word.toUpperCase()
      } else {
        return word.toLowerCase()
      }
    }).join('')
  case 4:
    return str.toUpperCase()
  case 5:
    return str.toLowerCase()
  default:
    return str
  }
}
// 去除空格,type: 1-所有空格 2-前后空格 3-前空格 4-后空格
export const trim = (str, type) => {
  type = type || 1
  switch (type) {
  case 1:
    return str.replace(/\s+/g, "")
  case 2:
    return str.replace(/(^\s*)|(\s*$)/g, "")
  case 3:
    return str.replace(/(^\s*)/g, "")
  case 4:
    return str.replace(/(\s*$)/g, "")
  default:
    return str
  }
}
// 求和
export const sum = arr => {
  return arr.reduce((pre, cur) => {
    return pre + cur
  })
}
// 平均数
export const average = arr => {
  return sum(arr) / arr.length
}
// 最小值
export const min = arr => {
  return Math.min.apply(null, arr)
}
// 最大
export const max = arr => {
  return Math.max.apply(null, arr)
}
// 将类数组转数组
export const formArray = ary => {
  let arr = []
  if (Array.isArray(ary)) {
    arr = ary
  } else {
    arr = Array.prototype.slice.call(ary)
  }
  return arr
}
// 将阿拉伯数字翻译成中文的大写数字
export const numberToChinese = num => {
  let AA = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"]
  let BB = ["", "十", "百", "仟", "萬", "億", "点", ""]
  let a = ("" + num).replace(/(^0*)/g, "").split(".")
  let k = 0
  let re = ""
  for (let i = a[0].length - 1; i >= 0; i--) {
    switch (k) {
    case 0:
      re = BB[7] + re
      break
    case 4:
      if (!new RegExp("0{4}//d{" + (a[0].length - i - 1) + "}$").test(a[0])) {
        re = BB[4] + re
      }
      break
    case 8:
      re = BB[5] + re
      BB[7] = BB[5]
      k = 0
      break
    }
    if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) {
      re = AA[0] + re
    }
    if (a[0].charAt(i) != 0) {
      re = AA[a[0].charAt(i)] + BB[k % 4] + re
    }
    k++
  }

  // 加上小数部分(如果有小数部分)
  if (a.length > 1) {
    re += BB[6]
    for (let i = 0; i < a[1].length; i++) {
      re += AA[a[1].charAt(i)]
    }
  }
  if (re == '一十') {
    re = "十"
  }
  if (re.match(/^一/) && re.length == 3) {
    re = re.replace("一", "")
  }
  return re
}
// 身份证校验
export const isCardID = sId => {
  if (!/(^\d{15}$)|(^\d{17}(\d|X|x)$)/.test(sId)) {
    return false
  }
  // 身份证城市
  let aCity = {
    11: "北京",
    12: "天津",
    13: "河北",
    14: "山西",
    15: "内蒙古",
    21: "辽宁",
    22: "吉林",
    23: "黑龙江",
    31: "上海",
    32: "江苏",
    33: "浙江",
    34: "安徽",
    35: "福建",
    36: "江西",
    37: "山东",
    41: "河南",
    42: "湖北",
    43: "湖南",
    44: "广东",
    45: "广西",
    46: "海南",
    50: "重庆",
    51: "四川",
    52: "贵州",
    53: "云南",
    54: "西藏",
    61: "陕西",
    62: "甘肃",
    63: "青海",
    64: "宁夏",
    65: "新疆",
    71: "台湾",
    81: "香港",
    82: "澳门",
    91: "国外"
  }
  if (!aCity[parseInt(sId.substr(0, 2))]) {
    return false
  }

  // 出生日期验证
  let sBirthday = (sId.substr(6, 4) + "-" + Number(sId.substr(10, 2)) + "-" + Number(sId.substr(12, 2))).replace(/-/g, "/")
  let d = new Date(sBirthday)
  if (sBirthday != (d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate())) {
    console.log('身份证上的出生日期非法')
    return false
  }

  // 身份证号码校验
  let sum = 0
  let weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
  let codes = "10X98765432"
  for (let i = 0; i < sId.length - 1; i++) {
    sum += sId[i] * weights[i]
  }
  // 计算出来的最后一位身份证号码
  let last = codes[sum % 11]
  if (sId[sId.length - 1] != last) {
    console.log('你输入的身份证号非法')
    return false
  }

  return true
}
// 保留两位小数
export const returnFloat = (value, decimal = 2) => {
  if (!value) {
    return ''
  } else {
    let d = 1
    if (decimal) {
      d = {
        1: 10,
        2: 100,
        3: 1000,
        4: 10000
      }[decimal]
    }
    // value = value.toString()
    // let index = value.indexOf('.')
    // if (index !== -1) {
    //   value = value.substring(0, 2 + index + 1)
    // } else {
    //   value = value.substring(0)
    // }
    // return parseFloat(value).toFixed(2)
    let val = Math.round(parseFloat(value) * d) / d
    let xsd = val.toString().split('.')
    if (xsd.length === 1) {
      return val.toString() + '.00'
    }
    if (xsd.length > 1) {
      if (xsd[1].length < 2) {
        val = val.toString() + '0'
      }
      return val
    }
  }
}
// 去重
export const flatten = arr => {
  return arr.reduce((accu, cur) => {
    // return accu.includes(cur) ? accu : accu.concat(cur);  // 1. 拼接方法
    return accu.includes(cur) ? accu : [...accu, cur]; // 2. 扩展运算
  }, [])
}
// 得到两个数组的并集, 两个数组的元素为数值或字符串
export const getUnion = (a, b) => {
  return a.concat(b.filter(val => {
    return !a.includes(val)
  }))
  // return Array.from(new Set([...arr1, ...arr2]))
}
// 差集
export const getDiff = (a, b) => {
  return a.concat(b).filter(v => !a.includes(v) || !b.includes(v))
}
// 交集
export const intersect = (a, b) => {
  return a.filter(item => {
    return b.includes(item)
  })
}
// 判断要查询的数组是否至少有一个元素包含在目标数组中
export const hasOneOf = (targetarr, arr) => {
  return targetarr.some(_ => arr.indexOf(_) > -1)
}

// 判断数据类型
export const getDataType = data => {
  const temp = Object.prototype.toString.call(data)
  const type = temp.match(/\b\w+\b/g)
  return (type.length < 2) ? 'Undefined' : type[1]
}

// 判断对象是否相等
export const isObjectChanged = (source, comparison) => {
  // 由于'Object','Array'都属于可遍历的数据类型，所以我们提前定义好判断方法，方便调用
  const iterable = data => ['Object', 'Array'].includes(getDataType(data))

  // 如果源数据不是可遍历数据，直接抛错，主要用于判断首次传入的值是否符合判断判断标准。
  if (!iterable(source)) {
    throw new Error(`应该是个对象或者数组，但是得到了 ${getDataType(source)}`)
  }

  // 如果数据类型不一致，说明数据已经发生变化，可以直接return结果
  if (getDataType(source) !== getDataType(comparison)) {
    return true
  }

  // 提取源数据的所有属性名
  const sourceKeys = Object.keys(source)

  // 将对比数据合并到源数据，并提取所有属性名。
  // 在这里进行对象合并，首先是要保证 对比数据>=源数据，好处一：后边遍历的遍历过程就不用做缺省判断了。
  const comparisonKeys = Object.keys({...source, ...comparison})

  // 好处二：如果属性数量不一致说明数据必然发生了变化，可以直接return结果
  if (sourceKeys.length !== comparisonKeys.length) {
    return true
  }

  // 这里遍历使用some，some的特性一旦找到符合条件的值，则会立即return，不会进行无意义的遍历。完美符合我们当前的需求

  return comparisonKeys.some(key => {
    // 如果源数据属于可遍历数据类型，则递归调用
    if (iterable(source[key])) {
      return isObjectChanged(source[key], comparison[key])
    } else {
      return source[key] !== comparison[key]
    }
  })
}

export const dateFormat = (date, fmt = 'yyyy-MM-dd') => {
  let o = {
    "M+": date.getMonth() + 1,                 // 月份
    "d+": date.getDate(),                    // 日
    "H+": date.getHours(),                   // 小时
    "m+": date.getMinutes(),                 // 分
    "s+": date.getSeconds(),                 // 秒
    "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
    "S": date.getMilliseconds()             // 毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length))
  }
  for (let k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)))
    }
  }
  return fmt
}

// 判断是否是数字
export const isNumber = val => {
  let regPos = /^\d+(\.\d+)?$/ // 非负浮点数
  let regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/ // 负浮点数
  if (regPos.test(val) || regNeg.test(val)) {
    return true
  } else {
    return false
  }
}
export const numberFormat = data => {
  if (!data) {
    return '0'
  }
  let intPartFormat
  if (data % 1 === 0) {
    intPartFormat = data.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  } else {
    intPartFormat = data.toString().replace(/\d(?=(\d{3})+\.)/g, '$&,') // 将小数部分逢三一断
  }
  return intPartFormat
}
// 滚动到页面顶部
export const scrollTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop
  if (c > 0) {
    window.requestAnimationFrame(scrollTop)
    window.screenTop(0, c - c / 8)
  }
}

// 将表单元素转对象
export const formToObject = form => {
  Array.form(new FormData(form)).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: value
  }), {})
}

// 获取两个时间之差-相差多少天
export const getDaysDiffBetweenDates = (dateStr, dateEnd) => {
  return (new Date(dateStr) - new Date(dateEnd)) / (1000 * 3600 * 24)
}

// 将http网址重定向https
export const httpsRedirect = () => {
  if (location.protocol !== 'https:') {
    location.replace('https://' + location.href.split('//')[1])
  }
}

// 判断数据是否为指定的数据类型，如果是则返回true
export const is = (type, val) => ![, null].includes(val) && val.constructor === type

// 计算两点之间的距离
export const distance = (x0, y0, x1, y1) => Math.hypot(x1 - x0, y1 - y0)

// 数组从左边开始删除 n 个元素
export const drop = (arr, n = 1) => arr.slice(n)

// 阻止冒泡事件
export const stopPropagation = e => {
  e = e || window.event
  if (e.stopPropagation) {    // W3C阻止冒泡方法
    e.stopPropagation()
  } else {
    e.cancelBubble = true; // IE阻止冒泡方法
  }
}

// 防抖函数
export const debounce = (fn, wait = 1000) => {
  let timer = null

  return function() {
    let context = this
    let args = arguments
    if (timer) {
      clearTimeout(timer)
      timer = null
    }

    timer = setTimeout(() => {
      fn.apply(context, args)
    }, wait)
  }
}

// 节流
export const throttle = (fn, delay = 3000) => {
  // let curTime = Date.now()

  // return function() {
  //   let context = this
  //   letargs = arguments
  //   let nowTime = Date.now()

  //   if (nowTime - curTime >= delay) {
  //     curTime = Date.now()
  //     return fn.apply(context, args)
  //   }
  // };
  let previous = 0
  return function() {
    let now = Date.now()
    let context = this
    let args = arguments
    if (now - previous > delay) {
      fn.apply(context, args)
      previous = now
    }
  }
}

// 随机字符串
export const randomString = len => {
  let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz123456789'
  let strLen = chars.length
  let randomStr = ''
  for (let i = 0; i < len; i++) {
    randomStr += chars.charAt(Math.floor(Math.random() * strLen))
  }
  return randomStr
}

// 手机号中间4位变成*
export const telFormat = tel => {
  tel = String(tel)
  return tel.substr(0, 3) + "****" + tel.substr(7)
}

// 滚动到页面顶部
export const scrollToTop = () => {
  const height = document.documentElement.scrollTop || document.body.scrollTop;
  if (height > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, height - height / 8);
  }
}

// 滚动到页面底部
export const scrollToBottom = () => {
  window.scrollTo(0, document.documentElement.clientHeight)
}

// 滚动到指定元素区域
export const smoothScroll = element => {
  document.querySelector(element).scrollIntoView({
    behavior: 'smooth'
  })
}

// 获取可视窗口高度
export const getClientHeight = () => {
  let clientHeight = 0;
  if (document.body.clientHeight && document.documentElement.clientHeight) {
    clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight;
  }
  else {
    clientHeight = (document.body.clientHeight > document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight;
  }
  return clientHeight;
}

// 获取可视窗口宽度
export const getPageViewWidth = () => {
  return (document.compatMode == "BackCompat" ? document.body : document.documentElement).clientWidth;
}

// 打开浏览器全屏
export const toFullScreen = () => {
  let element = document.body;
  if (element.requestFullscreen) {
    element.requestFullscreen()
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen()
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen()
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullScreen()
  }
}

// 退出浏览器全屏
export const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen()
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen()
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen()
  }
}

// 打印pdf
export const printPdf = (url, printer = '', call = true) => {
  let downloadUrl = "https://www.webprintworld.com/download/InstallClientA.zip"
  if (IsX64Windows()) {
    downloadUrl = "https://www.webprintworld.com/download/InstallClientA64.zip"
  }
  let pw = GetPrintWorld()  // 获取一个打天下对象。不提供URL参数，意为本机的打天下打印服务器（"ws://127.0.0.1:8888"）。
  pw.DownloadUrlForPdfPrint(downloadUrl) // 指定（打天下安装包）下载地址
  let json = {}
  // json.action = "previewfile" // previewfile:预览，printfile:打印
  json.action = "printfile" // previewfile:预览，printfile:打印
  json.format = "pdf_url"
  json.content = url // pdf文件的url路径
  json.printer = printer            // 打印机名称，为空或者不指定，则通过缺省打印机打印。
  json.quality = -4 // 打印质量，整型值，小于零有效（-1~-4，质量由低到高）。缺省为0，意为系统指定。
  json.cert = 'http://360wuye.net/upload/cert/print/0d4ac396e1b5e8b0eef76eac7502931a.ctf' // 授权证书文件URL,去除未授权水印
  if (call) {
    json.callbackurl = process.env.VUE_APP_BASE_PIRINT + '/Print/ChargePrintNotify'
  }
  // 直接打印Act
  if (!pw.Act(json)) {
    // 数据发送失败
    alert(pw.GetLastError())
  }
}

// 打印/预览模板
export const printTmp = config => {
  const {tmpUrl, action = 'print', printer = '', jsonData, call = true} = config
  let downloadUrl = "https://www.webprintworld.com/download/InstallClientA.zip"
  if (IsX64Windows()) {
    downloadUrl = "https://www.webprintworld.com/download/InstallClientA64.zip"
  }
  let pw = GetPrintWorld()  // 获取一个打天下对象。不提供URL参数，意为本机的打天下打印服务器（"ws://127.0.0.1:8888"）。
  pw.DownloadUrlForTemplatePrint(downloadUrl) // 指定（打天下安装包）下载地址
  // console.log(jsonData, printer, tmpUrl, action, call)
  let json = {}
  json.action = action
  json.printer = printer            // 打印机名称，为空或者不指定，则通过缺省打印机打印。
  json.quality = -4 // 打印质量，整型值，小于零有效（-1~-4，质量由低到高）。缺省为0，意为系统指定。
  json.template = ToAbsoluteURL(tmpUrl) // 模板地址
  json.data = jsonData
  if (call) {
    json.callbackurl = process.env.VUE_APP_BASE_PIRINT + '/Print/ChargePrintNotify'
  }
  // 直接打印Act
  if (!pw.Act(json)) {
    // 数据发送失败
    alert(pw.GetLastError())
  }
}

// 楼栋单元文字渲染
export const getBuildingUnitNodeText = node => {
  const {ancestors} = node
  let texts = ancestors.map(x => x.label)
  return texts.reverse().join('') + node.label
}

// 树转换成一维数组
export const getTreeToFlat = (params = [], result = [], key = '') => {
  if (Array.isArray(params) && params.length) {
    params.forEach(item => {
    // 每次开启新的一轮循环，拷贝一份副本（key），因为副本会用于当前轮的其它兄弟，所以你不能改变副本（即不能改变key的值）
      let currentkey =  key
      currentkey += item.name
      if (item.child) {
        getTreeToFlat(item.child, result, currentkey)
      } else {
      // 无children说明市最后一层，保留最后一层的节点数据
        result.push({
          name: currentkey,
          id: item.id
        })
      }
    })
  }
  return result
}

/**
 * 1、根据节点id,获取其所有父节点
 * @param {*} list 完整的树结构数组
 * @param {*} id 当前点击的id
 * @param {*} name 需要对比的id 的属性节点
 * @param {*} child 子节点名称
 * @returns
 */
export const getAllParentArr = (list, id, name, child = 'child') => {
  for (let i in list) {
    if (list[i][name] == id) {
      return [list[i]]
    }
    if (list[i][child]) {
      let node = getAllParentArr(list[i][child], id, name, child)
      if (!!node) {
        return node.concat(list[i])
      }
    }
  }
}

// base64解析成文件，传入base64字符串和文件名，返回file
export const base64ToFile = (base64, fileName) => {
  let arr = base64.split(",")
  // console.log(base64, fileName, arr[0], arr[0].match(/:(.*?);/), '8888888')
  let mime = arr[0].match(/:(.*?);/)[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], fileName, { type: mime })
}