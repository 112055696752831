<template>
  <el-dialog title="预警人员配置" :visible.sync="visible" :before-close="close" :close-on-click-modal="false" width="60%">
    <el-row :gutter="12">
      <el-col :span="12" v-for="(item, index) in peopleConfigList" :key="index" style="margin-bottom: 15px;">
        <el-card>
          <div slot="header" class="flex-h">
            <div>{{ item.earlyWarningTypeName }}</div>
          </div>
          <el-tag v-for="tag in item.users" :key="tag.id" closable @close="del(tag)" style="margin-right: 10px;" size="lagr">
            {{tag.name}}{{ tag.phone }}
          </el-tag>
          <el-select placeholder="请选择人员" v-if="item.showSelect" v-model="item.selectUser" @change="changeUser($event, item)" value-key="id">
            <el-option v-for="elem in peopleList" :key="elem.id" :value="elem" :label="elem.name"></el-option>
          </el-select>
          <el-button v-else class="button-new-tag" size="small" @click="add(item)">新增人员</el-button>
        </el-card>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import {warningManageGetPeople, warningManageDelPeople, servicesList, warningManageAddPeople} from '@/api/system'
import { mapGetters } from 'vuex'

export default {
  name: 'WarningManagePeople',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      peopleConfigList: [],
      peopleList: []
    }
  },
  computed: {
    ...mapGetters(['dict']),
  },
  watch: {
    visible (value) {
      if (value) {
        this.getPeopleConfig()
        this.getPeopleList()
      }
    }
  },
  mounted () {},
  methods: {
    getPeopleList () {
      servicesList().then(res => {
        this.peopleList = res.data
      })
    },
    getPeopleConfig () {
      warningManageGetPeople().then(res => {
        const {dict} = this
        let list = []
        dict.EarlyWarningType?.forEach(item => {
          let config = res.data.find(x => x.earlyWarningType === item.value)
          list.push({
            ...config,
            showSelect: false,
            selectUser: {}
          })
        })
        this.peopleConfigList = list
      })
    },
    close () {
      this.peopleConfigList = []
      this.$emit('update:visible', false)
    },
    del (tag) {
      this.$confirm(`确定删除预警人员<span style="color: #da251c">${tag.name}<span>吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        warningManageDelPeople({id: tag.id}).then(res => {
          this.$message.success(res.message)
          this.getPeopleConfig()
        })
      })
    },
    add(item) {
      item.showSelect = true
    },
    changeUser (value, item) {
      this.$confirm(`确定添加预警人员<span style="color: #de251c">${value.name}</span>?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        warningManageAddPeople({earlyWarningType: item.earlyWarningType, userId: value.id}).then(res => {
          this.$message.success(res.message)
          this.getPeopleConfig()
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.flex-h {
  justify-content: space-between;
}
</style>
