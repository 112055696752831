import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store' // vuex
import './permission' // 路由拦截
import permission from './directive' // 自定义指令-权限
import dragDialog from './directive/dragDialog' // 自定义指令-拖拽
import 'xe-utils'
import './plugins/element'
import './plugins/vxeTable'
import 'element-ui/lib/theme-chalk/index.css'
import MySearch from '@/components/MySearch'
import MyToolbar from '@/components/MyToolbar'
import MyTable from '@/components/MyTable'
import MyTinymce from '@/components/MyTinymce'
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"

// 高德的安全密钥
window._AMapSecurityConfig = {
  securityJsCode: '41e1820e7582f2ba80558318975d6ba6',
}

import echarts from "echarts/lib/echarts"
import china from 'echarts/map/json/china.json'
echarts.registerMap('china', china)
Vue.prototype.$echarts = echarts

Vue.component('MySearch', MySearch)
Vue.component('MyToolbar', MyToolbar)
Vue.component('MyTable', MyTable)
Vue.component('MyTinymce', MyTinymce)
Vue.component('Treeselect', Treeselect)

Vue.config.productionTip = false
Vue.prototype.bus = new Vue()
Vue.use(permission)
Vue.use(dragDialog)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
