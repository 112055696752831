<template>
  <el-dialog :title="title" width="40%" :visible.sync="showModal" :close-on-click-modal="false" destroy-on-close :before-close="close">
    <el-form :model="form" ref="form" :rules="rules" label-width="150px">
      <el-form-item label="所属公司" prop="companyId">
        <treeselect
          v-model="form.companyId"
          :searchable="false"
          :disabled="!!form.id"
          :options="companyTree"
          :normalizer="normalizer"
          :load-options="loadOptions"
          no-results-text="无搜索结果"
          no-children-text="无子节点"
          :show-count="true"
          no-options-text="无可用选项"
          placeholder="请选择">
        </treeselect>
      </el-form-item>
      <el-form-item label="员工姓名" prop="name">
        <el-input v-model="form.name" maxlength="20" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="手机号/账号" prop="phone">
        <el-input v-model="form.phone" maxlength="11" placeholder="请输入"></el-input>
      </el-form-item>
      <div v-if="title === '新增'">
        <el-form-item label="登录密码" prop="password">
          <el-input v-model="form.password" maxlength="16" placeholder="请输入"></el-input>
        </el-form-item>
      </div>
      <el-form-item label="邮箱">
        <el-input v-model="form.email" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="性别">
        <el-radio-group v-model="form.sexType">
          <el-radio v-for="item in dict.SexType" :label="item.value" :key="item.value">{{item.displayName}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <div v-if="title !== '新增'">
        <el-form-item label="状态">
          <el-radio-group v-model="form.status">
            <el-radio v-for="item in dict.EntityStatus" :label="item.value" :key="item.value">{{item.displayName}}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <el-form-item label="开启微信小程序登录">
        <vxe-switch open-label="是" close-label="否" v-model="form.isEnableWeAppLogin"></vxe-switch>
      </el-form-item>
      <el-form-item label="是否开启接收公众号业主缴费通知">
        <vxe-switch open-label="是" close-label="否" v-model="form.isEnableWeiXinChargeNotices"></vxe-switch>
        <div style="color: #da251c">必须是配置专人，不能随意配置</div>
      </el-form-item>
      <el-form-item label="是否开启工单提交通知">
        <vxe-switch open-label="是" close-label="否" v-model="form.isEnableWeAppSubmitWorkOrder"></vxe-switch>
        <div style="color: #da251c">必须是配置专人，不能随意配置</div>
      </el-form-item>
      <el-form-item label="备注">
        <el-input placeholder="请输入" v-model="form.remarks" type="textarea" :autosize="{minRows: 3}"></el-input>
      </el-form-item>
      <el-form-item label="聊天备注">
        <el-input placeholder="请输入" v-model="form.chatRemarks" type="textarea" :autosize="{minRows: 3}"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
// import Treeselect from "@riophae/vue-treeselect"
// import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import {editStaff} from '@/api/system'
import {validateMobilePhone, throttle} from '@/utils/tools'
import { mapGetters } from 'vuex'
import {companyList} from '@/api/project'

export default {
  name: 'StaffEdit',
  components: {},
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    },
    companyTree: {
      type: Array,
      default: []
    },
  },
  data () {
    return {
      rules: {
        name: [{required: true, message: '请输入', trigger: 'blur'}],
        companyId: [{required: true, message: '请选择', trigger: 'change'}],
        phone: [
          {required: true, message: '请输入', trigger: 'blur'},
          {validator: validateMobilePhone, message: '请输入正确的手机号'}
        ],
        password: [{required: true, message: '请输入', trigger: 'blur'}],
      },
      loading: false,
      form: {},
    }
  },
  computed: {
    title () {
      return this.items.id > 0 ? '编辑' + this.items.name : '新增员工'
    },
    ...mapGetters(['dict'])
  },
  watch: {
    showModal (val) {
      if (val) {
        this.form = this.items.id > 0 ? this.items : {
          name: '',
          phone: '',
          password: '123456',
          email: '',
          sexType: 1,
          status: 1,
          companyId: null,
          remarks: '',
          isEnableWeAppLogin: true,
          isEnableWeiXinChargeNotices: false,
          isEnableWeAppSubmitWorkOrder: false,
          chatRemarks: ''
        }
      }
    }
  },
  methods: {
    close () {
      this.$emit('update:showModal', false)
      this.$emit('update:items', {})
      this.form = {}
      this.$refs.form.resetFields()
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    submit: throttle(function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          editStaff(this.form).then(res => {
            this.$message.success(res.message || '操作成功')
            this.$emit('refresh')
            this.close()
          })
        }
      })
    }),
  }
}
</script>

<style scoped lang="scss">

</style>
