<template>
  <div
    ref="editor"
    class="input-box"
    :placeholder="placeholder"
    contenteditable="true"
    @input="inputText"
    @blur="inputBlur"
    @paste.prevent="paste"
    @keyup.enter.exact="submit"
    @keydown.enter.exact="handleKeydown"
    @focus="inputFocus">
  </div>
</template>

<script>
import onPaste from '@/utils/onPaste'
export default {
  name: 'MyEditable',
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: false
    }
  },
  data() {
    return {
      isBlur: true, // 解决赋值时光标自动定位到起始位置
      // imgUrls: []
    }
  },
  watch: {
    value(val) {
      if (this.isBlur) {
        this.$refs.editor.innerHTML = val
      }
    }
  },
  methods: {
    // 监听输入框内容
    inputText() {
      console.log('子组件输入框的输入内容', this.$refs.editor.innerHTML)
      this.$emit('input', this.$refs.editor.innerHTML)
      this.$emit('inputKeydown')
    },
    inputFocus() {
      this.isBlur = false
    },
    inputBlur() {
      this.isBlur = true
    },
    submit() {
      this.$emit('submit')
    },
    handleKeydown(event) {
      event.preventDefault()
      return false
    },
    clearText () {
      if (this.$refs.editor) {
        this.$refs.editor.innerText = ''
      }
    },
    // 注意是异步处理
    async paste(event) {
      const result = await onPaste(event) // 传入粘贴事件
      console.log('处理后的数据', result)
      const imgRegx = /^data:image\/png|jpg|jpeg|gif|svg|bmp|tif/ // 支持的图片格式
      if (imgRegx.test(result.compressedDataUrl)) {
        // this.imgUrls.push(result.data)
        // document.execCommand('insertImage', false, result.compressedDataUrl);
        const sel = window.getSelection() // 获取当前光标位置
        if (sel && sel.rangeCount === 1 && sel.isCollapsed) {
          const range = sel.getRangeAt(0)
          const img = new Image(300, 300)
          img.src = result.url
          range.insertNode(img)
          range.collapse(false)
          sel.removeAllRanges()
          sel.addRange(range)
          this.$emit('input', this.$refs.editor.innerHTML)
        }
      } else {
        // 插入文本
        document.execCommand('insertText', false, result.data)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.input-box {
  // border: 1px solid #6e6e6e;
  width: 100%;
  flex: 1;
  display: block;
  padding: 5px 10px;
  line-height: 1.5;
  box-sizing: border-box;
  font-size: 18px;
  color: #606266;
  background-color: #fff;
  background-image: none;
  outline: none; /* 隐藏聚焦时外边框 */
  /* border: 0; */
  overflow-y: auto;
  &:focus {
    outline: none;
    border-color: none;
  }
}
.input-box:empty:before{
  content: attr(placeholder);
  color:#777;
}
.input-box:focus{
  content: none;
}
</style>