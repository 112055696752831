import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import basic from './modules/basic'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    basic,
  },
  getters
})

export default store

// 清除store
export const clearStore = index => {
  if (!store) return
  const modules = ['Basic', 'User']
  if (index) {
    store.commit('clear' + modules[index])
  } else {
    modules.forEach(item => {
      store.commit('clear' + item)
    })
  }
}

// 重置state数据
export const clearState = (state, initData) => {
  Object.keys(state).forEach(key => {
    if (!initData[key]) {
      delete state[key]
    }
  })
  Object.keys(initData).forEach(key => {
    state[key] = initData[key]
  })
}