<template>
  <el-dialog :visible.sync="visible" :close-on-click-modal="false" :before-close="close" title="修改密码" width="30%">
    <el-form :model="form" label-width="100px" :rules="rules" ref="form">
      <el-form-item label="当前密码" prop="oldPassword">
        <el-input v-model="form.oldPassword" type="password" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input v-model="form.newPassword" type="password" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPassword">
        <el-input v-model="form.confirmPassword" type="password" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {editPwd} from '@/api/login'
export default {
  name: 'editPwd',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {
        newPassword: '',
        oldPassword: '',
        confirmPassword: ''
      },
      rules: {
        confirmPassword: [{ required: true, message: "请输入", trigger: "blur" }],
        oldPassword: [{ required: true, message: "请输入", trigger: "blur" }],
        newPassword: [{ required: true, message: "请输入", trigger: "blur" }]
      }
    }
  },
  mounted () {},
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.form = {
        newPassword: '',
        oldPassword: '',
        confirmPassword: ''
      }
      this.$refs.form.resetFields()
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const {form} = this
          if (form.newPassword !== form.confirmPassword) {
            this.$message.error('新密码和确认密码输入不一致！')
            return
          }
          editPwd(form).then(res => {
            this.$message.success(res.message + '即将退出登录')
            setTimeout(() => {
              // eslint-disable-next-line max-nested-callbacks
              this.$store.dispatch("FedLogOut").then(() => {
                this.$router.push("/login")
              })
            }, 1000)
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
