<template>
  <div class="margin-top-20 flex-h" style="align-items: flex-start;">
    <div v-for="(item, index) in files" :key="index">
      <el-tooltip effect="dark" content="点击查看大图" placement="bottom">
        <el-image
          v-if="isImg(item.src)"
          :src="item.src + '?x-oss-process=image/resize,m_lfit,h_100,w_100'"
          :style="customStyle"
          class="img-box"
          style="max-width: 100px;max-height: 100px;"
          :preview-src-list="imgs.map(x => x.src)">
        </el-image>
      </el-tooltip>
      <el-tooltip effect="dark" content="点击下载" placement="bottom">
        <el-image v-if="!isImg(item.src)">
          <div slot="error">
            <a target="_blank" :href="item.src">
              <img src="@/assets/images/file.png" alt="" :style="customStyle" class="img-box" style="height: 100px;width: 100px;">
            </a>
          </div>
        </el-image>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import EleGallery from '@/components/eleGalley'

export default {
  name: 'MyViewFiles',
  components: {
    EleGallery
  },
  props: {
    files: {
      type: Array,
      default: () => []
    },
    customStyle: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {

    }
  },
  computed: {
    imgs () {
      let imgs = []
      this.files.forEach(item => {
        if (this.isImg(item.src)) {
          imgs.push(item)
        }
      })
      return imgs
    },
  },
  mounted () {},
  methods: {
    isImg (src) {
      let isImg = false
      let fileExtension = ''
      if (src.lastIndexOf('.') > -1) {
        // 获取文件扩展名
        fileExtension = src.slice(src.lastIndexOf('.') + 1)
      }
      isImg = ['png', 'jpg', 'jpeg'].includes(fileExtension)
      return isImg
    },
  }
}
</script>

<style scoped lang="scss">
.img-box {
  border-radius: 5px;
  margin-right: 8px;
}
</style>
