<!-- 运营平台员工管理新增/编辑 -->
<template>
  <el-dialog :title="title" :visible.sync="visible" :close-on-click-modal="false" :before-close="close" width="30%">
    <el-form :model="form" :rules="rules" label-width="100px" ref="form">
      <el-form-item label="姓名" prop="name">
        <el-input v-model="form.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="登录名" prop="loginName" v-if="!form.id">
        <el-input v-model="form.loginName" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="昵称" prop="nickName">
        <el-input v-model="form.nickName" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="form.phone" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password" v-if="!form.id">
        <el-input v-model="form.password" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="授权公司" prop="authorizeCompanyIds">
        <div class="flex-h">
          <treeselect
            v-model="form.authorizeCompanyIds"
            multiple
            :options="companys"
            no-results-text="无搜索结果"
            :limitText="limitText"
            :limit="2"
            value-consists-of="ALL_WITH_INDETERMINATE"
            no-children-text="无子节点"
            no-options-text="无可用选项"
            :normalizer="normalizer"
            style="width: 90%;"
            placeholder="请选择公司" />
          <el-button type="text" style="margin-left: 10px;" @click="checkedAll">全选</el-button>
        </div>
      </el-form-item>
      <el-form-item label="角色">
        <el-select v-model="form.roleIds" placeholder="请选择" multiple style="width: 100%;">
          <el-option v-for="item in roles" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="邮箱">
        <el-input v-model="form.email" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="是否客服">
        <vxe-switch open-label="是" close-label="否" :open-value="true" :close-value="false" v-model="form.isCustomerService"></vxe-switch>
      </el-form-item>
      <el-form-item label="微信openId">
        <el-input v-model="form.weiXinOpenId" placeholder="请输入微信openid"></el-input>
      </el-form-item>
      <el-form-item label="小程序openId">
        <el-input v-model="form.weAppOpenId" placeholder="请输入小程序openid"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remarks" type="textarea" :autosize="{minRows: 2}" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {adminStaffEdit} from '@/api/system'
import {getTreeToFlat} from '@/utils/tools'
const initData = {
  phone: '',
  password: '123456',
  loginName: '',
  email: '',
  remarks: '',
  name: '',
  roleIds: [],
  authorizeCompanyIds: [],
  isCustomerService: false,
  nickName: '',
  weAppOpenId: '',
  weiXinOpenId: ''
}
export default {
  name: 'AdminStaffEdit',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    roles: {
      type: Array,
      default: () => []
    },
    companys: {
      type: Array,
      default: () => []
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      form: {...initData},
      rules: {
        name: { required: true, message: '请输入', trigger: 'blur' },
        loginName: { required: true, message: '请输入', trigger: 'blur' },
        password: { required: true, message: '请输入', trigger: 'blur' },
        phone: { required: true, message: '请输入', trigger: 'blur' },
        nickName: { required: true, message: '请输入', trigger: 'blur' },
      },
      loading: false
    }
  },
  computed: {
    title () {
      return this.items.id ? this.items.name : '新增员工'
    }
  },
  watch: {
    visible (value) {
      if (value && this.items.id) {
        this.form = {
          phone: this.items.phone,
          password: this.items.password || '123456',
          loginName: this.items.loginName,
          email: this.items.email,
          remarks: this.items.remarks,
          name: this.items.name,
          roleIds: this.items.ownerRoleIds || [],
          id: this.items.id,
          authorizeCompanyIds: this.items.authorizeCompanyIds,
          isCustomerService: this.items.isCustomerService,
          nickName: this.items.nickName,
          weiXinOpenId: this.items.weiXinOpenId,
          weAppOpenId: this.items.weAppOpenId,
        }
      }
    }
  },
  mounted () {},
  methods: {
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child
      }
      return {
        id: node.id,
        label: node.name + `(${node.alias})`,
        children: node.child
      }
    },
    limitText (e) {
      return "+" + e
    },
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
      this.form = {...initData}
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          adminStaffEdit(this.form).then(res => {
            this.loading = false
            this.$message.success(res.message)
            this.$emit('refresh')
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    checkedAll () {
      const data = getTreeToFlat(this.companys)
      this.form.authorizeCompanyIds = data.map(x => x.id)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
