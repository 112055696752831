<template>
  <el-dialog :title="title" :visible.sync="visible" :close-on-click-modal="false" :before-close="close" width="30%">
    <el-form :model="form" label-width="120px" :rules="rules" ref="form">
      <el-form-item label="项目名称" prop="projectId" v-if="!items.id">
        <el-cascader
          style="width: 100%"
          :props="{value: 'id', label: 'name', children: 'child', emitPath: false, multiple: true}"
          filterable
          clearable
          :options="tree"
          v-model="form.projectId">
        </el-cascader>
      </el-form-item>
      <el-form-item label="费用名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="资源账户类型" prop="accountType">
        <el-select v-model="form.accountType" placeholder="请选择" style="width: 100%">
          <el-option v-for="item in dict.ResourcesAccountType" :key="item.value" :value="item.value" :label="item.displayName"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="固定分类" prop="fixedFeeType" v-if="!items.id">
        <el-select v-model="form.fixedFeeType" placeholder="请选择">
          <el-option v-for="item in fixedTypeList" :key="item.id" :value="Number(item.id)" :label="item.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select v-model="form.status" placeholder="请选择">
          <el-option :value="1" label="启用"></el-option>
          <el-option :value="2" label="禁用"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remarks" placeholder="请输入" type="textarea"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import {fixedType, feeTypeEdit, /* accountSettings */} from '@/api/project'
import { mapGetters } from "vuex"
const initData = {
  projectId: null,
  name: '',
  status: 1,
  accountType: '',
  fixedFeeType: '',
  remarks: ''
}
export default {
  name: 'FeeTypeEdit',
  components: {Treeselect},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    tree: {
      type: Array,
      default: () => []
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      form: {...initData},
      rules: {
        projectId: [{ required: true, trigger: "change", message: "请选择" }],
        name: [{ required: true, trigger: "blur", message: "请输入" }],
        accountType: [{ required: true, trigger: "change", message: "请选择" }],
        fixedFeeType: [{ required: true, trigger: "change", message: "请选择" }],
      },
      fixedTypeList: [], // 固定分类
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['dict']),
    title () {
      return this.items.id ? this.items.name + '编辑' : '新增费用类型'
    }
  },
  watch: {
    visible (value) {
      if (value && this.items.id) {
        this.form = this.items
      }
    }
  },
  mounted () {
    this.getFixedType()
  },
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
      this.form = {...initData}
      this.$refs.form.resetFields()
    },
    // 获取固定分类
    getFixedType () {
      fixedType().then(res => {
        this.fixedTypeList = res.data
      })
    },
    // 提交数据
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          feeTypeEdit(this.form).then(res => {
            this.$message.success(res.message)
            this.$emit('refresh')
            this.loading = false
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
