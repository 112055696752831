import axios from 'axios'
import { getToken } from '@/utils/auth'
import qs from 'qs'

const mimeMap = {
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  zip: 'application/zip',
  pdf: "application/pdf"
}
/**
 * 下载.xlsx文件
 */
export const downloadXlsx = (url, params, fileName, cb) => {
  axios({
    url: url,
    method: 'post',
    data: params,
    timeout: 180000,
    responseType: 'blob',
    headers: {
      Authorization: 'Bearer ' +  getToken()
    }
  }).then(res => {
    cb()
    resolveBlob(res, mimeMap.xlsx, fileName)
  })
}
export const downloadPDF = (url, params, fileName, cb) => {
  axios({
    url: url + '?'  + qs.stringify(params, { arrayFormat: 'repeat' }),
    method: 'get',
    timeout: 180000,
    responseType: 'blob',
    headers: {
      Authorization: 'Bearer ' +  getToken()
    }
  }).then(res => {
    cb()
    resolveBlob(res, mimeMap.pdf, fileName)
  })
}
/**
 * 代码生成并下载为zip
 * @param {String} url 链接
 * @param {String} tables 表名
 */
export const genCodeZip = (url, tables) => {
  axios({
    url: url,
    method: 'get',
    params: { tables: tables },
    responseType: 'blob'
  }).then(res => {
    resolveBlob(res, mimeMap.zip)
  })
}

/**
 * 解析blob响应内容并下载
 * @param {*} res blob响应内容
 * @param {String} mimeType MIME类型
 */
export const resolveBlob = (res, mimeType, exportFileName) => {
  const aLink = document.createElement('a')
  let blob = new Blob([res.data], { type: mimeType })
  // //从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
  // let patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
  // let contentDisposition = decodeURI(res.headers['content-disposition'])
  // let result = patt.exec(contentDisposition)
  let fileName = exportFileName
  aLink.href = URL.createObjectURL(blob)
  aLink.setAttribute('download', fileName) // 设置下载文件名称
  document.body.appendChild(aLink)
  aLink.click()
  document.body.appendChild(aLink)
  URL.revokeObjectURL(aLink.href);// 释放URL 对象
}
