<template>
  <el-dialog :title="title" :visible="visible" :close-on-click-modal="false" :before-close="close">
    <el-descriptions :column="3" border :label-style="{width: '15%'}" :content-style="{width: '18%'}">
      <el-descriptions-item label="车牌号">{{items.number}}</el-descriptions-item>
      <el-descriptions-item label="联系人">{{items.owner}}</el-descriptions-item>
      <el-descriptions-item label="联系电话">{{items.phone}}</el-descriptions-item>
      <el-descriptions-item label="车辆类型">{{items.carTypeName}}</el-descriptions-item>
      <el-descriptions-item label="收费类型">{{items.carChargeTypeName}}</el-descriptions-item>
      <el-descriptions-item label="是否新能源">{{items.isNewenergy ? '是' : '否'}}</el-descriptions-item>
      <el-descriptions-item label="是否买保险">{{items.isInsure ? '是' : '否'}}</el-descriptions-item>
      <el-descriptions-item label="状态">{{items.status === 1 ? '启用' : '禁用'}}</el-descriptions-item>
      <el-descriptions-item label="品牌">{{items.brand}}</el-descriptions-item>
      <el-descriptions-item label="排量">{{items.displacementPower}}</el-descriptions-item>
      <el-descriptions-item label="VIN码">{{items.vinCode}}</el-descriptions-item>
      <el-descriptions-item label="发动机号">{{items.engineNumber}}</el-descriptions-item>
      <el-descriptions-item label="开始时间">{{items.startTime}}</el-descriptions-item>
      <el-descriptions-item label="结束时间">{{items.endTime}}</el-descriptions-item>
      <el-descriptions-item label="储值余额">{{items.balance}}</el-descriptions-item>
      <el-descriptions-item label="绑定时间">{{items.bindTime}}</el-descriptions-item>
    </el-descriptions>
    <el-descriptions :column="1" border :label-style="{width: '15.1%'}" style="border-top: none">
      <el-descriptions-item label="关联收费">
        <div v-for="elem in items.relationChargeItems" :key="elem.id">{{elem.chargeItemName}}</div>
      </el-descriptions-item>
      <el-descriptions-item label="备注">{{items.remark}}</el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</template>

<script>
export default {
  name: 'CarDetail',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {

    }
  },
  computed: {
    title () {
      return this.items.number + '车辆详情'
    }
  },
  mounted () {},
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
    }
  }
}
</script>

<style scoped lang="scss">

</style>
