<template>
  <div>
    <div class="flex-h flex-between">
      <el-page-header
        class="p-d-10"
        title="返回"
        @back="$router.back()"
        :content="`${query.projectName}-项目配置`"
      ></el-page-header>
      <i @click="$router.back()" class="el-icon-close"></i>
    </div>
    <el-tabs v-model="activeName" @tab-click="handlePay">
      <el-tab-pane v-for="item in dict.ProjectBasicsConfigType" :label="item.displayName" :key="item.value" :name="`config${item.value}`"></el-tab-pane>
      <div class="flex-h flex-wrap flex-between">
        <el-card v-for="item in sortConfigList" :key="item.code" v-show="`config${item.configType}` === activeName" style="margin-bottom: 10px; width: 48%">
          <div class="flex-h space-between">
            <div>
              <div class="flex-h">
                <div style="margin-right: 10px;">{{item.label}}</div>
                <el-input v-model="item.value" v-if="item.type === 1" style="width: 200px" placeholder=""></el-input>
                <el-input-number v-model="item.value" v-if="item.type === 2" placeholder=""></el-input-number>
                <vxe-switch open-label="开启" close-label="关闭" open-value="true" close-value="false" v-model="item.value" v-if="item.type === 3"></vxe-switch>
                <el-select v-model="item.value" placeholder="" v-if="item.type === 4" style="width: 200px">
                  <el-option v-for="elem in item.selectValues" :key="elem.key" :label="elem.value" :value="elem.key"></el-option>
                </el-select>
                <el-select v-model="item.value" placeholder="请选择" v-if="item.type === 5" style="width: 200px" multiple>
                  <el-option v-for="elem in item.selectValues" :key="elem.key" :label="elem.value" :value="elem.key"></el-option>
                </el-select>
              </div>
              <div v-if="item.code === 'DoesOpenOfficialAccount'" class="flex-h" v-show="item.value === 'true'">
                <div>公众号配置：</div>
                <el-select v-model="item.weiXinConfigId" placeholder="请选择" style="margin-right: 10px">
                  <el-option v-for="item in wxDownList" :key="item.configId" :value="item.configId" :label="item.configName"></el-option>
                </el-select>
                <el-button type="text" @click="viewQrCode(item, '公众号二维码')">查看公众号二维码</el-button>
                <el-button type="text" @click="viewProjectQrCode(item, '查看绑定项目房间二维码')">查看绑定项目房间二维码</el-button>
              </div>
              <div style="margin-top: 10px" v-show="item.remarks">说明：{{item.remarks}}</div>
            </div>
            <el-button type="primary" @click="submit(item)" :loading="item.loading">保存</el-button>
          </div>
        </el-card>
      </div>
      <el-tab-pane label="支付配置" name="pay">
        <el-button type="primary" @click="add" v-hasPermi="['project:areaConfig1:addPayConfig']">新增</el-button>
        <my-table :columns="columns" ref="payTable" url="/OnlinePaymentConfig/GetProjectConfigList" :auto-request="false">
          <div slot="isDefault" slot-scope="{row}">
            <vxe-switch @change="changeDefault($event, row)" open-label="是" close-label="否" :open-value="true" :close-value="false" v-model="row.isDefault">
            </vxe-switch>
          </div>
          <div slot="status" slot-scope="{row}">
            <vxe-switch @change="changeStatus(row)" open-label="启用" close-label="禁用" :open-value="1" :close-value="2" v-model="row.status"></vxe-switch>
          </div>
          <vxe-table-column title="操作" width="160">
            <template #default="{row}">
              <el-button type="text" :loading="row.loading" @click="edit(row)" v-hasPermi="['project:areaConfig1:editPayConfig']">编辑</el-button>
              <!-- <el-button type="text" :loading="row.loading" @click="copyEdit(row)">复制新增</el-button> -->
            </template>
          </vxe-table-column>
        </my-table>
      </el-tab-pane>
      <el-tab-pane label="停车场配置" name="parkingLot">
        <el-button type="primary" @click="visibleParkingLot = true" v-hasPermi="['project:areaConfig1:addParkingLot']">新增</el-button>
        <el-button type="primary" @click="initCar" v-hasPermi="['project:areaConfig1:init']">初始化项目车位车辆道闸</el-button>
        <my-table :columns="columnsParkingLot" auto-request ref="myTable" url="/ParkingLot/QueryPagerParkingLot" :query="{projectId: query.projectId}">
          <vxe-table-column title="操作" width="180">
            <template #default="{row}">
              <el-button type="text" :loading="row.loading" @click="editParkingLot(row)" v-hasPermi="['project:areaConfig1:editParkingLot']">编辑</el-button>
              <el-button type="text" :loading="row.loading" @click="gateConfig(row)" v-hasPermi="['project:areaConfig1:gateConfig']">道闸配置</el-button>
            </template>
          </vxe-table-column>
        </my-table>
        <parking-lot-edit :visible.sync="visibleParkingLot" :items.sync="items" @refresh="refreshParkingLot"/>
        <parking-lot-gate :visible.sync="visibleGate" :items.sync="items" @refresh="refreshParkingLot"/>
      </el-tab-pane>
    </el-tabs>
    <area-pay-config-edit :visible.sync="visiblePay" :projectId="query.projectId" :items.sync="items" @refresh="refresh"/>
    <el-dialog :title="title" :visible.sync="visibleQrCode" :before-close="closeQrcode" width="340px">
      <vue-qr
        ref="qrCode"
        :text="qrUrl"
        :size="300"
        :margin="10"
        :logoScale="0.2">
      </vue-qr>
    </el-dialog>
  </div>
</template>

<script>
import {
  projectConfigEdit,
  projectConfig,
  projectPayConfigIsdefault,
  projectPayConfigStatus,
  projectPayConfigGetDetail,
  wxConfigDown,
  initCarGate
} from '@/api/project'
import { mapGetters } from 'vuex'
import AreaPayConfigEdit from './components/AreaPayConfigEdit'
import ParkingLotEdit from './components/ParkingLotEdit'
import ParkingLotGate from './components/ParkingLotGate'
import VueQr from 'vue-qr'

export default {
  name: 'AreaConfigure',
  components: {AreaPayConfigEdit, ParkingLotEdit, ParkingLotGate, VueQr},
  data () {
    return {
      activeName: 'config1',
      loading: false,
      visibleParkingLot: false,
      visiblePay: false, // 打开、隐藏支付配置窗口
      tableData: [], // 支付配置表格数据
      columns: [
        {
          label: '名称',
          prop: 'name'
        },
        {
          label: '项目名称',
          prop: 'projectName'
        },
        {
          label: '通道类型',
          prop: 'passTypeName'
        },
        {
          label: '是否默认',
          prop: 'isDefault',
          operate: true
        },
        {
          label: '状态',
          prop: 'status',
          operate: true
        },
        {
          label: '费率',
          prop: 'serviceRate'
        },
        {
          label: '创建日期',
          prop: 'createdTime'
        }
      ],
      columnsParkingLot: [
        {
          label: '项目名称',
          prop: 'projectName'
        },
        {
          label: '停车场名称',
          prop: 'name'
        },
        {
          label: '状态',
          prop: 'statusName',
          operate: true,
          render: (h, {row}) => {
            let type = ''
            if (row.status) {
              type = {
                1: 'success',
                2: 'danger',
                3: 'info'
              }[row.status]
            }
            return h('el-tag', {
              props: {
                type: type
              }
            }, row.statusName)
          }
        },
        {
          label: '创建时间',
          prop: 'createdTime'
        },
        {
          label: '备注',
          prop: 'remark'
        },
      ],
      configList: [], // 基础配置
      wxDownList: [],
      visibleGate: false,
      items: {}, // 表单数据
      qrUrl: '',
      visibleQrCode: false,
      title: '',
      wxServer: process.env.VUE_APP_BASE_MOBILE,
    }
  },
  computed: {
    query () {
      return this.$route.query || {}
    },
    ...mapGetters(['dict']),
    sortConfigList () {
      const {configList} = this
      return configList.sort((a, b) => {
        let a1 = a['sortNumber']
        let b1 = b['sortNumber']
        return b1 - a1
      }) ?? []
    },
  },
  mounted () {
    this.getProject()
    wxConfigDown().then(res => {
      this.wxDownList = res.data
    })
  },
  methods: {
    getProject () {
      const {query} = this
      projectConfig({projectId: query.projectId}).then(res => {
        this.configList = res.data.map(x => {
          return {
            ...x,
            loading: false,
            value: x.type === 5 ? x.value.split(',') : x.value
          }
        })
        this.configList.forEach(item => {
          if (item.code === 'DoesOpenOfficialAccount' && query.configId !== null) {
            item.weiXinConfigId = query.configId
          }
        })
      })
    },
    submit (item) {
      const {query} = this
      item.loading = true
      let data = {
        code: item.code,
        projectId: query.projectId,
        value: item.type === 5 ? item.value.join(',') : item.value
      }
      if (item.code === 'DoesOpenOfficialAccount') {
        data.weiXinConfigId = item.weiXinConfigId
      }
      projectConfigEdit(data).then(res => {
        item.loading = false
        this.$message.success(res.message)
      }).catch(() => {
        item.loading = false
      })
    },
    handlePay () {
      const {activeName} = this
      if (activeName === 'pay') {
        this.getPayConfig()
      }
    },
    getPayConfig () {
      const {query} = this
      this.$refs.payTable.refresh({projectId: query.projectId})
    },
    refresh () {
      this.getPayConfig()
    },
    refreshParkingLot () {
      this.$refs.myTable.refresh()
    },
    changeDefault (e, row) {
      projectPayConfigIsdefault({id: row.id, isDefault: e.value}).then(res => {
        this.$message.success(res.message)
        this.getPayConfig()
      }).catch(() => {
        row.isDefault = !row.isDefault
      })
    },
    changeStatus (row) {
      projectPayConfigStatus({id: row.id, status: row.status === 1}).then(res => {
        this.$message.success(res.message)
        this.getPayConfig()
      }).catch(() => {
        row.status = !row.status
      })
    },
    edit (row) {
      row.loading = true
      projectPayConfigGetDetail({id: row.id}).then(res => {
        row.loading = false
        this.visiblePay = true
        this.items = res.data
      }).catch(() => {
        row.loading = false
      })
    },
    editParkingLot(row) {
      this.items = row
      this.visibleParkingLot = true
    },
    add () {
      this.visiblePay = true
    },
    gateConfig (row) {
      this.items = row
      this.visibleGate = true
    },
    viewQrCode (data, title) {
      let item = this.wxDownList.find(x => x.configId === data.weiXinConfigId)
      this.qrUrl = item.weiXinNumber
      this.title = title
      this.visibleQrCode = true
    },
    closeQrcode () {
      this.visibleQrCode = false
      this.qrUrl = ''
      this.title = ''
    },
    viewProjectQrCode (item, title) {
      this.qrUrl = this.wxServer + `/index.html?configId=${item.weiXinConfigId}#/bindRoom?projectId=${this.query.projectId}`
      this.title = title
      this.visibleQrCode = true
    },
    initCar () {
      const msg = `
        <div>1.当前操作属于重要高权限操作仅限admin才能执行</div>
        <div>2.初始化操作请先手动清空车场道闸的车位和车辆信息，确保完全删除了再来执行</div>
        <div>3.道闸初始化操作无法回滚还原，执行之前确保此操作是必要的且正确的</div>
      `
      this.$confirm(msg, '提示', {
        dangerouslyUseHTMLString: true
      }).then(() => {
        initCarGate({projectId: this.query.projectId}).then(res => {
          this.$message.success(res.message)
          this.refreshParkingLot()
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.space-between {
  justify-content: space-between;
}
</style>
