import axios from 'axios'
import { Notification, Message, MessageBox, Loading } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import router from '../router'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 600000,
})
let loadingInstance = null
let loading = false
// request拦截器
service.interceptors.request.use(config => {
  loading = config.loading || false
  if (loading) {
    loadingInstance = Loading.service({
      lock: true,
      text: '加载中，请稍等...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
  }
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?'
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName]
      let part = encodeURIComponent(propName) + "="
      if (value !== null && typeof (value) !== "undefined") {
        if (typeof value === 'object') {
          // eslint-disable-next-line max-depth
          for (const key of Object.keys(value)) {
            let params = propName + '[' + key + ']'
            let subPart = encodeURIComponent(params) + "="
            url += subPart + encodeURIComponent(value[key]) + "&"
          }
        } else {
          url += part + encodeURIComponent(value) + "&"
        }
      }
    }
    url = url.slice(0, -1)
    config.params = {}
    config.url = url
  }
  return config
}, error => {
  setTimeout(() => {
    loadingInstance.close()
  }, 500)
  Promise.reject(error)
})

// 响应拦截器
let count = 0
service.interceptors.response.use(res => {
  if (res.config.loading) {
    setTimeout(() => {
      loadingInstance.close()
    }, 500)
  }
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200
  // 获取错误信息
  if (code !== 200) {
    count++
  }
  console.log(location)
  const msg = errorCode[code] || res.data.message
  if ([401].includes(code) && count === 1) {
    MessageBox.confirm(msg, '系统提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      count = 0
      store.dispatch('FedLogOut').then(() => {
        // location.href = '/#/login'
        const hash = location.hash
        if (hash === '#/chatMsg') {
          router.replace({
            path: '/login',
            query: {
              redirect: 'chatMsg'
            }
          })
        } else {
          router.replace('/login')
        }
      })
      // location.href = '/#/login'
    }).catch(() => {
      count = 0
    })
  } else if (code === 500) {
    count = 0
    Message({
      message: msg,
      type: 'error'
    })
    return Promise.reject(new Error(msg))
  } else if (code !== 200 && count === 1) {
    Notification.error({
      title: msg
    })
    return Promise.reject('error')
  } else {
    return res.data
  }
}, error => {
  setTimeout(() => {
    loadingInstance.close()
  }, 500)
  let { message } = error
  if (message == "Network Error") {
    message = "后端接口连接异常"
  } else if (message.includes("timeout")) {
    message = "系统接口请求超时"
  } else if (message.includes("Request failed with status code")) {
    message = "系统接口" + message.substr(message.length - 3) + "异常"
  }
  Message({
    message: message,
    type: 'error',
    duration: 5 * 1000
  })
  return Promise.reject(error)
})

export default service
