/* 房间 */
<template>
  <div class="custom-flex">
    <el-card>
      <div slot="header" class="clearfix">
        <span>{{name}}房屋树</span>
        <el-button style="float: right; padding: 3px 0" type="text">导入</el-button>
      </div>
      <div style="width: 300px">
        <el-input placeholder="输入关键字进行过滤" v-model="filterText"></el-input>
        <el-tree :data="roomData" node-key="id" :expand-on-click-node="false" :filter-node-method="filterNode" ref="tree">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span>
              <el-button
                type="text"
                size="mini"
                @click="() => remove(node, data)">
                删除
              </el-button>
            </span>
          </span>
        </el-tree>
      </div>
    </el-card>
    <div class="room-form">
      <el-card>
        <div slot="header" class="clearfix">
          <span>手动添加</span>
        </div>
        <el-form :model="form">
          <el-form-item label="楼栋">
            <el-select v-model="form.d" placeholder="请选择">
            </el-select>
          </el-form-item>
          <el-form-item label="单元">
            <el-select v-model="form.d" placeholder="请选择">
            </el-select>
          </el-form-item>
          <el-form-item label="楼层">
            <el-select v-model="form.d" placeholder="请选择">
            </el-select>
          </el-form-item>
          <el-form-item label="房屋编号">
            <el-input v-model="form.roomNumber" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="width:100%;" :loading="loading">确定</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'rooms',
  data () {
    return {
      filterText: '',
      form: {},
      loading: false,
      roomData: []
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  computed: {
    query () {
      return this.$route.query || {}
    },
    name () {
      const {query} = this
      return decodeURIComponent(query.name)
    }
  },
  mounted () {
    let rooms = []
    for (let index = 0; index < 27; index++) { // 栋
      rooms.push({
        id: Math.random().toString(36).slice(-6),
        label: index + 1 + '栋',
        children: []
      })
      for (let x = 0; x < 4; x++) { // 单元
        rooms[index].children.push({
          id: Math.random().toString(36).slice(-6),
          label: x + 1 + '单元',
          children: []
        })
        for (let y = 0; y < 32; y++) { // 层
          for (let z = 0; z < 4; z++) { // 户
            rooms[index].children[x].children.push({
              id: Math.random().toString(36).slice(-6),
              label: index + 1 + '-' + (x + 1) + '-' + (y + 1) + '0' + (z + 1),
            })
          }
        }
      }
    }
    this.roomData = rooms
  },
  methods: {
    remove (node, data) {
      this.$confirm(`确定删除<span style="color: #da251c">${data.label}</span>及以下所有数据吗？`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        const parent = node.parent
        const children = parent.data.children || parent.data
        const index = children.findIndex(d => d.id === data.id)
        children.splice(index, 1)
      })
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    }
  }
}
</script>

<style scoped lang="scss">
.custom-flex {
  display: flex;
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
  .room-form {
    margin-left: 40px;
  }
}
</style>