<template>
  <el-dialog
    :title="`${Array.isArray(items.projectIds) ? '批量' : ''}修改项目到期时间`"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :before-close="close"
    width="600px">
    <el-form :model="form" ref="form" label-width="140px">
      <el-form-item label="合同约定到期时间">
        <el-date-picker v-model="form.ggreementEndTime" @change="changeTime" value-format="yyyy-MM-dd" placeholder="请选择"></el-date-picker>
      </el-form-item>
      <el-form-item label="系统停机时间">
        <el-date-picker
          v-model="form.useEndTime"
          :picker-options="{disabledDate (time) {return time.getTime() <= new Date(form.ggreementEndTime).getTime() - 24 * 3600 * 1000}}"
          value-format="yyyy-MM-dd"
          placeholder="如不限制则不填">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="续期">
        <el-input-number style="width: 80px" v-model="yearly" :controls="false" :min="0" :max="12" step-strictly></el-input-number>年
        <el-input-number style="width: 80px" v-model="quarter" :controls="false" :min="0" :max="4" step-strictly></el-input-number>季度
        <el-input-number style="width: 80px" v-model="month" :controls="false" :min="0" :max="12" step-strictly></el-input-number>月
        <el-button type="primary" @click="ok">计算</el-button>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {editUseDate} from '@/api/project'
export default {
  name: 'AreaEditUseDate',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      form: {
        useEndTime: null,
        ggreementEndTime: ''
      },
      loading: false,
      month: undefined,
      yearly: undefined,
      quarter: undefined
    }
  },
  watch: {
    visible (value) {
      if (value) {
        this.form.ggreementEndTime = this.items.ggreementEndTime
        this.form.useEndTime = this.items.useEndTime
      }
    }
  },
  computed: {
    title () {
      return Array.isArray(this.items.projectIds) ? '批量修改项目到期时间' : '修改项目到期时间'
    }
  },
  mounted () {},
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
      this.month = undefined,
      this.yearly = undefined,
      this.quarter = undefined
    },
    submit () {
      this.$confirm('确认执行此操作？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        editUseDate({...this.form, projectIds: this.items.projectIds}).then(res => {
          this.$message.success(res.message)
          this.loading = false
          this.$emit('refresh')
          this.close()
        }).catch(() => {
          this.loading = false
        })
      })
    },
    changeTime () {
      this.form.useEndTime = ''
    },
    ok () {
      if (!this.quarter && !this.yearly && !this.month) {
        this.$message.error('请输入值再点计算')
        return
      }
      const quarter = (this.quarter * 3) || 0 // 季度，3倍
      const yearly = this.yearly * 12 || 0 // 年，12倍
      const countMonth = yearly + quarter + (this.month || 0) // 总月数
      const ggreementEndTime = new Date(this.items.ggreementEndTime)
      ggreementEndTime.setMonth(ggreementEndTime.getMonth() + countMonth) // 增加月
      this.form.useEndTime = this.getData(ggreementEndTime)
      this.form.ggreementEndTime = this.getData(ggreementEndTime)
    },
    getData (val) { // 时间戳转换为年月日
      let n = new Date(val)
      return n.toLocaleDateString().replace(/\//g, '-')
    }
  }
}
</script>

<style scoped lang="scss">

</style>
