<template>
  <div>
    <my-search :items="searchItems" @search="search" @clear="clear">
      <el-button slot="btn" type="primary" @click="visible = true" v-hasPermi="['sms:smsTemplate:add']">新增</el-button>
    </my-search>
    <my-table :columns="columns" ref="myTable" url="/SMSTemplate/QueryTemplatePagerList">
      <vxe-table-column title="操作" width="180px">
        <template #default="{row}">
          <el-button type="text" v-hasPermi="['sms:smsTemplate:edit']" @click="edit(row)">编辑</el-button>
          <el-button type="text" v-hasPermi="['sms:smsTemplate:updateStatus']" @click="updateStatus(row)">更新模板状态</el-button>
          <el-button type="text" v-hasPermi="['sms:smsTemplate:del']" @click="del(row)">删除</el-button>
        </template>
      </vxe-table-column>
    </my-table>
    <sms-template-edit :visible.sync="visible" :items.sync="items" @refresh="refresh"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SmsTemplateEdit from './components/SmsTemplateEdit'
import {updateSmsTmpStatus, smsTemplateDel} from '@/api/sms'
import {companyList} from '@/api/project'

export default {
  name: 'smsTemplate',
  components: {
    SmsTemplateEdit
  },
  data () {
    return {
      columns: [
        {
          label: '公司名称',
          prop: 'companyName'
        },
        {
          label: '项目名称',
          prop: 'projectName',
          width: 100
        },
        {
          label: '模板类型名称',
          prop: 'templateTypeName',
          width: 100
        },
        {
          label: '标题',
          prop: 'title',
          width: 100
        },
        {
          label: '模板内容',
          prop: 'content',
          minWidth: 200
        },
        {
          label: '状态',
          prop: 'statusName',
          width: 100,
          operate: true,
          render: (h, {row}) => {
            let type = ''
            if (row.status) {
              type = {
                1: '', // 待审核
                2: 'success', // 成功
                3: 'warning',
              }[row.status]
            }
            return h('el-tag', {
              props: {
                type: type
              }
            }, row.statusName)
          }
        },
        // {
        //   label: '备注',
        //   prop: 'notes'
        // },
        {
          label: '创建日期',
          prop: 'createdTime',
          width: 140
        }
      ],
      items: {},
      visible: false,
      queryData: {},
      projectList: []
    }
  },
  computed: {
    ...mapGetters(['dict', 'companyTree']),
    searchItems () {
      const {dict, companyTree, projectList} = this
      return [
        {
          type: 'selectTree',
          label: '公司名称/编号：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany,
          style: 'margin-right: 0'
        },
        {
          type: 'select',
          label: '',
          name: 'projectId',
          options: projectList
        },
        {type: 'select', label: '模板类型：', name: 'type', options: dict.SMSTemplateType},
        {type: 'select', label: '审核状态：', name: 'status', options: dict.SMSTemplateStatus},
      ]
    }
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
  },
  methods: {
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    edit (row) {
      this.items = row
      this.visible = true
    },
    updateStatus (row) {
      updateSmsTmpStatus({id: row.id}).then(res => {
        this.$message.success(res.message)
        this.getList()
      })
    },
    refresh () {
      this.$refs.myTable.refresh(this.queryData)
    },
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    changeCompany (data) {
      this.$store.dispatch('getCompanyDownProject', {companyId: data.id, containsClosed: true}).then(res => {
        this.projectList = res
      })
    },
    del (row) {
      this.$confirm(`是否删除短信模板<span style="color: #da251c">${row.title}</span>吗?`, '提示', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        smsTemplateDel({id: row.id}).then(res => {
          this.$message.success(res.message)
          this.refresh()
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
