<!-- 回复记录 -->
<template>
  <div>
    <my-search :items="searchItems" @search="search" @clear="clear" :hideLength="4"></my-search>
    <my-table :columns="columns" ref="myTable" url="/SMSSendDetails/QueryBackReplyPager">
      <div slot="companyName" slot-scope="{row}">
        {{ row.companyName }}{{ row.companyAlias }}
      </div>
    </my-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import {companyList} from '@/api/project'
export default {
  name: 'replyLogs',
  components: {},
  data () {
    return {
      projectList: [],
      columns: [
        {
          label: '公司名称',
          prop: 'companyName',
          operate: true,
          width: 140
        },
        {
          label: '项目名称',
          prop: 'projectName',
          width: 100
        },
        {
          label: '短信内容',
          prop: 'content',
        },
        {
          label: '发送号码',
          prop: 'phone',
          width: 100
        },
        {
          label: '发送时间',
          prop: 'sendTime',
          width: 140
        },
        {
          label: '回复内容',
          prop: 'replyContents',
          width: 200
        },
        {
          label: '回复时间',
          prop: 'replyTime',
          width: 140
        },
      ]
    }
  },
  computed: {
    ...mapGetters(['companyTree']),
    searchItems () {
      const {companyTree, projectList} = this
      return [
        {
          type: 'selectTree',
          label: '公司名称/编号：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany,
          style: 'margin-right: 0'
        },
        {
          type: 'select',
          label: '',
          name: 'projectId',
          options: projectList
        },
        {type: 'input', label: '发送记录id：', name: 'smsId'},
        {type: 'input', label: '手机号/短信内容/回复内容：', name: 'keyWorks'},
        {type: 'datePicker', label: '开始时间：', name: 'startDate'},
        {type: 'datePicker', label: '结束时间：', name: 'endDate'},
      ]
    }
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
  },
  methods: {
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    changeCompany (data) {
      this.$store.dispatch('getCompanyDownProject', {companyId: data.id, containsClosed: true}).then(res => {
        this.projectList = res
      })
    },
    search (query) {
      this.$refs.myTable.search(query)
    },
    clear () {
      this.$refs.myTable.reset()
    }
  }
}
</script>

<style scoped lang="scss">

</style>
