<template>
  <el-dialog title="支付配置应用" :visible.sync="visible" :before-close="close" :close-on-click-modal="false" width="30%">
    <div class="flex-h">
      <div>应用项目：</div>
      <treeselect
        style="flex: 1"
        v-model="projectId"
        search-nested
        :disable-branch-nodes="true"
        :show-count="true"
        multiple
        :normalizer="normalizer"
        :options="projectList"
        no-results-text="无搜索结果"
        no-children-text="无子节点"
        no-options-text="无可用选项"
        placeholder="请选择" />
    </div>
    <div style="margin-top: 20px;">友情提示: 应用不会新增配置，是覆盖选择项目下相同名称的配置信息</div>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {companyGetProjectTree, payConfigUseProject} from '@/api/project'

export default {
  name: 'AreaPayUse',
  components: {},
  props: {
    items: {
      type: Object,
      default: () => {
        return {}
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      projectList: [],
      projectId: []
    }
  },
  watch: {
    visible (value) {
      if (value) {
        this.getCompanyProject()
      }
    }
  },
  mounted () {},
  methods: {
    normalizer(node) {
      if (node.child === null) {
        delete node.child
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    getCompanyProject () {
      companyGetProjectTree({companyId: this.items.parentCompanyId}).then(res => {
        this.projectList = res.data
      })
    },
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
    },
    submit () {
      payConfigUseProject({configId: this.items.id, projectId: this.projectId}).then(res => {
        this.$message.success(res.data)
        this.$emit('refresh')
        this.close()
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
