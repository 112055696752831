<template>
  <el-dialog :title="`${items.projectName}任务详情`" :visible.sync="visible" :close-on-click-modal="false" :before-close="close" width="50%">
    <el-form :model="items" label-width="100px">
      <el-form-item label="任务名称:">{{items.companyName}}-{{items.projectName}}</el-form-item>
      <el-form-item label="导入类型:">{{items.importTypeName}}</el-form-item>
      <el-form-item label="导入文件:">
        <a :href="url + items.importFilePath" class="link">点击下载</a>
      </el-form-item>
      <el-form-item label="状态:">
        <el-tag :type="getTagType(items.importStatus)">{{items.importStatusName}}</el-tag>
      </el-form-item>
      <el-form-item label="开始时间:">{{items.operationTime}}</el-form-item>
      <el-form-item label="执行结果:">
        <el-input v-model="items.result" readonly type="textarea" :autosize="{ minRows: 10, maxRows: 15}"></el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'ImportTaskDetail',
  props: {
    items: {
      type: Object,
      default: () => {
        return {}
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      url: process.env.VUE_APP_BASE_OLDIMG
    }
  },
  mounted () {},
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
    },
    getTagType (status) {
      if (status) {
        return {
          3: 'success',
          2: 'warning',
          1: ''
        }[status]
      }
      return ''
    }
  }
}
</script>

<style scoped lang="scss">
.link {
  text-decoration: none;
  color: #409EFF
}
</style>
