<!-- 设置公司配置，logo，标题 -->
<template>
  <el-dialog title="设置公司配置" :visible.sync="visible" :before-close="close" :close-on-click-modal="false" width="30%">
    <el-form :model="form" ref="form" label-width="140px">
      <el-form-item label="自定义logo" prop="logicLogo">
        <my-upload-file v-model="form.logicLogo"/>
        <div>建议上传：372x124px</div>
      </el-form-item>
      <el-form-item label="自定义系统标题" prop="logicTitle">
        <el-input v-model="form.logicTitle" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="submit" :loading="loading" type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {companyConfig} from '@/api/system'
import MyUploadFile from '@/components/MyUploadFile'

const initData = {
  logicLogo: '',
  logicTitle: ''
}
export default {
  name: 'CompanyConfig',
  components: {MyUploadFile},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return ''
      }
    }
  },
  data () {
    return {
      form: {...initData},
      loading: false,
    }
  },
  watch: {
    visible (value) {
      if (value) {
        this.form = this.items
      }
    }
  },
  mounted () {},
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
    },
    submit () {
      this.loading = true
      companyConfig({...this.form, companyId: this.items.id}).then(res => {
        this.loading = false
        this.$message.success(res.message)
        this.$emit('refresh')
        this.close()
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
