import Vue from 'vue'
import Router from 'vue-router'
const layout = () => import('@/layout')
const login = () => import(/* webpackChunkName: 'login' */ '@/views/login') // 登录页
const home = () => import(/* webpackChunkName: 'home' */ '@/views/home') // 首页
const areaConfig = () => import(/* webpackChunkName: 'areaConfig' */ '@/views/project/areaConfig') // 项目配置
const noticeEdit = () => import('@/views/system/notice/edit') // 公告新增/编辑
const wxMenu = () => import(/* webpackChunkName: 'wxMenu' */ '@/views/project/wxMenu') // 微信菜单配置
const chatMsg = () => import(/* webpackChunkName: 'chatMsg' */ '@/views/chatMsg') // 消息页

import {project} from './project'
import {system} from './system'
import {sms} from './sms'
import {statement} from './statement'
import {print} from './print'
Vue.use(Router)

export const constantRoutes = {
  ...project,
  ...system,
  ...sms,
  ...statement,
  ...print
}

export const routerMap = [
  {
    name: 'login',
    path: '/login',
    component: login
  },
  {
    name: 'chatMsg',
    path: '/chatMsg',
    component: chatMsg
  },
  {
    path: '/',
    component: layout,
    hidden: true,
    redirect: '/login',
    children: [
      {
        name: 'home',
        path: '/home',
        component: home,
        meta: {
          hidden: true
        }
      },
      {
        name: 'areaConfig',
        path: '/areaConfig',
        component: areaConfig,
        meta: {
          hidden: true
        }
      },
      {
        name: 'noticeEdit',
        path: '/noticeEdit',
        component: noticeEdit,
        meta: {
          title: '公告管理',
          hidden: false
        }
      },
      {
        name: 'wxMenu',
        path: '/wxMenu',
        component: wxMenu,
        meta: {
          title: '微信菜单配置',
          hidden: false
        }
      },
    ]
  },
]

export default new Router({
  scrollBehavior: () => ({ y: 0 }),
  routes: routerMap
})

