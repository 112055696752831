<template>
  <div>
    <div class="btn-group flex-h">
      <el-button type="primary" @click="editMenu(1)" v-hasPermi="['system:companyRoles:add']">添加权限</el-button>
      <el-button type="primary" @click="editMenu(2)" v-hasPermi="['system:companyRoles:del']">删除权限</el-button>
      <el-button type="primary" @click="view">查看菜单</el-button>
      <div class="flex-h" style="align-items: center; margin-left: 20px;">
        <div>菜单标签类型：</div>
        <el-select v-model="functionFlagTypes" placeholder="请选择" multiple @change="changeFlag" collapse-tags>
          <el-option v-for="item in dict.FunctionFlagType" :key="item.value" :value="item.value" :label="item.displayName"></el-option>
        </el-select>
      </div>
    </div>
    <div class="flex-h">
      <div style="flex: 1">
        <div style="margin-bottom: 20px;">
          <el-radio-group v-model="activeName" @input="changeTab">
            <!-- id=2是运营平台 -->
            <el-radio-button v-for="item in menu" v-show="item.id !== '2'" :key="item.id" :label="item.functionId">{{ item.name }}</el-radio-button>
          </el-radio-group>
          <el-checkbox style="margin-left: 10px;" v-model="menuCheckStrictly" @change="handleCheckedTreeConnect($event)">父子联动</el-checkbox>
        </div>
        <vxe-table
          border
          ref="xTable"
          size="small"
          :data="tableData"
          height="640px"
          :row-config="{isCurrent: true, keyField: 'id'}"
          empty-text="暂无数据"
          :tree-config="{children: 'child', reserve: true, trigger: 'row', indent: 25, expandAll: true}">
          <vxe-table-column title="菜单目录" tree-node align="left" field="name" width="270px">
            <template #default="{row}">
              <el-checkbox
                v-model="row.isSelected"
                @change="changeCheckEvent($event, row)"
                :indeterminate="row.isIndeterminate"
                :key="row.id">
                <span :style="{color: row.functionFlagType !== 1 ? '' : '#da251c'}">{{row.name}}</span>
              </el-checkbox>
            </template>
          </vxe-table-column>
          <vxe-table-column title="新增" width="100" align="left" field="hasPermissionGroup">
            <template #default="{row}">
              <el-checkbox
                @change="changeCheckEventChild($event, row)"
                v-model="item.isSelected"
                v-if="item.functionFlagType === 3"
                v-for="(item, index) in row.hasPermissionGroup"
                :key="index">
                <span :style="{color: item.functionFlagType !== 1 ? '' : '#da251c'}">{{item.name}}</span>
              </el-checkbox>
            </template>
          </vxe-table-column>
          <vxe-table-column title="编辑" width="100" align="left" field="hasPermissionGroup">
            <template #default="{row}">
              <el-checkbox
                @change="changeCheckEventChild($event, row)"
                v-model="item.isSelected"
                v-if="item.functionFlagType === 4"
                v-for="(item, index) in row.hasPermissionGroup"
                :key="index">
                <span :style="{color: item.functionFlagType !== 1 ? '' : '#da251c'}">{{item.name}}</span>
              </el-checkbox>
            </template>
          </vxe-table-column>
          <vxe-table-column title="删除" width="100" align="left" field="hasPermissionGroup">
            <template #default="{row}">
              <el-checkbox
                @change="changeCheckEventChild($event, row)"
                v-model="item.isSelected"
                v-if="item.functionFlagType === 5"
                v-for="(item, index) in row.hasPermissionGroup"
                :key="index">
                <span :style="{color: item.functionFlagType !== 1 ? '' : '#da251c'}">{{item.name}}</span>
              </el-checkbox>
            </template>
          </vxe-table-column>
          <vxe-table-column title="导出" width="100" align="left" field="hasPermissionGroup">
            <template #default="{row}">
              <el-checkbox
                @change="changeCheckEventChild($event, row)"
                v-model="item.isSelected"
                v-if="item.functionFlagType === 6"
                v-for="(item, index) in row.hasPermissionGroup"
                :key="index">
                <span :style="{color: item.functionFlagType !== 1 ? '' : '#da251c'}">{{item.name}}</span>
              </el-checkbox>
            </template>
          </vxe-table-column>
          <vxe-table-column title="其他" align="left" field="hasPermissionGroup">
            <template #default="{row}">
              <el-checkbox
                @change="changeCheckEventChild($event, row)"
                v-model="item.isSelected"
                v-if="![3, 4, 5, 6].includes(item.functionFlagType)"
                v-for="(item, index) in row.hasPermissionGroup"
                :key="index">
                <span :style="{color: item.functionFlagType !== 1 ? '' : '#da251c'}">{{item.name}}</span>
              </el-checkbox>
            </template>
          </vxe-table-column>
        </vxe-table>
      </div>
      <div class="tree-width">
        <el-button type="primary" @click="checkAllCompany(true)">全选</el-button>
        <el-button type="primary" @click="checkAllCompany(false)">取消全选</el-button>
        <el-checkbox label="全字匹配（精确查询）" style="margin-left: 10px;" v-model="queryData.isWholeMatch"></el-checkbox>
        <div class="tree-box">
          <el-input placeholder="请输入内容" v-model="filterText" class="input-with-select">
            <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 120px;">
              <el-option label="公司名/编号" value="1"></el-option>
              <el-option label="角色名" value="2"></el-option>
            </el-select>
            <div slot="append">
              <el-button @click="search">查询</el-button>
              <el-button @click="clear">重置</el-button>
            </div>
          </el-input>
          <el-tree
            :data="companyTreeData"
            show-checkbox
            ref="menu"
            :default-expand-all="expandAll"
            node-key="id"
            :props="defaultProps">
          </el-tree>
        </div>
      </div>
    </div>
    <company-roles-view :visible.sync="visible" :items.sync="items"/>
  </div>
</template>

<script>
import {companyTree} from '@/api/system'
import CompanyRolesView from './components/CompanyRolesView'
import { mapGetters } from 'vuex'
import {getAllParentArr} from '@/utils/tools'

export default {
  name: 'companyRoles',
  components: {CompanyRolesView},
  data () {
    return {
      select: '1',
      companyTreeData: [], // 公司树菜单
      defaultProps: {
        children: 'child',
        label: 'name'
      },
      filterText: '', // 查询内容，公司名称/编号/角色
      treeData: [],
      menu: [], // 菜单
      checkAll: false, // 是否全选
      activeName: '2',
      // viewData: [],
      items: {},
      // nodeId: [],
      visible: false,
      queryData: {
        isWholeMatch: false,
      },
      expandAll: false, // 是否默认全部展开
      functionFlagTypes: [0, 1, 2, 3, 4, 5, 6],
      menuCheckStrictly: false // 是否父子联动选择
    }
  },
  computed: {
    // 公司树菜单转一维数组
    flatData () {
      return this.treeToflat(this.companyTreeData)
    },
    // 菜单选中
    checkedKeys () {
      if (this.tableData.length > 0) {
        return this.reduceDimension(this.tableData).filter(x => x.isSelected || x.isIndeterminate).map(x => x.functionId)
      }
      return []
    },
    tableData () {
      return this.generator(this.treeData.filter(x => x.functionId === this.activeName))
    },
    ...mapGetters(['dict'])
  },
  mounted () {
    this.getCompanyTree()
    this.getMenuTree()
  },
  methods: {
    getMenuTree () {
      this.$store.dispatch('getMenu', {functionFlagTypes: this.functionFlagTypes}).then(res => {
        this.menu = res.data.map(x => {
          return {
            name: x.name,
            functionId: x.functionId,
            id: x.id
          }
        })
        this.treeData = res.data
        this.changeTab()
      })
    },
    changeFlag () {
      this.getMenuTree()
    },
    search () {
      if (this.select === '1') {
        this.queryData.companyNameOrAlias = this.filterText
        delete this.queryData.roleName
      } else {
        delete this.queryData.companyNameOrAlias
        this.queryData.roleName = this.filterText
      }
      this.getCompanyTree()
    },
    clear () {
      this.queryData = {
        isWholeMatch: false
      }
      this.filterText = ''
      this.getCompanyTree()
    },
    reduceDimension (arr = []) {
      let ret = []
      let toArr = arr => {
        arr.forEach(item => {
          toArr(item.child && item.child.length > 0 ? item.child : (item.hasPermissionGroup || []))
          ret.push(item)
        })
      }
      toArr(arr)
      return ret
    },
    treeToflat (arr = [], ret = [],  key = '') {
      arr.forEach(item => {
        let currentkey =  key
        currentkey += item.name
        if (item.child) {
          this.treeToflat(item.child, ret, currentkey)
        }
        ret.push({
          name: currentkey,
          id: item.id,
          treeProjectType: item.treeProjectType
        })
      })
      return ret
    },
    getCompanyTree () {
      companyTree(this.queryData).then(res => {
        this.companyTreeData = res.data
        if (this.filterText) {
          setTimeout(() => {
            for (let i = 0; i < this.companyTreeData.length; i++) {
              this.$refs.menu.store.nodesMap[this.companyTreeData[i].id].expanded = true
            }
          }, 300)
        }
      })
    },
    changeTab () {
      setTimeout(() => {
        this.$refs.xTable.setAllTreeExpand(true)
      }, 200)
    },
    generator (arr = []) {
      return arr.map(item => {
        const data = {
          ...item,
          isIndeterminate: false,
          isSelected: this.checkAll || false
        }
        if (item.child && item.child.length > 0 && item.child[0].functionType !== 3) {
          data.child = this.generator(item.child)
        } else {
          data.hasPermissionGroup = data.child?.map(y => {
            return {
              ...y,
              isSelected: this.checkAll ? this.checkAll : false,
            }
          })
          delete data.child
        }
        return data
      })
    },
    changeCheckEventChild (value, row) {
      if (!this.menuCheckStrictly) {
        return
      }
      if (row.hasPermissionGroup.length === row.hasPermissionGroup.filter(x => x.isSelected).length) {
        row.isSelected = true
        row.isIndeterminate = false
      } else {
        row.isSelected = false
        row.isIndeterminate = row.hasPermissionGroup.filter(x => x.isSelected).length !== 0
      }
      this.setCheck(this.tableData, row, value)
    },
    // 树权限（父子联动）
    handleCheckedTreeConnect(value) {
      this.menuCheckStrictly = !!value
    },
    changeCheckEvent (value, row) {
      if (!this.menuCheckStrictly) {
        return
      }
      // 点击二级设置子全选
      if (row.child && row.child.length > 0) {
        row.isIndeterminate = false
        row.child.forEach(item => {
          console.log(11, row, item)
          // 只有不是禁用状态下才能选择
          item.isSelected = value || false
          if (item.hasPermissionGroup && item.hasPermissionGroup.length > 0) {
            item.hasPermissionGroup.forEach(x => {
              // 只有不是禁用状态下才能选择
              x.isSelected = value
            })
          } else {
            this.changeCheckEvent(value, item)
          }
        })
      } else {
        // 点击三级设置子全选
        row.hasPermissionGroup.forEach(item => {
          // 只有不是禁用状态下才能选择
          item.isSelected = value
        })
      }
      this.setCheck(this.tableData, row, value)
    },
    setCheck (treeData, row, value) {
      // 点击子集设置父级全选,数组第一个是点击的本身，第二个是父级，第三个是父级的父级，依次类推
      const parentList = getAllParentArr(treeData, row.functionId, 'functionId')
      console.log(parentList, 12)
      parentList.forEach(item => {
        let parentCheck = []
        // 排除设置子集，按钮组
        if (!item.hasPermissionGroup && !item.isSelected) {
          parentCheck = item.child.filter(x => x.isSelected)
          // 选中的和child长度相同，判断全选
          if (parentCheck.length === item.child.length) {
            item.isSelected = true
            item.isIndeterminate = false
          } else {
            item.isSelected = false
            // item.isIndeterminate = true
            if (parentCheck.length !== 0) {
              item.isIndeterminate = true
            }
            if (parentCheck.length === 0) {
              item.isIndeterminate = false
            }
            // item.isIndeterminate = childNoChick.length !== 0
            console.log(item, 99, parentCheck)
          }
        } else {
          let childNoChick = item.child?.filter(x => x.isSelected) || []
          if (!item.hasPermissionGroup) {
            // 有子集不选中，设置最上级不选中
            if (childNoChick.length !== item.child?.length) {
              item.isIndeterminate = true
            } else {
              item.isIndeterminate = false
            }
            item.isSelected = value
          } else {
            item.isSelected = value
          }
        }
      })
    },
    checkAllCompany (value) {
      this.$refs.menu.setCheckedNodes(value ? this.flatData : [])
    },
    getMenuAllCheckedKeys () {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs.menu.getCheckedKeys()
      // 半选中的菜单节点
      let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys()
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys)
      return checkedKeys
    },
    editMenu (value) {
      const roleIds = this.getMenuAllCheckedKeys() // 获取选中角色
      if (this.checkedKeys.length === 0 || roleIds.length === 0) {
        this.$message.error('请选择角色或菜单！')
        return
      }
      this.items = {
        roleIds: roleIds,
        functions: this.checkedKeys,
        operaType: value
      }
      this.visible = true
    },
    view () {
      const roleIds = this.getMenuAllCheckedKeys() // 获取选中角色
      if (this.checkedKeys.length === 0 || roleIds.length === 0) {
        this.$message.error('请选择角色或菜单！')
        return
      }
      this.items = {
        roleIds: roleIds,
        functions: this.checkedKeys,
      }
      this.visible = true
    },
  }
}
</script>

<style scoped lang="scss">
.flex-h {
  align-items: flex-start;
}
.tree-width {
  width: 30%;
  margin-left: 20px;
}
.tree-box {
  max-height: 680px;
  overflow-y: auto;
  margin-top: 20px;
}
.btn-group {
  margin-bottom: 20px;
}
</style>
