<template>
  <el-dialog title="新增反馈" :visible.sync="visible" :before-close="close" :close-on-click-modal="false">
    <el-form :model="form" :rules="rules" label-width="100px" ref="form">
      <el-form-item label="反馈分类" prop="workOrderType">
        <el-radio-group v-model="form.workOrderType">
          <el-radio :label="item.value" v-for="item in dict.PlatformWorkOrderType" :key="item.value">{{ item.displayName }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="紧急程度" prop="urgent">
        <el-radio-group v-model="form.urgent">
          <el-radio :label="item.value" v-for="item in dict.PlatformWorkOrderUrgent" :key="item.value">{{ item.displayName }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="所属项目" prop="projectId">
        <el-cascader
          style="width: 100%"
          :props="{value: 'id', label: 'name', children: 'child', emitPath: false}"
          filterable
          clearable
          :options="tree"
          v-model="form.projectId">
        </el-cascader>
      </el-form-item>
      <el-form-item label="联系人" prop="contacts">
        <el-input v-model="form.contacts" placeholder="请输入联系人姓名"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input v-model="form.phone" placeholder="请输入联系电话"></el-input>
      </el-form-item>
      <el-form-item label="问题描述" prop="contents">
        <el-input v-model="form.contents" placeholder="请输入问题描述" type="textarea" :autosize="{minRows: 3}"></el-input>
      </el-form-item>
      <el-form-item label="附件信息">
        <my-upload-file multiple v-model="files" :limit="5" :fileSize="8" :fileType="['png', 'jpg', 'jpeg', 'text', 'doc', 'docx', 'xls', 'xlsx']"/>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="submit" :loading="loading" type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import {onlineQuestionEdit} from '@/api/project'
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import MyUploadFile from '@/components/MyUploadFile'

export default {
  name: 'onlineQuestionEdit',
  components: {Treeselect, MyUploadFile},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    tree: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      form: {
        projectId: null,
        contents: '',
        contacts: '',
        phone: '',
        workOrderType: 1,
        urgent: 1,
      },
      files: [],
      loading: false,
      rules: {
        workOrderType: [{ required: true, message: '请选择', trigger: 'change' }],
        contents: [{ required: true, message: '请输入', trigger: 'blur' }],
        contacts: [{ required: true, message: '请输入', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入', trigger: 'blur' }],
        urgent: [{ required: true, message: '请选择', trigger: 'change' }],
        projectId: [{ required: true, message: '请选择', trigger: 'change' }],
      }
    }
  },
  computed: {
    ...mapGetters(['dict'])
  },
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.form = {
        contents: '',
        contacts: '',
        phone: '',
        workOrderType: 1,
        urgent: 1,
      }
      this.files = []
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.form.files = this.files.map(x => x.src)
          onlineQuestionEdit(this.form).then(res => {
            this.loading = false
            this.$message.success(res.message)
            this.$emit('refresh')
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
