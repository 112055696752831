<template>
  <el-dialog :title="title" :visible.sync="visible" :close-on-click-modal="false" :before-close="close" destroy-on-close width="50%">
    <el-form :model="form" ref="form" label-width="100px" :rules="rules">
      <el-form-item label="所属公司" prop="companyId" v-if="!form.id">
        <treeselect v-model="form.companyId" :options="companyTree" retryText="重试" :load-options="loadOptions" :normalizer="normalizer" placeholder="选择公司" />
      </el-form-item>
      <el-form-item label="项目名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="项目类型" prop="projectTypes">
        <el-select v-model="form.projectTypes" multiple placeholder="请选择" style="width: 100%">
          <el-option label="住宅" :value="1"></el-option>
          <el-option label="商铺" :value="2"></el-option>
          <el-option label="写字楼" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系电话" prop="contactNumber">
        <el-input v-model="form.contactNumber" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="地图">
        <my-map ref="myMap" @selectLocation="updateLocation"/>
      </el-form-item>
      <el-form-item label="省" prop="province">
        <el-input v-model="form.province" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="市" prop="city">
        <el-input v-model="form.city" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="区县" prop="area">
        <el-input v-model="form.area" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input v-model="form.address" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="状态" v-if="form.id">
        <el-radio-group v-model="form.status">
          <el-radio :label="item.value" :key="item.value" v-for="item in dict.ProjectEntityStatus">{{item.displayName}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否信托制">
        <vxe-switch open-label="是" close-label="否" :open-value="true" :close-value="false" v-model="form.isTrustSystem"></vxe-switch>
      </el-form-item>
      <el-form-item label="旧系统项目id" v-if="form.isTrustSystem">
        <el-input v-model="form.oldSystemId" placeholder="如果是信托制，请输入旧系统的项目id"></el-input>
      </el-form-item>
      <el-form-item label="信托制开始公开缴费时间" v-if="form.isTrustSystem">
        <el-date-picker v-model="form.trustOpenPayDateStart" placeholder="请选择" value-format="yyyy-MM-dd"></el-date-picker>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remarks" type="textarea" :autosize="{minRows: 2}" placeholder="请输入"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="submit" :loading="loading" type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {projectEdit, companyList} from '@/api/project'
import {chinaAreaData} from '@/utils/chinaAreaData'
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import { mapGetters } from 'vuex'
import MyMap from '@/components/MyMap'
import { dateFormat } from "@/utils/tools"

const initData = {
  contactNumber: '',
  companyId: null,
  name: '',
  projectTypes: [],
  province: '',
  city: '',
  area: '',
  remarks: '',
  address: '',
  // local: [],
  status: 1,
  isTrustSystem: false,
  mapPointX: '',
  mapPointY: '',
  oldSystemId: '',
  trustOpenPayDateStart: ''
}
export default {
  name: 'AreaEdit',
  components: {
    Treeselect,
    MyMap
  },
  data () {
    return {
      list: [], // 公司树
      chinaAreaData,
      form: {...initData},
      rules: {
        companyId: [{ required: true, trigger: "change", message: "请选择" }],
        projectTypes: [{ required: true, trigger: "change", message: "请选择" }],
        name: [{ required: true, trigger: "blur", message: "请输入" }],
        // address: [{ required: true, trigger: "blur", message: "请输入" }],
        // local: [{ required: true, trigger: "change", message: "请选择" }],
      }, // 表单规则
      visible: false, // 打开弹出
      title: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters(['dict', 'companyTree'])
  },
  methods: {
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    open (data = {}) {
      this.visible = true
      if (data.id) {
        for (const key in initData) {
          this.form[key] = data[key]
        }
        this.form.id = data.id
        // this.form.local = [data.province, data.city, data.area]
        if (this.form.isTrustSystem) {
          this.form.oldSystemId = data.oldSystemId || ''
          this.form.trustOpenPayDateStart = data.trustOpenPayDateStart ? dateFormat(new Date(data.trustOpenPayDateStart)) : ''
        }
        this.form.status = data.status
        this.title = '编辑' + this.form.name
      } else {
        this.title = '新增'
        if (this.companyTree.length === 0) {
          this.$store.dispatch('getCompanyList')
        }
      }
    },
    close () {
      this.visible = false
      this.form = {...initData}
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          projectEdit(this.form).then(res => {
            this.loading = false
            this.$message.success(res.message)
            this.$emit('refresh')
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    updateLocation(Addr) {
      console.log('位置信息：', Addr)
      this.form.province = Addr.province
      this.form.city = Addr.city
      this.form.area = Addr.district
      this.form.address = Addr.address
      this.form.mapPointX = Addr.lat // 纬度
      this.form.mapPointY = Addr.lng // 经度
    },
  }
}
</script>

<style scoped lang="scss">
.amap-demo {
  height: 15rem;
  margin-top: 0.3rem;
}

</style>
