<template>
  <div>
    <my-search :items="searchItems" @clear="clear" @search="search">
      <el-button type="primary" slot="btn" @click="visible = true" v-hasPermi="['project:roles:add']">新增</el-button>
    </my-search>
    <my-table :columns="columns" ref="myTable" url="/SystemRole/QuerySystemRolePageList">
      <vxe-table-column title="操作" width="220px">
        <template #default="{row}">
          <el-button type="text" @click="edit(row)" v-hasPermi="['project:roles:edit']">编辑</el-button>
          <el-button type="text" @click="del(row)" v-hasPermi="['project:roles:del']">删除</el-button>
          <el-button type="text" @click="rolesCopy(row, 'roles')" v-hasPermi="['project:roles:copyRoles']">复制角色</el-button>
          <el-button type="text" @click="rolesCopy(row, 'authorization')" v-hasPermi="['project:roles:copyAuthorization']">复制权限</el-button>
        </template>
      </vxe-table-column>
    </my-table>
    <roles-edit :visible.sync="visible" :companyTree="companyTree" :items.sync="items" @refresh="refresh"/>
    <roles-copy :visible.sync="visibleCopy" :items.sync="items" @refresh="refresh"/>
  </div>
</template>

<script>
import {rolesDel} from '@/api/system'
import {companyList} from '@/api/project'
import { mapGetters } from 'vuex'
import RolesEdit from './components/RolesEdit'
import RolesCopy from './components/RolesCopy'
export default {
  name: 'roles',
  components: {RolesEdit, RolesCopy},
  data () {
    return {
      queryData: {
        // companyId: ''
      },
      columns: [
        {
          label: '角色名称',
          prop: 'name'
        },
        {
          label: '公司名称',
          prop: 'companyName'
        },
        {
          label: '状态',
          prop: 'statusName'
        },
        {
          label: '备注',
          prop: 'remarks'
        },
      ],
      visible: false,
      visibleCopy: false,
      items: {},
      // companyTree: []
    }
  },
  computed: {
    ...mapGetters(['companyTree']),
    searchItems () {
      const {companyTree} = this
      return [
        {
          type: 'selectTree',
          label: '选择公司：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions
        },
      ]
    }
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
  },
  methods: {
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    edit (row) {
      this.items = row
      this.visible = true
    },
    refresh () {
      this.$refs.myTable.refresh(this.queryData)
    },
    del (row) {
      this.$confirm(`是否删除<span style="color: #da251c">${row.name}</span>`, '提示', {
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        rolesDel({id: row.id}).then(res => {
          this.$message.success(res.message)
          this.$refs.myTable.refresh()
        })
      })
    },
    rolesCopy (row, type) {
      this.items = {...row, ...{copyType: type}}
      this.visibleCopy = true
    }
  }
}
</script>

<style scoped lang="scss">
.flex-h {
  align-items: flex-start;
}
</style>
