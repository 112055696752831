<template>
  <el-dialog title="短信模板" :visible.sync="visible" :before-close="close" :close-on-click-modal="false">
    <el-form :model="form" ref="form" :rules="rules" label-width="100px">
      <el-form-item label="所属项目">
        <el-input v-model="form.projectName" placeholder="请选择" :readonly="true">
          <el-button slot="append" @click="openProject">请选择项目</el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="模板类型" prop="templateType">
        <el-select v-model="form.templateType" placeholder="请选择" style="width: 100%" @change="change">
          <el-option v-for="item in dict.SMSTemplateType" :key="item.value" :label="item.displayName" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="模板内容" prop="content">
        <el-input v-model="form.content" placeholder="请输入" type="textarea" :autosize="{ minRows: 3}"></el-input>
        <div>模板共计
          <span style="color: #da251c">{{form.content.length}}</span>字，
          预计<span style="color: #da251c">{{getNum()}}</span>
          条短信(注：此处短信数量只做参考，扣除短信数量以实际发送为准)
        </div>
      </el-form-item>
      <el-form-item label="备注信息">
        <el-input v-model="form.notes" placeholder="请输入" type="textarea" :autosize="{ minRows: 3}"></el-input>
      </el-form-item>
      <el-form-item label="">
        <div>1.短信发送内容只会替换指定的关键字:
          <span style="color: #da251c">
            {业主}、{房间编号}、{日期}、{金额}、{违约金}
          </span>
        </div>
        <div>2.短信模版请<span style="color: #da251c">不要使用空格</span>，以免造成短信浪费</div>
        <div>3.模版审核通过后可以先发送一条至自己手机上检测是否无误</div>
        <div>4.短信按条数收费，<span style="color: #da251c">65字左右一条，超过会按几条收费</span>，请尽量简洁。</div>
        <div>5.已审核模版修改后将会变为未审核状态</div>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确定</el-button>
    </div>
    <area-select :visible.sync="visibleArea" @select="select"></area-select>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import {smsTemplate} from '@/api/sms'
import AreaSelect from '@/views/project/components/AreaSelect'

export default {
  name: 'SmsTemplateEdit',
  components: {
    AreaSelect
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      visibleArea: false,
      loading: false,
      form: {
        content: '尊敬的业主{房间编号}-{业主},截止{日期},您欠费{金额}元,请到物业服务中心缴纳。谢谢合作!'
      },
      rules: {
        templateType: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        title: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请选择', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['dict'])
  },
  watch: {
    visible (val) {
      if (val) {
        this.form = this.items.id > 0 ? {
          templateType: this.items.templateType,
          title: this.items.title,
          content: this.items.content,
          notes: this.items.notes,
          id: this.items.id,
          projectName: this.items.projectName,
          projectId: this.items.projectId
        } : {
          templateType: '',
          title: '',
          content: '尊敬的业主{房间编号}-{业主},截止{日期},您欠费{金额}元,请到物业服务中心缴纳。谢谢合作!',
          notes: '',
          projectId: '',
          projectName: ''
        }
      }
    }
  },
  mounted () {},
  methods: {
    openProject () {
      this.visibleArea = true
    },
    select (item) {
      this.form.projectId = item.id
      this.form.projectName = item.name
    },
    getNum () {
      const {form} = this
      let num = form.content?.length / 65
      return Math.ceil(num)
    },
    change () {
      let item = this.dict.SMSTemplateType.find(x => x.value == this.form.templateType)
      this.form.content = item.desc || ''
    },
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
      this.form = {
        content: ''
      }
      this.$refs.form.resetFields()
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          smsTemplate(this.form).then(res => {
            this.$message.success(res.message)
            this.loading = false
            this.$emit('refresh')
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
