<template>
  <el-dialog :title="title" append-to-body width="70%" fullscreen :visible.sync="visible" :close-on-click-modal="false" destroy-on-close :before-close="close">
    <el-form :model="form" label-width="100px" :rules="rules" ref="form" :disabled="disabled">
      <el-row>
        <el-col :span="6">
          <el-form-item label="所属公司" prop="companyId">
            <treeselect
              @select="changeCompany"
              :disabled="!!form.id"
              v-model="form.companyId"
              :searchable="false"
              :options="companyTree"
              :load-options="loadOptions"
              :normalizer="normalizer"
              no-results-text="无搜索结果"
              no-children-text="无子节点"
              :show-count="true"
              no-options-text="无可用选项"
              placeholder="请选择">
            </treeselect>
          </el-form-item>
          <el-form-item label="角色名称" prop="name">
            <el-input placeholder="请输入" v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="备注说明">
            <el-input type="textarea" v-model="form.remarks" placeholder="请输入" :autosize="{minRows: 3}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="18">
          <el-form-item label="权限分配">
            <div class="flex-h menu" v-if="tree.length > 0" style="margin-bottom: 10px;">
              <el-radio-group v-model="activeName" @input="changeTab">
                <el-radio-button v-for="item in tree" :key="item.id" :label="item.functionId">{{ item.name }}</el-radio-button>
              </el-radio-group>
            </div>
            <div style="margin-bottom: 10px;">
              <el-button type="primary" @click="checkAll = true">全选</el-button>
              <el-button type="primary" v-if="!form.id" @click="checkAll = false">取消全选</el-button>
              <el-button type="primary" v-if="form.id" @click="changeCompany">重置默认</el-button>
              <el-checkbox style="margin-left: 10px;" v-model="menuCheckStrictly" @change="handleCheckedTreeConnect($event)">父子联动</el-checkbox>
            </div>
            <vxe-table
              border
              ref="xTable"
              size="small"
              :data="treeData.filter(x => x.functionId === activeName)"
              :height="clientHeight + 'px'"
              :row-config="{isCurrent: true, keyField: 'id'}"
              empty-text="暂无数据"
              :tree-config="{children: 'child', reserve: true, trigger: 'row', indent: 25, expandAll: true}">
              <vxe-table-column title="菜单目录" tree-node align="left" field="name" width="270px">
                <template #default="{row}">
                  <el-checkbox
                    @change="changeCheckEvent($event, row)"
                    v-model="row.isSelected"
                    :indeterminate="row.isIndeterminate"
                    :key="row.id">
                    {{row.name}}
                  </el-checkbox>
                </template>
              </vxe-table-column>
              <vxe-table-column title="按钮权限" align="left" field="hasPermissionGroup">
                <template #default="{row}">
                  <el-checkbox
                    v-model="item.isSelected"
                    @change="changeCheckEvent1($event, row)"
                    v-for="(item, index) in row.hasPermissionGroup"
                    :key="index">
                    {{item.buttonName}}
                  </el-checkbox>
                </template>
              </vxe-table-column>
            </vxe-table>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="submit" type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {editRoles, authorizationTree} from '@/api/system'
import {throttle, /* flatten */ getAllParentArr, getClientHeight} from '@/utils/tools'
import {companyList} from '@/api/project'

const initData = {
  companyId: null,
  name: '',
  remarks: '',
}
export default {
  name: 'RolesEdit',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    companyTree: {
      type: Array,
      default: []
    },
  },
  data () {
    return {
      form: {...initData},
      rules: {
        name: [{ required: true, trigger: "blur", message: "请输入" }],
        companyId: [{ required: true, trigger: "change", message: "请选择" }]
      },
      defaultProps: {
        children: "child",
        label: "name"
      },
      menuCheckStrictly: false,
      activeName: '2',
      checkAll: false,
      tree: []
    }
  },
  computed: {
    title () {
      return this.items.id > 0 ? '编辑' + this.items.name : '新增角色'
    },
    treeData () {
      return this.generator(this.tree)
    },
    checkedKeys () {
      if (this.treeData.length > 0) {
        return this.reduceDimension(this.treeData).filter(x => x.isSelected || x.isIndeterminate).map(x => x.functionId)
      }
      return []
    },
    clientHeight () {
      // dialog的title, padding, tab, form-item标题，确定，取消按钮
      return getClientHeight() - 60 - 90 - 30 - 30 - 60
    }
  },
  watch: {
    visible (val) {
      if (val && this.items.id) {
        this.form = this.items
        this.changeCompany()
      }
    }
  },
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
      this.$emit('update:tree', [])
      this.form = {initData}
      this.checkAll = false
      this.tree = []
      this.menuCheckStrictly = false
    },
    submit: throttle(function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          let data = {}
          for (const key in initData) {
            data[key] = this.form[key]
          }
          if (this.items.id > 0) {
            data.id = this.form.id
          }
          data.functionIds = this.checkedKeys
          editRoles(data).then(res => {
            this.$message.success(res.message || '操作成功')
            this.$emit('refresh')
            this.close()
          })
        }
      })
    }),
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    reduceDimension (arr = []) {
      let ret = []
      let toArr = arr => {
        arr.forEach(item => {
          toArr(item.child && item.child.length > 0 ? item.child : (item.hasPermissionGroup || []))
          ret.push(item)
        })
      }
      toArr(arr)
      return ret
    },
    generator (arr = []) {
      return arr.map(item => {
        const data = {
          ...item,
          isIndeterminate: false,
          isSelected: item.isSelected || this.checkAll
        }
        if (item.child && item.child.length > 0 && item.child[0].functionType !== 3) {
          data.child = this.generator(item.child)
        } else {
          data.hasPermissionGroup = data.child?.map(y => {
            return {
              buttonName: y.name,
              functionId: y.functionId,
              functionType: y.functionType,
              functionTypeName: y.functionTypeName,
              isSelected: this.checkAll ? this.checkAll : y.isSelected,
              sortNumber: y.sortNumber,
              status: y.status,
              statusName: y.statusName,
              id: y.id
            }
          })
          delete data.child
        }
        return data
      })
    },
    changeCheckEvent (value, row) {
      if (!this.menuCheckStrictly) {
        return
      }
      // 点击二级设置子全选
      if (row.child && row.child.length > 0) {
        row.isIndeterminate = false
        row.child.forEach(item => {
          console.log(11, row, item)
          item.isSelected = value
          if (item.hasPermissionGroup && item.hasPermissionGroup.length > 0) {
            item.hasPermissionGroup.forEach(x => {
              x.isSelected = value
            })
          } else {
            this.changeCheckEvent(value, item)
          }
        })
      } else {
        console.log(81812, row)
        // 点击三级设置子全选
        // let parentCheck = row.child.filter(x => x.isSelected)
        // row.isIndeterminate = parentCheck.length !== 0
        // row.isIndeterminate = value
        row.hasPermissionGroup.forEach(item => {
          item.isSelected = value
        })
      }
      this.setCheck(this.treeData, row, value)
    },
    setCheck (treeData, row, value) {
      // 点击子集设置父级全选,数组第一个是点击的本身，第二个是父级，第三个是父级的父级，依次类推
      const parentList = getAllParentArr(treeData, row.functionId, 'functionId')
      console.log(parentList, 12)
      parentList.forEach(item => {
        let parentCheck = []
        // 排除设置子集，按钮组
        if (!item.hasPermissionGroup && !item.isSelected) {
          parentCheck = item.child.filter(x => x.isSelected)
          // 选中的和child长度相同，判断全选
          if (parentCheck.length === item.child.length) {
            item.isSelected = true
            item.isIndeterminate = false
          } else {
            item.isSelected = false
            // item.isIndeterminate = true
            if (parentCheck.length !== 0) {
              item.isIndeterminate = true
            }
            if (parentCheck.length === 0) {
              item.isIndeterminate = false
            }
            // item.isIndeterminate = childNoChick.length !== 0
            console.log(item, 99, parentCheck)
          }
        } else {
          let childNoChick = item.child?.filter(x => x.isSelected) || []
          if (!item.hasPermissionGroup) {
            // 有子集不选中，设置最上级不选中
            if (childNoChick.length !== item.child?.length) {
              item.isIndeterminate = true
            } else {
              item.isIndeterminate = false
            }
            item.isSelected = value
          } else {
            item.isSelected = value
          }
        }
      })
    },
    changeCheckEvent1 (value, row) {
      if (!this.menuCheckStrictly) {
        return
      }
      console.log(row, 'aaaa')
      if (row.hasPermissionGroup.length === row.hasPermissionGroup.filter(x => x.isSelected).length) {
        row.isSelected = true
        row.isIndeterminate = false
      } else {
        row.isSelected = false
        row.isIndeterminate = row.hasPermissionGroup.filter(x => x.isSelected).length !== 0
      }
      this.setCheck(this.treeData, row, value)
    },
    // 树权限（父子联动）
    handleCheckedTreeConnect(value) {
      this.menuCheckStrictly = !!value
    },
    changeTab () {
      this.checkAll = false
      setTimeout(() => {
        this.$refs.xTable.setAllTreeExpand(true)
      }, 200)
    },
    changeCompany () {
      setTimeout(() => {
        this.checkAll = false
        authorizationTree({companyId: this.form.companyId, roleId: this.form.id || 0}).then(res => {
          this.tree = res.data
          this.changeTab()
        })
      }, 200)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .vue-treeselect--disabled {
  background-color: #F5F7FA;
  border-color: #E4E7ED;
  cursor: not-allowed;
  .vue-treeselect__single-value {
    color: #C0C4CC;
  }
}
</style>
