import Vue from 'vue'
import VXETablePluginElement from 'vxe-table-plugin-element'
import 'vxe-table-plugin-element/dist/style.css'
import {
  Header,
  VXETable,
  Icon,
  Column,
  Table,
  Footer,
  Edit,
  Export,
  Tooltip,
  Switch,
  // Input,
  // Grid
} from 'vxe-table'
// 先按需导入依赖的模块
Vue.use(VXETable)
Vue.use(Edit)
Vue.use(Export)
Vue.use(Header)
Vue.use(Icon)
Vue.use(Tooltip)
Vue.use(Column)
Vue.use(Footer)
Vue.use(Switch)
// Vue.use(Input)
// Vue.use(Grid)
// 最后安装核心库
Vue.use(Table)
VXETable.use(VXETablePluginElement)

Vue.prototype.$XPrint = VXETable.print