<!-- 周期类导入收费项修复 -->
<template>
  <el-tabs v-model="activeName" @tab-click="tabClick">
    <el-tab-pane label="周期类导入收费项修复" name="1">
      <div class="repair-box">
        <el-form :model="form" :rules="rules" label-width="100px" ref="form">
          <el-form-item label="选择项目" prop="projectId">
            <el-cascader
              style="width: 100%"
              :props="{value: 'id', label: 'name', children: 'child', emitPath: false}"
              filterable
              clearable
              :options="tree"
              v-model="form.projectId">
            </el-cascader>
          </el-form-item>
          <el-form-item label="上传文件" prop="importFilePath">
            <my-upload-file
              :isOss="false"
              upload-name="FormFile"
              singleFileType="other"
              v-model="form.importFilePath"
              :fileType="['xlsx', 'xls']">
            </my-upload-file>
          </el-form-item>
          <el-form-item label="对比文件">
            <el-button type="text" :disabled="disabled" @click="exportExcel">下载对比文件</el-button>
          </el-form-item>
          <el-form-item label="状态" v-if="form.reulstMsg">
            <el-tag :type="form.successed ? 'success' : ''">{{form.successed ? '成功' : '失败'}}</el-tag>
          </el-form-item>
          <el-form-item label="执行结果">
            <el-input v-model="form.reulstMsg" readonly type="textarea" :autosize="{minRows: 10, maxRows: 15}" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
        <div style="margin-left: 100px;">
          <el-button type="primary" :disabled="disabled" :loading="loading" @click="repair">修复数据</el-button>
          <el-button type="primary" :disabled="disabled" :loading="loading" @click="reset">重置</el-button>
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="道闸车位车辆初始日期" name="2">
      <div class="repair-box">
        <el-form :model="form" :rules="rules" label-width="100px" ref="form">
          <el-form-item label="选择项目" prop="projectId">
            <el-cascader
              style="width: 100%"
              :props="{value: 'id', label: 'name', children: 'child', emitPath: false}"
              filterable
              clearable
              :options="tree"
              v-model="form.projectId">
            </el-cascader>
          </el-form-item>
          <el-form-item label="上传文件" prop="importFilePath">
            <my-upload-file
              :isOss="false"
              upload-name="FormFile"
              singleFileType="other"
              v-model="form.importFilePath"
              :fileType="['xlsx', 'xls']">
            </my-upload-file>
          </el-form-item>
          <el-form-item label="对比文件">
            <el-button type="text" :disabled="disabled" @click="exportExcel">下载对比文件</el-button>
          </el-form-item>
          <el-form-item label="状态" v-if="form.reulstMsg">
            <el-tag :type="form.successed ? 'success' : ''">{{form.successed ? '成功' : '失败'}}</el-tag>
          </el-form-item>
          <el-form-item label="执行结果">
            <el-input v-model="form.reulstMsg" readonly type="textarea" :autosize="{minRows: 10, maxRows: 15}" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
        <div style="margin-left: 100px;">
          <el-button type="primary" :disabled="disabled" :loading="loading" @click="repairDate">修复数据</el-button>
          <el-button type="primary" :disabled="disabled" :loading="loading" @click="reset">重置</el-button>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import {downloadXlsx} from '@/utils/fileDownload'
import { dateFormat, getTreeToFlat, /* debounce */ } from "@/utils/tools"
import { mapGetters } from 'vuex'
import MyUploadFile from '@/components/MyUploadFile'
import {companyProjectTree} from '@/api/system'
import {repairData, dateRepairData} from '@/api/project'
const initData = {
  projectId: '',
  importFilePath: ''
}
export default {
  name: 'weekChargeRepair',
  components: {MyUploadFile},
  data () {
    return {
      form: {...initData},
      rules: {
        importFilePath: [{ required: true, trigger: "change", message: "请选择" }],
        projectId: [{ required: true, trigger: "change", message: "请选择" }],
      },
      tree: [],
      loading: false,
      activeName: '1'
    }
  },
  computed: {
    ...mapGetters(['project']),
    disabled () {
      return !this.form.importFilePath
    },
    flatProject () {
      return getTreeToFlat(this.tree)
    },
    checkedProject () {
      return this.flatProject.find(x => x.id === this.form.projectId) || {}
    }
  },
  mounted () {
    this.getTreeList()
  },
  methods: {
    getTreeList () {
      companyProjectTree({types: [1, 2, 3]}).then(res => {
        this.tree = res.data.map(x => {
          return {
            ...x,
            name: x.name + `(${x.companyAlias})`
          }
        })
      })
    },
    exportExcel () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '下载中，请稍等...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          let url = '/api/Import/CycleChargeItemContrast'
          if (this.activeName === '2') {
            url = '/api/Import/CarplaceRoadgateParkingDateRepairContrast'
          }
          downloadXlsx(url,
            {...this.form},
            `${this.checkedProject.name}修复数据导出${dateFormat(new Date(), 'yyyy-MM-dd')}`,
            () => loading.close()
          )
        }
      })
    },
    repair () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          repairData(this.form).then(res => {
            this.loading = false
            this.form.reulstMsg = res.data.reulstMsg
            this.form.successed = res.data.successed
            this.$message.success(res.message)
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    repairDate () {
      console.log(this.$refs)
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          dateRepairData(this.form).then(res => {
            this.loading = false
            this.form.reulstMsg = res.data.reulstMsg
            this.form.successed = res.data.successed
            this.$message.success(res.message)
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    reset () {
      this.form = {...initData}
    },
    tabClick () {
      this.form = {...initData}
    }
  }
}
</script>

<style scoped lang="scss">
.repair-box {
  width: 40%;
  margin: auto;
}
</style>
