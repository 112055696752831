import request from '@/utils/request'

// 根据公司获取打印模板
export const companyPrintTmp = params => {
  return request({
    url: '/PrintTemplateRelation/GetTemplatByCompany',
    method: 'get',
    params
  })
}

// 修改公司项目下模板信息
export const companyProjectPrintTmpEdit = data => {
  return request({
    url: '/PrintTemplateRelation/UpdateProjectTemplat',
    method: 'post',
    data
  })
}

// 获取打印模板
export const printTmpList = params => {
  return request({
    url: '/PrintTemplate/GetPrintTemplateList',
    method: 'get',
    params
  })
}

// 单户收费打印模板预览
export const chargePrintView = data => {
  return request({
    url: '/PrintTemplate/ChargePrintPreview',
    method: 'post',
    data
  })
}

// 欠费催收打印模板预览
export const arrearsPrintView = data => {
  return request({
    url: '/PrintTemplate/ArrearsPrintPreview',
    method: 'post',
    data
  })
}

// 公司打印模板删除
export const companyPrintDel = params => {
  return request({
    url: '/printTemplateRelation/remove',
    method: 'get',
    params,
    loading: true
  })
}

// 公司打印模板获取详情
export const companyPrintDetail = params => {
  return request({
    url: '/printTemplateRelation/detail',
    method: 'get',
    params
  })
}

// 打印模板编辑、新增
export const printTmpEdit = data => {
  return request({
    url: '/PrintTemplate' + (data.id ? '/updateTemplate' : '/add'),
    method: 'post',
    data,
    loading: true
  })
}

// 设置打印控件版本
export const printVersion = data => {
  return request({
    url: '/PrintTemplate/SetPrintVersion',
    method: 'post',
    data,
    loading: true
  })
}

// 获取打印控件版本
export const getPrintVersion = params => {
  return request({
    url: '/PrintTemplate/GetPrintVersion',
    method: 'get',
    params
  })
}