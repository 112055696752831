<template>
  <el-dialog title="选择项目" :visible.sync="visible" :close-on-click-modal="false" :before-close="close" width="40%" v-loading="loading">
    <!-- <div class="flex-h">
      <div>项目类型：</div>
      <el-checkbox-group v-model="projectTypes" @change="change">
        <el-checkbox :label="1">住宅</el-checkbox>
        <el-checkbox :label="2">商铺</el-checkbox>
        <el-checkbox :label="3">写字楼</el-checkbox>
      </el-checkbox-group>
    </div> -->
    <el-input placeholder="请输入内容" v-model="filterText" class="input-with-select">
      <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 120px;">
        <el-option label="公司名/编号" value="1"></el-option>
        <el-option label="项目名称" value="2"></el-option>
      </el-select>
      <div slot="append">
        <el-button @click="search">查询</el-button>
        <el-button @click="clear">重置</el-button>
      </div>
    </el-input>
    <el-checkbox style="margin: 20px 0;" @change="handleCheckedTreeNodeAll($event)">全选/全不选</el-checkbox>
    <el-checkbox label="全字匹配（精确查询）" style="margin-left: 10px;" v-model="queryData.isWholeMatch"></el-checkbox>
    <el-tree
      :data="tree"
      show-checkbox
      ref="menu"
      node-key="uid"
      :props="defaultProps"
    ></el-tree>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="submit" type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {projectTree} from '@/api/system'
import {randomString} from '@/utils/tools'
export default {
  name: 'projectModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      projectTypes: [1, 2, 3],
      tree: [],
      defaultProps: {
        children: "child",
        label: "name"
      },
      menuCheckStrictly: false,
      loading: false,
      charStr: 'abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789',
      filterText: '',
      select: '1',
      queryData: {
        isWholeMatch: false,
      }
    }
  },
  watch: {
    visible (value) {
      if (value) this.getList()
    }
  },
  computed: {
    treeList () {
      return this.reduceDimension(this.tree)
    },
    checkListProjectList () {
      const {checkList} = this
      let list = []
      checkList.forEach(item => {
        if (item.treeProjectType === 2) list.push(item)
      })
      return list
    },
    checkList () {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs.menu.getCheckedNodes()
      checkedKeys.unshift.apply(checkedKeys)
      return checkedKeys
    }
  },
  mounted () {},
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:tree', [])
    },
    change () {
      this.getList()
    },
    getList () {
      this.loading = true
      projectTree(this.queryData).then(res => {
        this.tree = this.setUid(res.data)
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    setUid (arr) {
      arr.forEach(el => {
        el.uid = randomString(10)
        el.child = el.child || []
        this.setUid(el.child || [])
      })
      return arr
    },
    // 树权限（全选/全不选）
    handleCheckedTreeNodeAll(value) {
      this.$refs.menu.setCheckedNodes(value ? this.treeList : [])
    },
    reduceDimension (arr) {
      let ret = []
      let toArr = arr => {
        arr.forEach(item => {
          toArr(item.child)
          ret.push(item)
        })
      }
      toArr(arr)
      return ret
    },
    submit () {
      this.$emit('result', this.checkListProjectList)
      this.close()
    },
    search () {
      if (this.select === '1') {
        this.queryData.companyNameOrAlias = this.filterText
        delete this.queryData.projectName
      } else {
        delete this.queryData.companyNameOrAlias
        this.queryData.projectName = this.filterText
      }
      this.getList()
    },
    clear () {
      this.queryData = {
        isWholeMatch: false
      }
      this.filterText = ''
      this.getList()
    }
  }
}
</script>

<style scoped lang="scss">

</style>
