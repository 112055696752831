
export const system = {
  menu: () => import('@/views/system/menu'), // 菜单
  configure: () => import('@/views/system/configure'), // 配置
  notice: () => import('@/views/system/notice/list'), // 公告管理
  menuAllot: () => import('@/views/system/menuAllot'), // 批量分配权限
  companyRoles: () => import('@/views/system/companyRoles'), // 批量公司角色权限管理
  logs: () => import('@/views/system/logs'), // 日志
  adminRoles: () => import('@/views/system/adminRoles'), // 角色管理
  adminStaff: () => import('@/views/system/adminStaff'), // 员工
  historyMessage: () => import('@/views/system/historyMessage'), // 客服会话记录
  carGate: () => import('@/views/system/carGate'), // 车场道闸
  warningManage: () => import('@/views/system/warningManage'), // 预警管理
}