<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="close" :close-on-click-modal="false" width="90%">
    <el-descriptions :column="3" border :label-style="{width: '15%'}" :content-style="{width: '18%'}">
      <el-descriptions-item v-for="item in columns" :key="item.prop" :label="item.label">{{detail[item.prop]}}</el-descriptions-item>
      <el-descriptions-item label="描述">
        <div v-html="items.contents"></div>
      </el-descriptions-item>
    </el-descriptions>
  </el-dialog>
</template>

<script>
import {dateFormat} from '@/utils/tools'
import {settlementDetail} from '@/api/system'
export default {
  name: 'OnlineDailySettlement',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      detail: {},
      columns: [
        {
          label: '清算日期',
          prop: 'settlementDate'
        },
        {
          label: '金额',
          prop: 'amount'
        },
        {
          label: '手续费',
          prop: 'fee'
        },
        {
          label: '状态',
          prop: 'statusName'
        },
        {
          label: '划款交易类型',
          prop: 'transactionTypeName'
        },
        {
          label: '重新划款标识',
          prop: 'repayTag'
        },
        {
          label: '重新划款日期',
          prop: 'repayDt'
        },
        {
          label: '附加手续费',
          prop: 'addnFee'
        },
        {
          label: '划款户名',
          prop: 'accountName'
        },
        {
          label: '划款账号',
          prop: 'accountNo'
        },
        {
          label: '划款成功时间',
          prop: 'successDate'
        },
        {
          label: '划拨失败状态描述',
          prop: 'failureDescription'
        },
        {
          label: '划款流水号',
          prop: 'serialNumber'
        },
        {
          label: '重新划款流水号',
          prop: 'repaySerialNumber'
        },
        {
          label: '原划款流水号',
          prop: 'origSerialNumber'
        },
        {
          label: '原划款时间',
          prop: 'repayOrigDate'
        },
        {
          label: '收款行',
          prop: 'receivingBank'
        }
      ]
    }
  },
  computed: {
    title () {
      return this.items.companyName + dateFormat(new Date(this.items.feeDate)) + '划账详情'
    }
  },
  watch: {
    visible (value) {
      if (value) {
        this.getList()
      }
    }
  },
  mounted () {},
  methods: {
    getList () {
      settlementDetail({settlementId: this.items.settlementId}).then(res => {
        this.detail = res.data
      })
    },
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
      this.detail = {}
    }
  }
}
</script>

<style scoped lang="scss">

</style>
