<template>
  <div>
    <my-search :items="searchItems" @clear="clear" @search="search" :values="queryData"></my-search>
    <my-table :columns="columns" ref="myTable" url="/ChatSession/QueryChatSessionsPager">
      <vxe-table-column title="操作" width="100">
        <template #default="{row}">
          <el-button type="text" @click="eye(row)" v-if="row.messageCount > 0">查看聊天记录</el-button>
        </template>
      </vxe-table-column>
    </my-table>
    <history-message-detail :visible.sync="visible" :items.sync="items"/>
  </div>
</template>

<script>
import {sessionMessage, servicesList} from '@/api/system'
import HistoryMessageDetail from './components/HistoryMessageDetail'
import { mapGetters } from 'vuex'

export default {
  name: 'historyMessage',
  components: { HistoryMessageDetail },
  data () {
    return {
      columns: [
        {
          label: '会话名称',
          prop: 'platFormUserName',
          minWidth: 300
        },
        {
          label: '客服名称',
          prop: 'operatorUserName'
        },
        {
          label: '开始时间',
          prop: 'createTime'
        },
        {
          label: '结束时间',
          prop: 'closedTime'
        },
        {
          label: '会话时长',
          prop: 'chatTimeSeconds',
          width: 80,
          operate: true,
          render: (h, {row}) => {
            return h('div', {}, this.formatSeconds(row.chatTimeSeconds))
          }
        },
        {
          label: '状态',
          prop: 'statusName'
        },
        {
          label: '消息数量',
          prop: 'messageCount'
        },
        {
          label: '评价星',
          prop: 'feedbackStars'
        },
        {
          label: '反馈标签',
          prop: 'feedbackFlagsObj'
        },
        {
          label: '反馈备注',
          prop: 'feedbackRemark'
        },
      ],
      visible: false,
      items: {},
      queryData: {
        hasMessages: true
      },
      peopleList: []
    }
  },
  computed: {
    ...mapGetters(['dict']),
    searchItems () {
      const {dict, peopleList} = this
      const feedbackStars = [
        {
          displayName: '1',
          value: 1
        },
        {
          displayName: '2',
          value: 2
        },
        {
          displayName: '3',
          value: 3
        },
        {
          displayName: '4',
          value: 4
        },
        {
          displayName: '5',
          value: 5
        }
      ]
      return [
        {type: 'input', label: '咨询用户：', name: 'customName'},
        {type: 'select', label: '客服：', name: 'customServiceId', options: peopleList, props: {label: 'name', value: 'id'}},
        {type: 'datePicker', label: '开始时间：', name: 'createStart'},
        {type: 'datePicker', label: '结束时间：', name: 'createEnd'},
        {type: 'select', label: '状态：', name: 'status', options: dict.ChatSessionStatus},
        {type: 'checkbox', text: '是否有聊天消息', name: 'hasMessages'},
        {type: 'select', label: '评价星：', name: 'feedbackStars', options: feedbackStars, multiple: true},
        {type: 'select', label: '评价反馈标签：', name: 'feedbackFlag', options: dict.FeedbackFlagType, props: {label: 'displayName', value: 'displayName'}}
      ]
    }
  },
  mounted () {
    this.getServicesList()
  },
  methods: {
    getServicesList () {
      servicesList().then(res => {
        this.peopleList = res.data.filter(x => x.isCustomerService).map(x => {
          return {
            ...x,
            name: x.nickName + '(' + x.name + ')'
          }
        })
      })
    },
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    clear () {
      this.$refs.myTable.reset()
    },
    // 秒数转化为时分秒
    formatSeconds (value) {
      if (value === null) {
        return ''
      }
      let secondTime = parseInt(value) // 秒
      let minuteTime = 0 // 分
      let hourTime = 0 // 小时
      // 如果秒数大于60，将秒数转换成整数
      if (secondTime > 60) {
        // 获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60)
        // 获取秒数，秒数取余，得到整数秒数
        secondTime = parseInt(secondTime % 60)
        // 如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
          // 获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60)
          // 获取小时后取余的分，获取分钟除以60取余的分
          minuteTime = parseInt(minuteTime % 60)
        }
      }
      let result = "" + parseInt(secondTime) + "秒"

      if (minuteTime > 0) {
        result = "" + parseInt(minuteTime) + "分" + result
      }
      if (hourTime > 0) {
        result = "" + parseInt(hourTime) + "小时" + result
      }
      return result
    },
    eye (row) {
      sessionMessage({sessionId: row.id}).then(res => {
        this.items = {
          msgList: res.data.reverse(),
          title: `${row.platFormUserName}与${row.operatorUserName}的对话，时长${this.formatSeconds(row.chatTimeSeconds)}`
        }
        if (res.data.length === 0) {
          this.$message.error('暂无聊天记录')
          return
        }
        this.visible = true
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
