<template>
  <div v-show="isShowPreview">
    <el-image
      class="w-h-0"
      lazy
      ref="imgPrview"
      :src="src"
      fit="cover"
      :preview-src-list="thumbs"
    />
  </div>
</template>

<script>
export default {
  name: 'PrviewImg',
  props: {
    thumbs: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      isShowPreview: false,
      src: ''
    }
  },
  methods: {
    startPreview (index) {
      this.src = this.thumbs[index]
      this.isShowPreview = true
      this.$refs.imgPrview.clickHandler()
    }
  }
}
</script>

<style scoped lang="scss">
.w-h-0 {
  width: 0px;
  height: 0px
}
</style>
