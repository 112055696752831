<template>
  <div>
    <my-search :items="searchItems" @search="search" @clear="clear">
      <el-alert :closable="false" type="warning" show-icon slot="alert">
        <div slot="title" style="line-height: 1.5">
          <span>短信发送条数：{{items.totalCount}}</span>
        </div>
      </el-alert>
      <el-button slot="btn" type="primary" @click="callFail" v-hasPermi="['sms:sendLogs:errorReturn']">发送失败回执</el-button>
    </my-search>
    <my-table
      :columns="columns"
      :custom="true"
      dataKey="pager"
      @custom="value => items = value"
      ref="myTable"
      url="/SMSSendDetails/QuerySMSSendDetailsPager">
    </my-table>
  </div>
</template>

<script>
import {sendSmsFailCall} from '@/api/sms'
import {companyList} from '@/api/project'
import { mapGetters } from "vuex"

export default {
  name: 'sendLogs',
  components: {},
  data () {
    return {
      columns: [
        {
          label: '公司名称',
          prop: 'companyName',
          width: 100
        },
        {
          label: '项目名称',
          prop: 'projectName',
          width: 100
        },
        {
          label: '模板类型',
          prop: 'typeName',
          width: 100
        },
        {
          label: '资源名',
          prop: 'resourceName',
          width: 100
        },
        {
          label: '手机号',
          prop: 'phone',
          width: 100
        },
        {
          label: '内容条数',
          prop: 'count',
          width: 100
        },
        {
          label: '发送时间',
          prop: 'createdTime',
          width: 140
        },
        {
          label: '发送状态',
          prop: 'sendStatusName',
          operate: true,
          width: 80,
          render: (h, {row}) => {
            let type = ''
            if (row.sendStatus) {
              type = {
                1: 'success', // 成功
                2: 'warning', // 失败
              }[row.sendStatus]
            }
            return h('el-tag', {
              props: {
                type: type
              }
            // eslint-disable-next-line no-nested-ternary
            }, row.sendStatusName ? row.sendStatusName : (row.sendStatus === 1 ? '成功' : '失败'))
          }
        },
        {
          label: '批次号',
          prop: 'groupCode',
          width: 150
        },
        {
          label: '发送内容',
          prop: 'content',
          minWidth: 300
        },
        {
          label: '报告状态',
          prop: 'reportStatusName',
          width: 100
        },
        {
          label: '报告描述',
          prop: 'reportDesc',
          minWidth: 150
        },
        {
          label: '报告时间',
          prop: 'reportTime',
          minWidth: 150
        },
      ],
      items: {},
      queryData: {},
      projectList: []
    }
  },
  computed: {
    ...mapGetters(['companyTree', 'dict']),
    searchItems () {
      const sendStatusList = [
        {displayName: '成功', value: '1'},
        {displayName: '失败', value: '2'},
      ]
      const {companyTree, projectList, dict} = this
      return [
        {
          type: 'selectTree',
          label: '公司名称/编号：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany,
          style: 'margin-right: 0'
        },
        {
          type: 'select',
          label: '',
          name: 'projectId',
          options: projectList
        },
        {type: 'input', label: '电话：', name: 'phone'},
        {type: 'input', label: '批次号：', name: 'groupCode'},
        {type: 'input', label: '资源名称：', name: 'resourceName'},
        {type: 'select', label: '发送状态：', name: 'sendStatus', options: sendStatusList},
        {type: 'select', label: '报告状态：', name: 'reportStatus', options: dict.SMSBackReportStatus},
        {type: 'datePicker', label: '开始时间：', name: 'startDate'},
        {type: 'datePicker', label: '结束时间：', name: 'endDate'},
      ]
    }
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
  },
  methods: {
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    refresh () {
      this.$refs.myTable.refresh(this.queryData)
    },
    callFail () {
      this.$prompt('请输入批次号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入',
        inputValidator: value => {
          if (!value) return '输入不能为空'
        },
      }).then(({ value }) => {
        sendSmsFailCall({groupCode: value}).then(res => {
          this.$message.success(res.message)
          this.refresh()
        })
      })
    },
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    changeCompany (data) {
      this.$store.dispatch('getCompanyDownProject', {companyId: data.id, containsClosed: true}).then(res => {
        this.projectList = res
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
