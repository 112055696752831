<template>
  <el-dialog :title="title" :visible.sync="visible" :close-on-click-modal="false" :before-close="close">
    <div class="tip">工单基础信息</div>
    <el-descriptions :column="5" direction="vertical" border :label-style="{width: '15%'}" :content-style="{width: '18%'}">
      <el-descriptions-item label="服务编号">{{ items.id }}</el-descriptions-item>
      <el-descriptions-item label="问题分类">{{ items.workOrderTypeName }}</el-descriptions-item>
      <el-descriptions-item label="状态">
        <el-tag :type="['', 'success', 'danger'][items.status - 1]">{{ items.statusName }}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="紧急程度">
        <el-tag :type="['info', '', 'warning', 'danger'][items.urgent - 1]">{{ items.urgentName }}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="提交时间">{{ items.createTime }}</el-descriptions-item>
      <el-descriptions-item label="联系人">{{ items.createName }}</el-descriptions-item>
      <el-descriptions-item label="电话">{{ items.phone }}</el-descriptions-item>
      <el-descriptions-item label="问题描述">{{ items.contents }}</el-descriptions-item>
    </el-descriptions>
    <div class="tip">工单处理信息</div>
    <my-table :data="items.tracks" :columns="columns" ref="myTable">
      <vxe-table-column title="内容">
        <template #default="{row}">
          <div class="flex-h">
            <div>{{ row.contents }}</div>
            <my-view-files
              style="margin: 0;"
              :custom-style="{width: '25px', height: '25px'}"
              :files="row.files.map(x => {return {src: x.filePath}})"/>
          </div>
        </template>
      </vxe-table-column>
    </my-table>
    <el-form :model="form" :rules="rules" label-width="100px" ref="form" v-if="items.canTrack">
      <el-form-item label="问题描述" prop="contents">
        <el-input v-model="form.contents" placeholder="请输入问题描述" type="textarea" :autosize="{minRows: 3}"></el-input>
      </el-form-item>
      <el-form-item label="附件信息">
        <my-upload-file multiple v-model="files" :limit="5" :fileSize="8" :fileType="['png', 'jpg', 'jpeg', 'text', 'doc', 'docx', 'xls', 'xlsx']"/>
      </el-form-item>
    </el-form>
    <div slot="footer" v-if="items.canClose || items.canTrack">
      <el-button type="danger" v-if="items.canClose" @click="finish">关闭工单</el-button>
      <el-button v-if="items.canTrack" @click="close">取消</el-button>
      <el-button v-if="items.canTrack" type="primary" @click="submit" :loading="loading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import MyViewFiles from '@/components/MyViewFiles'
import MyUploadFile from '@/components/MyUploadFile'
import {onlineQuestionFinish, onlineQuestionTrack} from '@/api/project'
import {throttle} from '@/utils/tools'
export default {
  name: 'OnlineQuestionDetail',
  components: {MyViewFiles, MyUploadFile},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      url: process.env.VUE_APP_BASE_IMG,
      form: {
        contents: ''
      },
      files: [],
      rules: {
        contents: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      loading: false,
      columns: [
        {
          label: '时间',
          prop: 'createTime',
          width: 140
        },
        {
          label: '操作人',
          prop: 'operateName',
          width: 100
        }
      ]
    }
  },
  computed: {
    title () {
      return this.items.id + '工单详情'
    }
  },
  mounted () {},
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.form = {
        contents: ''
      }
      this.files = []
    },
    finish: throttle(function() {
      this.$confirm('是否结束当前工单？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        onlineQuestionFinish({workOrderId: this.items.id}).then(res => {
          this.$message.success(res.message)
          this.$emit('refresh')
          this.close()
        })
      })
    }),
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const data = {
            workOrderId: this.items.id,
            ...this.form,
            files: this.files.map(x => x.src)
          }
          onlineQuestionTrack(data).then(res => {
            this.loading = false
            this.$message.success(res.message)
            this.$emit('refresh')
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.tip {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 10px 0;
}

// .tip p {
//   margin: 0;
// }
</style>
