<template>
  <div style="display: inline-block" v-loading="showLoading ? uploading: false">
    <div class="ele-upload-image">
      <!-- 图片列表 -->
      <slot>
        <!-- 多个上传文件展示 -->
        <ele-gallery
          :lazy="lazy"
          :remove-fn="remove"
          :size="size"
          :sliceSingle="true"
          type="image"
          :source="items.imgList.map(x => {return x.src})"
          />
        <ele-gallery
          :lazy="lazy"
          :remove-fn="remove"
          :size="size"
          :sliceSingle="true"
          type="video"
          :source="items.videoList.map(x => {return x.src})"
          />
        <ele-gallery
          :lazy="lazy"
          :remove-fn="removeOther"
          :size="size"
          :sliceSingle="true"
          type="other"
          :source="items.otherFileList.map(x => {return x.src})"
        />
        <!-- 单个上传文件展示 -->
        <ele-gallery
          :lazy="lazy"
          v-if="!multiple"
          :remove-fn="removeSigleFile"
          :size="size"
          :sliceSingle="true"
          :type="singleFileType"
          :source="value"
        />
      </slot>
      <!-- 上传组件 -->
      <el-upload
        ref="upload"
        :data="isOss ? oss : fileData"
        v-show="isShowUpload"
        :accept="fileType.join(',')"
        :action="action || url"
        :before-upload="beforeUpload"
        :disabled="uploading"
        v-loading.fullscreen.lock="uploading"
        element-loading-text="Loading"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.7)"
        :headers="headers"
        :limit="limit"
        :name="uploadName"
        :multiple="multiple"
        :on-change="change"
        :on-error="uploadError"
        :on-exceed="exceed"
        :on-success="uploadSuccess"
        :show-file-list="false"
        :style="{
          marginBottom: multiple && computedValues.length ? '20px' : '0px'
        }"
      >
      <slot name="content">
        <div v-loading="uploading" class="upload-border">
          <template>
            <div :style="{ width: size + 'px', height: size + 'px', lineHeight: size + 'px' }">
              <i class="el-icon-plus"></i>
            </div>
          </template>
        </div>
        <!-- 公共 -->
        <div class="el-upload__tip line" slot="tip" :style="{ width: size + 10 + 'px' }">
          请上传
          <b style="color: #F56C6C">{{fileType.join('/')}}</b>
          格式文件
          <template v-if="fileSize">
            ，且大小不超过
            <b style="color: #F56C6C">{{fileSize}}MB</b>
          </template>
        </div>
      </slot>
      </el-upload>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EleGallery from './eleGalley'
import {aliOssPolicy} from '@/api/login'
import {getLocalStorage, setLocalStorage} from '@/utils/auth'
import {throttle} from '@/utils/tools'
export default {
  name: 'myUploadFile',
  components: {
    EleGallery
  },
  props: {
    uploadName: {
      type: String,
      default: 'file'
    },
    // 值，回显
    value: {
      type: [String, Array],
      default: () => {
        return []
      }
    },
    // 图片显示大小
    size: {
      type: Number,
      default: 160
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 5
    },
    // 上传类型
    fileType: {
      type: Array,
      default: () => {
        return ['png', 'jpg', 'jpeg']
      }
    },
    // 图片懒加载
    lazy: {
      type: Boolean,
      default: false
    },
    // // 上传地址
    // action: {
    //   type: String,
    //   default: '/api/Common/UploadFile'
    // },
    // 文件个数显示,
    limit: {
      type: Number,
    },
    // 是否支持多选文件
    multiple: {
      type: Boolean,
      default: false
    },
    fileData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 是否走oss上传
    isOss: {
      type: Boolean,
      default: true
    },
    showLoading: {
      type: Boolean,
      default: false
    },
    // 拼接的url，域名
    returnUrl: {
      type: String,
      default: ''
    },
    // 单个上传文件类型，image, video, other
    singleFileType: {
      type: String,
      default: 'image'
    }
  },
  data () {
    return {
      // cropData: {},
      uploading: false,
      selectFileType: '',
      fileList: [],
      url: this.returnUrl || process.env.VUE_APP_BASE_IMG,
      oss: getLocalStorage('ossConfig') || {},
    }
  },
  computed: {
    // 多选文件上传，生成集合，包含图片文件，其他文件
    items () {
      const {value, multiple} = this
      let otherFileList = []
      let imgList = []
      let videoList = []
      if (multiple) {
        value.forEach(item => {
          let isImg = false
          let isVideo = false
          let fileExtension = ''
          if (item.src.lastIndexOf('.') > -1) {
            // 获取文件扩展名
            fileExtension = item.src.slice(item.src.lastIndexOf('.') + 1)
          }
          isImg = ['png', 'jpg', 'jpeg'].includes(fileExtension)
          isVideo = ['mp4', 'ogg', 'flv', 'avi', 'rmvb'].includes(fileExtension)
          if (isImg) {
            imgList.push(item)
          } else if (isVideo) {
            videoList.push(item)
          } else {
            otherFileList.push(item)
          }
        })
      }
      return {
        imgList,
        otherFileList,
        videoList
      }
    },
    ...mapGetters(['token']),
    // 上传头
    headers () {
      return {
        'Authorization': 'Bearer ' + this.token
      }
    },
    computedValues () {
      if (this.value) {
        if (typeof this.value === 'string') {
          return [this.value]
        } else {
          return [...this.value]
        }
      } else {
        return []
      }
    },
    isShowUpload () {
      return this.multiple ? true : this.computedValues.length === 0
    },
    successFiles () {
      return this.fileList.filter(file => file.status === 'success')
    },
    // 判断文件是视频文件还是图片文件或者其他文件
    // galleryType () {
    //   if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/rmvb'].includes(this.selectFileType)) {
    //     return 'video'
    //   } else if (['image/jpeg', 'image/jpg', 'image/png'].includes(this.selectFileType)) {
    //     return 'image'
    //   }
    //   return 'other'
    // },
    action () {
      return this.isOss ? this.oss.host : '/api/Common/UploadFile'
    }
  },
  mounted() {
    if (this.isOss) {
      this.getAliOss()
    }
  },
  methods: {
    getAliOss: throttle(function() {
      aliOssPolicy().then(res => {
        let oss = {...res.data}
        oss.success_action_status = '200'
        oss.OSSAccessKeyId = res.data.accessid
        delete oss.accessid
        this.oss = oss
        const ossConfig = {...oss}
        setLocalStorage('ossConfig', ossConfig)
      })
    }),
    // 上传前校检格式和大小
    beforeUpload (file) {
      const date = new Date().getTime()
      // 判断是否过期
      if (date - Number(this.oss.expire) > 0) {
        // 移除ossConfig并且重新请求
        localStorage.removeItem('ossConfig')
        this.getAliOss()
      } else {
        // key就是上传的文件的路径，这个是postobject的必须参数
        this.oss.key = this.oss.dir + file.uid + "/" + file.name
      }
      this.selectFileType = file.type
      let isImg = false
      if (this.fileType.length > 0) {
        let fileExtension = ''
        if (file.name.lastIndexOf('.') > -1) {
          // 获取文件扩展名
          fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1)
        }
        isImg = this.fileType.some(type => {
          if (file.type.indexOf(type) > -1) return true
          if (fileExtension && fileExtension.indexOf(type) > -1) return true
          return false
        })
      } else {
        isImg = file.type.indexOf('image') > -1
      }
      if (!isImg) {
        this.$message.error(`文件格式不正确, 请上传${(this.fileType || []).join('/')}格式文件!`)
        return false
      }
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize
        if (!isLt) {
          this.$message.error(`上传大小不能超过 ${this.fileSize} MB!`)
          return false
        }
      }
      this.uploading = true
      // loading暴露到外面，可以在自定义slot上传的时候loading
      this.$emit('loading', this.uploading)
      return true
    },
    change (file, fileList) {
      this.uploading = false
      this.fileList = fileList
    },
    // 文件个数超出
    exceed () {
      this.$message.error(`最多上传${this.limit}个文件`)
    },
    // 上传失败
    uploadError (err) {
      this.uploading = false
      this.$message.error('上传失败, 请重试')
      if (this.isOss) {
        this.getAliOss()
      }
      this.$emit('error', err)
    },
    // 上传成功回调
    uploadSuccess (response, file) {
      // oss上传没有返回值，只能依靠file的状态
      if (this.isOss) {
        if (file.status === 'success') {
          this.uploading = false
          this.$message.success('上传成功')
          let url = this.oss.key
          let fileName = file.name
          if (this.multiple) {
            if (Array.isArray(this.value)) {
              this.$emit('input', [...this.value, {src: this.url + url, name: fileName}])
            } else {
              this.$emit('input', [this.url + url])
            }
          } else {
            this.$emit('input', this.url + url)
          }
        } else {
          this.$message.error(res.message || '上传失败, 请重试')
          if (this.isOss) {
            this.getAliOss()
          }
        }
      } else {
        if (response.code === 200) {
          this.uploading = false
          this.$message.success('上传成功')
          let url = response.data.localPath
          let fileName = file.name
          if (this.multiple) {
            if (Array.isArray(this.value)) {
              this.$emit('input', [...this.value, {src: url, name: fileName}])
            } else {
              this.$emit('input', [url])
            }
          } else {
            this.$emit('input', url)
          }
        } else if (response.code === 402) {
          let itemMsg = JSON.parse(response.message)
          let msg = `<div>
            <div>${itemMsg.Message}</div>
            <div>登录时间：${itemMsg.LoginTime}</div>
            <div>登录IP：${itemMsg.LoginIp}(${itemMsg.IpAddress})</div>
            <div>登录浏览器：${itemMsg.DevicesName}</div>
          </div>`
          this.$confirm(msg, '系统提示', {
            confirmButtonText: '重新登录',
            showCancelButton: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            dangerouslyUseHTMLString: true,
            showClose: false,
            type: 'warning'
          }).then(() => {
            this.$store.dispatch('FedLogOut').then(() => {
              this.$router.replace('/login')
            })
          })
        } else {
          this.$message.error(response.message || '上传失败, 请重试')
          if (this.isOss) {
            this.getAliOss()
          }
        }
      }
    },
    // 移除事件
    remove (index) {
      if (this.multiple) {
        const data = [...this.items.imgList]
        data.splice(index, 1)
        this.$emit('input', [...data, ...this.items.otherFileList] || [])
      } else {
        this.$emit('input', null)
      }
    },
    removeOther (index) {
      if (this.multiple) {
        const data = [...this.items.otherFileList]
        data.splice(index, 1)
        this.$emit('input', [...data, ...this.items.imgList] || [])
      }
    },
    removeSigleFile () {
      this.$emit('input', '')
    }
  },
}
</script>

<style lang="scss" scoped>

.upload-border {
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
}
.ele-upload-image {
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
}
.ele-upload-image .el-loading-spinner {
  line-height: 1;
}
.ele-upload-image .el-icon-plus {
  vertical-align: middle;
}
.ele-upload-image .el-upload--picture-card {
  width: auto;
  height: auto;
  background: none;
  line-height: inherit;
}
.line {
  line-height: 1.5;
}
</style>
