<template>
  <div>
    <my-search :items="searchItems" @search="search" @clear="clear">
      <el-button type="primary" slot="btn" @click="visible = true" v-hasPermi="['system:adminStaff:add']">新增</el-button>
    </my-search>
    <my-table :columns="columns" ref="myTable" url="/SystemUser/List" auto-request>
      <div slot="statusName" slot-scope="{row}">
        <vxe-switch
          v-hasPermi="['system:adminStaff:updateStatus']"
          open-label="启用"
          close-label="禁用"
          :value="row.status"
          :close-value="2"
          @change="changeStatus($event, row)"
          :open-value="1">
        </vxe-switch>
        <div v-if="!permissions.includes('system:adminStaff:updateStatus')">{{ row.statusName }}</div>
      </div>
      <vxe-table-column title="操作" width="200px">
        <template #default="{row}">
          <el-button type="text" @click="edit(row)" v-hasPermi="['system:adminStaff:edit']">编辑</el-button>
          <el-button type="text" @click="resetPwd(row)" v-hasPermi="['system:adminStaff:resetPwd']">重置密码</el-button>
          <el-button type="text" @click="viewCompany(row)" v-show="row.authorizeCompanyNames.length > 0">查看授权公司</el-button>
        </template>
      </vxe-table-column>
    </my-table>
    <admin-staff-edit :visible.sync="visible" :items.sync="items" :roles="rolesList" :companys="companyList" @refresh="refresh"/>
    <authorize-company :visible.sync="visibleAuthorize" :items.sync="items"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {adminStaffUpdateStatus, adminRolesDownList, adminStaffResetPwd, authCompany} from '@/api/system'
import AdminStaffEdit from './components/AdminStaffEdit'
import authorizeCompany from './components/authorizeCompany'
export default {
  name: 'adminStaff',
  components: {AdminStaffEdit, authorizeCompany},
  data () {
    return {
      queryData: {},
      visibleAuthorize: false,
      visible: false,
      items: {},
      columns: [
        {
          label: '姓名',
          prop: 'name',
          width: '100px'
        },
        {
          label: '登录名',
          prop: 'loginName',
          width: '100px'
        },
        {
          label: '昵称',
          prop: 'nickName',
          width: '100px'
        },
        {
          label: '手机号/账号',
          prop: 'phone'
        },
        {
          label: '拥有角色',
          prop: 'ownerRoleNames',
          operate: true,
          render: (h, {row}) => {
            return h('div', {
            }, row.ownerRoleNames.join('，'))
          }
        },
        {
          label: '是否客服',
          prop: 'IsCustomerService',
          operate: true,
          render: (h, {row}) => {
            return h('div', {
            }, row.isCustomerService ? '是' : '否')
          }
        },
        {
          label: '状态',
          prop: 'statusName',
          operate: true,
        },
        {
          label: '最近一次登录IP',
          prop: 'lastLoginIp'
        },
        {
          label: '最近一次登录时间',
          prop: 'lastLoginTime'
        },
        {
          label: '最近一次登录设备',
          prop: 'loginDeviceTypeName'
        },
        {
          label: '创建时间',
          prop: 'createdTime'
        },
      ],
      rolesList: [],
      companyList: [], // 授权公司
    }
  },
  computed: {
    ...mapGetters(['dict', 'permissions']),
    searchItems () {
      const {dict, rolesList} = this
      return [
        {type: 'input', label: '姓名：', name: 'name'},
        {type: 'input', label: '登录名：', name: 'loginName'},
        {type: 'input', label: '手机号：', name: 'phone'},
        {type: 'select', label: '状态：', name: 'status', options: dict.EntityStatus},
        {type: 'select', label: '角色：', name: 'roles', multiple: true, options: rolesList, props: {label: 'name', value: 'id'}},
        {type: 'checkbox', text: '是否客服', name: 'isCustomerService'},
      ]
    }
  },
  mounted () {
    this.getRolesDownList()
    this.getAuthCompany()
  },
  methods: {
    getAuthCompany () {
      authCompany().then(res => {
        this.companyList = res.data
      })
    },
    getRolesDownList () {
      adminRolesDownList().then(res => {
        this.rolesList = res.data
      })
    },
    edit (row) {
      this.items = row
      this.visible = true
    },
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    refresh () {
      this.$refs.myTable.refresh(this.queryData)
    },
    changeStatus (value, row) {
      adminStaffUpdateStatus({id: row.id}).then(res => {
        this.$message.success(res.message)
        this.refresh()
      })
    },
    resetPwd (row) {
      this.$confirm(`你确定将<span style="color: #da251c">${row.name}</span>的密码重置吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        adminStaffResetPwd({id: row.id}).then(res => {
          this.$notify.success({
            title: '密码重置成功',
            dangerouslyUseHTMLString: true,
            duration: '100000',
            message: `${row.name}当前密码为<span style="color: #da251c">${res.data}</span>`,
          })
        })
      })
    },
    viewCompany (row) {
      this.items = row
      this.visibleAuthorize = true
    }
  }
}
</script>

<style scoped lang="scss">

</style>
