<template>
  <div>
    <my-search :items="searchItems" :values="queryData" @clear="clear" @search="search"></my-search>
    <my-table url="/OnlinePayment/GetDailyFeeList" :columns="columns" ref="myTable">
      <vxe-table-column title="操作">
        <template #default="{row}">
          <el-button type="text" @click="feeDetail(row)">项目费用明细</el-button>
          <el-button type="text" @click="settlementDetail(row)">划账详情</el-button>
        </template>
      </vxe-table-column>
    </my-table>
    <online-daily-detail :visible.sync="visible" :items.sync="items"/>
    <online-daily-settlement :visible.sync="visibleSettlement" :items.sync="items"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {companyList} from '@/api/project'
import {companyGetMerchart} from '@/api/system'
import OnlineDailyDetail from './components/OnlineDailyDetail'
import OnlineDailySettlement from './components/OnlineDailySettlement'
import {dateFormat} from '@/utils/tools'

export default {
  name: 'onlineDaily',
  components: {OnlineDailyDetail, OnlineDailySettlement},
  data () {
    return {
      queryData: {},
      columns: [
        {
          label: '所属公司',
          prop: 'companyName'
        },
        {
          label: '商户号',
          prop: 'merchartNumber'
        },
        {
          label: '当日总收入金额',
          prop: 'amount'
        },
        {
          label: '手续费',
          prop: 'addnFee'
        },
        {
          label: '退费金额',
          prop: 'refundAmount'
        },
        {
          label: '结余金额',
          prop: 'balanceAmount'
        },
        {
          label: '状态',
          prop: 'dailyFeeAccountStatusName'
        },
        {
          label: '费用日期',
          prop: 'feeDate',
          operate: true,
          width: 120,
          render: (h, {row}) => {
            return h('div', {
            }, dateFormat(new Date(row.feeDate)))
          }
        },
        {
          label: '说明',
          prop: 'describe'
        },
      ],
      merchartList: [],
      items: {},
      visible: false,
      visibleSettlement: false
    }
  },
  computed: {
    ...mapGetters(['dict', 'companyTree']),
    searchItems () {
      const {companyTree, dict, merchartList} = this
      return [
        {
          type: 'selectTree',
          label: '公司名称/编号：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany,
          style: 'margin-right: 0'
        },
        {type: 'select', label: '商户号：', name: 'merchartNumber', options: merchartList, multiple: true},
        {type: 'datePicker', label: '开始时间：', name: 'startTime'},
        {type: 'datePicker', label: '结束时间：', name: 'endTime'},
        {type: 'select', label: '状态：', name: 'status', options: dict.DailyFeeAccountStatus},
      ]
    }
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
  },
  methods: {
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    changeCompany (data) {
      this.queryData.merchartNumber = []
      companyGetMerchart({companyId: data.id}).then(res => {
        this.merchartList = res.data.map(x => {
          return {
            displayName: `${x.settlementBank}(${x.settlementBankNumber})`,
            value: x.merchartNumber,
          }
        })
      })
    },
    feeDetail (row) {
      this.visible = true
      this.items = row
    },
    settlementDetail (row) {
      this.visibleSettlement = true
      this.items = row
    },
  }
}
</script>

<style scoped lang="scss">

</style>
