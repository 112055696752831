<template>
  <el-dialog title="变更费用标准" :visible.sync="visible" :before-close="close" :close-on-click-modal="false" width="30%">
    <el-form :model="form" :rules="rules" ref="form" label-width="100px">
      <el-form-item label="变更类型" prop="feeTypeId">
        <el-select v-model="form.feeTypeId" placeholder="请选择费用类型">
          <el-option v-for="item in feeTypeList" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="submit" type="primary" :loading="loading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {changeFeeStandard, queryDownFeeType} from '@/api/project'
export default {
  name: 'changeFeeEdit',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      form: {
        feeTypeId: ''
      },
      rules: {
        feeTypeId: [{ required: true, trigger: "change", message: "请选择" }],
      },
      loading: false,
      feeTypeList: [],
    }
  },
  watch: {
    visible (value) {
      if (value) {
        this.getFeeType()
      }
    }
  },
  mounted () {},
  methods: {
    getFeeType () {
      queryDownFeeType({projectId: this.items.projectId}).then(res => {
        this.feeTypeList = res.data.map(x => {
          return {
            id: x.id,
            name: `${x.feeModeName}-${x.name}(${x.statusName})`
          }
        })
      })
    },
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
      this.form = {
        feeTypeId: ''
      }
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$confirm('确定变更费用标准吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.loading = true
            changeFeeStandard({
              projectId: this.items.projectId,
              feeStandardIds: this.items.feeStandardIds,
              ...this.form
            }).then(res => {
              this.$message.success(res.message)
              this.loading = false
              this.$emit('refresh')
              this.close()
            }).catch(() => {
              this.loading = false
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
