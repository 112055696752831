import request from '@/utils/request'

// 新增、编辑公司
export const companyEdit = data => {
  return request({
    url: '/Company/' + (data.id ? 'Update' : 'Add'),
    method: 'post',
    data,
    loading: true
  })
}
// 公司列表
export const companyList = data => {
  return request({
    url: '/Company/List',
    method: 'post',
    data,
    loading: true
  })
}
// 查询公司授权菜单
export const companyMenu = data => {
  return request({
    url: '/SystemFunction/GetCompanyFunction',
    method: 'post',
    data
  })
}
// 项目新增、编辑
export const projectEdit = data => {
  return request({
    url: '/Project' + (data.id > 0 ? '/Edit' : '/Add'),
    method: 'post',
    data,
    loading: true
  })
}
// 项目详情
export const projectDetail = params => {
  return request({
    url: '/Project/GetById',
    method: 'get',
    params
  })
}
// 公司新增、修改菜单
export const companyEditMenu = data => {
  return request({
    url: '/Company/CompanyFunction',
    method: 'post',
    data,
    loading: true
  })
}

// 项目列表
export const projectList = data => {
  return request({
    url: '/Project/List',
    method: 'post',
    data
  })
}

// 新增导入任务
export const importTaskAdd = data => {
  return request({
    url: '/import/add',
    method: 'post',
    data,
    loading: true
  })
}

// 删除任务
export const importTaskDel = params => {
  return request({
    url: '/import/delete',
    method: 'get',
    params,
    loading: true
  })
}

// 任务详情
export const importTaskDetail = params => {
  return request({
    url: '/Import/detail',
    method: 'get',
    params
  })
}

// 获取项目配置
export const projectConfig = params => {
  return request({
    url: '/ProjectBasicsConfig/GetProjectConfig',
    method: 'get',
    params
  })
}

// 配置修改
export const projectConfigEdit = data => {
  return request({
    url: '/ProjectBasicsConfig/SaveProjectConfig',
    method: 'post',
    data,
    loading: true
  })
}

export const companyPrintEdit = data => {
  return request({
    url: '/printTemplateRelation' + (data.id > 0 ? '/edit' : '/add'),
    method: 'post',
    data,
    loading: true
  })
}

// 项目支付配置新增/编辑
export const projectPayConfigEdit = data => {
  return request({
    url: '/OnlinePaymentConfig' + (data.id > 0 ? '/Edit' : '/Add'),
    method: 'post',
    data,
    loading: true
  })
}

// 项目支付配置修改状态
export const projectPayConfigStatus = params => {
  return request({
    url: '/OnlinePaymentConfig/ModifyStatus',
    method: 'get',
    params
  })
}

// 项目支付配置修改默认
export const projectPayConfigIsdefault = params => {
  return request({
    url: '/OnlinePaymentConfig/SetDefault',
    method: 'get',
    params
  })
}

// 项目支付配置查询
export const projectPayConfigGetDetail = params => {
  return request({
    url: '/OnlinePaymentConfig/GetById',
    method: 'get',
    params
  })
}

// 微信配置查询
export const wxConfig = params => {
  return request({
    url: '/Company/GetWeiXinConfig',
    method: 'get',
    params
  })
}

// 微信配置
export const wxConfigEdit = data => {
  return request({
    url: '/WeiXinConfig' + (data.id > 0 ? '/WeiXinConfigEdit' : '/WeiXinConfigAdd'),
    method: 'post',
    data,
    loading: true
  })
}

// 微信配置详情
export const wxConfigDetail = params => {
  return request({
    url: '/WeiXinConfig/GetWeiXinConfig',
    method: 'get',
    params
  })
}

// 微信配置下拉查询
export const wxConfigDown = data => {
  return request({
    url: '/WeiXinConfig/GetWeiXinConfigDownList',
    method: 'post',
    data
  })
}

// 获取微信菜单配置
export const getWxMenu = data => {
  return request({
    url: '/WeiXinConfig/GetMenu',
    method: 'post',
    data
  })
}

// 保存公众号菜单配置
export const saveWxMenu = data => {
  return request({
    url: '/WeiXinConfig/CreateMenu',
    method: 'post',
    data,
    loading: true
  })
}

// 保存公众号菜单配置json模式
// export const saveWxMenuJson = data => {
//   return request({
//     url: '/WeiXinConfig/CreateMenuFromJson',
//     method: 'post',
//     data,
//     loading: true
//   })
// }


// 生成微信密钥和token
export const wxDevCreateKeyToken = data => {
  return request({
    url: '/WeiXinConfig/BuildDevelopArgs',
    method: 'post',
    data
  })
}

// 获取项目下拉
export const projectDownList = params => {
  return request({
    url: '/Project/DownList',
    method: 'get',
    params
  })
}

// 新增停车场
export const parkingLotEdit = data => {
  return request({
    url: '/ParkingLot' + (data.id ? '/Edit' : '/Add'),
    method: 'post',
    data,
    loading: true
  })
}

// 批量/单个修改项目使用日期
export const editUseDate = data => {
  return request({
    url: '/Project/BatchEditUserTime',
    method: 'post',
    data,
    loading: true
  })
}

// 道闸编辑
export const parkingLotGate = data => {
  return request({
    url: '/ParkingLot/EditRoadGateConfig',
    method: 'post',
    data,
    loading: true
  })
}

// 获取固定分类
export const fixedType = params => {
  return request({
    url: '/FeeType/FixedFeeTypeSelect',
    method: 'get',
    params
  })
}

// 费用类型新增、编辑
export const feeTypeEdit = data => {
  return request({
    url: '/FeeType' + (data.id ? '/EditFeeType' : '/AddFeeType'),
    method: 'post',
    data,
    loading: true
  })
}

// 获取钱包配置
export const accountSettings = params => {
  return request({
    url: '/FeeType/GetResourcesAccountSettings',
    method: 'get',
    params
  })
}

// 删除
export const delFeeType = data => {
  return request({
    url: '/FeeType/RemoveFeeType',
    method: 'post',
    data
  })
}

// 反馈管理提交
export const onlineQuestionEdit = data => {
  return request({
    url: '/PlatformWorkOrder/Add',
    method: 'post',
    data,
    loading: true
  })
}

// 反馈管理详情
export const onlineQuestionDetail = params => {
  return request({
    url: '/PlatformWorkOrder/Details',
    method: 'get',
    params
  })
}

// 反馈管理关闭
export const onlineQuestionFinish = data => {
  return request({
    url: '/PlatformWorkOrder/Close',
    method: 'post',
    data
  })
}

// 反馈管理跟进
export const onlineQuestionTrack = data => {
  return request({
    url: '/PlatformWorkOrder/AddTrack',
    method: 'post',
    data,
    loading: true
  })
}

// 反馈管理分配
export const onlineQuestionAllot = data => {
  return request({
    url: '/PlatformWorkOrder/Assign',
    method: 'post',
    data,
    loading: true
  })
}

// 项目配置
export const areaConfigList = params => {
  return request({
    url: '/ProjectBasicsConfig/GetConfigByCompanyId',
    method: 'get',
    params
  })
}

// 批量修改项目配置
export const allAreaConfigEdit = data => {
  return request({
    url: '/ProjectBasicsConfig/BatchModifyConfiguration',
    method: 'post',
    data,
    loading: true
  })
}

// 根据公司获取项目
export const companyGetProjectTree = params => {
  return request({
    url: '/Project/GetCompanyProjectListByCompany',
    method: 'get',
    params
  })
}

// 支付配置应用到项目
export const payConfigUseProject = data => {
  return request({
    url: '/OnlinePaymentConfig/ApplyOtherProject',
    method: 'post',
    data,
    loading: true
  })
}

// 获取公众号二维码链接
export const createdWxQrcodeLink = data => {
  return request({
    url: '/WeiXinConfig/GetWeiXinQrLink',
    method: 'post',
    data,
    loading: true
  })
}

// 同步道闸资源信息
export const sycnProject = data => {
  return request({
    url: '/EntranceGuard/InitProjectResourceEM',
    method: 'post',
    data,
    loading: true
  })
}

// 获取道闸项目列表
export const entranceGuardProject = data => {
  return request({
    url: '/EntranceGuard/GetVillageList',
    method: 'post',
    data
  })
}

// 添加道闸
export const entranceGuardAdd = data => {
  return request({
    url: '/EntranceGuard/AddRelation',
    method: 'post',
    data
  })
}

// 根据项目查询下拉选择费用类型
export const queryDownFeeType = params => {
  return request({
    url: '/FeeType/QueryFeeTypeDownList',
    method: 'get',
    params
  })
}

// 变更费用标准
export const changeFeeStandard = data => {
  return request({
    url: '/FeeStandard/ChangeFeeStandardType',
    method: 'post',
    data
  })
}

// 刷新accessToken
export const refreshAccessToken = params => {
  return request({
    url: '/WeiXinConfig/RefreshWeiXinAccessToken',
    method: 'get',
    params
  })
}

// 删除项目导入数据
export const delImport = data => {
  return request({
    url: '/Import/RemoveImportData',
    method: 'post',
    data,
    loading: true
  })
}

// 周期类导入收费项修复数据
export const repairData = data => {
  return request({
    url: '/Import/CycleChargeItemRepair',
    method: 'post',
    data,
    loading: true
  })
}

// 道闸车位车辆初始日期修复
export const dateRepairData = data => {
  return request({
    url: '/Import/CarplaceRoadgateParkingDateRepair',
    method: 'post',
    data,
    loading: true
  })
}

// 合并费用标准
export const feeStandardMerge = data => {
  return request({
    url: '/FeeStandard/MergeFeeStandards',
    method: 'post',
    data,
    loading: true
  })
}

// 费用标准管理删除
export const feeStandardDel = params => {
  return request({
    url: '/FeeStandard/Delete',
    method: 'get',
    params,
    loading: true
  })
}

// 费用标准管理获取详情
export const feeStandardDetail = params => {
  return request({
    url: '/FeeStandard/Detail',
    method: 'get',
    params,
    loading: true
  })
}

// 费用标准管理编辑
export const feeStandardEdit = data => {
  return request({
    url: '/FeeStandard/Edit',
    method: 'post',
    data
  })
}

// 初始化项目车位车辆道闸
export const initCarGate = data => {
  return request({
    url: '/ParkinglotRoadgate/OnClickInitProjectCarplaceRoadgateQueue',
    method: 'post',
    data,
    loading: true
  })
}

// 根据公司获取项目
export const companyGetProject = params => {
  return request({
    url: '/Project/GetCompanyProjectsDownList',
    method: 'get',
    params
  })
}