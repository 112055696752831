import request from '@/utils/request'

// 短信充值
export const recharge = data => {
  return request({
    url: '/SMSAccount/Recharge',
    method: 'post',
    data,
    loading: true
  })
}

// 创建短信账户
export const createdSmsAccount = data => {
  return request({
    url: '/SMSAccount/Add',
    method: 'post',
    data,
    loading: true
  })
}

export const smsTemplate = data => {
  return request({
    url: '/SMSTemplate' + (data.id ? '/Edit' : '/Add'),
    method: 'post',
    data,
    loading: true
  })
}

// 更新短信模板
export const updateSmsTmpStatus = data => {
  return request({
    url: '/SMSTemplate/UpdateStatus',
    method: 'post',
    data,
    loading: true
  })
}

// 发送失败回执
export const sendSmsFailCall = data => {
  return request({
    url: '/SMSSendDetails/BatchSendOwnerSMSFail',
    method: 'post',
    data,
    loading: true
  })
}

// 短信模板删除
export const smsTemplateDel = data => {
  return request({
    url: '/SMSTemplate/Delete',
    method: 'post',
    data,
    loading: true
  })
}