<template>
  <div>
    <my-search @search="search" @clear="clear" :items="searchItems">
      <el-button slot="btn" type="primary" @click="visible = true" v-hasPermi="['project:wxConfig:add']">新增</el-button>
    </my-search>
    <my-table :columns="columns" url="/WeiXinConfig/GetWeiXinConfigPagerList" :query="{orderBy: ['id desc']}" ref="myTable">
      <vxe-table-column title="操作" width="240">
        <template #default="{row}">
          <el-button @click="edit(row)" type="text" v-hasPermi="['project:wxConfig:edit']">编辑</el-button>
          <el-button type="text" v-if="row.isEnableDeveloper" v-hasPermi="['project:wxConfig:wxMenu']" @click="wxMenu(row)">微信菜单</el-button>
          <el-button type="text" @click="refreshToken(row)" v-hasPermi="['project:wxConfig:refreshToken']">刷新accessToken</el-button>
        </template>
      </vxe-table-column>
    </my-table>
    <wx-config-edit :visible.sync="visible" :items.sync="items" @refresh="refresh"/>
  </div>
</template>

<script>
import WxConfigEdit from './components/WxConfigEdit'
import {wxConfigDetail, refreshAccessToken} from '@/api/project'
export default {
  name: 'wxConfig',
  components: {WxConfigEdit},
  data () {
    return {
      visible: false,
      items: {},
      columns: [
        {
          label: '配置名称',
          prop: 'configName'
        },
        {
          label: 'appId',
          prop: 'appId'
        },
        {
          label: '秘钥',
          prop: 'appSecret'
        },
        {
          label: '是否启动开发者模式',
          prop: 'isEnableDeveloper',
          operate: true,
          render: (h, {row}) => {
            return h('div', {}, row.isEnableDeveloper ? '是' : '否')
          }
        },
        {
          label: '开发者模式token',
          prop: 'developerToken'
        },
        {
          label: '首页标题',
          prop: 'homeTime'
        }
      ],
      queryData: {}
    }
  },
  computed: {
    searchItems () {
      return [
        {type: 'input', label: '配置名称关键字：', name: 'KeyWord'}
      ]
    }
  },
  mounted () {},
  methods: {
    search (query) {
      this.queryData = query
      this.$refs.myTable.refresh(query)
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    refresh () {
      this.$refs.myTable.refresh(this.queryData)
    },
    edit (row) {
      wxConfigDetail({id: row.id}).then(res => {
        this.items = res.data
        this.visible = true
      })
    },
    wxMenu (row) {
      this.$router.push({
        name: 'wxMenu',
        query: {
          configId: row.id
        }
      })
    },
    refreshToken (row) {
      this.$confirm('此操作是解决微信接口调用返回40001或42001错误', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        refreshAccessToken({configId: row.id}).then(res => {
          this.$message.success(res.message)
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
