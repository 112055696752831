<template>
  <el-dialog title="项目配置" :visible.sync="visible" :before-close="close" width="50%" :close-on-click-modal="false">
    <el-form :model="form" label-width="160px" ref="form" :rules="rules">
      <el-form-item label="唯一编码" prop="code">
        <el-input v-model="form.code" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="控件名" prop="label">
        <el-input v-model="form.label" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="控件类型" prop="type">
        <el-select v-model="form.type" placeholder="请选择" style="width: 100%">
          <el-option v-for="item in dict.ControlType" :label="item.displayName" :value="item.value" :key="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="配置类型" prop="configType">
        <el-select v-model="form.configType" placeholder="请选择" style="width: 100%">
          <el-option v-for="item in dict.ProjectBasicsConfigType" :label="item.displayName" :value="item.value" :key="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="默认值" prop="defaultValue">
        <el-input v-model="form.defaultValue" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="是否更新项目的初始值" prop="isUpdateProjectDefaultValue" v-if="form.id">
        <vxe-switch
          open-label="是"
          close-label="否"
          :open-value="true"
          :close-value="false"
          @change="updateProjectIdsName = ''"
          v-model="form.isUpdateProjectDefaultValue">
        </vxe-switch>
      </el-form-item>
      <el-form-item label="是否开放收费端配置" prop="isOpenChargeSets">
        <vxe-switch
          open-label="是"
          close-label="否"
          :open-value="true"
          :close-value="false"
          v-model="form.isOpenChargeSets">
        </vxe-switch>
      </el-form-item>
      <el-form-item label="项目" v-if="form.id && form.isUpdateProjectDefaultValue">
        <el-input v-model="updateProjectIdsName" placeholder="请选择更新项目，不选择则更新所有公司项目" readonly>
          <el-button slot="append" @click="openProject">请选择项目</el-button>
        </el-input>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
      <el-form-item label="排序" prop="sortNumber">
        <el-input-number :controls="false" :min="0" v-model="form.sortNumber" placeholder="请输入"></el-input-number>
      </el-form-item>
      <el-form-item label="收费端排序号" prop="chargeSortNumber">
        <el-input-number :controls="false" :min="0" v-model="form.chargeSortNumber" placeholder="请输入"></el-input-number>
      </el-form-item>
      <el-form-item label="可选值" prop="selectValues" v-if="[4,5].includes(form.type)">
        <div v-for="(item, index) in form.selectValues" :key="index" class="select-item">
          <el-input v-model="item.value" placeholder="选项名"/>
          <el-input v-model="item.key" placeholder="选项值"/>
          <div class="close-btn select-line-icon" @click="form.selectValues.splice(index, 1)">
            <i class="el-icon-remove-outline" />
          </div>
        </div>
        <div style="margin-left: 20px;">
          <el-button
            style="padding-bottom: 0"
            icon="el-icon-circle-plus-outline"
            type="text"
            @click="addSelectItem"
          >
            添加选项
          </el-button>
        </div>
      </el-form-item>
      <el-form-item label="说明">
        <el-input v-model="form.remarks" placeholder="请输入" type="textarea" :autosize="{minRows: 2}"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button @click="submit" type="primary" :loading="loading">确定</el-button>
    </div>
    <area-select :visible.sync="visibleArea" :selection="true" @select="select"></area-select>
  </el-dialog>
</template>

<script>
import {configureEdit} from '@/api/system'
import { mapGetters } from 'vuex'
import AreaSelect from '@/views/project/components/AreaSelect'

export default {
  name: 'ConfigureEdit',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    AreaSelect
  },
  data () {
    return {
      visibleArea: false,
      form: {
        code: '',
        label: '',
        type: '',
        remarks: '',
        isOpenChargeSets: false,
        chargeSortNumber: 0,
        selectValues: [
          {
            key: '值1',
            value: '选项1'
          },
          {
            key: '值2',
            value: '选项2'
          }
        ],
        defaultValue: '',
        sortNumber: 0,
        configType: '',
        // updateProjectIds: [],
        // isUpdateProjectDefaultValue: false
      },
      updateProjectIdsName: '',
      rules: {
        code: [{ required: true, trigger: "blur", message: "请输入" }],
        label: [{ required: true, trigger: "blur", message: "请输入" }],
        sortNumber: [{ required: true, trigger: "blur", message: "请输入" }],
        chargeSortNumber: [{ required: true, trigger: "blur", message: "请输入" }],
        type: [{ required: true, trigger: "change", message: "请选择" }],
        configType: [{ required: true, trigger: "change", message: "请选择" }],
      },
      loading: false,
    }
  },
  watch: {
    visible (value) {
      if (value) {
        this.form = this.items.id ? this.items : {
          code: '',
          label: '',
          type: '',
          remarks: '',
          selectValues: [
            {
              key: '值1',
              value: '选项1'
            },
            {
              key: '值2',
              value: '选项2'
            }
          ],
          defaultValue: '',
          configType: '',
          sortNumber: 0,
          isOpenChargeSets: false,
          chargeSortNumber: 0
        }
      }
    }
  },
  computed: {
    ...mapGetters(['dict'])
  },
  mounted () {},
  methods: {
    select (item) {
      this.updateProjectIdsName = item.names.join(',')
      this.form.updateProjectIds = item.ids
    },
    openProject () {
      this.visibleArea = true
    },
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
      this.updateProjectIdsName = ''
      this.form = {
        code: '',
        label: '',
        type: '',
        remarks: '',
        isOpenChargeSets: false,
        chargeSortNumber: 0,
        selectValues: [
          {
            key: '值1',
            value: '选项1'
          },
          {
            key: '值2',
            value: '选项2'
          }
        ],
        defaultValue: '',
        configType: '',
        sortNumber: 0
      }
      this.$refs.form.resetFields()
    },
    reset () {
      this.updateProjectIdsName = ''
      this.form.updateProjectIds = []
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const {form} = this
          let data = {
            code: form.code,
            label: form.label,
            type: form.type,
            configType: form.configType,
            defaultValue: form.defaultValue,
            sortNumber: form.sortNumber,
            remarks: form.remarks,
            chargeSortNumber: form.chargeSortNumber,
            isOpenChargeSets: form.isOpenChargeSets,
          }
          if (form.id) {
            data.id = form.id
            data.status = form.status || 1
            data.isUpdateProjectDefaultValue = form.isUpdateProjectDefaultValue
            if (form.isUpdateProjectDefaultValue) {
              data.updateProjectIds = form.updateProjectIds || []
            }
          }
          if ([4, 5].includes(this.form.type)) {
            data.selectValues = this.form.selectValues
          }
          this.loading = true
          configureEdit(data).then(res => {
            this.loading = false
            this.$message.success(res.message)
            this.$emit('refresh')
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    addSelectItem() {
      if (!this.form.selectValues) {
        this.form.selectValues = []
      }
      this.form.selectValues.push({
        key: '',
        value: ''
      })
    },
  }
}
</script>

<style scoped lang="scss">
.select-item {
  display: flex;
  border: 1px dashed #fff;
  box-sizing: border-box;
  & .close-btn {
    cursor: pointer;
    color: #f56c6c;
  }
  & .el-input + .el-input {
    margin-left: 4px;
  }
}
.select-item + .select-item {
  margin-top: 4px;
}
.select-line-icon {
  line-height: 32px;
  font-size: 22px;
  padding: 0 4px;
  color: #777;
}
</style>