<template>
  <div class="flex-h margin-t-b-20">
    <div class="grid-left">
      <slot></slot>
    </div>
    <div class="grid-right">
      <vxe-tooltip class="item" effect="dark" content="导出" v-if="showExport" placement="top">
        <el-button size="mini" circle icon="el-icon-download" @click="exportFile" />
      </vxe-tooltip>
      <vxe-tooltip class="item" effect="dark" :content="showSearch ? '隐藏搜索' : '显示搜索'">
        <el-button size="mini" circle icon="el-icon-search" @click="toggleSearch()" />
      </vxe-tooltip>
      <vxe-tooltip class="item" effect="dark" content="显隐列" placement="top">
        <el-popover
          placement="bottom"
          v-model="showPopover"
          trigger="click">
          <div v-for="(item, index) in filterColumns" :key="index">
            <el-checkbox v-model="item.visible">{{item.title}}</el-checkbox>
          </div>
          <div class="btn-group">
            <el-link @click="ok">确定</el-link>
            <el-link @click="reset">还原</el-link>
          </div>
          <el-button size="mini" style="margin-left: 10px" circle icon="el-icon-s-grid" slot="reference"></el-button>
        </el-popover>
      </vxe-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Toolbar',
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    showExport: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      filterColumns: [],
      showPopover: false,
    }
  },
  mounted() {
    this.bus.$on('setCol', this.bload)
  },
  methods: {
    bload (val) {
      this.filterColumns = val
    },
    ok () {
      this.showPopover = false
      this.bus.$emit('refreshCol')
    },
    reset () {
      this.showPopover = false
      this.bus.$emit('resetCol')
    },
    // 搜索
    toggleSearch() {
      this.$emit("update:showSearch", !this.showSearch);
    },
    exportFile () {}
  }
}
</script>

<style scoped lang="scss">
.btn-group {
  display: flex;
  font-size: 14px;
  margin-top: 10px;
  justify-content: space-around;
}
.grid-left {
  width: 70%;
  text-align: left;
}
.grid-right {
  width: 30%;
  text-align: right;
}
// .margin-t-b-20 {
//   margin: 20px 0;
// }
</style>
