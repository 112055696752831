import Vue from 'vue'
import {
  Tree,
  Button,
  Input,
  Container,
  Aside,
  Menu,
  Header,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Main,
  Badge,
  Icon,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Popover,
  Checkbox,
  Link,
  Card,
  Message,
  MessageBox,
  Loading,
  InputNumber,
  Row,
  Col,
  Select,
  Tabs,
  TabPane,
  Pagination,
  Dialog,
  DatePicker,
  Option,
  Form,
  FormItem,
  Tag,
  PageHeader,
  RadioGroup,
  Radio,
  Notification,
  ColorPicker,
  Table,
  TableColumn,
  CheckboxGroup,
  Image,
  Upload,
  Cascader,
  Tooltip,
  Descriptions,
  DescriptionsItem,
  Alert,
  RadioButton,
  Empty,
  OptionGroup,
  Collapse,
  CollapseItem
} from 'element-ui'
Vue.use(Button)
Vue.use(Tree)
Vue.use(Input),
Vue.use(Container),
Vue.use(Aside),
Vue.use(Menu),
Vue.use(Submenu),
Vue.use(MenuItem),
Vue.use(Header)
Vue.use(MenuItemGroup)
Vue.use(Main)
Vue.use(Badge)
Vue.use(Icon)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Popover)
Vue.use(Checkbox)
Vue.use(Link)
Vue.use(Card)
Vue.use(InputNumber)
Vue.use(Row)
Vue.use(Col)
Vue.use(Select)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(DatePicker)
Vue.use(Option)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tag)
Vue.use(PageHeader)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(ColorPicker)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(CheckboxGroup)
Vue.use(Image)
Vue.use(Upload)
Vue.use(Cascader)
Vue.use(Tooltip)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Alert)
Vue.use(RadioButton)
Vue.use(Empty)
Vue.use(OptionGroup)
Vue.use(Collapse)
Vue.use(CollapseItem)

Vue.use(Loading.directive)

Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 }
Vue.prototype.$message = Message
Vue.prototype.$notify = Notification
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$loading = Loading.service