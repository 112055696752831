/* 菜单 */
<template>
  <div>
    <my-search :items="searchItems" @clear="clear" @search="search" :values="queryData">
      <div slot="btn">
        <el-button type="primary" @click="visibleConfig = true" v-hasPermi="['system:menu:config']">常用菜单设置</el-button>
        <el-button type="primary" @click="visible = true" v-hasPermi="['system:menu:add']">新增</el-button>
      </div>
    </my-search>
    <vxe-table
      border
      style="margin-top: 20px;"
      row-id="id"
      :tree-config="{children: 'child', reserve: true}"
      :data="menu"
      align="center"
      size="small"
      :row-class-name="rowClass"
      empty-text="暂无数据">
      <vxe-table-column tree-node title="菜单名称" align="left" field="name" width="240"></vxe-table-column>
      <vxe-table-column title="权限标识" field="code"></vxe-table-column>
      <vxe-table-column title="菜单类型" field="functionTypeName" width="80"></vxe-table-column>
      <vxe-table-column title="路由" field="routeUrl"></vxe-table-column>
      <vxe-table-column title="api地址" field="apiRoute"></vxe-table-column>
      <vxe-table-column title="图标" field="iconName" width="60">
        <template #default="{row}">
          <i :class="row.iconName" style="font-size: 20px"></i>
        </template>
      </vxe-table-column>
      <vxe-table-column title="状态" field="statusName" width="60">
        <template #default="{row}">
          <el-tag :type="getType(row.status)">{{row.statusName}}</el-tag>
        </template>
      </vxe-table-column>
      <vxe-table-column title="菜单标签" field="functionFlagTypeName" width="80"></vxe-table-column>
      <vxe-table-column title="排序" sortable field="sortNumber" width="80"></vxe-table-column>
      <vxe-table-column title="备注" field="remarks"></vxe-table-column>
      <vxe-table-column title="操作" width="180px">
        <template #default="{row}">
          <el-button type="text" :loading="row.loading" @click="edit(row)" v-hasPermi="['system:menu:edit']">编辑</el-button>
          <el-button type="text" @click="del(row)" v-hasPermi="['system:menu:del']">删除</el-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <menu-edit :visible.sync="visible" :items.sync="items" :menu="menu" @refresh="getMenu"/>
    <menu-config :visible.sync="visibleConfig"/>
  </div>
</template>

<script>
import {menuDetail, menuDel} from '@/api/system'
import MenuEdit from './components/MenuEdit'
import MenuConfig from './components/MenuConfig'
import { mapGetters } from 'vuex'
export default {
  name: 'systemMenu',
  components: { MenuEdit, MenuConfig },
  data () {
    return {
      visibleConfig: false,
      visible: false, // 打开新增、编辑对话框
      items: {}, // 编辑详情
      queryData: {
        functionFlagTypes: [0, 1, 2, 3, 4, 5, 6]
      }
    }
  },
  computed: {
    ...mapGetters(['menu', 'dict']),
    searchItems () {
      const {dict} = this
      return [
        {type: 'select', label: '菜单标签类型：', name: 'functionFlagTypes', options: dict.FunctionFlagType, multiple: true}
      ]
    }
  },
  mounted () {
    this.getMenu()
  },
  methods: {
    getMenu () {
      this.$store.dispatch('getMenu', this.queryData)
    },
    getType (status) {
      if (status) {
        return {
          1: 'success',
          2: 'danger',
          3: 'info'
        }[status]
      }
    },
    edit (row) {
      row.loading = true
      menuDetail({id: row.id}).then(res => {
        this.items = res.data
        this.visible = true
        row.loading = false
      }).catch(() => {
        row.loading = false
      })
    },
    del (row) {
      this.$confirm(`确定删除<span style="color: #da251c">${row.name}</span>吗?`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        menuDel({id: row.id}).then(res => {
          this.$message.success(res.message)
          this.getMenu()
        })
      })
    },
    search (query) {
      this.queryData = query
      this.getMenu()
    },
    clear () {
      this.queryData = {}
      this.getMenu()
    },
    rowClass ({ row, rowIndex }) {
      console.log(row, rowIndex)
      if (row.functionFlagType === 1) {
        return 'row-red'
      }
      return ''
    },
  }
}
</script>

<style lang="scss">
.row-red {
  color: #da251c;
}
</style>
