<template>
  <div>
    <el-page-header style="margin-bottom: 10px" title="返回" @back="back" content="公告编辑"></el-page-header>
    <div class="flex-h">
      <div style="width: 70%">
        <el-form :model="form" label-width="120px" ref="form" :rules="rules">
          <el-row>
            <el-col :span="12">
              <el-form-item label="公告标题" prop="title">
                <el-input placeholder="请输入内容" v-model="form.title" size="medium">
                  <el-select slot="prepend" v-model="form.titleColor" placeholder="请选择" style="width: 120px">
                    <el-option label="黑色标题" value="black"></el-option>
                    <el-option label="红色标题" value="red"></el-option>
                    <el-option label="绿色标题" value="green"></el-option>
                    <el-option label="灰色标题" value="gray"></el-option>
                    <el-option label="蓝色标题" value="blue"></el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item label="公告类型" prop="noticeType">
                <el-select v-model="form.noticeType" placeholder="请选择" size="medium" style="width: 100%">
                  <el-option v-for="item in dict.NoticeType" :key="item.value" :label="item.displayName" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="发布状态" prop="publishStatus" v-if="query.id">
                <el-radio-group v-model="form.publishStatus">
                  <el-radio v-for="item in dict.PublishStatus" :label="item.value" :key="item.value">{{item.displayName}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="结束时间">
                <el-date-picker
                  v-model="form.endTime"
                  size="medium"
                  style="width: 100%"
                  placeholder="请选择"
                  type="datetime"
                  default-time="23:59:59"
                  value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="是否强制阅读">
                <vxe-switch v-model="form.isMustRed" open-label="是" close-label="否"></vxe-switch>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="通知对象" prop="receivingObject" size="medium">
                <el-select v-model="form.receivingObject" placeholder="请选择" style="width: 100%">
                  <el-option :value="1" label="物业用户"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="发布开始时间" prop="startTime">
                <el-date-picker
                  v-model="form.startTime"
                  size="medium"
                  type="datetime"
                  style="width: 100%"
                  placeholder="请选择"
                  :default-time="curTime"
                  value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="是否置顶">
                <vxe-switch v-model="form.isTop" open-label="是" close-label="否"></vxe-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="通知项目" prop="receivingObject">
            <span>{{resultData.map(x => x.name).join('、')}}</span>
            <el-button type="text" @click="visible = true">请选择</el-button>
          </el-form-item>
          <el-form-item label="公告内容" prop="content">
            <my-tinymce ref="myTinymce" v-model="form.content"/>
          </el-form-item>
          <div style="text-align:center">
            <el-button @click="back()">取消</el-button>
            <el-button type="primary" @click="submit" :loading="loading">保存</el-button>
          </div>
        </el-form>
        <project-modal :visible.sync="visible" @result="result"/>
      </div>
      <div style="width: 30%">
        <div class="iphone-text">
          <div class="content">
            <div class="iphone-title">{{ form.title }}</div>
            <div class="iphone-date">{{ form.startTime }}</div>
            <div v-html="form.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {noticeEdit, noticeDetail} from '@/api/system'
import { mapGetters } from 'vuex'
import ProjectModal from './projectModal'

export default {
  name: 'edit',
  components: {
    ProjectModal
  },
  data () {
    return {
      resultData: [],
      form: {
        title: '',
        titleColor: 'black',
        noticeType: '',
        isMustRed: false,
        receivingObject: '',
        startTime: '',
        endTime: '',
        isTop: false,
        content: '',
        publishStatus: 1,
      },
      loading: false,
      rules: {
        title: [{required: true, trigger: "blur", message: "请输入"}],
        content: [{required: true, trigger: "blur", message: "请输入"}],
        noticeType: [{required: true, trigger: "change", message: "请选择"}],
        receivingObject: [{required: true, trigger: "change", message: "请选择"}],
        startTime: [{required: true, trigger: "change", message: "请选择"}],
        publishStatus: [{required: true, trigger: "change", message: "请选择"}],
      },
      visible: false,
    }
  },
  computed: {
    query () {
      return this.$route.query || {}
    },
    ...mapGetters(['dict']),
    // 当前时分秒
    curTime () {
      let date = new Date()
      let hour = date.getHours()
      hour = hour > 9 ? hour : '0' + hour.toString()
      let minute = date.getMinutes()
      minute = minute > 9 ? minute : '0' + minute.toString()
      let second = date.getSeconds()
      return `${hour}:${minute}:${second}`
    },
  },
  mounted () {
    if (this.query.id) {
      this.getDetail()
    }
  },
  methods: {
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.form.projectIds = this.resultData.map(x => x.id)
          noticeEdit(this.form).then(res => {
            this.$message.success(res.message)
            this.loading = false
            this.back()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    getDetail () {
      const {query} = this
      noticeDetail({id: query.id}).then(res => {
        for (const key in this.form) {
          this.form[key] = res.data[key]
        }
        this.form.id = res.data.id
        this.resultData = res.data.projects.map(x => {
          return {
            name: x.projectName,
            id: x.projectId
          }
        })
      })
    },
    result (list) {
      this.resultData = list
    },
    back () {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
.iphone-text {
  margin-left: 20px;
  background: url('~@/assets/images/iphone.jpg');
  background-repeat: no-repeat;
  height: 700px;
  background-size: contain;
  width: 360px;
  position: relative;
  .iphone-title {
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .iphone-date {
    text-align: center;
    color: 14px;
    color: #999;
  }
  .content {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    bottom: 65px;
    overflow-y: auto;   //内容长度超过触发这个y的滚动条
    width: 280px;
    margin: auto;
  }
  ::v-deep p{
    line-height: 150%;
  }
  ::v-deep img{
    width: 100%;
    height:auto;
  }
  ::-deep table {
    width: auto;
  }
}
.iphone-text .content::-webkit-scrollbar{
  width: 0;   //将滚动条隐藏不显示
}
</style>
