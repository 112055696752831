<template>
  <el-dialog :title="title" :visible.sync="visible" :close-on-click-modal="false" :before-close="close" destroy-on-close width="30%">
    <el-form :model="form" label-width="100px" :rules="rules" ref="form">
      <el-form-item label="上级" prop="parentId">
        <treeselect
          v-model="form.parentId"
          :options="[{id: 0, name: '无'}, ...dept]"
          :load-options="loadOptions"
          :normalizer="normalizer"
          :class="{'disabled': disabled}"
          :disabled="disabled"
          placeholder="选择上级部门" />
      </el-form-item>
      <el-form-item label="公司全称" prop="fullName">
        <el-input v-model="form.fullName" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="公司简称" prop="name">
        <el-input v-model="form.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="负责人电话" v-if="!form.id">
        <el-input v-model="form.phone" placeholder="请输入"></el-input>
        <div sytle="color: #da251c">如不填则不会初始化公司领导默认账号</div>
      </el-form-item>
      <el-form-item label="状态" v-if="form.id">
        <el-radio-group v-model="form.status">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="2">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="公司编号" prop="alias" v-if="!form.id && form.parentId == 0">
        <el-input v-model="form.alias" placeholder="请输入5位数字公司编号"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remarks" placeholder="请输入" type="textarea" :autosize="{ minRows: 2}"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {companyEdit, companyList} from '@/api/project'
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"

export default {
  name: 'CompanyEdit',
  components: {
    Treeselect
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    },
    dept: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      form: {},
      rules: {
        parentId: [{ required: true, trigger: "change", message: "请选择" }],
        name: [{ required: true, trigger: "blur", message: "请输入" }],
        fullName: [{ required: true, trigger: "blur", message: "请输入" }],
        alias: [
          { required: true, trigger: "blur", message: "请输入" },
        ],
      },
      loading: false
    }
  },
  computed: {
    disabled () {
      return this.items.id > 0
    },
    title () {
      return this.items.id > 0 ? '编辑' + this.items.name : '新增'
    },
  },
  watch: {
    visible (val) {
      if (val) {
        this.form = this.items.id > 0 ? this.items : {
          name: '',
          phone: null,
          fullName: '',
          remarks: null,
          parentId: 0,
          alias: '',
          status: 1
        }
      }
    }
  },
  mounted () {},
  methods: {
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.nameAlias,
        children: node.child
      }
    },
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
      this.form = {}
      this.$refs.form.resetFields()
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    submit () {
      this.$refs.form.validate(val => {
        if (val) {
          this.loading = true
          companyEdit(this.form).then(res => {
            this.$message.success(res.message)
            this.loading = false
            this.$emit('refresh')
            this.close()
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.disabled {
  cursor: not-allowed;
}
</style>
