<template>
  <el-dialog :title="items.title" :visible.sync="visible" :before-close="close" :close-on-click-modal="false">
    <div class="msg-box">
      <div v-for="item in items.msgList" :key="item.id">
        <!-- 收费平台发送的消息 -->
        <div class="flex-h charge-send-logs-item" v-if="item.isCustom">
          <img class="avatar" src="@/assets/images/avatar.png"/>
          <el-tooltip class="item" effect="dark" :content="`发送时间: ${item.sendTime}`" placement="top">
            <div v-if="item.messageType === 1" class="msg-cell">
              <div>{{ item.contents }}</div>
            </div>
            <div v-if="item.messageType === 2">
              <el-image
                style="max-height: 100px;max-width: 100px;"
                :src="item.contents + '?x-oss-process=image/resize,m_lfit,h_100,w_100'"
                :preview-src-list="[item.contents]">
              </el-image>
            </div>
          </el-tooltip>
        </div>
        <!-- 运营平台发送的消息 -->
        <div class="flex-h operate-send-logs-item" v-if="!item.isCustom">
          <el-tooltip class="item" effect="dark" :content="`发送时间：${item.sendTime}`" placement="top">
            <div v-if="item.messageType === 1" class="msg-cell">
              <div>{{ item.contents }}</div>
            </div>
            <div v-if="item.messageType === 2">
              <el-image
                style="max-height: 100px;max-width: 100px;"
                :src="item.contents + '?x-oss-process=image/resize,m_lfit,h_100,w_100'"
                :preview-src-list="[item.contents]">
              </el-image>
            </div>
          </el-tooltip>
          <img class="avatar" src="@/assets/images/kf.png"/>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'historyMessageDetail',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {

    }
  },
  mounted () {},
  methods: {
    close () {
      this.$emit('update:visible', false)
      this.$emit('update:items', {})
    }
  }
}
</script>

<style scoped lang="scss">
.msg-box {
  height: 500px;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.4);
  background-clip: padding-box;
  border: 3px solid transparent;
  border-radius: 7px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
.charge-send-logs-item {
  margin: 10px 0 0 10px;
  .avatar {
    margin-right: 10px;
    width: 40px;
    height: 40px;
  }
  .msg-cell {
    font-size: 16px;
    background-color: #f5f5f5;
    max-width: 55%;
    padding: 8px 10px;
  }
}
.operate-send-logs-item {
  margin: 10px 10px 0 0;
  .avatar {
    margin-left: 10px;
    width: 40px;
    height: 40px;
  }
  .msg-cell {
    font-size: 16px;
    background-color: #f5f5f5;
    padding: 8px 10px;
    max-width: 55%;
  }
  justify-content: end;
}
.send-time {
  font-size: 12px;
}
</style>
