<template>
  <div>
    <my-search :items="searchItems" @search="search" @clear="clear">
      <el-button slot="btn" type="primary" @click="visible = true" v-hasPermi="['project:entranceGuard:add']">新增</el-button>
    </my-search>
    <my-table :columns="columns" ref="myTable" url="/EntranceGuard/QueryRelationPagerList">
      <vxe-table-column title="同步信息">
        <template #default="{row}">
          <el-popover
            placement="right"
            width="200"
            trigger="hover">
            <div v-if="row.syncMessageObj">
              <div>同步成功/失败楼栋数量：{{ row.syncMessageObj.bCount }}/<span style="color: #da241c">{{ row.syncMessageObj.bfCount }}</span></div>
              <div>同步成功/失败单元数量：{{ row.syncMessageObj.uCount }}/<span style="color: #da241c">{{ row.syncMessageObj.ufCount }}</span></div>
              <div>同步成功/失败房间数量：{{ row.syncMessageObj.rCount }}/<span style="color: #da241c">{{ row.syncMessageObj.rfCount }}</span></div>
              <div>同步时间：{{ row.syncMessageObj.time }}</div>
            </div>
            <i slot="reference" class="el-icon-view"></i>
          </el-popover>
        </template>
      </vxe-table-column>
      <vxe-table-column title="操作">
        <template #default="{row}">
          <el-button type="text" v-show="row.syncing">同步中</el-button>
          <el-button type="text" @click="sync(row)" v-show="!row.syncing" v-hasPermi="['project:entranceGuard:sync']">同步资源信息</el-button>
        </template>
      </vxe-table-column>
    </my-table>
    <entrance-guard-edit :visible.sync="visible" :tree="tree" @refresh="refresh"/>
  </div>
</template>

<script>
import {sycnProject, companyList} from '@/api/project'
import {companyProjectTree} from '@/api/system'
import EntranceGuardEdit from './components/EntranceGuardEdit'
import { mapGetters } from "vuex"

export default {
  name: 'entranceGuard',
  components: { EntranceGuardEdit },
  data () {
    return {
      visible: false,
      columns: [
        {
          label: '公司名称',
          prop: 'companyName',
          operate: true,
          render: (h, {row}) => {
            return h('div', {}, row.companyName + `(${row.companyAlias})`)
          }
        },
        {
          label: '项目名称',
          prop: 'projectName'
        },
        {
          label: '门禁道闸系统小区名称',
          prop: 'entranceGuardVillageName'
        },
      ],
      queryData: {},
      tree: [],
      projectList: []
    }
  },
  computed: {
    ...mapGetters(['companyTree']),
    searchItems () {
      const {companyTree, projectList} = this
      return [
        {
          type: 'selectTree',
          label: '公司名称/编号：',
          name: 'companyId',
          options: companyTree,
          normalizer: this.normalizer,
          loadOptions: this.loadOptions,
          cb: this.changeCompany,
          style: 'margin-right: 0'
        },
        {
          type: 'select',
          label: '',
          name: 'projectId',
          options: projectList
        },
        {type: 'input', label: '门禁小区：', name: 'villageName'},
      ]
    }
  },
  mounted () {
    if (this.companyTree.length === 0) {
      this.$store.dispatch('getCompanyList')
    }
    this.getTreeList()
  },
  methods: {
    getTreeList () {
      companyProjectTree({types: [1, 2, 3]}).then(res => {
        this.tree = res.data.map(x => {
          return {
            ...x,
            name: x.name + `(${x.companyAlias})`
          }
        })
      })
    },
    clear () {
      this.queryData = {}
      this.$refs.myTable.reset()
    },
    search (query) {
      this.queryData = query
      this.$refs.myTable.search(query)
    },
    refresh () {
      this.$refs.myTable.search(this.queryData)
    },
    sync (row) {
      sycnProject({projectId: row.projectId}).then(res => {
        this.$message.success(res.message)
        this.refresh()
      })
    },
    normalizer(node) {
      if (node.child && !node.child.length) {
        delete node.child;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.child
      }
    },
    loadOptions ({ action, parentNode, callback }) {
      if (action === 'LOAD_CHILDREN_OPTIONS') {
        companyList({parentId: parentNode.id}).then(res => {
          if (res.data.length > 0) {
            parentNode.child = res.data.map(x => {
              return {
                ...x,
                child: null
              }
            })
            callback()
          } else {
            callback(new Error('暂无下级'))
          }
        })
      }
    },
    changeCompany (data) {
      this.$store.dispatch('getCompanyDownProject', {companyId: data.id, containsClosed: true}).then(res => {
        this.projectList = res
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
