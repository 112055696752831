/* 公司 */
<template>
  <div>
    <my-search :items="searchItems" @search="search" @clear="clear" :show-columns="false">
      <el-button slot="btn" type="primary" @click="visible = true" v-hasPermi="['project:company:add']">新增</el-button>
    </my-search>
    <vxe-table
      border
      row-id="id"
      style="margin-top: 20px"
      :tree-config="{children: 'child', hasChild: 'hasChild', lazy: true, loadMethod: loadChildrenMethod}"
      :data="tableData"
      align="center"
      size="small"
      empty-text="暂无数据">
      <vxe-table-column
        type="seq"
        width="60"
        title="序号"
        fixed="left"
      ></vxe-table-column>
      <vxe-table-column tree-node align="left" title="公司名称" field="nameAlias"></vxe-table-column>
      <vxe-table-column title="全称" field="fullName"></vxe-table-column>
      <vxe-table-column title="状态" field="statusName">
        <template #default="{row}">
          <el-tag :type="getType(row.status)">{{row.statusName}}</el-tag>
        </template>
      </vxe-table-column>
      <vxe-table-column title="编号" field="id"></vxe-table-column>
      <vxe-table-column title="公司编号" field="alias"></vxe-table-column>
      <vxe-table-column title="电话" field="phone"></vxe-table-column>
      <vxe-table-column title="备注" field="remarks"></vxe-table-column>
      <vxe-table-column title="自定义logo">
        <template #default="{row}">
          <el-image
            v-if="row.logicLogo"
            style="max-width: 60px; max-height: 60px"
            :src="row.logicLogo + '?x-oss-process=image/resize,m_lfit,h_60,w_60'"
            :preview-src-list="[row.logicLogo]">
          </el-image>
        </template>
      </vxe-table-column>
      <vxe-table-column title="自定义系统标题" field="logicTitle"></vxe-table-column>
      <vxe-table-column title="操作" width="200px">
        <template #default="{row}">
          <el-button type="text" @click="edit(row)" v-hasPermi="['project:company:edit']">编辑</el-button>
          <el-button type="text" :loading="row.loading" @click="setMenu(row)" v-hasPermi="['project:company:menu']">菜单分配</el-button>
          <el-button type="text" :loading="row.loading" @click="setConfig(row)" v-hasPermi="['project:company:config']">业务信息配置</el-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <company-edit :visible.sync="visible" @refresh="getList" :items.sync="items" :dept="tableData"/>
    <company-authorization-menu :visible.sync="authorizationMenu" :items.sync="items" :list.sync="menuList"/>
    <company-config :visible.sync="visibleConfig" :items.sync="items" @refresh="getList"/>
  </div>
</template>

<script>
import {companyList, companyMenu, wxConfig} from '@/api/project'
import CompanyEdit from './components/CompanyEdit'
import { mapGetters } from 'vuex'
import CompanyAuthorizationMenu from './components/CompanyAuthorizationMenu'
import CompanyConfig from './components/CompanyConfig'
export default {
  name: 'company',
  components: {
    CompanyEdit,
    CompanyAuthorizationMenu,
    CompanyConfig
  },
  data () {
    return {
      authorizationMenu: false, // 打开授权菜单
      items: {}, // 数据集合
      tableData: [], // 表格数据
      visible: false, // 打开新增编辑对话框
      menuList: [], // 查询授权菜单
      visibleConfig: false
    }
  },
  computed: {
    ...mapGetters(['menu', 'dict']),
    searchItems () {
      return [
        {type: 'input', label: '公司名称/编号：', name: 'companyNameOrAlias'}
      ]
    }
  },
  mounted () {
    this.getCompany()
  },
  methods: {
    getCompany (params = {}) {
      companyList(params).then(res => {
        this.tableData = res.data.map(x => {
          return {
            ...x,
            hasChild: 'hasChild',
            loading: false,
            child: null,
            nameAlias: `${x.name}(${x.alias})`
          }
        })
      })
    },
    getType (status) {
      if (status) {
        return {
          1: 'success',
          2: 'danger',
          3: 'info'
        }[status]
      }
    },
    search (query) {
      this.getList(query)
    },
    clear () {
      this.getList()
    },
    getList (query = {}) {
      let params = {}
      params = Object.assign({}, params, query)
      this.getCompany(params)
    },
    edit (row) {
      this.items = Object.assign({}, row)
      this.visible = true
    },
    loadChildrenMethod ({row}) {
      return new Promise(resolve => {
        companyList({parentId: row.id}).then(res => {
          setTimeout(() => {
            if (res.data.length > 0) {
              let childs = res.data.map(x => {
                return {
                  ...x,
                  hasChild: 'hasChild',
                  loading: false,
                  child: null
                }
              })
              resolve(childs)
            } else {
              row.hasChild = false
            }
          }, 500)
        })
      })
    },
    setMenu (row) {
      row.loading = true
      companyMenu({companyId: row.id}).then(res => {
        row.loading = false
        this.authorizationMenu = true
        this.menuList = res.data
        this.items = Object.assign({}, row)
      }).catch(() => {
        row.loading = false
      })
    },
    wxConfig (row) {
      wxConfig({companyId: row.id}).then(res => {
        this.items = res.data || {}
        this.items.companyId = row.id
        this.visibleWx = true
      })
    },
    setConfig (row) {
      this.items = {
        id: row.id,
        logicLogo: row.logicLogo,
        logicTitle: row.logicTitle
      }
      this.visibleConfig = true
    }
  }
}
</script>

<style scoped lang="scss">

</style>